import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Switch,
  Chip,
  IconButton,
  Tooltip,
  Box,
  Tabs,
  Tab,
} from '@mui/material';
import {
  Visibility,
  VisibilityOff,
  ArrowForward,
  QrCode,
  Notifications,
} from '@mui/icons-material';
import { getSystemTrackings, markTrackingAsViewed } from '../services/systemTrackingService';
import FullScreenLayout from './layouts/FullScreenLayout';
import BarcodeSheetImport from './BarcodeSheetImport';

const Management = () => {
  const [trackings, setTrackings] = useState([]);
  const [showAll, setShowAll] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem('user'));
  
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  useEffect(() => {
    fetchTrackings();
  }, [showAll]);

  const fetchTrackings = async () => {
    try {
      const data = await getSystemTrackings(showAll);
      setTrackings(data);
    } catch (error) {
      console.error('Error fetching trackings:', error);
    }
  };

  const handleMarkAsViewed = async (trackingId, event) => {
    event.stopPropagation();
    try {
      await markTrackingAsViewed(trackingId);
      fetchTrackings();
    } catch (error) {
      console.error('Error marking tracking as viewed:', error);
    }
  };

  const getPriorityColor = (priority) => {
    switch (priority.toLowerCase()) {
      case 'urgent': return 'error';
      case 'high': return 'warning';
      case 'medium': return 'info';
      case 'low': return 'success';
      default: return 'default';
    }
  };

  const renderHeader = () => (
    <Box sx={{ 
      display: 'flex', 
      flexDirection: 'column',
      p: 2,
      borderBottom: 1,
      borderColor: 'divider',
      bgcolor: 'background.paper'
    }}>
      <Box sx={{ 
        display: 'flex', 
        justifyContent: 'space-between', 
        alignItems: 'center',
        mb: 2
      }}>
        <Typography variant="h4">System Management</Typography>
        {tabValue === 0 && (
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <Typography>Show All</Typography>
            <Switch
              checked={showAll}
              onChange={(e) => setShowAll(e.target.checked)}
            />
          </Box>
        )}
      </Box>

      <Tabs 
        value={tabValue} 
        onChange={handleTabChange}
        sx={{ borderBottom: 1, borderColor: 'divider' }}
      >
        <Tab 
          label="System Notifications" 
          icon={<Notifications />} 
          iconPosition="start"
        />
        <Tab 
          label="Barcode Sheet Import" 
          icon={<QrCode />} 
          iconPosition="start"
        />
      </Tabs>
    </Box>
  );

  const renderSystemTrackingsPanel = () => (
    <Box sx={{ p: 2, height: '100%', overflow: 'auto' }}>
      <TableContainer component={Paper} sx={{ boxShadow: 2, borderRadius: 2 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontWeight: 'bold', backgroundColor: 'primary.main', color: 'white' }}>Type</TableCell>
              <TableCell sx={{ fontWeight: 'bold', backgroundColor: 'primary.main', color: 'white' }}>Title</TableCell>
              <TableCell sx={{ fontWeight: 'bold', backgroundColor: 'primary.main', color: 'white' }}>Priority</TableCell>
              <TableCell sx={{ fontWeight: 'bold', backgroundColor: 'primary.main', color: 'white' }}>Created At</TableCell>
              <TableCell sx={{ fontWeight: 'bold', backgroundColor: 'primary.main', color: 'white' }}>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {trackings.map((tracking) => (
              <TableRow
                key={tracking.tracking_id}
                hover
                sx={{ cursor: 'pointer' }}
                onClick={() => navigate(`/management/tracking/${tracking.tracking_id}`)}
              >
                <TableCell>{tracking.type}</TableCell>
                <TableCell>{tracking.title}</TableCell>
                <TableCell>
                  <Chip
                    label={tracking.priority}
                    color={getPriorityColor(tracking.priority)}
                    size="small"
                  />
                </TableCell>
                <TableCell>
                  {new Date(tracking.created_at).toLocaleString()}
                </TableCell>
                <TableCell>
                  {!tracking.users_viewed?.includes(user.userId) && (
                    <Tooltip title="Mark as read">
                      <IconButton
                        size="small"
                        onClick={(e) => handleMarkAsViewed(tracking.tracking_id, e)}
                      >
                        <Visibility />
                      </IconButton>
                    </Tooltip>
                  )}
                  <Tooltip title="View Details">
                    <IconButton
                      size="small"
                      onClick={(e) => {
                        e.stopPropagation();
                        navigate(`/management/tracking/${tracking.tracking_id}`);
                      }}
                    >
                      <ArrowForward />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
  
  const renderBarcodeImportPanel = () => (
    <Box sx={{ p: 2, height: '100%', overflow: 'auto' }}>
      <BarcodeSheetImport />
    </Box>
  );

  const renderMainPanel = () => (
    <>
      {tabValue === 0 && renderSystemTrackingsPanel()}
      {tabValue === 1 && renderBarcodeImportPanel()}
    </>
  );

  return (
    <FullScreenLayout
      header={renderHeader()}
      mainPanel={renderMainPanel()}
    />
  );
};

export default Management; 