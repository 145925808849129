import React, { useState } from 'react';
import {
  Box,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Chip,
  IconButton,
  Menu,
  MenuItem,
  FormControlLabel,
  Switch,
  Typography,
  Tooltip,
} from '@mui/material';
import { format } from 'date-fns';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ShareIcon from '@mui/icons-material/Share';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { StyledTableContainer } from './StyledComponents';

const ProductionEntriesTab = ({
  productionEntries,
  handleProductionClick,
  handleActionClick,
  actionMenuAnchor,
  handleActionClose,
  handleShareClick,
  handleExportGoogleSheets,
  handleExportExcel,
  selectedCheckId,
}) => {
  const [showCancelled, setShowCancelled] = useState(false);
  
  // Filter out cancelled entries if showCancelled is false
  const filteredEntries = productionEntries.filter(entry => 
    showCancelled || entry.status !== 'cancelled'
  );
  
  // Count of hidden cancelled entries
  const hiddenCount = productionEntries.length - filteredEntries.length;

  return (
    <Box sx={{ p: 2 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <FormControlLabel
          control={
            <Switch
              checked={showCancelled}
              onChange={(e) => setShowCancelled(e.target.checked)}
              color="primary"
            />
          }
          label="Show Cancelled Entries"
        />
        
        {!showCancelled && hiddenCount > 0 && (
          <Tooltip title="Cancelled entries are hidden">
            <Box sx={{ display: 'flex', alignItems: 'center', color: 'text.secondary' }}>
              <VisibilityOffIcon fontSize="small" sx={{ mr: 1 }} />
              <Typography variant="body2">
                {hiddenCount} cancelled {hiddenCount === 1 ? 'entry' : 'entries'} hidden
              </Typography>
            </Box>
          </Tooltip>
        )}
      </Box>
      
      <StyledTableContainer>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Created At</TableCell>
              <TableCell>Production Date</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Items Count</TableCell>
              <TableCell>Notes</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredEntries.length === 0 ? (
              <TableRow>
                <TableCell colSpan={7} align="center">
                  <Typography variant="body1" sx={{ py: 2 }}>
                    No production entries found
                  </Typography>
                </TableCell>
              </TableRow>
            ) : (
              filteredEntries.map((entry) => (
                <TableRow 
                  key={entry.production_id}
                  hover
                  sx={{ 
                    cursor: 'pointer',
                    '&:hover': {
                      backgroundColor: 'rgba(0, 0, 0, 0.04)',
                    }
                  }}
                  onClick={() => handleProductionClick(entry.production_id)}
                >
                  <TableCell>{entry.production_id}</TableCell>
                  <TableCell>
                    {format(new Date(entry.created_at), 'MMM d, yyyy HH:mm')}
                  </TableCell>
                  <TableCell>
                    {format(new Date(entry.production_date), 'MMM d, yyyy HH:mm')}
                  </TableCell>
                  <TableCell>
                    <Chip 
                      label={entry.status}
                      color={
                        entry.status === 'pending' ? 'warning' :
                        entry.status === 'confirmed' ? 'success' :
                        entry.status === 'cancelled' ? 'error' :
                        'default'
                      }
                    />
                  </TableCell>
                  <TableCell>{entry.items?.length || 0}</TableCell>
                  <TableCell>{entry.notes}</TableCell>
                  <TableCell>
                    <Box sx={{ display: 'flex', gap: 1 }}>
                      <IconButton
                        size="small"
                        onClick={(e) => {
                          e.stopPropagation(); // Prevent row click
                          handleActionClick(e, entry.production_id, 'production');
                        }}
                      >
                        <MoreVertIcon />
                      </IconButton>
                    </Box>
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </StyledTableContainer>

      <Menu
        anchorEl={actionMenuAnchor}
        open={Boolean(actionMenuAnchor)}
        onClose={handleActionClose}
      >
        <MenuItem onClick={handleShareClick}>
          <ShareIcon fontSize="small" sx={{ mr: 1 }} />
          Share
        </MenuItem>
        <MenuItem onClick={handleExportGoogleSheets}>
          <CloudDownloadIcon fontSize="small" sx={{ mr: 1 }} />
          Export to Google Sheets
        </MenuItem>
        <MenuItem onClick={handleExportExcel}>
          <CloudDownloadIcon fontSize="small" sx={{ mr: 1 }} />
          Export to Excel
        </MenuItem>
      </Menu>
    </Box>
  );
};

export default ProductionEntriesTab;
