import React, { useState, useEffect, useMemo } from 'react';
import {
  Box,
  CircularProgress,
  Alert,
  ToggleButtonGroup,
  ToggleButton,
  Typography,
  Button,
  Snackbar,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Chip,
  Divider,
  Card,
  CardContent,
} from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import EditIcon from '@mui/icons-material/Edit';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import OptimizedSpreadsheet from '../spreadsheets/OptimizedSpreadsheet';
import reorderLevelApi from '../../services/reorderLevelApi';
import ItemsCacheService from '../../services/itemsCacheService';

const InventoryGridTab = ({
  spreadsheetData,
  spreadsheetLoading,
  onRefreshData,
  warehouseId,
}) => {
  // State to track selected view mode
  const [viewMode, setViewMode] = useState(() => {
    // Try to load from localStorage, default to just quantity
    const saved = localStorage.getItem('inventoryGridViewMode');
    return saved || 'quantity';
  });

  // State for edit mode
  const [editMode, setEditMode] = useState(false);
  
  // State for tracking changes
  const [changedData, setChangedData] = useState({});
  
  // State for local copy of spreadsheet data
  const [localSpreadsheetData, setLocalSpreadsheetData] = useState({});
  
  // State for confirmation dialog
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  
  // State for snackbar
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success'
  });
  
  // State for saving status
  const [saving, setSaving] = useState(false);
  
  // State for cached items
  const [cachedItems, setCachedItems] = useState([]);

  // Load cached items for displaying item names in the confirmation dialog
  useEffect(() => {
    const loadCachedItems = async () => {
      try {
        const items = await ItemsCacheService.getItems();
        setCachedItems(items || []);
      } catch (error) {
        console.error('Error loading cached items:', error);
      }
    };
    
    loadCachedItems();
  }, []);

  // Initialize local spreadsheet data when the original data changes or edit mode is toggled
  useEffect(() => {
    if (spreadsheetData && Object.keys(spreadsheetData).length > 0) {
      // Create a deep copy of the spreadsheet data
      const dataCopy = JSON.parse(JSON.stringify(spreadsheetData));
      setLocalSpreadsheetData(dataCopy);
    }
  }, [spreadsheetData, editMode]);

  // Save selected view mode to localStorage when it changes
  useEffect(() => {
    localStorage.setItem('inventoryGridViewMode', viewMode);
  }, [viewMode]);

  // Handle view mode change
  const handleViewModeChange = (event, newMode) => {
    if (newMode !== null) {
      setViewMode(newMode);
    }
  };

  // Determine which metrics to show based on view mode
  const getMetricsForViewMode = () => {
    switch (viewMode) {
      case 'quantity':
        return ['quantity'];
      case 'reorder_level':
        return ['reorder_level'];
      case 'both':
        return ['quantity', 'reorder_level'];
      default:
        return ['quantity'];
    }
  };
  
  // Handle cell value changes
  const handleCellValueChanged = (params) => {
    // Only track changes for reorder_level
    if (params.metric === 'reorder_level') {
      // Update the local spreadsheet data
      setLocalSpreadsheetData(prev => {
        const newData = { ...prev };
        if (!newData[params.itemId]) {
          newData[params.itemId] = {};
        }
        newData[params.itemId] = {
          ...newData[params.itemId],
          reorder_level: params.newValue
        };
        return newData;
      });
      
      // Track changes for saving
      setChangedData(prev => ({
        ...prev,
        [params.itemId]: {
          ...prev[params.itemId],
          reorder_level: params.newValue,
          oldValue: spreadsheetData[params.itemId]?.reorder_level
        }
      }));
    }
  };
  
  // Handle save button click
  const handleSaveClick = () => {
    if (Object.keys(changedData).length > 0) {
      setConfirmDialogOpen(true);
    } else {
      setSnackbar({
        open: true,
        message: 'No changes to save',
        severity: 'info'
      });
    }
  };
  
  // Handle cancel button click
  const handleCancelClick = () => {
    setEditMode(false);
    setChangedData({});
    // Reset local data to original data
    if (spreadsheetData) {
      setLocalSpreadsheetData(JSON.parse(JSON.stringify(spreadsheetData)));
    }
    setSnackbar({
      open: true,
      message: 'Changes discarded',
      severity: 'info'
    });
  };
  
  // Handle confirm save
  const handleConfirmSave = async () => {
    setConfirmDialogOpen(false);
    setSaving(true);
    
    try {
      // Format data for batch update
      const items = Object.entries(changedData).map(([itemId, data]) => ({
        warehouse_item_id: itemId,
        reorder_level: data.reorder_level
      }));
      
      // Call API to update reorder levels
      await reorderLevelApi.batchUpdateReorderLevels(items);
      
      // Reset states
      setEditMode(false);
      setChangedData({});
      
      // Show success message
      setSnackbar({
        open: true,
        message: 'Reorder levels updated successfully',
        severity: 'success'
      });
      
      // Refresh data using the provided callback
      if (onRefreshData) {
        onRefreshData();
      }
      
    } catch (error) {
      console.error('Error updating reorder levels:', error);
      setSnackbar({
        open: true,
        message: `Error updating reorder levels: ${error.message || 'Unknown error'}`,
        severity: 'error'
      });
    } finally {
      setSaving(false);
    }
  };
  
  // Handle dialog close
  const handleDialogClose = () => {
    setConfirmDialogOpen(false);
  };
  
  // Handle snackbar close
  const handleSnackbarClose = () => {
    setSnackbar(prev => ({ ...prev, open: false }));
  };

  // Determine which data to use based on edit mode
  const displayData = useMemo(() => {
    return editMode ? localSpreadsheetData : spreadsheetData;
  }, [editMode, localSpreadsheetData, spreadsheetData]);
  
  // Prepare changes summary for the confirmation dialog
  const changesSummary = useMemo(() => {
    return Object.entries(changedData).map(([itemId, data]) => {
      const item = cachedItems.find(item => item.id === itemId) || {};
      return {
        id: itemId,
        name: item.name || `Item ${itemId}`,
        type: item.type || '',
        size: item.size || '',
        brim: item.brim || '',
        shape: item.shape || '',
        oldValue: data.oldValue || 0,
        newValue: data.reorder_level || 0,
        change: (data.reorder_level || 0) - (data.oldValue || 0)
      };
    }).sort((a, b) => Math.abs(b.change) - Math.abs(a.change));
  }, [changedData, cachedItems]);
  
  // Calculate summary statistics
  const summaryStats = useMemo(() => {
    const totalItems = changesSummary.length;
    const increases = changesSummary.filter(item => item.change > 0).length;
    const decreases = changesSummary.filter(item => item.change < 0).length;
    const noChanges = changesSummary.filter(item => item.change === 0).length;
    
    return { totalItems, increases, decreases, noChanges };
  }, [changesSummary]);

  return (
    <Box sx={{ p: 2 }}>
      <Box sx={{ 
        display: 'flex', 
        alignItems: 'center', 
        mb: 2, 
        gap: 2,
        flexWrap: 'wrap',
        justifyContent: 'space-between'
      }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          <Typography variant="body1" sx={{ fontWeight: 500 }}>
            View:
          </Typography>
          <ToggleButtonGroup
            value={viewMode}
            exclusive
            onChange={handleViewModeChange}
            aria-label="inventory view mode"
            size="small"
          >
            <ToggleButton value="quantity" aria-label="quantity only">
              Quantity
            </ToggleButton>
            <ToggleButton value="reorder_level" aria-label="reorder level only">
              Reorder Level
            </ToggleButton>
            <ToggleButton value="both" aria-label="both metrics">
              Both
            </ToggleButton>
          </ToggleButtonGroup>
        </Box>
        
        {/* Edit mode controls */}
        <Box sx={{ display: 'flex', gap: 1 }}>
          {!editMode ? (
            <Button
              variant="outlined"
              startIcon={<EditIcon />}
              onClick={() => setEditMode(true)}
              disabled={viewMode === 'quantity' || spreadsheetLoading}
            >
              Edit Reorder Levels
            </Button>
          ) : (
            <>
              <Button
                variant="contained"
                color="primary"
                startIcon={<SaveIcon />}
                onClick={handleSaveClick}
                disabled={saving || Object.keys(changedData).length === 0}
              >
                Save Changes
              </Button>
              <Button
                variant="outlined"
                startIcon={<CancelIcon />}
                onClick={handleCancelClick}
                disabled={saving}
              >
                Cancel
              </Button>
            </>
          )}
        </Box>
      </Box>

      {spreadsheetLoading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
          <CircularProgress />
        </Box>
      ) : Object.keys(spreadsheetData).length === 0 ? (
        <Alert severity="info">
          No inventory data available for spreadsheet view
        </Alert>
      ) : (
        <Box sx={{ height: 'calc(100vh - 300px)', minHeight: '500px' }}>
          <OptimizedSpreadsheet
            data={displayData}
            metrics={getMetricsForViewMode()}
            readOnly={!editMode || viewMode === 'quantity'}
            readOnlyMetrics={['quantity']} // Always make quantity read-only
            onCellValueChanged={handleCellValueChanged}
          />
        </Box>
      )}
      
      {/* Enhanced Confirmation Dialog */}
      <Dialog
        open={confirmDialogOpen}
        onClose={handleDialogClose}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle sx={{ 
          display: 'flex', 
          alignItems: 'center', 
          gap: 1,
          borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
          pb: 2
        }}>
          <CheckCircleIcon color="primary" />
          <Typography variant="h6">
            Confirm Reorder Level Updates
          </Typography>
        </DialogTitle>
        <DialogContent sx={{ pt: 3 }}>
          <Box sx={{ mb: 3 }}>
            <Typography variant="subtitle1" gutterBottom>
              You are about to update reorder levels for {summaryStats.totalItems} items:
            </Typography>
            
            <Box sx={{ display: 'flex', gap: 2, mt: 2, flexWrap: 'wrap' }}>
              <Chip 
                label={`${summaryStats.totalItems} Total Changes`} 
                color="primary" 
                variant="outlined" 
              />
              {summaryStats.increases > 0 && (
                <Chip 
                  label={`${summaryStats.increases} Increases`} 
                  color="success" 
                  variant="outlined" 
                />
              )}
              {summaryStats.decreases > 0 && (
                <Chip 
                  label={`${summaryStats.decreases} Decreases`} 
                  color="error" 
                  variant="outlined" 
                />
              )}
              {summaryStats.noChanges > 0 && (
                <Chip 
                  label={`${summaryStats.noChanges} No Change`} 
                  color="default" 
                  variant="outlined" 
                />
              )}
            </Box>
          </Box>
          
          <Divider sx={{ my: 2 }} />
          
          <TableContainer component={Paper} variant="outlined" sx={{ maxHeight: 400 }}>
            <Table stickyHeader size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Item</TableCell>
                  <TableCell>Details</TableCell>
                  <TableCell align="right">Current Level</TableCell>
                  <TableCell align="right">New Level</TableCell>
                  <TableCell align="right">Change</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {changesSummary.map((item) => (
                  <TableRow key={item.id}>
                    <TableCell component="th" scope="row">
                      {item.name}
                    </TableCell>
                    <TableCell>
                      {[item.type, item.size, item.brim, item.shape].filter(Boolean).join(' - ')}
                    </TableCell>
                    <TableCell align="right">{item.oldValue}</TableCell>
                    <TableCell align="right">{item.newValue}</TableCell>
                    <TableCell align="right">
                      <Box sx={{ 
                        display: 'flex', 
                        alignItems: 'center', 
                        justifyContent: 'flex-end',
                        gap: 1
                      }}>
                        <Chip
                          size="small"
                          label={item.change > 0 ? `+${item.change}` : item.change}
                          color={item.change > 0 ? 'success' : item.change < 0 ? 'error' : 'default'}
                          variant={item.change === 0 ? 'outlined' : 'filled'}
                        />
                      </Box>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          
          <Box sx={{ mt: 3 }}>
            <Alert severity="warning">
              This action cannot be undone. Please review the changes carefully before confirming.
            </Alert>
          </Box>
        </DialogContent>
        <DialogActions sx={{ px: 3, py: 2, borderTop: '1px solid rgba(0, 0, 0, 0.12)' }}>
          <Button 
            onClick={handleDialogClose} 
            disabled={saving}
            variant="outlined"
          >
            Cancel
          </Button>
          <Button 
            onClick={handleConfirmSave} 
            color="primary" 
            variant="contained"
            disabled={saving}
            startIcon={saving ? <CircularProgress size={20} /> : <SaveIcon />}
          >
            {saving ? 'Saving...' : 'Confirm Updates'}
          </Button>
        </DialogActions>
      </Dialog>
      
      {/* Snackbar for notifications */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert 
          onClose={handleSnackbarClose} 
          severity={snackbar.severity}
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default InventoryGridTab;
