import React, { useState, useEffect, useRef } from 'react';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  IconButton,
  Typography,
  Snackbar,
  Alert,
  Grid,
  Divider,
  Checkbox,
  Chip,
  Grow,
  Zoom,
  Card,
  InputAdornment
} from '@mui/material';
import {
  Add as AddIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  Save as SaveIcon,
  Business as BusinessIcon,
  Phone as PhoneIcon,
  Email as EmailIcon,
  LocationOn as LocationIcon,
  Lock as LockIcon,
  Check as CheckIcon,
  Close as CloseIcon,
  Search as SearchIcon
} from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import {
  getAllCustomers,
  createCustomer,
  updateCustomer,
  deleteCustomer,
  getAllPortalAccess,
  createPortalAccess,
  updatePortalAccess,
  getCustomerOrders,
  updateOrderStatus
} from '../services/api';
import FullScreenLayout from './layouts/FullScreenLayout';
import { useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { LoadingButton } from '@mui/lab';

// Styled components
const PageContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(4),
  maxWidth: '100%',
  margin: '0 auto',
  backgroundColor: theme.palette.background.default,
}));

const ContentPaper = styled(Paper)(({ theme }) => ({
  borderRadius: '16px',
  padding: theme.spacing(3),
  backgroundColor: theme.palette.background.paper,
  boxShadow: '0 2px 12px rgba(0,0,0,0.03)',
}));

const CreateCustomerButton = styled(Button)(({ theme }) => ({
  borderRadius: '12px',
  padding: '10px 24px',
  fontSize: '1rem',
  fontWeight: 600,
  textTransform: 'none',
  boxShadow: '0 4px 12px rgba(0,0,0,0.05)',
  '&:hover': {
    boxShadow: '0 6px 16px rgba(0,0,0,0.1)',
    transform: 'translateY(-1px)',
  },
  transition: 'all 0.2s ease',
}));

const StatsCard = styled(Card)(({ theme }) => ({
  padding: theme.spacing(3),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
  transition: 'all 0.2s ease',
  boxShadow: 'none',
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: '16px',
  backgroundColor: theme.palette.background.paper,
  '&:hover': {
    transform: 'translateY(-4px)',
    boxShadow: '0 4px 12px rgba(0,0,0,0.05)',
  },
}));

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    borderRadius: '16px',
    padding: theme.spacing(2),
  },
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    borderRadius: '12px',
    backgroundColor: '#ffffff',
    transition: 'all 0.2s ease-in-out',
    border: '1px solid #e2e8f0',
    '&:hover': {
      borderColor: theme.palette.primary.main,
      backgroundColor: '#ffffff',
      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.05)',
    },
    '&.Mui-focused': {
      borderColor: theme.palette.primary.main,
      backgroundColor: '#ffffff',
      boxShadow: '0 0 0 3px rgba(66, 153, 225, 0.15)',
    },
  },
  '& .MuiInputLabel-root': {
    color: '#64748b',
    fontWeight: 500,
  },
  '& .MuiInputBase-input': {
    padding: '16px',
  },
}));

const DialogButton = styled(Button)(({ theme }) => ({
  borderRadius: '12px',
  padding: '12px 24px',
  fontWeight: 600,
  textTransform: 'none',
  fontSize: '0.95rem',
  boxShadow: 'none',
  '&.MuiButton-contained': {
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
      boxShadow: '0 4px 6px rgba(66, 153, 225, 0.2)',
    },
  },
  '&.MuiButton-outlined': {
    borderColor: '#e2e8f0',
    color: '#64748b',
    '&:hover': {
      backgroundColor: '#f8fafc',
      borderColor: '#cbd5e1',
    },
  },
}));

const PasswordRequirement = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
  transition: 'all 0.2s ease-in-out',
}));

const EmptyStateContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  padding: theme.spacing(4),
  textAlign: 'center',
  minHeight: '300px',
  backgroundColor: '#fff',
  borderRadius: theme.shape.borderRadius,
  boxShadow: '0 2px 4px rgba(0,0,0,0.05)',
}));

const NewCustomerHighlight = styled(Box)(({ theme }) => ({
  position: 'relative',
  '&::after': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    animation: 'highlight 2s ease-out forwards',
    pointerEvents: 'none',
  },
  '@keyframes highlight': {
    '0%': {
      backgroundColor: theme.palette.primary.light,
      opacity: 0.2,
    },
    '100%': {
      backgroundColor: 'transparent',
      opacity: 0,
    },
  }
}));

const PASSWORD_REQUIREMENTS = [
  'At least 8 characters long',
  'At least one uppercase letter',
  'At least one lowercase letter',
  'At least one number',
  'At least one special character (!@#$%^&*(),.?":{}|<>)'
];

const PASSWORD_REQUIREMENT_CHECKS = [
  {
    label: 'At least 8 characters long',
    check: (password) => password?.length >= 8
  },
  {
    label: 'At least one uppercase letter',
    check: (password) => password ? /[A-Z]/.test(password) : false
  },
  {
    label: 'At least one lowercase letter',
    check: (password) => password ? /[a-z]/.test(password) : false
  },
  {
    label: 'At least one number',
    check: (password) => password ? /[0-9]/.test(password) : false
  },
  {
    label: 'At least one special character',
    check: (password) => password ? /[!@#$%^&*(),.?":{}|<>]/.test(password) : false
  }
];

const validateEmail = (email) => {
  const re = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
  return re.test(email);
};

const validateCustomerData = (customer) => {
  const errors = [];
  
  // Required fields validation
  if (!customer.company_name?.trim()) {
    errors.push('Company name is required');
  }
  
  if (!customer.primary_contact_name?.trim()) {
    errors.push('Primary contact name is required');
  }
  
  if (!customer.primary_contact_email?.trim()) {
    errors.push('Primary contact email is required');
  } else if (!validateEmail(customer.primary_contact_email)) {
    errors.push('Primary contact email is invalid');
  }

  // Optional email validation
  if (customer.secondary_contact_email && !validateEmail(customer.secondary_contact_email)) {
    errors.push('Secondary contact email is invalid');
  }

  return errors;
};

const getStatusColor = (status) => {
  switch (status.toLowerCase()) {
    case 'completed':
      return 'success';
    case 'processing':
      return 'info';
    case 'pending':
      return 'warning';
    case 'cancelled':
      return 'error';
    case 'on hold':
      return 'secondary';
    default:
      return 'default';
  }
};

function CustomerManagement() {
  const [customers, setCustomers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: 'contains' },
    company_name: { value: null, matchMode: 'contains' },
    business_type: { value: null, matchMode: 'contains' },
    primary_contact_name: { value: null, matchMode: 'contains' },
    primary_contact_email: { value: null, matchMode: 'contains' }
  });
  const [globalFilterValue, setGlobalFilterValue] = useState('');
  const [openDialog, setOpenDialog] = useState(false);
  const [formData, setFormData] = useState({
    company_name: '',
    business_type: '',
    tax_id: '',
    primary_contact_name: '',
    primary_contact_email: '',
    primary_contact_phone: '',
    billing_address_street: '',
    billing_address_city: '',
    billing_address_state: '',
    billing_address_postal: '',
    billing_address_country: '',
    shipping_address_street: '',
    shipping_address_city: '',
    shipping_address_state: '',
    shipping_address_postal: '',
    shipping_address_country: '',
    send_emails: true
  });
  const [isEditing, setIsEditing] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
  const [portalAccess, setPortalAccess] = useState({});
  const [portalDialog, setPortalDialog] = useState(false);
  const [currentPortal, setCurrentPortal] = useState({
    username: '',
    password: ''
  });
  const [orderConfirmDialog, setOrderConfirmDialog] = useState({
    open: false,
    orders: [],
    selectedOrders: [],
    customerId: null
  });
  const [newCustomerId, setNewCustomerId] = useState(null);
  const [showPortalPrompt, setShowPortalPrompt] = useState(false);
  const timeoutRef = useRef(null);
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [existingCompanyNames, setExistingCompanyNames] = useState(new Set());

  useEffect(() => {
    fetchCustomers();
    fetchPortalAccess();
    
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  useEffect(() => {
    // Update existing company names set when customers change
    const names = new Set(customers.map(c => c.company_name.toLowerCase()));
    setExistingCompanyNames(names);
  }, [customers]);

  const fetchCustomers = async () => {
    setLoading(true);
    try {
      const response = await getAllCustomers();
      setCustomers(response.data);
    } catch (error) {
      setSnackbar({
        open: true,
        message: 'Error fetching customers: ' + error.message,
        severity: 'error'
      });
    } finally {
      setLoading(false);
    }
  };

  const fetchPortalAccess = async () => {
    try {
      const response = await getAllPortalAccess();
      const portalMap = {};
      response.data.forEach(portal => {
        portalMap[portal.customer_id] = portal;
      });
      setPortalAccess(portalMap);
    } catch (error) {
      setSnackbar({
        open: true,
        message: 'Error fetching portal access details: ' + error.message,
        severity: 'error'
      });
    }
  };

  const handleOpenDialog = (customer = null) => {
    if (customer) {
      setFormData(customer);
      setIsEditing(true);
    } else {
      setFormData({
        company_name: '',
        business_type: '',
        tax_id: '',
        primary_contact_name: '',
        primary_contact_email: '',
        primary_contact_phone: '',
        billing_address_street: '',
        billing_address_city: '',
        billing_address_state: '',
        billing_address_postal: '',
        billing_address_country: '',
        shipping_address_street: '',
        shipping_address_city: '',
        shipping_address_state: '',
        shipping_address_postal: '',
        shipping_address_country: '',
        send_emails: true
      });
      setIsEditing(false);
    }
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setFormData({});
    setIsEditing(false);
  };

  const handleInputChange = (event) => {
    const { name, value, type, checked } = event.target;
    
    // Email validation for email fields
    if ((name === 'primary_contact_email' || name === 'secondary_contact_email') && value) {
      if (!validateEmail(value)) {
        setSnackbar({
          open: true,
          message: `Invalid email format for ${name.replace(/_/g, ' ')}`,
          severity: 'warning'
        });
      }
    }

    // Company name uniqueness check
    if (name === 'company_name' && value && !isEditing) {
      if (existingCompanyNames.has(value.toLowerCase())) {
        setSnackbar({
          open: true,
          message: 'A company with this name already exists',
          severity: 'warning'
        });
      }
    }

    setFormData(prev => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  const handleSubmit = async () => {
    try {
      const validationErrors = validateCustomerData(formData);
      
      // Check company name uniqueness for new customers
      if (!isEditing && existingCompanyNames.has(formData.company_name.toLowerCase())) {
        validationErrors.push('A company with this name already exists');
      }
      
      if (validationErrors.length > 0) {
        setSnackbar({
          open: true,
          message: validationErrors.join(', '),
          severity: 'error'
        });
        return;
      }

      setIsSubmitting(true);

      if (isEditing) {
        await updateCustomer(formData.customer_id, formData);
        setSnackbar({
          open: true,
          message: 'Customer updated successfully',
          severity: 'success'
        });
      } else {
        const response = await createCustomer(formData);
        setSnackbar({
          open: true,
          message: 'Customer created successfully',
          severity: 'success'
        });
        setNewCustomerId(response.data.customer_id);
        setShowPortalPrompt(true);
      }
      
      fetchCustomers();
      handleCloseDialog();
    } catch (error) {
      let errorMessage = 'An unexpected error occurred';
      
      if (error.response?.data?.error) {
        errorMessage = error.response.data.error;
      } else if (error.response?.data?.details) {
        errorMessage = error.response.data.details;
      } else if (error.message) {
        errorMessage = error.message;
      }

      setSnackbar({
        open: true,
        message: `Error: ${errorMessage}`,
        severity: 'error'
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleOrderSelection = (orderId) => {
    setOrderConfirmDialog(prev => ({
      ...prev,
      selectedOrders: prev.selectedOrders.includes(orderId)
        ? prev.selectedOrders.filter(id => id !== orderId)
        : [...prev.selectedOrders, orderId]
    }));
  };

  const handleDeleteCustomer = async (customerId) => {
    try {
      // First fetch all orders for this customer
      const ordersResponse = await getCustomerOrders(customerId);
      const activeOrders = ordersResponse.filter(order => 
        !['completed', 'cancelled'].includes(order.status.toLowerCase())
      );

      if (activeOrders.length > 0) {
        // Show dialog with active orders
        setOrderConfirmDialog({
          open: true,
          orders: activeOrders,
          selectedOrders: [],
          customerId
        });
      } else {
        // If no active orders, proceed with normal deletion
        if (window.confirm('Are you sure you want to delete this customer?')) {
          await proceedWithCustomerDeletion(customerId);
        }
      }
    } catch (error) {
      setSnackbar({
        open: true,
        message: `Error checking customer orders: ${error.message}`,
        severity: 'error'
      });
    }
  };

  const proceedWithCustomerDeletion = async (customerId) => {
    try {
      await deleteCustomer(customerId);
      setSnackbar({
        open: true,
        message: 'Customer deleted successfully',
        severity: 'success'
      });
      fetchCustomers();
    } catch (error) {
      setSnackbar({
        open: true,
        message: `Error: ${error.response?.data?.error || error.message}`,
        severity: 'error'
      });
    }
  };

  const handleOrdersAndDelete = async () => {
    try {
      // Cancel selected orders
      for (const orderId of orderConfirmDialog.selectedOrders) {
        await updateOrderStatus(orderId, 'cancelled', 'Customer account deletion');
      }

      // Delete the customer
      await proceedWithCustomerDeletion(orderConfirmDialog.customerId);

      // Close the dialog
      setOrderConfirmDialog({
        open: false,
        orders: [],
        selectedOrders: [],
        customerId: null
      });
    } catch (error) {
      setSnackbar({
        open: true,
        message: `Error processing deletion: ${error.message}`,
        severity: 'error'
      });
    }
  };

  const handlePortalDialogOpen = (customer) => {
    setFormData(customer);
    const existingPortal = portalAccess[customer.customer_id];
    setCurrentPortal({
      username: existingPortal?.username || '',
      password: ''
    });
    setPortalDialog(true);
  };

  const handlePortalDialogClose = () => {
    setPortalDialog(false);
    setCurrentPortal({ username: '', password: '' });
  };

  const validatePassword = (password) => {
    const requirements = [
      password.length >= 8,
      /[A-Z]/.test(password),
      /[a-z]/.test(password),
      /[0-9]/.test(password),
      /[!@#$%^&*(),.?":{}|<>]/.test(password)
    ];
    
    const errors = [];
    requirements.forEach((isValid, index) => {
      if (!isValid) {
        errors.push(PASSWORD_REQUIREMENTS[index]);
      }
    });
    
    return {
      isValid: errors.length === 0,
      errors
    };
  };

  const handlePortalSubmit = async () => {
    try {
      const strength = getPasswordStrength(currentPortal.password);
      if (strength < PASSWORD_REQUIREMENT_CHECKS.length) {
        setSnackbar({
          open: true,
          message: 'Please meet all password requirements before submitting',
          severity: 'error'
        });
        return;
      }

      const customerId = formData.customer_id;
      if (portalAccess[customerId]) {
        await updatePortalAccess(customerId, currentPortal);
      } else {
        await createPortalAccess(customerId, currentPortal);
      }
      
      await fetchPortalAccess();
      setSnackbar({
        open: true,
        message: 'Portal access updated successfully',
        severity: 'success'
      });
      handlePortalDialogClose();
    } catch (error) {
      setSnackbar({
        open: true,
        message: `Error: ${error.response?.data?.error || error.message}`,
        severity: 'error'
      });
    }
  };

  const getPasswordStrength = (password) => {
    if (!password) return 0;
    return PASSWORD_REQUIREMENT_CHECKS.filter(req => req.check(password)).length;
  };

  const handlePortalPromptResponse = (createPortal) => {
    setShowPortalPrompt(false);
    if (createPortal) {
      // Find the newly created customer
      const newCustomer = customers.find(c => c.customer_id === newCustomerId);
      if (newCustomer) {
        handlePortalDialogOpen(newCustomer);
      }
    }
    // Clear the new customer highlight after 2 seconds
    timeoutRef.current = setTimeout(() => {
      setNewCustomerId(null);
    }, 2000);
  };

  const renderHeader = () => {
    return (
      <Box sx={{ 
        display: 'flex', 
        justifyContent: 'space-between', 
        alignItems: 'center', 
        mb: 3,
        flexWrap: 'wrap',
        gap: 2
      }}>
        <Typography variant="h4" sx={{ fontWeight: 600, color: 'text.primary' }}>
          Enterprise Customers
        </Typography>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          <TextField
            placeholder="Search customers..."
            variant="outlined"
            size="small"
            value={globalFilterValue}
            onChange={(e) => {
              setGlobalFilterValue(e.target.value);
              setFilters({
                ...filters,
                global: { value: e.target.value, matchMode: 'contains' }
              });
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
              sx: {
                borderRadius: '12px',
                '& fieldset': {
                  borderColor: 'divider'
                }
              }
            }}
          />
          <CreateCustomerButton
            startIcon={<AddIcon />}
            onClick={() => handleOpenDialog()}
            variant="contained"
            color="primary"
          >
            Add New Customer
          </CreateCustomerButton>
        </Box>
      </Box>
    );
  };

  const mainContent = (
    <>
      <Grid container spacing={3} sx={{ mb: 4 }}>
        <Grid item xs={12} md={3}>
          <StatsCard>
            <Typography variant="h3" sx={{ mb: 1, color: 'text.primary', fontWeight: 600 }}>
              {customers.length}
            </Typography>
            <Typography variant="subtitle1" color="text.secondary">
              Total Customers
            </Typography>
          </StatsCard>
        </Grid>
        <Grid item xs={12} md={3}>
          <StatsCard>
            <Typography variant="h3" sx={{ mb: 1, color: 'primary.main', fontWeight: 600 }}>
              {customers.filter(c => c.status === 'active').length}
            </Typography>
            <Typography variant="subtitle1" color="text.secondary">
              Active Customers
            </Typography>
          </StatsCard>
        </Grid>
        <Grid item xs={12} md={3}>
          <StatsCard>
            <Typography variant="h3" sx={{ mb: 1, color: 'warning.main', fontWeight: 600 }}>
              {customers.filter(c => !c.last_order_date).length}
            </Typography>
            <Typography variant="subtitle1" color="text.secondary">
              New Customers
            </Typography>
          </StatsCard>
        </Grid>
        <Grid item xs={12} md={3}>
          <StatsCard>
            <Typography variant="h3" sx={{ mb: 1, color: 'success.main', fontWeight: 600 }}>
              {Object.keys(portalAccess).length}
            </Typography>
            <Typography variant="subtitle1" color="text.secondary">
              Portal Users
            </Typography>
          </StatsCard>
        </Grid>
      </Grid>

      <ContentPaper elevation={0}>
        <DataTable
          value={customers}
          paginator
          rows={10}
          dataKey="customer_id"
          filters={filters}
          filterDisplay="menu"
          loading={loading}
          globalFilterFields={['company_name', 'business_type', 'primary_contact_name', 'primary_contact_email']}
          header={renderHeader()}
          emptyMessage={
            <EmptyStateContainer>
              <BusinessIcon sx={{ fontSize: 48, color: 'text.secondary', mb: 2 }} />
              <Typography variant="h6" gutterBottom>
                No Customers Found
              </Typography>
              <Typography variant="body2" color="text.secondary" sx={{ mb: 3 }}>
                {globalFilterValue 
                  ? "No customers match your search criteria"
                  : "Get started by adding your first customer"}
              </Typography>
              <Button
                variant="contained"
                startIcon={<AddIcon />}
                onClick={() => handleOpenDialog()}
              >
                Add New Customer
              </Button>
            </EmptyStateContainer>
          }
          responsiveLayout="scroll"
          scrollHeight="600px"
          onRowClick={(e) => navigate(`/customers/${e.data.customer_id}`)}
          rowHover
          className="customers-table"
          style={{ 
            borderRadius: '12px',
            overflow: 'hidden',
          }}
        >
          <Column 
            field="company_name" 
            header="Company Name" 
            sortable 
            filter
            body={(rowData) => (
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <BusinessIcon sx={{ color: 'primary.main', fontSize: 20 }} />
                <Typography>{rowData.company_name}</Typography>
              </Box>
            )}
          />
          <Column 
            field="business_type" 
            header="Business Type" 
            sortable 
            filter
            body={(rowData) => (
              <Chip 
                label={rowData.business_type || 'N/A'} 
                size="small"
                variant="outlined"
              />
            )}
          />
          <Column 
            field="primary_contact_name" 
            header="Primary Contact" 
            sortable
            body={(rowData) => (
              <Box>
                <Typography variant="body2">{rowData.primary_contact_name}</Typography>
                <Typography variant="caption" color="text.secondary">
                  {rowData.primary_contact_email}
                </Typography>
              </Box>
            )}
          />
          <Column
            field="account_status"
            header="Status"
            sortable
            body={(rowData) => (
              <Chip
                label={rowData.account_status}
                color={rowData.account_status === 'active' ? 'success' : 'default'}
                size="small"
                variant={rowData.account_status === 'active' ? 'filled' : 'outlined'}
              />
            )}
          />
          <Column
            field="created_at"
            header="Created"
            sortable
            body={(rowData) => format(new Date(rowData.created_at), 'MMM dd, yyyy')}
          />
          <Column
            body={(rowData) => (
              <Box sx={{ display: 'flex', gap: 1, justifyContent: 'flex-end' }}>
                <IconButton
                  size="small"
                  onClick={(e) => {
                    e.stopPropagation();
                    handlePortalDialogOpen(rowData);
                  }}
                  title={portalAccess[rowData.customer_id] ? "Update Portal Access" : "Create Portal Access"}
                >
                  <LockIcon fontSize="small" color={portalAccess[rowData.customer_id] ? "primary" : "action"} />
                </IconButton>
                <IconButton
                  size="small"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleOpenDialog(rowData);
                  }}
                >
                  <EditIcon fontSize="small" />
                </IconButton>
                <IconButton
                  size="small"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleDeleteCustomer(rowData.customer_id);
                  }}
                >
                  <DeleteIcon fontSize="small" />
                </IconButton>
              </Box>
            )}
          />
        </DataTable>
      </ContentPaper>

      <StyledDialog open={openDialog} onClose={handleCloseDialog} maxWidth="md" fullWidth>
        <DialogTitle>
          {isEditing ? 'Edit Customer' : 'Add New Customer'}
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h6" gutterBottom>
                Company Information
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <StyledTextField
                name="company_name"
                label="Company Name"
                fullWidth
                required
                value={formData.company_name || ''}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <StyledTextField
                name="business_type"
                label="Business Type"
                fullWidth
                value={formData.business_type || ''}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <StyledTextField
                name="tax_id"
                label="Tax ID"
                fullWidth
                value={formData.tax_id || ''}
                onChange={handleInputChange}
              />
            </Grid>

            <Grid item xs={12}>
              <Divider sx={{ my: 2 }} />
              <Typography variant="h6" gutterBottom>
                Primary Contact
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <StyledTextField
                name="primary_contact_name"
                label="Name"
                fullWidth
                required
                value={formData.primary_contact_name || ''}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <StyledTextField
                name="primary_contact_email"
                label="Email"
                fullWidth
                required
                value={formData.primary_contact_email || ''}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <StyledTextField
                name="primary_contact_phone"
                label="Phone"
                fullWidth
                value={formData.primary_contact_phone || ''}
                onChange={handleInputChange}
              />
            </Grid>

            <Grid item xs={12}>
              <Divider sx={{ my: 2 }} />
              <Typography variant="h6" gutterBottom>
                Secondary Contact
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <StyledTextField
                name="secondary_contact_name"
                label="Name"
                fullWidth
                value={formData.secondary_contact_name || ''}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <StyledTextField
                name="secondary_contact_email"
                label="Email"
                fullWidth
                value={formData.secondary_contact_email || ''}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <StyledTextField
                name="secondary_contact_phone"
                label="Phone"
                fullWidth
                value={formData.secondary_contact_phone || ''}
                onChange={handleInputChange}
              />
            </Grid>

            <Grid item xs={12}>
              <Divider sx={{ my: 2 }} />
              <Typography variant="h6" gutterBottom>
                Billing Address
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <StyledTextField
                name="billing_address_street"
                label="Street Address"
                fullWidth
                value={formData.billing_address_street || ''}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <StyledTextField
                name="billing_address_city"
                label="City"
                fullWidth
                value={formData.billing_address_city || ''}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <StyledTextField
                name="billing_address_state"
                label="State/Province"
                fullWidth
                value={formData.billing_address_state || ''}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <StyledTextField
                name="billing_address_postal"
                label="Postal Code"
                fullWidth
                value={formData.billing_address_postal || ''}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <StyledTextField
                name="billing_address_country"
                label="Country"
                fullWidth
                value={formData.billing_address_country || ''}
                onChange={handleInputChange}
              />
            </Grid>

            <Grid item xs={12}>
              <Divider sx={{ my: 2 }} />
              <Typography variant="h6" gutterBottom>
                Shipping Address
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <StyledTextField
                name="shipping_address_street"
                label="Street Address"
                fullWidth
                value={formData.shipping_address_street || ''}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <StyledTextField
                name="shipping_address_city"
                label="City"
                fullWidth
                value={formData.shipping_address_city || ''}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <StyledTextField
                name="shipping_address_state"
                label="State/Province"
                fullWidth
                value={formData.shipping_address_state || ''}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <StyledTextField
                name="shipping_address_postal"
                label="Postal Code"
                fullWidth
                value={formData.shipping_address_postal || ''}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <StyledTextField
                name="shipping_address_country"
                label="Country"
                fullWidth
                value={formData.shipping_address_country || ''}
                onChange={handleInputChange}
              />
            </Grid>

            <Grid item xs={12}>
              <div className="form-group">
                <label className="form-label">
                  <input
                    type="checkbox"
                    name="send_emails"
                    checked={formData.send_emails}
                    onChange={handleInputChange}
                    className="form-check-input me-2"
                  />
                  Send Email Notifications
                </label>
                <small className="form-text text-muted">
                  Allow sending email notifications for order updates and important information
                </small>
              </div>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button 
            onClick={handleCloseDialog}
            disabled={isSubmitting}
          >
            Cancel
          </Button>
          <LoadingButton
            onClick={handleSubmit}
            loading={isSubmitting}
            variant="contained"
            color="primary"
            startIcon={isEditing ? <SaveIcon /> : <AddIcon />}
          >
            {isEditing ? 'Save Changes' : 'Create Customer'}
          </LoadingButton>
        </DialogActions>
      </StyledDialog>

      <StyledDialog open={portalDialog} onClose={handlePortalDialogClose}>
        <DialogTitle>
          {portalAccess[formData?.customer_id] ? 'Update Portal Access' : 'Create Portal Access'}
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="subtitle1" gutterBottom>
                {formData?.company_name}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <StyledTextField
                name="username"
                label="Username"
                fullWidth
                required
                value={currentPortal.username}
                onChange={(e) => setCurrentPortal(prev => ({
                  ...prev,
                  username: e.target.value
                }))}
              />
            </Grid>
            <Grid item xs={12}>
              <StyledTextField
                name="password"
                label="Password"
                type="password"
                fullWidth
                required
                value={currentPortal.password}
                onChange={(e) => setCurrentPortal(prev => ({
                  ...prev,
                  password: e.target.value
                }))}
              />
              <Box sx={{ mt: 2 }}>
                <Box sx={{ mb: 1, display: 'flex', alignItems: 'center', gap: 1 }}>
                  <Typography variant="subtitle2" color="textSecondary">
                    Password Strength:
                  </Typography>
                  <Box sx={{ 
                    flex: 1, 
                    height: 4, 
                    bgcolor: 'grey.200', 
                    borderRadius: 2,
                    overflow: 'hidden'
                  }}>
                    <Box sx={{ 
                      height: '100%', 
                      width: `${(getPasswordStrength(currentPortal.password) / PASSWORD_REQUIREMENT_CHECKS.length) * 100}%`,
                      bgcolor: (theme) => {
                        const strength = getPasswordStrength(currentPortal.password);
                        if (strength <= 2) return theme.palette.error.main;
                        if (strength <= 4) return theme.palette.warning.main;
                        return theme.palette.success.main;
                      },
                      transition: 'all 0.3s ease'
                    }} />
                  </Box>
                </Box>
                <Box sx={{ mt: 1 }}>
                  {PASSWORD_REQUIREMENT_CHECKS.map((req, index) => {
                    const isValid = req.check(currentPortal.password);
                    return (
                      <PasswordRequirement 
                        key={index}
                        sx={{ 
                          color: isValid ? 'success.main' : 'text.secondary',
                          opacity: currentPortal.password ? 1 : 0.7,
                        }}
                      >
                        {isValid ? (
                          <CheckIcon fontSize="small" color="success" />
                        ) : (
                          <CloseIcon fontSize="small" color="disabled" />
                        )}
                        <Typography 
                          variant="body2"
                          sx={{ 
                            textDecoration: isValid ? 'line-through' : 'none',
                            transition: 'all 0.2s ease'
                          }}
                        >
                          {req.label}
                        </Typography>
                      </PasswordRequirement>
                    );
                  })}
                </Box>
              </Box>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handlePortalDialogClose}>Cancel</Button>
          <Button
            onClick={handlePortalSubmit}
            variant="contained"
            color="primary"
            disabled={!currentPortal.username || !currentPortal.password}
          >
            {portalAccess[formData?.customer_id] ? 'Update Access' : 'Create Access'}
          </Button>
        </DialogActions>
      </StyledDialog>

      <StyledDialog
        open={orderConfirmDialog.open} 
        onClose={() => setOrderConfirmDialog(prev => ({ ...prev, open: false }))}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>Active Orders Found</DialogTitle>
        <DialogContent>
          <Typography paragraph>
            This customer has {orderConfirmDialog.orders.length} active orders. 
            Please select the orders you would like to cancel before deleting the customer.
          </Typography>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={orderConfirmDialog.selectedOrders.length === orderConfirmDialog.orders.length}
                      indeterminate={
                        orderConfirmDialog.selectedOrders.length > 0 && 
                        orderConfirmDialog.selectedOrders.length < orderConfirmDialog.orders.length
                      }
                      onChange={(e) => {
                        if (e.target.checked) {
                          setOrderConfirmDialog(prev => ({
                            ...prev,
                            selectedOrders: prev.orders.map(order => order.order_id)
                          }));
                        } else {
                          setOrderConfirmDialog(prev => ({
                            ...prev,
                            selectedOrders: []
                          }));
                        }
                      }}
                    />
                  </TableCell>
                  <TableCell>Order ID</TableCell>
                  <TableCell>Date</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Total Items</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {orderConfirmDialog.orders.map((order) => (
                  <TableRow key={order.order_id}>
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={orderConfirmDialog.selectedOrders.includes(order.order_id)}
                        onChange={() => handleOrderSelection(order.order_id)}
                      />
                    </TableCell>
                    <TableCell>{order.order_id}</TableCell>
                    <TableCell>{format(new Date(order.created_at), 'MMM dd, yyyy')}</TableCell>
                    <TableCell>
                      <Chip 
                        label={order.status} 
                        color={getStatusColor(order.status)}
                        size="small"
                      />
                    </TableCell>
                    <TableCell>{order.total_items}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions>
          <Button 
            onClick={() => setOrderConfirmDialog(prev => ({ ...prev, open: false }))}
          >
            Cancel
          </Button>
          <Button
            onClick={handleOrdersAndDelete}
            variant="contained"
            color="error"
            startIcon={<DeleteIcon />}
          >
            Cancel Selected Orders & Delete Customer
          </Button>
        </DialogActions>
      </StyledDialog>

      <StyledDialog
        open={showPortalPrompt}
        onClose={() => handlePortalPromptResponse(false)}
      >
        <DialogTitle>
          <Zoom in={true}>
            <Box display="flex" alignItems="center" gap={1}>
              <LockIcon color="primary" />
              <Typography variant="h6">Create Portal Access?</Typography>
            </Box>
          </Zoom>
        </DialogTitle>
        <DialogContent>
          <Typography>
            Would you like to create portal access for this customer now?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handlePortalPromptResponse(false)}>
            Not Now
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => handlePortalPromptResponse(true)}
          >
            Create Portal Access
          </Button>
        </DialogActions>
      </StyledDialog>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert
          onClose={() => setSnackbar({ ...snackbar, open: false })}
          severity={snackbar.severity}
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </>
  );

  return (
    <FullScreenLayout
      mainPanel={
        <PageContainer>
          {mainContent}
        </PageContainer>
      }
    />
  );
}

export default CustomerManagement;