import React, { useState, useEffect, useMemo, useCallback } from 'react';
import {
    Box,
    Paper,
    Grid,
    Typography,
    Button,
    CircularProgress,
    List,
    ListItem,
    ListItemText,
    Chip,
    TextField,
    InputAdornment,
    FormControlLabel,
    Switch,
    Tooltip,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    IconButton
} from '@mui/material';
import { Search as SearchIcon } from '@mui/icons-material';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputNumber } from 'primereact/inputnumber';
import { InputText } from 'primereact/inputtext';
import { shipmentCreationApi } from '../../services/shipment-creation-api';
import { Checkbox } from 'primereact/checkbox';
import { FilterMatchMode } from 'primereact/api';
import { MultiSelect } from 'primereact/multiselect';
import { debounce } from 'lodash';
import CurrentShipmentItems from './CurrentShipmentItems';

export default function OrderItemsStep({
    shipmentId,
    availableOrders,
    selectedOrderItems,
    setSelectedOrderItems,
    onError,
    setAvailableOrders,
    onSuccess
}) {
    // Add logging
    useEffect(() => {
        console.log('OrderItemsStep received availableOrders:', availableOrders);
    }, [availableOrders]);

    const [loading, setLoading] = useState(false);
    const [addingItems, setAddingItems] = useState(false);
    const [selectedOrder, setSelectedOrder] = useState(null);
    const [ordersPanelCollapsed, setOrdersPanelCollapsed] = useState(false);
    const [availableOrderItems, setAvailableOrderItems] = useState([]);
    const [selectedQuantities, setSelectedQuantities] = useState({});
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: 'contains' },
        item_name: { value: null, matchMode: 'contains' },
        ordered_quantity: { value: null, matchMode: 'equals' },
        already_shipped: { value: null, matchMode: 'equals' },
        already_in_this_shipment: { value: null, matchMode: 'equals' },
        remaining_to_ship: { value: null, matchMode: 'equals' },
        reserved_quantity: { value: null, matchMode: 'equals' },
        available_quantity: { value: null, matchMode: 'equals' }
    });
    const [globalFilterValue, setGlobalFilterValue] = useState('');
    const [itemFilterValue, setItemFilterValue] = useState('');
    const [visibleColumns, setVisibleColumns] = useState({
        item_name: true,
        ordered_quantity: true,
        already_shipped: true,
        already_in_this_shipment: true,
        remaining_to_ship: true,
        reserved_quantity: true,
        available_quantity: true,
        quantity: true
    });
    const [selection, setSelection] = useState([]);
    // Remove unused state
    const [respectReserved, setRespectReserved] = useState(true);
    // Remove unused state
    const [currentShipmentItems, setCurrentShipmentItems] = useState(null);

    // State for selection
    const [selectedRows, setSelectedRows] = useState([]);
    const [selectAll, setSelectAll] = useState(false);
    const [ignoreReservedInventory, setIgnoreReservedInventory] = useState(false);
    const [maxQuantityDialogOpen, setMaxQuantityDialogOpen] = useState(false);

    // Process available orders
    const orders = useMemo(() => {
        if (!availableOrders) return [];
        return Array.isArray(availableOrders) ? availableOrders : [availableOrders];
    }, [availableOrders]);
    
    // Save and restore selected order using session storage
    useEffect(() => {
        if (selectedOrder) {
            sessionStorage.setItem(`shipment_${shipmentId}_selected_order`, selectedOrder.order_id);
        }
    }, [selectedOrder, shipmentId]);
    
    // When available orders change and we have no selection, try to restore from session storage
    useEffect(() => {
        if (!selectedOrder && orders.length > 0 && shipmentId) {
            const savedOrderId = sessionStorage.getItem(`shipment_${shipmentId}_selected_order`);
            if (savedOrderId) {
                const matchingOrder = orders.find(order => order.order_id.toString() === savedOrderId);
                if (matchingOrder) {
                    setSelectedOrder(matchingOrder);
                }
            }
        }
    }, [orders, selectedOrder, shipmentId]);

    // Load available items when an order is selected or when respectReserved changes
    useEffect(() => {
        if (selectedOrder) {
            // Save current quantities before reload
            const currentQuantities = {...selectedQuantities};
            
            // Load available items with saved quantities
            loadAvailableItems().then(() => {
                // Restore quantities after items are loaded
                setSelectedQuantities(prevQuantities => ({
                    ...prevQuantities,
                    ...currentQuantities
                }));
            });
        }
    }, [selectedOrder, respectReserved, ignoreReservedInventory]);

    const loadAvailableItems = async () => {
        setLoading(true);
        try {
            // Respect reserved is the opposite of ignore reserved
            const shouldRespectReserved = respectReserved && !ignoreReservedInventory;
            
            // Add timestamp to prevent caching
            const timestamp = new Date().getTime();
            const response = await shipmentCreationApi.getAvailableOrderItems(
                shipmentId,
                selectedOrder.order_id,
                shouldRespectReserved,
                timestamp // Cache-busting parameter
            );
            
            if (response?.success && Array.isArray(response.data)) {
                console.log("Refreshed order items, received:", response.data.length, "items");
                
                // Process data to ensure all fields are properly parsed
                const items = response.data.map(item => ({
                    ...item,
                    order_item_id: parseInt(item.order_item_id || 0),
                    warehouse_item_id: parseInt(item.warehouse_item_id || 0),
                    ordered_quantity: parseInt(item.ordered_quantity || 0),
                    total_quantity: parseInt(item.total_quantity || 0),
                    available_quantity: parseInt(item.available_quantity || 0),
                    reserved_quantity: parseInt(item.reserved_quantity || 0),
                    already_shipped: parseInt(item.already_shipped || 0),
                    already_in_this_shipment: parseInt(item.already_in_this_shipment || 0),
                    remaining_to_ship: parseInt(item.remaining_to_ship || 0)
                }));
                
                setAvailableOrderItems(items);
                
                // Keep existing quantities without resetting them
                // This will preserve user input when toggling reserved inventory options
                
                // Keep selected rows that still exist in the refreshed data
                const updatedSelectedRows = selectedRows.filter(row => 
                    items.some(item => item.order_item_id === row.order_item_id)
                );
                setSelectedRows(updatedSelectedRows);
                
                // Update selectAll state - don't filter by available_quantity
                setSelectAll(updatedSelectedRows.length === items.length);
            } else {
                console.error("Failed to refresh order items:", response?.error);
                throw new Error('Invalid response format from server');
            }
        } catch (error) {
            console.error('Error loading order items:', error);
            onError(error.message || 'Failed to load order items');
        } finally {
            setLoading(false);
        }
    };

    // Use memoized debounced function to prevent excessive re-renders
    const handleQuantityChange = useMemo(() => {
        const updateQuantity = (orderItemId, value) => {
            // Ensure value is a proper number or default to 0
            const numericValue = parseFloat(value) || 0;
            
            // Find the corresponding item
            const item = availableOrderItems.find(item => item.order_item_id === orderItemId);
            
            if (item) {
                // Calculate remaining unshipped quantity
                const remainingToShip = item.remaining_to_ship || (item.ordered_quantity - (item.already_shipped || 0));
                
                // Backend now guarantees consistent available_quantity values
                const maxQuantity = Math.min(parseInt(item.available_quantity), remainingToShip);
                const clampedValue = Math.min(Math.max(0, numericValue), maxQuantity);
                
                // Update selection state based on new value - using functional updates
                if (clampedValue <= 0) {
                    // If quantity is set to 0, remove from selection
                    setSelectedRows(prev => prev.filter(row => row.order_item_id !== orderItemId));
                } 
                else if (clampedValue > 0 && !selectedRows.find(row => row.order_item_id === orderItemId)) {
                    // If quantity is set > 0 and item not selected, add to selection
                    setSelectedRows(prev => [...prev, item]);
                }
                
                // Always update quantity value - use Math.floor to ensure we get a valid integer
                setSelectedQuantities(prev => ({
                    ...prev,
                    [orderItemId]: Math.floor(clampedValue)
                }));
            } else {
                console.warn(`Item with ID ${orderItemId} not found in available items`);
                
                // Update with the original value if item not found (shouldn't happen)
                setSelectedQuantities(prev => ({
                    ...prev,
                    [orderItemId]: Math.max(0, Math.floor(numericValue))
                }));
            }
        };
        
        // Use a short 50ms delay for debounce to make UI feel responsive
        // but still batch updates for performance
        return debounce(updateQuantity, 50);
    }, [availableOrderItems, selectedRows]);

    const [currentItemsOpen, setCurrentItemsOpen] = useState(false);
    const [successDialogOpen, setSuccessDialogOpen] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');

    const [reserveInventory, setReserveInventory] = useState(false);
    const [reserveReason, setReserveReason] = useState('');
    
    const handleAddItems = async (autoReserve = false) => {
        setAddingItems(true);
        try {
            const itemsToAdd = Object.entries(selectedQuantities)
                .filter(([_, quantity]) => quantity > 0)
                .map(([orderItemId, quantity]) => {
                    const item = availableOrderItems.find(
                        item => item.order_item_id.toString() === orderItemId.toString()
                    );
                    
                    if (!item || !item.warehouse_item_id) {
                        throw new Error(`No warehouse item found for order item ${orderItemId}`);
                    }

                    return {
                        orderItemId: parseInt(orderItemId),
                        warehouseItemId: parseInt(item.warehouse_item_id),
                        quantity: parseInt(quantity)
                    };
                });

            if (itemsToAdd.length === 0) {
                throw new Error('No items selected to add');
            }

            // Check if we should reserve the inventory
            const shouldReserve = autoReserve || reserveInventory;
            const reason = reserveReason || `Added from order ${selectedOrder.order_number}`;

            const response = await shipmentCreationApi.addOrderItems(
                shipmentId, 
                itemsToAdd,
                shouldReserve,
                reason
            );
            
            if (response.success) {
                const totalQuantity = itemsToAdd.reduce((sum, item) => sum + item.quantity, 0);
                const orderNumbers = [...new Set(itemsToAdd.map(item => {
                    const orderItem = availableOrderItems.find(oi => oi.order_item_id === item.orderItemId);
                    return orderItem?.order_number;
                }).filter(Boolean))];
                
                const successMsg = `Successfully added ${itemsToAdd.length} items (${totalQuantity} total units)` +
                    (orderNumbers.length ? ` from order(s) #${orderNumbers.join(', #')}` : '') +
                    (shouldReserve ? ' and reserved inventory' : '');
                
                setSuccessMessage(successMsg);
                setSuccessDialogOpen(true);
                setSelectedOrderItems(prev => [...prev, ...itemsToAdd]);
                
                // Save current order before refreshing
                const currentOrder = selectedOrder;
                
                // Clear temporary state for refresh
                setAvailableOrderItems([]);
                setSelectedQuantities({});
                setSelectedRows([]);
                
                // Refresh current items data
                const shipmentResponse = await shipmentCreationApi.getShipmentDetails(shipmentId);
                if (shipmentResponse.success) {
                    setCurrentShipmentItems(shipmentResponse.data);
                }
                
                // Continue with same order - IMPORTANT TO REFRESH DATA
                if (currentOrder) {
                    console.log("Refreshing data for order:", currentOrder.order_id);
                    // Set the order back
                    setSelectedOrder(currentOrder);
                    
                    try {
                        // Reload available items for the same order - force refresh
                        const response = await shipmentCreationApi.getAvailableOrderItems(
                            shipmentId,
                            currentOrder.order_id,
                            respectReserved && !ignoreReservedInventory
                        );
                        
                        if (response?.success && Array.isArray(response.data)) {
                            const items = response.data.map(item => ({
                                ...item,
                                order_item_id: parseInt(item.order_item_id),
                                warehouse_item_id: parseInt(item.warehouse_item_id)
                            }));
                            console.log("Received fresh data:", items.length, "items");
                            setAvailableOrderItems(items);
                        } else {
                            console.error("Failed to refresh order items:", response?.error);
                        }
                    } catch (refreshError) {
                        console.error("Error refreshing order items:", refreshError);
                    }
                }
            } else {
                onError(response.error);
            }
        } catch (error) {
            console.error('Error in handleAddItems:', error);
            onError(error.message || 'Failed to add items to shipment');
        } finally {
            setAddingItems(false);
        }
    };

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };
        _filters['global'].value = value;
        setFilters(_filters);
        setGlobalFilterValue(value);
    };

    // Separate handler for item filter
    const onItemFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };
        _filters['global'].value = value;
        setFilters(_filters);
        setItemFilterValue(value);
    };

    // Create a column options array for user selection
    const columnOptions = [
        { label: 'Item Details', value: 'item_name' },
        { label: 'Ordered', value: 'ordered_quantity' },
        { label: 'Already Shipped', value: 'already_shipped' },
        { label: 'In This Shipment', value: 'already_in_this_shipment' },
        { label: 'Remaining', value: 'remaining_to_ship' },
        { label: 'Reserved', value: 'reserved_quantity' },
        { label: 'Inventory', value: 'available_quantity' },
        { label: 'Quantity', value: 'quantity' }
    ];

    // Add a column selector component
    const columnSelectionTemplate = () => {
        return (
            <MultiSelect
                value={Object.keys(visibleColumns).filter(key => visibleColumns[key])}
                options={columnOptions}
                onChange={(e) => {
                    const selectedColumns = e.value;
                    const updatedVisibleColumns = {};
                    
                    // Set all columns to hidden
                    columnOptions.forEach(option => {
                        updatedVisibleColumns[option.value] = false;
                    });
                    
                    // Set selected columns to visible
                    selectedColumns.forEach(column => {
                        updatedVisibleColumns[column] = true;
                    });
                    
                    setVisibleColumns(updatedVisibleColumns);
                }}
                placeholder="Show/Hide Columns"
                className="p-column-filter"
                style={{ width: '12rem' }}
            />
        );
    };

    const renderHeader = (title) => {
        return (
            <Box className="flex justify-content-between align-items-center" sx={{ mb: 1 }}>
                <Typography variant="h6">{title}</Typography>
                <Box className="flex align-items-center" sx={{ gap: 2 }}>
                    <span className="p-input-icon-left">
                        <i className="pi pi-search" />
                        <InputText
                            value={itemFilterValue}
                            onChange={onItemFilterChange}
                            placeholder="Search items..."
                            className="p-inputtext-sm"
                        />
                    </span>
                    <div>
                        {columnSelectionTemplate()}
                    </div>
                </Box>
            </Box>
        );
    };

    // Define filter input templates for each column type
    const textFilterTemplate = (options) => {
        return (
            <InputText
                value={options.value}
                onChange={(e) => options.filterCallback(e.target.value, options.index)}
                placeholder="Search"
                className="p-column-filter"
                style={{ width: '100%' }}
            />
        );
    };

    const numericFilterTemplate = (options) => {
        return (
            <InputText
                type="number"
                value={options.value}
                onChange={(e) => options.filterCallback(e.target.value, options.index)}
                placeholder="Filter"
                className="p-column-filter"
                style={{ width: '100%' }}
            />
        );
    };

    const detailsTemplate = (rowData) => {
        const details = [];
        if (rowData.item_name) details.push(rowData.item_name);
        if (rowData.type) details.push(rowData.type);
        if (rowData.size) details.push(rowData.size);
        if (rowData.shape) details.push(rowData.shape);
        if (rowData.brim) details.push(rowData.brim);
        return details.filter(Boolean).join(' - ');
    };

    // Add new helper function for number formatting
    const formatNumber = (num) => {
        if (num >= 1000000) {
            return `${(num / 1000000).toFixed(1)}M`;
        } else if (num >= 1000) {
            return `${(num / 1000).toFixed(1)}K`;
        }
        return num.toString();
    };

    const orderedQuantityTemplate = (rowData) => {
        return (
            <Typography>
                {formatNumber(rowData.ordered_quantity || 0)}
            </Typography>
        );
    };

    const availableQuantityTemplate = (rowData) => {
        const inventoryQuantity = parseInt(rowData.inventory_quantity || rowData.total_quantity) || 0;
        const reservedQuantity = parseInt(rowData.reserved_quantity) || 0;
        
        return (
            <Box>
                <Typography color={'textPrimary'}>
                    {formatNumber(inventoryQuantity)}
                </Typography>
                {reservedQuantity > 0 && (
                    <Tooltip title={`${reservedQuantity} units are reserved`}>
                        <Typography variant="caption" color="warning.main">
                            ({reservedQuantity} reserved)
                        </Typography>
                    </Tooltip>
                )}
            </Box>
        );
    };

    // Memoize the quantity template to prevent unnecessary re-renders
    const quantityTemplate = useMemo(() => (rowData) => {
        // Calculate max quantity only once 
        const remainingToShip = rowData.remaining_to_ship || (rowData.ordered_quantity - (rowData.already_shipped || 0));
        const allocatableQuantity = parseInt(rowData.allocatable_quantity || rowData.available_quantity) || 0;
        const maxQuantity = Math.min(allocatableQuantity, remainingToShip);
        
        // Use minimal rendering with direct DOM elements for best performance
        return (
            <div style={{ textAlign: 'center' }}>
                <input
                    type="number"
                    value={selectedQuantities[rowData.order_item_id] || 0}
                    onChange={(e) => handleQuantityChange(
                        rowData.order_item_id, 
                        e.target.value
                    )}
                    min={0}
                    max={maxQuantity}
                    style={{ 
                        width: '100%', 
                        padding: '8px', 
                        textAlign: 'center',
                        border: '1px solid #ccc',
                        borderRadius: '4px'
                    }}
                />
                <div style={{ 
                    marginTop: '4px', 
                    fontSize: '0.75rem', 
                    fontWeight: 'bold',
                    color: '#d32f2f',
                    whiteSpace: 'nowrap'
                }}>
                    Max: {maxQuantity}
                </div>
            </div>
        );
    }, [selectedQuantities, handleQuantityChange]);

    const getFilteredOrders = () => {
        if (!orders || !Array.isArray(orders)) return [];
        
        // Only apply search filter
        if (globalFilterValue) {
            const searchTerm = globalFilterValue.toLowerCase();
            return orders.filter(order => 
                (order.order_number?.toLowerCase().includes(searchTerm)) ||
                (order.status?.toLowerCase().includes(searchTerm)) ||
                (order.customer_name?.toLowerCase().includes(searchTerm))
            );
        }
        
        return orders;
    };

    // Simple filtered orders without extra complexity
    const filteredOrders = useMemo(() => getFilteredOrders(), [
        orders,
        globalFilterValue
    ]);

    const columns = [
        {
            field: 'order_number',
            headerName: 'Order Number',
            flex: 1,
            minWidth: 150
        },
        {
            field: 'customer_name',
            headerName: 'Customer',
            flex: 1,
            minWidth: 200
        },
        {
            field: 'status',
            headerName: 'Status',
            flex: 0.5,
            minWidth: 120
        },
        {
            field: 'total_items',
            headerName: 'Items',
            flex: 0.5,
            minWidth: 100,
            type: 'number'
        },
        {
            field: 'availability_percentage',
            headerName: 'Available',
            flex: 0.5,
            minWidth: 120,
            renderCell: (params) => `${params.value}%`
        }
    ];

    const handleSelectAll = (checked) => {
        if (checked) {
            // Select all items - don't filter by available quantity
            // This allows users to see all items, even if fully reserved
            setSelectedRows(availableOrderItems);
            
            // Keep existing quantities - don't reset to 0
            // This preserves user input when toggling "select all"
            setSelectAll(checked);
        } else {
            // Clear all selections but preserve the quantity values for future use
            setSelectedRows([]);
            // Don't reset quantities here - keep them in case user re-selects
            setSelectAll(checked);
        }
    };

    const handleRowSelect = (item, checked) => {
        let newSelectedRows;
        if (checked) {
            newSelectedRows = [...selectedRows, item];
            // Don't change the quantity when an item is selected
            // This allows previously set quantities to be preserved
        } else {
            newSelectedRows = selectedRows.filter(row => row.order_item_id !== item.order_item_id);
            // Clear quantity only when explicitly deselected by the user
            setSelectedQuantities(prev => ({
                ...prev,
                [item.order_item_id]: 0
            }));
        }
        setSelectedRows(newSelectedRows);
        
        // Update selectAll state - don't filter by available_quantity
        setSelectAll(newSelectedRows.length === availableOrderItems.length);
    };

    const getSelectedItemsCount = () => {
        return Object.values(selectedQuantities).filter(q => q > 0).length;
    };

    // Update the getTotalSelectedQuantity function
    const getTotalSelectedQuantity = () => {
        const total = Object.values(selectedQuantities).reduce((sum, q) => sum + (q || 0), 0);
        return formatNumber(total);
    };

    return (
        <Box sx={{ width: '100%', height: '100%' }}>
            <Grid container spacing={3} sx={{ height: '100%', p: 2 }}>
                {/* Left Panel - Available Orders (30%) with the ability to minimize */}
                <Grid item xs={12} md={ordersPanelCollapsed ? 1 : (selectedOrder ? 3.6 : 12)} sx={{ 
                    height: '100%',
                    transition: 'all 0.3s ease'
                }}>
                    <Paper 
                        elevation={2} 
                        sx={{ 
                            p: ordersPanelCollapsed ? 1 : 3, 
                            height: '100%',
                            overflow: 'auto'
                        }}
                    >
                        <Box sx={{ mb: 2 }}>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1 }}>
                                {!ordersPanelCollapsed && (
                                    <Typography variant="h6" gutterBottom>
                                        Available Orders
                                    </Typography>
                                )}
                                <Box sx={{ display: 'flex', gap: 1, ml: ordersPanelCollapsed ? 0 : 'auto' }}>
                                    {!ordersPanelCollapsed && (
                                        <Button
                                            variant="outlined"
                                            size="small"
                                            onClick={() => setCurrentItemsOpen(true)}
                                        >
                                            View Current Items
                                        </Button>
                                    )}
                                    {selectedOrder && (
                                        <IconButton
                                            size="small"
                                            color="primary"
                                            onClick={() => setOrdersPanelCollapsed(!ordersPanelCollapsed)}
                                            sx={{ ml: ordersPanelCollapsed ? -1 : 0 }}
                                        >
                                            {ordersPanelCollapsed ? 
                                                <span>⮞</span> : 
                                                <span>⮜</span>
                                            }
                                        </IconButton>
                                    )}
                                </Box>
                            </Box>
                            {!ordersPanelCollapsed && (
                                <TextField
                                    fullWidth
                                    size="small"
                                    placeholder="Search orders..."
                                    value={globalFilterValue}
                                    onChange={onGlobalFilterChange}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <SearchIcon />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            )}
                        </Box>
                        {!ordersPanelCollapsed && (
                            <List sx={{ overflow: 'auto' }}>
                                {getFilteredOrders().map((order) => (
                                    <ListItem
                                        key={order.order_id}
                                        button
                                        selected={selectedOrder?.order_id === order.order_id}
                                        onClick={() => {
                                            setSelectedOrder(order);
                                            // Auto-collapse panel when selecting an order on mobile
                                            if (window.innerWidth < 960) {
                                                setOrdersPanelCollapsed(true);
                                            }
                                        }}
                                        sx={{
                                            mb: 1,
                                            borderRadius: 1,
                                            border: '1px solid',
                                            borderColor: selectedOrder?.order_id === order.order_id ? 'primary.main' : 'divider',
                                            backgroundColor: selectedOrder?.order_id === order.order_id ? 'rgba(25, 118, 210, 0.08)' : 'transparent'
                                        }}
                                    >
                                        <ListItemText
                                            primary={
                                                <Typography variant="subtitle1" color={selectedOrder?.order_id === order.order_id ? "primary.main" : "text.secondary"} fontWeight={selectedOrder?.order_id === order.order_id ? "bold" : "normal"}>
                                                    {order.customer_name} - {order.order_number}
                                                </Typography>
                                            }
                                            secondary={
                                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mt: 1 }}>
                                                    <Typography variant="body2" color="text.secondary">
                                                        Items: {order.total_items}
                                                    </Typography>
                                                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                                        <Tooltip title="Percentage breakdown of order items">
                                                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                                                                {order.percent_in_shipments > 0 && (
                                                                    <Chip 
                                                                        size="small" 
                                                                        label={`${order.percent_in_shipments}% in shipments`}
                                                                        color="warning"
                                                                    />
                                                                )}
                                                                {order.percent_available > 0 && (
                                                                    <Chip 
                                                                        size="small" 
                                                                        label={order.percent_available === 100 ? 
                                                                            "Check reserved inventory" : 
                                                                            `${order.percent_available}% available`}
                                                                        color={order.percent_available === 100 ? "warning" : "success"}
                                                                    />
                                                                )}
                                                                {order.percent_not_available > 0 && (
                                                                    <Chip 
                                                                        size="small" 
                                                                        label={`${order.percent_not_available}% not available`}
                                                                        color="error"
                                                                    />
                                                                )}
                                                            </Box>
                                                        </Tooltip>
                                                    </Box>
                                                </Box>
                                            }
                                        />
                                    </ListItem>
                                ))}
                            </List>
                        )}
                        {ordersPanelCollapsed && selectedOrder && (
                            <Box sx={{ 
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                height: '100%'
                            }}>
                                <Typography 
                                    variant="body2" 
                                    sx={{ 
                                        transform: 'rotate(90deg)',
                                        whiteSpace: 'nowrap',
                                        marginTop: '100px',
                                        fontWeight: 'bold'
                                    }}
                                >
                                    Order #{selectedOrder.order_number}
                                </Typography>
                            </Box>
                        )}
                    </Paper>
                </Grid>

                {/* Right Panel - Selected Order Items (70%) */}
                {selectedOrder && (
                  <Grid item xs={12} md={ordersPanelCollapsed ? 11 : 8.4} sx={{ 
                    height: '100%',
                    transition: 'all 0.3s ease'
                  }}>
                    <Paper 
                        elevation={2} 
                        sx={{ 
                            p: 3, 
                            height: '100%',
                            overflow: 'auto'
                        }}
                    >
                        <Box>
                            <Box sx={{ mb: 2 }}>
                                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                                    <Typography variant="h6">
                                        Order Items for {selectedOrder.order_number}
                                    </Typography>
                                    <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={ignoreReservedInventory}
                                                    onChange={(e) => setIgnoreReservedInventory(e.target.checked)}
                                                />
                                            }
                                            label={
                                                <Tooltip title="When enabled, shows all available inventory including reserved items">
                                                    <Typography variant="body2">Ignore reserved inventory</Typography>
                                                </Tooltip>
                                            }
                                        />
                                        <Button
                                            variant="outlined"
                                            onClick={() => setSelectedOrder(null)}
                                        >
                                            Minimize Order Panel
                                        </Button>
                                    </Box>
                                </Box>
                                    
                                    <Box sx={{ 
                                        display: 'flex', 
                                        justifyContent: 'space-between', 
                                        alignItems: 'center',
                                        bgcolor: 'background.paper',
                                        p: 2,
                                        borderRadius: 1,
                                        border: '1px solid',
                                        borderColor: 'divider',
                                        mb: 2
                                    }}>
                                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                            <Checkbox
                                                checked={selectAll}
                                                onChange={(e) => handleSelectAll(e.target.checked)}
                                                indeterminate={selectedRows.length > 0 && selectedRows.length < availableOrderItems.length}
                                            />
                                            <Typography>
                                                {selectedRows.length > 0 ? (
                                                    `${selectedRows.length} items selected (${getTotalSelectedQuantity()})`
                                                ) : (
                                                    'Select items'
                                                )}
                                            </Typography>
                                        </Box>
                                        
                                        {selectedRows.length > 0 && (
                                            <Box sx={{ display: 'flex', gap: 1, flexDirection: 'column', alignItems: 'flex-end' }}>
                                                <Box sx={{ display: 'flex', gap: 1 }}>
                                                    <Button
                                                        variant="outlined"
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            setMaxQuantityDialogOpen(true);
                                                        }}
                                                        disabled={addingItems}
                                                    >
                                                        Set Max Quantities
                                                    </Button>
                                                </Box>
                                                
                                                <Box sx={{ display: 'flex', gap: 1 }}>
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            // Show confirmation dialog to ask about reserving
                                                            const shouldReserve = window.confirm("Do you want to reserve the inventory when adding these items?");
                                                            handleAddItems(shouldReserve);
                                                        }}
                                                        disabled={addingItems || !selectedRows.some(item => (selectedQuantities[item.order_item_id] || 0) > 0)}
                                                        startIcon={addingItems && <CircularProgress size={20} />}
                                                    >
                                                        {addingItems ? 'Adding...' : 'Add Items to Shipment'}
                                                    </Button>
                                                </Box>
                                            </Box>
                                        )}
                                    </Box>
                                </Box>

                                <DataTable
                                    value={availableOrderItems}
                                    loading={loading}
                                    filters={filters}
                                    globalFilterFields={['item_name', 'type', 'size', 'shape', 'brim']}
                                    header={renderHeader('Available Items')}
                                    emptyMessage="No items available"
                                    responsiveLayout="scroll"
                                    selection={selectedRows}
                                    onSelectionChange={(e) => {
                                        setSelectedRows(e.value);
                                        // Only clear quantities for items being deselected
                                        const newQuantities = { ...selectedQuantities };
                                        
                                        // Items were removed from selection - set their quantities to 0
                                        selectedRows.forEach(item => {
                                            if (!e.value.some(val => val.order_item_id === item.order_item_id)) {
                                                newQuantities[item.order_item_id] = 0;
                                            }
                                        });
                                        
                                        // For newly selected items, keep their existing quantities
                                        setSelectedQuantities(newQuantities);
                                    }}
                                    paginator
                                    rows={10}
                                    rowsPerPageOptions={[5, 10, 25, 50]}
                                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                                    selectionMode="multiple"
                                    columnResizeMode="fit"
                                    filterDisplay="menu"
                                    showGridlines
                                >
                                    <Column 
                                        selectionMode="multiple" 
                                        headerStyle={{ width: '3rem' }}
                                        exportable={false}
                                    />
                                    {visibleColumns.item_name && (
                                        <Column 
                                            field="item_name" 
                                            header="Item Details" 
                                            body={detailsTemplate} 
                                            sortable 
                                            filter 
                                            filterPlaceholder="Search by item"
                                            filterElement={textFilterTemplate}
                                        />
                                    )}
                                    {visibleColumns.ordered_quantity && (
                                        <Column 
                                            field="ordered_quantity" 
                                            header="Ordered" 
                                            body={orderedQuantityTemplate}
                                            sortable 
                                            filter
                                            filterElement={numericFilterTemplate}
                                            style={{ width: '100px', textAlign: 'center' }}
                                        />
                                    )}
                                    {visibleColumns.already_shipped && (
                                        <Column 
                                            field="already_shipped" 
                                            header="Already Shipped" 
                                            sortable
                                            filter
                                            filterElement={numericFilterTemplate}
                                            style={{ width: '100px', textAlign: 'center' }}
                                        />
                                    )}
                                    {visibleColumns.already_in_this_shipment && (
                                        <Column 
                                            field="already_in_this_shipment" 
                                            header="In This Shipment" 
                                            sortable
                                            filter
                                            filterElement={numericFilterTemplate}
                                            style={{ width: '100px', textAlign: 'center' }}
                                        />
                                    )}
                                    {visibleColumns.remaining_to_ship && (
                                        <Column 
                                            field="remaining_to_ship" 
                                            header="Remaining" 
                                            sortable
                                            filter
                                            filterElement={numericFilterTemplate}
                                            style={{ width: '100px', textAlign: 'center' }}
                                        />
                                    )}
                                    {visibleColumns.reserved_quantity && (
                                        <Column 
                                            field="reserved_quantity"
                                            header="Reserved"
                                            sortable
                                            filter
                                            filterElement={numericFilterTemplate}
                                            style={{ width: '100px', textAlign: 'center' }}
                                            body={(rowData) => {
                                                const reservedQuantity = parseInt(rowData.reserved_quantity) || 0;
                                                
                                                return (
                                                    <Typography 
                                                        color={reservedQuantity > 0 ? "warning.main" : "textSecondary"}
                                                    >
                                                        {formatNumber(reservedQuantity)}
                                                    </Typography>
                                                );
                                            }}
                                        />
                                    )}
                                    {visibleColumns.available_quantity && (
                                        <Column 
                                            field="available_quantity" 
                                            header="Inventory" 
                                            body={availableQuantityTemplate}
                                            sortable 
                                            filter
                                            filterElement={numericFilterTemplate}
                                            style={{ width: '100px', textAlign: 'center' }}
                                        />
                                    )}
                                    {visibleColumns.quantity && (
                                        <Column 
                                            field="quantity" 
                                            header="Quantity" 
                                            body={quantityTemplate}
                                            style={{ width: '120px', textAlign: 'center' }}
                                        />
                                    )}
                                </DataTable>
                            </Box>
                    </Paper>
                  </Grid>
                )}
            </Grid>

            {/* Current Shipment Items Dialog */}
            <CurrentShipmentItems
                open={currentItemsOpen}
                onClose={() => setCurrentItemsOpen(false)}
                shipmentId={shipmentId}
            />

            {/* Success Dialog */}
            <Dialog
                open={successDialogOpen}
                onClose={() => {
                    setSuccessDialogOpen(false);
                    // Refresh current items when closing success dialog
                    const loadCurrentItems = async () => {
                        try {
                            const response = await shipmentCreationApi.getShipmentDetails(shipmentId);
                            console.log('Refreshed shipment details:', response);
                            if (response.success) {
                                setCurrentShipmentItems(response.data);
                            }
                        } catch (error) {
                            console.error('Error refreshing shipment details:', error);
                        }
                    };
                    loadCurrentItems();
                }}
                maxWidth="sm"
                fullWidth
            >
                <DialogTitle>Success</DialogTitle>
                <DialogContent>
                    <Typography>{successMessage}</Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {
                        setSuccessDialogOpen(false);
                        setCurrentItemsOpen(true);
                    }}>View Current Items</Button>
                    <Button onClick={() => setSuccessDialogOpen(false)} variant="contained">Continue</Button>
                </DialogActions>
            </Dialog>

            {/* Simple Maximum Quantity Dialog */}
            <Dialog
                open={maxQuantityDialogOpen}
                onClose={() => setMaxQuantityDialogOpen(false)}
                maxWidth="xs"
                fullWidth
            >
                <DialogTitle>Set Maximum Quantities</DialogTitle>
                <DialogContent>
                    <Box sx={{ py: 1 }}>
                        <Typography variant="body1">
                            Use reserved inventory?
                        </Typography>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button 
                        onClick={(e) => {
                            e.preventDefault();
                            // Respect reserved - use available_quantity
                            const newQuantities = { ...selectedQuantities };
                            
                            console.log("Setting max quantities with RESPECTING reserved inventory");
                            
                            selectedRows.forEach(item => {
                                // Backend now guarantees consistent inventory values
                                const availableQuantity = parseInt(item.available_quantity) || 0;
                                
                                // Calculate remaining to ship for this order item
                                const remainingToShip = item.remaining_to_ship || (item.ordered_quantity - (item.already_shipped || 0));
                                
                                // Final quantity is minimum of what's available and what's remaining to ship
                                const maxQuantity = Math.min(availableQuantity, remainingToShip);
                                
                                console.log(`Item ${item.item_name}: available=${availableQuantity}, remaining=${remainingToShip}, max=${maxQuantity}`);
                                
                                // Set the quantity
                                newQuantities[item.order_item_id] = maxQuantity;
                            });
                            
                            setSelectedQuantities(newQuantities);
                            setMaxQuantityDialogOpen(false);
                        }}
                    >
                        No - Respect Reserved
                    </Button>
                    <Button 
                        color="warning"
                        onClick={(e) => {
                            e.preventDefault();
                            // Ignore reserved - use total_quantity
                            const newQuantities = { ...selectedQuantities };
                            
                            console.log("Setting max quantities while IGNORING reserved inventory");
                            
                            selectedRows.forEach(item => {
                                // Backend now provides available_with_reserved, which is the total quantity
                                // Or fallback to total_quantity
                                const totalQuantity = parseInt(item.available_with_reserved || item.total_quantity) || 0;
                                
                                // Calculate remaining to ship for this order item
                                const remainingToShip = item.remaining_to_ship || (item.ordered_quantity - (item.already_shipped || 0));
                                
                                // Final quantity is minimum of total available (ignoring reserved) and what's remaining to ship
                                const maxQuantity = Math.min(totalQuantity, remainingToShip);
                                
                                console.log(`Item ${item.item_name}: total=${totalQuantity}, remaining=${remainingToShip}, max=${maxQuantity}`);
                                
                                // Set the quantity
                                newQuantities[item.order_item_id] = maxQuantity;
                            });
                            
                            setSelectedQuantities(newQuantities);
                            setMaxQuantityDialogOpen(false);
                        }}
                    >
                        Yes - Ignore Reserved
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};