import React, { useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { shipmentCreationApi } from '../services/shipment-creation-api';
import { getAllWarehouses, getLocations, getAllCustomers } from '../services/api';
import {
    Box,
    Stepper,
    Step,
    StepLabel,
    Button,
    Typography,
    Container,
    Paper,
    Alert,
    CircularProgress,
    Backdrop,
    IconButton,
    Tooltip,
    Snackbar
} from '@mui/material';
import FullScreenLayout from './layouts/FullScreenLayout';
import InventoryIcon from '@mui/icons-material/Inventory';

// Import step components
import GeneralInformationStep from './shipment/GeneralInformationStep';
import OrderItemsStep from './shipment/OrderItemsStep';
import AdditionalItemsStep from './shipment/AdditionalItemsStep';
import ReviewStep from './shipment/ReviewStep';
import ShipmentItemsPopup from './shipment/ShipmentItemsPopup';

const steps = [
    'General Information',
    'Order Items',
    'Additional Items',
    'Review & Confirm'
];

export default function CreateShipment() {
    const navigate = useNavigate();
    const [activeStep, setActiveStep] = useState(0);
    const [error, setError] = useState(null);
    const [shipmentId, setShipmentId] = useState(null);
    const [shipmentDetails, setShipmentDetails] = useState(null);
    const [warehouses, setWarehouses] = useState([]);
    const [locations, setLocations] = useState([]);
    const [loadingData, setLoadingData] = useState(true);
    const [customers, setCustomers] = useState([]);
    const [itemTypes, setItemTypes] = useState([]);
    // Default settings
    const [shipmentSettings] = useState({
        orderStatuses: ['processing'],
        selectedCustomers: []
    });

    // Step 1 state
    const [generalInfo, setGeneralInfo] = useState({
        sourceWarehouseId: '',
        destinationType: 'warehouse',
        destinationId: '',
        shippingMethod: 'air',
        scheduledDate: '',
        carrier: '',
        trackingNumber: '',
        notes: ''
    });

    // Separate state for order items and additional items
    const [selectedOrderItems, setSelectedOrderItems] = useState([]);
    const [selectedAdditionalItems, setSelectedAdditionalItems] = useState([]);
    const [availableOrders, setAvailableOrders] = useState([]);

    const [searchParams] = useSearchParams();
    const draftId = searchParams.get('draft');

    const [itemsPopupOpen, setItemsPopupOpen] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [currentShipmentItems, setCurrentShipmentItems] = useState(null);
    const [loadingItems, setLoadingItems] = useState(false);

    const loadShipmentDetails = async () => {
        if (!shipmentId) return;
        
        setLoadingData(true);
        try {
            const response = await shipmentCreationApi.getShipmentDetails(shipmentId, shipmentSettings);
            console.log('Loaded shipment details:', response);
            
            if (response.success) {
                const shipmentData = response.data.data || response.data;
                setShipmentDetails(shipmentData);
                setError(null);
            } else {
                setError(response.error || 'Failed to load shipment details');
            }
        } catch (error) {
            console.error('Error loading shipment details:', error);
            setError('Failed to load shipment details');
        } finally {
            setLoadingData(false);
        }
    };

    useEffect(() => {
        console.log('Available Orders State:', availableOrders);
    }, [availableOrders]);

    const handleNext = async () => {
        setError(null);
        const stepLoading = setLoadingData;
        
        try {
            stepLoading(true);
            
            if (activeStep === 0) {
                // Validate required fields
                if (!generalInfo.sourceWarehouseId) {
                    throw new Error('Source warehouse is required');
                }
                if (!generalInfo.destinationId) {
                    throw new Error('Destination is required');
                }
                if (!generalInfo.shippingMethod) {
                    throw new Error('Shipping method is required');
                }

                const response = await shipmentCreationApi.createInitialShipment(generalInfo);
                if (!response?.success) {
                    throw new Error(response?.error || 'Failed to create shipment');
                }
                
                setShipmentId(response.data.shipmentId);
                handleSuccessMessage('Shipment created successfully');
                
                const ordersResponse = await shipmentCreationApi.getAvailableOrders(
                    response.data.shipmentId,
                    shipmentSettings
                );
                setAvailableOrders(ordersResponse.data || []);
                
                // Show warning message if no orders are available, but allow proceeding
                if (ordersResponse.data?.length === 0) {
                    setError(ordersResponse.message || 'No orders available. You can still proceed to add additional items, or adjust filter settings to see more orders.');
                }
            }

            // Validate before moving to review step
            if (activeStep === 2) {
                // Load shipment details to verify items were added
                const response = await shipmentCreationApi.getShipmentDetails(shipmentId, shipmentSettings);
                if (!response?.success) {
                    throw new Error('Failed to verify shipment items');
                }

                const shipmentData = response.data.data || response.data;
                const hasItems = shipmentData.items && shipmentData.items.length > 0;
                
                if (!hasItems) {
                    throw new Error('Please add at least one item to the shipment before proceeding');
                }

                setShipmentDetails(shipmentData);
            }
            
            setActiveStep((prevStep) => prevStep + 1);
            
            if (activeStep === steps.length - 2) {
                await loadShipmentDetails();
            }
        } catch (error) {
            console.error('Error in handleNext:', error);
            setError(error.message || 'Failed to proceed to next step');
            return; // Don't proceed if there's an error
        } finally {
            stepLoading(false);
        }
    };

    const handleBack = () => {
        setActiveStep((prevStep) => prevStep - 1);
    };

    const handleSubmit = async () => {
        setLoadingData(true);
        try {
            // Final review has been done, navigate to shipment details
            navigate(`/shipments/${shipmentId}`);
        } catch (error) {
            setError('Failed to complete shipment creation');
        } finally {
            setLoadingData(false);
        }
    };

    const handleDelete = async () => {
        if (!shipmentId) return;
        
        setLoadingData(true);
        try {
            const response = await shipmentCreationApi.deleteShipment(shipmentId);
            if (response.success) {
                navigate('/shipments');
            } else {
                setError(response.error);
            }
        } catch (error) {
            setError('Failed to delete shipment');
        } finally {
            setLoadingData(false);
        }
    };

    const renderStepContent = (step) => {
        switch (step) {
            case 0:
                return (
                    <GeneralInformationStep
                        generalInfo={generalInfo}
                        setGeneralInfo={setGeneralInfo}
                        warehouses={warehouses}
                        locations={locations}
                        loading={loadingData}
                    />
                );
            case 1:
                return (
                    <OrderItemsStep
                        shipmentId={shipmentId}
                        availableOrders={availableOrders}
                        selectedOrderItems={selectedOrderItems}
                        setSelectedOrderItems={setSelectedOrderItems}
                        onError={setError}
                        setAvailableOrders={setAvailableOrders}
                        onSuccess={handleSuccessMessage}
                    />
                );
            case 2:
                return (
                    <AdditionalItemsStep
                        shipmentId={shipmentId}
                        additionalItems={selectedAdditionalItems}
                        setAdditionalItems={setSelectedAdditionalItems}
                        onError={setError}
                        onSuccess={(message) => {
                            handleSuccessMessage(message);
                            // Refresh shipment details after adding items
                            loadShipmentDetails();
                        }}
                    />
                );
            case 3:
                return (
                    <ReviewStep
                        shipmentDetails={shipmentDetails || { items: [] }}
                        loading={loadingData}
                        onSuccess={() => {
                            // Refresh shipment details
                            loadShipmentDetails();
                            // Navigate to shipment details page since creation is complete
                            navigate(`/shipments/${shipmentId}`);
                        }}
                    />
                );
            default:
                return null;
        }
    };

    const renderFooter = () => (
        <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
            <Box>
                {shipmentId && (
                    <Button
                        color="error"
                        onClick={handleDelete}
                        disabled={loadingData}
                        startIcon={loadingData && <CircularProgress size={20} color="inherit" />}
                    >
                        {loadingData ? 'Deleting...' : 'Delete Shipment'}
                    </Button>
                )}
            </Box>
            <Box>
                {activeStep > 0 && (
                    <Button 
                        onClick={handleBack} 
                        sx={{ mr: 1 }}
                        disabled={loadingData}
                    >
                        Back
                    </Button>
                )}
                {activeStep === steps.length - 1 ? (
                    <Button
                        variant="contained"
                        onClick={handleSubmit}
                        color="primary"
                        disabled={loadingData}
                        startIcon={loadingData && <CircularProgress size={20} color="inherit" />}
                    >
                        {loadingData ? 'Completing...' : 'Complete Shipment'}
                    </Button>
                ) : (
                    <Button
                        variant="contained"
                        onClick={handleNext}
                        color="primary"
                        disabled={
                            loadingData ||
                            (activeStep === 0 && !generalInfo.sourceWarehouseId) ||
                            false
                        }
                        startIcon={loadingData && <CircularProgress size={20} color="inherit" />}
                    >
                        {loadingData ? 'Loading...' : 'Next'}
                    </Button>
                )}
            </Box>
        </Box>
    );

    // Add useEffect to load warehouses and locations
    useEffect(() => {
        const loadInitialData = async () => {
            setLoadingData(true);
            try {
                // Load warehouses and locations
                const [warehousesResponse, locationsResponse] = await Promise.all([
                    getAllWarehouses(),
                    getLocations()
                ]);
                
                if (Array.isArray(warehousesResponse)) {
                    setWarehouses(warehousesResponse);
                } else {
                    setError('Failed to load warehouses');
                }
                
                if (Array.isArray(locationsResponse)) {
                    setLocations(locationsResponse);
                } else {
                    setError('Failed to load locations');
                }

                // If we have a draft ID, load the draft shipment
                if (draftId) {
                    const shipmentResponse = await shipmentCreationApi.getShipmentDetails(draftId);
                    if (shipmentResponse.success) {
                        const shipmentData = shipmentResponse.data;
                        
                        // Set shipment ID
                        setShipmentId(draftId);
                        
                        // Set general info
                        setGeneralInfo({
                            sourceWarehouseId: shipmentData.source_warehouse_id,
                            destinationType: shipmentData.destination_type,
                            destinationId: shipmentData.destination_id,
                            shippingMethod: shipmentData.shipping_method,
                            scheduledDate: shipmentData.scheduled_date,
                            carrier: shipmentData.carrier,
                            trackingNumber: shipmentData.tracking_number,
                            notes: shipmentData.notes
                        });

                        // Load available orders for this shipment
                        const ordersResponse = await shipmentCreationApi.getAvailableOrders(draftId);
                        setAvailableOrders(ordersResponse.data || []);

                        // Skip to step 2 since we already have the general info
                        setActiveStep(1);
                    } else {
                        setError('Failed to load draft shipment');
                    }
                }
            } catch (error) {
                setError('Failed to load initial data');
                console.error('Error loading initial data:', error);
            } finally {
                setLoadingData(false);
            }
        };

        loadInitialData();
    }, [draftId]);

    // Load fresh shipment details only when entering review step
    useEffect(() => {
        if (activeStep === steps.length - 1 && shipmentId) {
            loadShipmentDetails();
        }
    }, [activeStep]);

    // Load customers and item types
    useEffect(() => {
        const loadCustomersAndTypes = async () => {
            try {
                const [customersResponse, itemTypesResponse] = await Promise.all([
                    getAllCustomers(),
                    shipmentCreationApi.getItemTypes()
                ]);
                
                if (Array.isArray(customersResponse)) {
                    setCustomers(customersResponse);
                }
                
                if (itemTypesResponse?.success) {
                    setItemTypes(itemTypesResponse.data);
                }
            } catch (error) {
                console.error('Error loading customers and item types:', error);
            }
        };

        loadCustomersAndTypes();
    }, []);


    // Function to load current shipment items
    const loadCurrentItems = async () => {
        if (!shipmentId) return;
        
        setLoadingItems(true);
        try {
            const response = await shipmentCreationApi.getShipmentDetails(shipmentId);
            if (response.success) {
                setCurrentShipmentItems(response.data);
            }
        } catch (error) {
            console.error('Error loading current items:', error);
        } finally {
            setLoadingItems(false);
        }
    };

    // Add success message handler
    const handleSuccessMessage = (message) => {
        setSuccessMessage(message);
        // Refresh current items when success message is shown
        loadCurrentItems();
    };

    // Update the header to include view items button
    const renderHeader = () => (
        <>
            {error && (
                <Alert 
                    severity="error" 
                    sx={{ mb: 2 }}
                    onClose={() => setError(null)}
                >
                    {error}
                </Alert>
            )}
            <Box sx={{ mb: 3, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography variant="h4">
                    Create New Shipment
                </Typography>
                {shipmentId && (
                    <Box>
                        <Tooltip title="View Current Items">
                            <IconButton 
                                onClick={() => {
                                    loadCurrentItems();
                                    setItemsPopupOpen(true);
                                }}
                                color="primary"
                            >
                                <InventoryIcon />
                            </IconButton>
                        </Tooltip>
                    </Box>
                )}
            </Box>
            <Stepper activeStep={activeStep} sx={{ mb: 4 }}>
                {steps.map((label) => (
                    <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                    </Step>
                ))}
            </Stepper>
        </>
    );

    return (
        <FullScreenLayout
            header={renderHeader()}
            mainPanel={
                <>
                    {renderStepContent(activeStep)}
                    <ShipmentItemsPopup
                        open={itemsPopupOpen}
                        onClose={() => setItemsPopupOpen(false)}
                        shipmentDetails={currentShipmentItems}
                        loading={loadingItems}
                    />
                    <Snackbar
                        open={!!successMessage}
                        autoHideDuration={6000}
                        onClose={() => setSuccessMessage('')}
                        message={successMessage}
                    />
                </>
            }
            footer={renderFooter()}
        />
    );
}
