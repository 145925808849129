import React, { useState } from 'react';
import {
  Box,
  TextField,
  IconButton,
  Button,
  Menu,
  MenuItem,
} from '@mui/material';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import ViewColumnIcon from '@mui/icons-material/ViewColumn';
import SearchIcon from '@mui/icons-material/Search';
import { StyledDataTable } from './StyledComponents';
import BatchEditDialog from './BatchEditDialog';

const WarehouseItemsTab = ({
  items,
  loading,
  filters,
  globalFilterValue,
  onGlobalFilterChange,
  selectedItems,
  setSelectedItems,
  visibleColumns,
  handleColumnVisibilityChange,
  editingItem,
  editedValues,
  setEditedValues,
  handleEditItem,
  handleSaveItem,
  setEditingItem,
  batchEditMode,
  setBatchEditMode,
  batchEditValues,
  handleBatchUpdate,
  updateProgress,
  handleBatchFieldChange,
}) => {
  const [columnMenuAnchor, setColumnMenuAnchor] = useState(null);

  const columns = [
    { field: 'item_name', header: 'Item Name', sortable: true },
    { field: 'item_type', header: 'Type', sortable: true },
    { field: 'size', header: 'Size', sortable: true },
    { field: 'shape', header: 'Shape', sortable: true },
    { field: 'brim', header: 'Brim', sortable: true },
    { field: 'quantity', header: 'Quantity', sortable: true, editable: true },
    { field: 'reserved_inventory', header: 'Reserved', sortable: true, editable: false },
    { field: 'reason_reserved', header: 'Reserved For', sortable: true, editable: false },
    { field: 'reorder_level', header: 'Reorder Level', sortable: true, editable: true },
  ];

  const renderHeader = () => {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <span className="p-input-icon-left">
            <SearchIcon />
            <InputText
              value={globalFilterValue}
              onChange={onGlobalFilterChange}
              placeholder="Search items..."
              style={{ width: '300px' }}
            />
          </span>
        </Box>
        <Box sx={{ display: 'flex', gap: 1 }}>
          <Button
            variant="outlined"
            startIcon={<ViewColumnIcon />}
            onClick={(e) => setColumnMenuAnchor(e.currentTarget)}
          >
            Columns
          </Button>
          <Menu
            anchorEl={columnMenuAnchor}
            open={Boolean(columnMenuAnchor)}
            onClose={() => setColumnMenuAnchor(null)}
          >
            {columns.map((col) => (
              <MenuItem key={col.field}>
                <Box
                  component="label"
                  sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer', width: '100%' }}
                >
                  <input
                    type="checkbox"
                    checked={visibleColumns[col.field]}
                    onChange={() => handleColumnVisibilityChange(col.field)}
                    style={{ marginRight: '8px' }}
                  />
                  {col.header}
                </Box>
              </MenuItem>
            ))}
          </Menu>
          <Button
            variant="contained"
            color="primary"
            disabled={selectedItems.length === 0}
            onClick={() => setBatchEditMode(true)}
          >
            Batch Edit ({selectedItems.length})
          </Button>
        </Box>
      </Box>
    );
  };

  const editItemTemplate = (rowData) => {
    return (
      <Box sx={{ display: 'flex', gap: 1 }}>
        {editingItem === rowData.warehouse_item_id ? (
          <>
            <IconButton color="primary" onClick={handleSaveItem} size="small">
              <SaveIcon />
            </IconButton>
            <IconButton
              color="error"
              onClick={() => setEditingItem(null)}
              size="small"
            >
              <CancelIcon />
            </IconButton>
          </>
        ) : (
          <IconButton
            color="primary"
            onClick={() => handleEditItem(rowData)}
            size="small"
          >
            <EditIcon />
          </IconButton>
        )}
      </Box>
    );
  };

  return (
    <Box sx={{ p: 2 }}>
      <StyledDataTable
        value={items}
        paginator
        rows={10}
        dataKey="warehouse_item_id"
        filters={filters}
        filterDisplay="menu"
        loading={loading}
        globalFilterFields={['item_name', 'item_type', 'size', 'shape', 'brim']}
        header={renderHeader()}
        emptyMessage="No items found."
        responsiveLayout="scroll"
        scrollHeight="600px"
        selection={selectedItems}
        onSelectionChange={(e) => setSelectedItems(e.value)}
        selectionMode="multiple"
        className="warehouse-items-table"
      >
        <Column selectionMode="multiple" headerStyle={{ width: '3rem' }} />
        {columns.map(col => (
          visibleColumns[col.field] && (
            <Column
              key={col.field}
              field={col.field}
              header={col.header}
              sortable={col.sortable}
              filter
              filterPlaceholder={`Search by ${col.header.toLowerCase()}`}
              body={col.editable ? (rowData) => (
                editingItem === rowData.warehouse_item_id ? (
                  <TextField
                    type="number"
                    value={editedValues[col.field] || ''}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (value === '' || (!isNaN(value) && parseInt(value) >= 0)) {
                        setEditedValues({
                          ...editedValues,
                          [col.field]: value
                        });
                      }
                    }}
                    size="small"
                    inputProps={{ min: 0 }}
                  />
                ) : (
                  rowData[col.field]
                )
              ) : undefined}
            />
          )
        ))}
        <Column
          header="Actions"
          body={editItemTemplate}
          style={{ width: '100px' }}
        />
      </StyledDataTable>

      <BatchEditDialog
        open={batchEditMode}
        onClose={() => {
          setBatchEditMode(false);
          // Get setBatchEditValues from props
          handleBatchFieldChange('quantity', '');
          handleBatchFieldChange('reorder_level', '');
        }}
        batchEditValues={batchEditValues}
        onBatchFieldChange={handleBatchFieldChange}
        selectedItems={selectedItems}
        onBatchUpdate={handleBatchUpdate}
        updateProgress={updateProgress}
      />
    </Box>
  );
};

export default WarehouseItemsTab;
