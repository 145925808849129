import { styled } from '@mui/material/styles';
import {
  Card,
  Typography,
  Box,
  Paper,
  TableContainer,
  Button,
  Chip,
} from '@mui/material';
import { DataTable } from 'primereact/datatable';
import { motion } from 'framer-motion';

// Styled components for the warehouse details page
export const StyledCard = styled(Card)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  backgroundColor: '#ffffff',
  borderRadius: 8,
  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
  border: '1px solid #e0e0e0',
  padding: theme.spacing(2),
}));

export const InventoryCategory = styled(Typography)(({ theme }) => ({
  fontSize: '1.1rem',
  fontWeight: 600,
  marginBottom: theme.spacing(1),
  color: '#2c3e50',
}));

export const InventoryCard = styled(motion.div)(({ theme }) => ({
  background: 'linear-gradient(135deg, #fff 0%, #f5f5f5 100%)',
  borderRadius: 16,
  padding: theme.spacing(3),
  boxShadow: '0 2px 12px rgba(0,0,0,0.03)',
  cursor: 'pointer',
  position: 'relative',
  overflow: 'hidden',
  transition: 'all 0.3s ease',
  border: `1px solid ${theme.palette.divider}`,
  '&:hover': {
    boxShadow: '0 4px 12px rgba(0,0,0,0.05)',
    transform: 'translateY(-2px)',
    borderColor: theme.palette.primary.main,
  },
}));

export const TotalInventory = styled(Typography)(({ theme }) => ({
  fontSize: '2.5rem',
  fontWeight: 700,
  color: '#2c3e50',
  marginBottom: theme.spacing(1),
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
}));

export const TypeGrid = styled(motion.div)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(auto-fit, minmax(150px, 1fr))',
  gap: theme.spacing(2),
  [theme.breakpoints.down('sm')]: {
    gridTemplateColumns: 'repeat(auto-fit, minmax(120px, 1fr))',
    gap: theme.spacing(1.5),
  },
  marginTop: theme.spacing(3),
}));

export const TypeCard = styled(motion.div)(({ theme }) => ({
  background: '#fff',
  padding: theme.spacing(2),
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(1.5),
  },
  borderRadius: 12,
  boxShadow: '0 2px 12px rgba(0,0,0,0.03)',
  border: `1px solid ${theme.palette.divider}`,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: theme.spacing(1),
  transition: 'all 0.2s ease',
  '&:hover': {
    boxShadow: '0 4px 12px rgba(0,0,0,0.05)',
    transform: 'translateY(-2px)',
    borderColor: theme.palette.primary.main,
  },
}));

export const PageContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(4),
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(2),
  },
  maxWidth: '100%',
  margin: '0 auto',
  backgroundColor: theme.palette.background.default,
}));

export const ContentPaper = styled(Paper)(({ theme }) => ({
  borderRadius: '16px',
  padding: theme.spacing(3),
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(2),
    borderRadius: '12px',
  },
  backgroundColor: theme.palette.background.paper,
  boxShadow: '0 2px 12px rgba(0,0,0,0.03)',
  overflow: 'hidden',
}));

export const HeaderContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3),
  width: '100%',
  backgroundColor: theme.palette.background.paper,
  borderBottom: `1px solid ${theme.palette.divider}`,
  position: 'sticky',
  top: 0,
  zIndex: 1100,
  boxShadow: '0 2px 4px rgba(0,0,0,0.05)'
}));

export const WarehouseNameContainer = styled(Box)(({ theme }) => ({
  display: 'inline-block',
  padding: theme.spacing(2, 3),
  borderLeft: `4px solid ${theme.palette.primary.main}`,
  background: theme.palette.background.paper,
  borderRadius: theme.spacing(1),
  boxShadow: '0 2px 4px rgba(0,0,0,0.05)',
  minWidth: '300px',
  width: 'fit-content',
  '& h1': {
    margin: 0,
    color: theme.palette.text.primary,
    fontWeight: 600,
    fontSize: '1.75rem',
    lineHeight: 1.2,
  },
  '& .location': {
    marginTop: theme.spacing(1),
    color: theme.palette.text.secondary,
    fontSize: '1rem'
  }
}));

export const HeaderButton = styled(Button)(({ theme }) => ({
  borderRadius: '8px',
  padding: '8px 16px',
  [theme.breakpoints.down('sm')]: {
    padding: '6px 12px',
    fontSize: '0.8rem',
  },
  fontWeight: 600,
  textTransform: 'none',
  boxShadow: 'none',
  '&:hover': {
    boxShadow: '0 4px 12px rgba(0,0,0,0.05)',
    transform: 'translateY(-1px)',
  },
  transition: 'all 0.2s ease',
}));

export const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: '12px',
    boxShadow: 'none',
    border: `1px solid ${theme.palette.divider}`,
  },
  '& .MuiTableHead-root': {
    '& .MuiTableCell-root': {
      backgroundColor: theme.palette.background.paper,
      fontWeight: 600,
    }
  },
  '& .MuiTableBody-root': {
    '& .MuiTableRow-root': {
      '&:hover': {
        backgroundColor: theme.palette.action.hover,
      },
      '& .MuiTableCell-root': {
        borderBottom: `1px solid ${theme.palette.divider}`,
      }
    }
  }
}));

export const StyledDataTable = styled(DataTable)`
  .p-datatable-wrapper {
    border-radius: 12px;
    border: 1px solid ${props => props.theme.palette.divider};
  }
  
  .p-datatable-header {
    background: transparent;
    border: none;
    padding: ${props => props.theme.spacing(2)};
  }
  
  .p-datatable-thead > tr > th {
    background: ${props => props.theme.palette.background.paper};
    border-bottom: 1px solid ${props => props.theme.palette.divider};
    padding: ${props => props.theme.spacing(1.5, 2)};
    font-weight: 600;
  }
  
  .p-datatable-tbody > tr > td {
    border-bottom: 1px solid ${props => props.theme.palette.divider};
    padding: ${props => props.theme.spacing(1.5, 2)};
  }
  
  .p-datatable-tbody > tr:hover {
    background-color: ${props => props.theme.palette.action.hover};
  }
`;

// Constants
// export const INVENTORY_METRICS = ['quantity'];

export const getStatusColor = (status) => {
  switch (status.toLowerCase()) {
    case 'pending':
      return 'warning';
    case 'processing':
      return 'info';
    case 'in_transit':
      return 'primary';
    case 'delivered':
      return 'success';
    case 'cancelled':
      return 'error';
    default:
      return 'default';
  }
};
