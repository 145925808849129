import React from 'react';
import {
  Box,
  Typography,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Chip,
  Button,
  CircularProgress,
  Paper,
  TableContainer,
  Alert,
} from '@mui/material';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import { getStatusColor } from './StyledComponents';

const ShipmentsTab = ({
  shipments,
  shipmentsLoading,
  shipmentsError,
  navigate,
}) => {
  return (
    <Box sx={{ p: 2 }}>
      <Box sx={{ mb: 2 }}>
        <Typography variant="h6" sx={{ mb: 2 }}>
          Undelivered Shipments
        </Typography>
      </Box>

      {shipmentsLoading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
          <CircularProgress />
        </Box>
      ) : shipmentsError ? (
        <Alert severity="error" sx={{ mb: 2 }}>
          {shipmentsError}
        </Alert>
      ) : shipments.length === 0 ? (
        <Alert severity="info">
          No undelivered shipments found
        </Alert>
      ) : (
        <TableContainer component={Paper}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>Shipment ID</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Created Date</TableCell>
                <TableCell>Items Count</TableCell>
                <TableCell>Destination</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {shipments.map((shipment) => (
                <TableRow key={shipment.shipment_id}>
                  <TableCell>{shipment.shipment_number}</TableCell>
                  <TableCell>
                    <Chip 
                      label={shipment.status} 
                      color={getStatusColor(shipment.status)}
                    />
                  </TableCell>
                  <TableCell>
                    {new Date(shipment.created_at).toLocaleDateString()}
                  </TableCell>
                  <TableCell>{shipment.items_count}</TableCell>
                  <TableCell>{shipment.destination_name}</TableCell>
                  <TableCell>
                    <Button
                      variant="contained"
                      size="small"
                      onClick={() => navigate(`/shipments/${shipment.shipment_id}/pack`)}
                      startIcon={<LocalShippingIcon />}
                      disabled={!['pending', 'processing'].includes(shipment.status)}
                    >
                      Start Packing
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Box>
  );
};

export default ShipmentsTab;
