import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Box,
  Typography,
  Collapse,
  IconButton,
  Snackbar,
  Alert,
} from '@mui/material';
import {
  Home,
  LocationOn,
  Settings,
  Store,
  Group,
  BarChart,
  DeleteOutline,
  TrendingUp,
  CompareArrows,
  Business,
  ShoppingCart,
  LocalShipping,
  Inventory,
  KeyboardArrowDown,
  KeyboardArrowUp,
  QrCode,
  AutoFixHigh,
  Assessment,
  EventNote,
  Logout,
  Menu,
  ChevronLeft,
  ChevronRight,
  ManageAccounts,
  Share as ShareIcon,
  MenuBook,
} from '@mui/icons-material';
import { styled, alpha } from '@mui/material/styles';
import { DRAWER_WIDTH } from '../constants/layout';
import { getAllWarehouses, logout, getSetting } from '../services/api';
import { motion } from 'framer-motion';

const StyledDrawer = styled(Drawer)(({ theme, isMobile, isMinimized }) => ({
  width: isMobile ? '100%' : (isMinimized ? '65px' : DRAWER_WIDTH),
  flexShrink: 0,
  '& .MuiDrawer-paper': {
    width: isMobile ? '85%' : (isMinimized ? '65px' : DRAWER_WIDTH),
    boxSizing: 'border-box',
    backgroundColor: '#ffffff',
    color: '#637381',
    border: 'none',
    boxShadow: '0 2px 20px 0 rgba(0,0,0,0.05)',
    transition: theme.transitions.create(['width', 'box-shadow'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    '&:hover': {
      boxShadow: '0 2px 20px 0 rgba(0,0,0,0.1)',
    }
  },
}));

const StyledListItem = styled(ListItem)(({ theme, active, isMinimized }) => ({
  margin: isMinimized ? '4px 8px' : '4px 16px',
  borderRadius: '12px',
  height: '48px',
  color: active ? '#1a73e8' : '#637381',
  backgroundColor: active ? alpha('#1a73e8', 0.08) : 'transparent',
  transition: theme.transitions.create(['background-color', 'color', 'transform'], {
    duration: theme.transitions.duration.shorter,
  }),
  '&:hover': {
    backgroundColor: active ? alpha('#1a73e8', 0.12) : alpha('#637381', 0.08),
    color: active ? '#1a73e8' : '#212B36',
    transform: 'translateX(4px)',
  },
  '& .MuiListItemIcon-root': {
    color: 'inherit',
    minWidth: isMinimized ? '0' : '36px',
    width: isMinimized ? '100%' : 'auto',
    display: 'flex',
    justifyContent: 'center',
    transition: theme.transitions.create(['transform', 'width', 'min-width'], {
      duration: theme.transitions.duration.shorter,
    }),
  },
  '& .MuiListItemText-primary': {
    fontSize: '0.875rem',
    fontWeight: active ? 600 : 500,
    transition: theme.transitions.create(['font-weight'], {
      duration: theme.transitions.duration.shorter,
    }),
  },
  '&:active': {
    transform: 'scale(0.98)',
  }
}));

const CategoryLabel = styled(Typography)(({ theme }) => ({
  fontSize: '0.75rem',
  fontWeight: 700,
  textTransform: 'uppercase',
  color: '#919EAB',
  padding: '24px 16px 8px',
  letterSpacing: '1px',
  position: 'relative',
  '&::after': {
    content: '""',
    position: 'absolute',
    bottom: '4px',
    left: '16px',
    width: '32px',
    height: '2px',
    backgroundColor: alpha('#1a73e8', 0.24),
    borderRadius: '2px',
  }
}));

const LogoContainer = styled(Box)(({ theme, isMinimized }) => ({
  padding: theme.spacing(2.5),
  display: 'flex',
  alignItems: 'center',
  justifyContent: isMinimized ? 'center' : 'space-between',
  borderBottom: `1px solid ${alpha('#919EAB', 0.12)}`,
  marginBottom: theme.spacing(1),
  position: 'relative',
}));

const LogoText = styled(Typography)(({ theme }) => ({
  fontWeight: 800,
  color: '#212B36',
  fontSize: '1.25rem',
  letterSpacing: '0.5px',
  background: 'linear-gradient(45deg, #1a73e8 30%, #304FFE 90%)',
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
  textShadow: '0 2px 4px rgba(26,115,232,0.2)',
}));

const LogoutButton = styled(Box)(({ theme }) => ({
  position: 'sticky',
  bottom: 0,
  width: '100%',
  padding: '16px',
  display: 'flex',
  alignItems: 'center',
  backgroundColor: '#ffffff',
  borderTop: `1px solid ${alpha('#919EAB', 0.12)}`,
  cursor: 'pointer',
  transition: 'background-color 0.2s ease',
  '&:hover': {
    backgroundColor: alpha(theme.palette.primary.main, 0.04),
  }
}));

const IconWrapper = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: '#637381',
});

const MobileToggleButton = styled(IconButton)(({ theme }) => ({
  position: 'fixed',
  left: 10,
  top: 10,
  zIndex: 1200,
  backgroundColor: '#fff',
  boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
  '&:hover': {
    backgroundColor: '#f5f5f5',
  },
  [theme.breakpoints.up('md')]: {
    display: 'none',
  },
}));

const MinimizeButton = styled(IconButton)(({ theme, isMinimized }) => ({
  transition: theme.transitions.create(['transform', 'background-color'], {
    duration: theme.transitions.duration.shorter,
  }),
  position: isMinimized ? 'absolute' : 'relative',
  right: isMinimized ? '-12px' : 'auto',
  top: isMinimized ? '50%' : 'auto',
  transform: isMinimized ? 'translateY(-50%)' : 'none',
  backgroundColor: isMinimized ? '#fff' : 'transparent',
  boxShadow: isMinimized ? '0 2px 8px rgba(0,0,0,0.1)' : 'none',
  '&:hover': {
    backgroundColor: alpha(theme.palette.primary.main, 0.08),
    transform: isMinimized ? 'translateY(-50%) scale(1.1)' : 'scale(1.1)',
  },
}));

function SideNav({ isMinimized, setIsMinimized }) {
  const location = useLocation();
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const [expandedSection, setExpandedSection] = useState(() => {
    const savedSection = localStorage.getItem('expandedSection');
    return savedSection || '';
  });
  const [warehouses, setWarehouses] = useState([]);
  const [warehousesExpanded, setWarehousesExpanded] = useState(() => {
    return localStorage.getItem('warehousesExpanded') === 'true';
  });
  const [docsExpanded, setDocsExpanded] = useState(() => {
    return localStorage.getItem('docsExpanded') === 'true';
  });
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
  const [isMobile, setIsMobile] = useState(window.innerWidth < 960);
  const [isOpen, setIsOpen] = useState(!isMobile);
  const [openInNewTab, setOpenInNewTab] = useState(() => {
    return localStorage.getItem('openInNewTab') === 'true';
  });

  const menuItems = {
    retail: [
      { text: 'Dashboard', icon: <Home />, path: '/retail/home', adminOnly: false },
      { text: 'Locations', icon: <LocationOn />, path: '/locations', adminOnly: false },
      { text: 'Transfer Orders', icon: <CompareArrows />, path: '/transfer-orders', adminOnly: true },
      { text: 'Sales', icon: <BarChart />, path: '/sales', adminOnly: true },
      { text: 'Optimize', icon: <TrendingUp />, path: '/optimize', adminOnly: true },
    ],
    wholesale: [
      { text: 'Customers', icon: <Group />, path: '/customers', adminOnly: false },
      { text: 'Shipments', icon: <LocalShipping />, path: '/shipments', adminOnly: false },
      { text: 'Orders', icon: <ShoppingCart />, path: '/orders', adminOnly: false },
      { text: 'Distribute Inventory', icon: <ShareIcon />, path: '/inventory-distribution', adminOnly: false },
      { 
        text: 'Warehouses', 
        icon: <Business />, 
        path: '/warehouses', 
        adminOnly: false, 
        hasSubItems: true,
        subItems: warehouses.map(warehouse => ({
          id: warehouse.warehouse_id,
          title: warehouse.name,
          path: `/warehouses/${warehouse.warehouse_id}`
        }))
      },
    ],
    analytics: [
      { text: 'Production Plan', icon: <EventNote />, path: '/production-plan', comingSoon: true },
      { 
        text: 'Metrics & Views', 
        icon: <BarChart />, 
        path: '/metrics-and-views',
        description: 'Track and analyze business metrics'
      },
      { text: 'Management', icon: <ManageAccounts />, path: '/management' },
      { text: 'Automation', icon: <AutoFixHigh />, path: '/automation', comingSoon: true },
      { text: 'Insights', icon: <Assessment />, path: '/insights', comingSoon: true },
    ],
    configuration: [
      { text: 'Settings', icon: <Settings />, path: '/settings', adminOnly: true },
      { text: 'User Management', icon: <Group />, path: '/user-management', adminOnly: true },
      { text: 'POS Management', icon: <Store />, path: '/pos-management', adminOnly: true },
      { text: 'Item Forwarders', icon: <Inventory />, path: '/item-forwarders', adminOnly: true },
      { text: 'Items', icon: <QrCode />, path: '/items', adminOnly: true },
      { 
        text: 'Documentation', 
        icon: <MenuBook />, 
        path: '/docs', 
        adminOnly: false,
        hasSubItems: true,
        subItems: [
          { 
            id: '1',
            title: 'Inventory Distribution',
            path: '/docs/inventory-distribution'
          }
        ]
      },
    ]
  };

  useEffect(() => {
    // Get user from localStorage and parse it
    const storedUser = localStorage.getItem('user');
    if (storedUser) {
      try {
        const parsedUser = JSON.parse(storedUser);
        setUser(parsedUser);
      } catch (error) {
        console.error('Error parsing user from localStorage:', error);
      }
    }
  }, []);

  useEffect(() => {
    // Fetch warehouses when component mounts
    const fetchWarehouses = async () => {
      try {
        const data = await getAllWarehouses();
        setWarehouses(data.filter(w => w.status === 'active'));
      } catch (error) {
        console.error('Error fetching warehouses:', error);
        setSnackbar({
          open: true,
          message: 'Failed to fetch warehouses',
          severity: 'error',
        });
      }
    };
    fetchWarehouses();
  }, []);

  useEffect(() => {
    localStorage.setItem('expandedSection', expandedSection);
  }, [expandedSection]);

  useEffect(() => {
    localStorage.setItem('warehousesExpanded', warehousesExpanded.toString());
  }, [warehousesExpanded]);

  useEffect(() => {
    localStorage.setItem('docsExpanded', docsExpanded);
  }, [docsExpanded]);

  useEffect(() => {
    const handleResize = () => {
      const mobile = window.innerWidth < 960;
      setIsMobile(mobile);
      if (!mobile) {
        setIsOpen(true);
      } else {
        setIsOpen(false);
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    // Add event listener to watch for changes in localStorage
    const handleStorageChange = (e) => {
      if (e.key === 'openInNewTab') {
        setOpenInNewTab(e.newValue === 'true');
      }
    };

    window.addEventListener('storage', handleStorageChange);
    return () => window.removeEventListener('storage', handleStorageChange);
  }, []);

  useEffect(() => {
    // Update menu items when warehouses change
    const updatedMenuItems = {
      ...menuItems,
      wholesale: menuItems.wholesale.map(item => {
        if (item.text === 'Warehouses') {
          return {
            ...item,
            subItems: warehouses.map(warehouse => ({
              id: warehouse.warehouse_id,
              title: warehouse.name,
              path: `/warehouses/${warehouse.warehouse_id}`
            }))
          };
        }
        return item;
      })
    };
    // No need to set state since menuItems is not in state
  }, [warehouses]);

  const isAuthorized = (item) => {
    if (!user) {
      return false;
    }

    if (!item.adminOnly) {
      return true;
    }

    // Give test users the same access as admins
    const isAdminOrTest = user.role === 'admin' || user.role_id === 3 || user.role === 'test';
    return isAdminOrTest;
  };

  const handleNavigate = (path) => {
    if (openInNewTab) {
      // Open in new tab
      window.open(path, '_blank');
    } else {
      // Open in same tab
      navigate(path);
    }
  };

  const handleSectionClick = (section) => {
    setExpandedSection(expandedSection === section ? '' : section);
  };

  const handleWarehousesClick = () => {
    setWarehousesExpanded(!warehousesExpanded);
  };

  const handleDocsClick = () => {
    setDocsExpanded(!docsExpanded);
  };

  const handleComingSoonClick = (itemName) => {
    setSnackbar({
      open: true,
      message: `${itemName} feature is coming soon!`,
      severity: 'info'
    });
  };

  const handleLogout = async () => {
    try {
      // Add a simple fade out animation to the entire app
      document.body.style.transition = 'opacity 0.5s ease';
      document.body.style.opacity = '0';
      
      // Clear all localStorage items
      localStorage.clear();
      
      // Call the logout API endpoint
      await logout();
      
      // Redirect after animation
      setTimeout(() => {
        window.location.href = '/login';
      }, 500);
    } catch (error) {
      console.error('Error during logout:', error);
      localStorage.clear();
      window.location.href = '/login';
    }
  };

  const renderWarehouseSubItems = () => {
    if (!warehousesExpanded) return null;

    return (
      <Collapse in={warehousesExpanded} timeout="auto">
        <List component="div" disablePadding>
          {warehouses.filter(w => w.status === 'active').map((warehouse) => (
            <StyledListItem
              button
              key={warehouse.warehouse_id}
              active={location.pathname === `/warehouses/${warehouse.warehouse_id}` ? 1 : 0}
              onClick={() => handleNavigate(`/warehouses/${warehouse.warehouse_id}`)}
              isMinimized={isMinimized}
              sx={{ 
                pl: 4,
                '&:hover': {
                  backgroundColor: (theme) => theme.palette.action.hover
                }
              }}
            >
              <ListItemIcon>
                <Business />
              </ListItemIcon>
              {!isMinimized && (
                <ListItemText 
                  primary={warehouse.name}
                  primaryTypographyProps={{
                    variant: 'body2',
                    noWrap: true
                  }}
                />
              )}
            </StyledListItem>
          ))}
        </List>
      </Collapse>
    );
  };

  const renderSubItems = (item) => {
    if (!item.hasSubItems || !item.subItems) return null;
    
    const isDocsItem = item.text === 'Documentation';
    if (isDocsItem) {
      return (
        <Collapse in={docsExpanded} timeout="auto">
          <List component="div" disablePadding>
            {item.subItems.map((subItem) => (
              <StyledListItem
                button
                key={subItem.id}
                active={location.pathname === subItem.path ? 1 : 0}
                onClick={() => handleNavigate(subItem.path)}
                sx={{ 
                  pl: 4,
                  '&:hover': {
                    backgroundColor: (theme) => theme.palette.action.hover
                  }
                }}
              >
                <ListItemIcon>
                  <MenuBook />
                </ListItemIcon>
                <ListItemText 
                  primary={subItem.title}
                  primaryTypographyProps={{
                    variant: 'body2',
                    sx: { fontSize: '0.8rem' }
                  }}
                />
              </StyledListItem>
            ))}
          </List>
        </Collapse>
      );
    }
    return renderWarehouseSubItems();
  };

  const renderMenuSection = (items, label) => {
    const authorizedItems = items.filter(item => isAuthorized(item));
    
    if (authorizedItems.length === 0) return null;

    const isExpanded = expandedSection === label;

    return (
      <Box key={label}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            cursor: 'pointer',
          }}
          onClick={() => handleSectionClick(label)}
        >
          {!isMinimized && (
            <>
              <CategoryLabel>{label}</CategoryLabel>
              <IconButton size="small" sx={{ mr: 1, transform: isExpanded ? 'rotate(180deg)' : 'none' }}>
                <KeyboardArrowDown />
              </IconButton>
            </>
          )}
        </Box>
        <Collapse in={isExpanded || isMinimized} timeout="auto">
          <List>
            {authorizedItems.map((item) => {
              const isActive = location.pathname === item.path || 
                             (item.hasSubItems && location.pathname.startsWith(item.path));
              return (
                <React.Fragment key={item.text}>
                  <StyledListItem
                    button
                    active={isActive ? 1 : 0}
                    isMinimized={isMinimized}
                    onClick={(e) => {
                      if (item.hasSubItems) {
                        if (item.text === 'Warehouses') {
                          handleWarehousesClick();
                        } else if (item.text === 'Documentation') {
                          handleDocsClick();
                        }
                        if (!e.target.closest('.expand-arrow')) {
                          handleNavigate(item.path);
                        }
                      } else if (item.comingSoon) {
                        handleComingSoonClick(item.text);
                      } else {
                        handleNavigate(item.path);
                      }
                    }}
                  >
                    <ListItemIcon>{item.icon}</ListItemIcon>
                    {!isMinimized && <ListItemText primary={item.text} />}
                    {!isMinimized && item.hasSubItems && (
                      <Box 
                        className="expand-arrow"
                        onClick={(e) => {
                          e.stopPropagation();
                          if (item.text === 'Warehouses') {
                            handleWarehousesClick();
                          } else if (item.text === 'Documentation') {
                            handleDocsClick();
                          }
                        }}
                        sx={{ 
                          padding: '4px',
                          borderRadius: '50%',
                          '&:hover': {
                            backgroundColor: 'rgba(0, 0, 0, 0.04)'
                          }
                        }}
                      >
                        <KeyboardArrowDown 
                          sx={{ 
                            transform: (item.text === 'Warehouses' && warehousesExpanded) || 
                                     (item.text === 'Documentation' && docsExpanded) ? 
                              'rotate(180deg)' : 'none',
                            transition: 'transform 0.3s'
                          }} 
                        />
                      </Box>
                    )}
                  </StyledListItem>
                  {item.hasSubItems && renderSubItems(item)}
                </React.Fragment>
              );
            })}
          </List>
        </Collapse>
      </Box>
    );
  };

  const toggleDrawer = () => {
    setIsOpen(!isOpen);
  };

  const toggleMinimize = () => {
    if (!isMobile) {
      setIsMinimized(!isMinimized);
    }
  };

  const handleExpandClick = (e, item) => {
    e.stopPropagation();
    if (item.text === 'Documentation') {
      setDocsExpanded(!docsExpanded);
    } else if (item.text === 'Warehouses') {
      setWarehousesExpanded(!warehousesExpanded);
    }
  };

  return (
    <>
      {isMobile && (
        <MobileToggleButton onClick={toggleDrawer}>
          {isOpen ? <ChevronLeft /> : <Menu />}
        </MobileToggleButton>
      )}

      <StyledDrawer 
        variant={isMobile ? "temporary" : "permanent"}
        open={isOpen}
        onClose={toggleDrawer}
        isMobile={isMobile}
        isMinimized={isMinimized}
      >
        <LogoContainer isMinimized={isMinimized}>
          <LogoText>{isMinimized ? 'PIM' : 'PIM BY ELI'}</LogoText>
          {!isMobile && (
            <MinimizeButton 
              onClick={toggleMinimize}
              isMinimized={isMinimized}
              sx={{
                transform: isMinimized ? 'translateY(-50%) rotate(180deg)' : 'none',
              }}
            >
              <ChevronLeft />
            </MinimizeButton>
          )}
        </LogoContainer>

        <Box sx={{ overflow: 'auto', height: '100%', pb: 2 }}>
          {!isMinimized ? (
            <>
              {renderMenuSection(menuItems.retail, 'RETAIL - POS')}
              {renderMenuSection(menuItems.wholesale, 'WHOLESALE')}
              {renderMenuSection(menuItems.analytics, 'ANALYTICS')}
              {renderMenuSection(menuItems.configuration, 'CONFIGURATION')}
            </>
          ) : (
            <List>
              {Object.values(menuItems).flat().map((item, index) => (
                isAuthorized(item) && (
                  <StyledListItem
                    key={index}
                    button
                    active={location.pathname === item.path ? 1 : 0}
                    onClick={() => handleNavigate(item.path)}
                    isMinimized={true}
                    sx={{
                      justifyContent: 'center',
                      padding: '12px 0',
                      margin: '4px 8px',
                      minWidth: 0,
                      '& .MuiListItemText-root': { display: 'none' }
                    }}
                  >
                    <ListItemIcon>
                      {item.icon}
                    </ListItemIcon>
                  </StyledListItem>
                )
              ))}
            </List>
          )}
        </Box>

        <LogoutButton
          onClick={handleLogout}
          sx={{
            justifyContent: isMinimized ? 'center' : 'flex-start',
            '& .MuiTypography-root': {
              display: isMinimized ? 'none' : 'block'
            }
          }}
        >
          <IconWrapper>
            <Logout />
          </IconWrapper>
          {!isMinimized && (
            <Typography
              variant="body2"
              sx={{
                ml: 2,
                color: 'text.secondary',
                fontWeight: 500
              }}
            >
              Logout
            </Typography>
          )}
        </LogoutButton>
      </StyledDrawer>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar(prev => ({ ...prev, open: false }))}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert 
          onClose={() => setSnackbar(prev => ({ ...prev, open: false }))} 
          severity={snackbar.severity}
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </>
  );
}

export default SideNav; 