import api from './api';
import axios from 'axios';

export const getAvailableInventory = async (locationId, type) => {
    try {
        const response = await api.get(`/inventory-distribution/available/${locationId}/${type}`);
        return response.data;
    } catch (error) {
        console.error('Error fetching available inventory:', error);
        throw error;
    }
};

export const createDistribution = async (data) => {
    try {
        const response = await api.post('/inventory-distribution/create', {
            sourceLocationId: data.sourceLocationId,
            destinations: data.destinations,
            items: data.items,
            distributionType: data.distributionType,
            distributionMethod: data.distributionMethod
        });
        return response.data;
    } catch (error) {
        console.error('Error creating distribution:', error);
        throw error;
    }
};

export const getDistributions = async () => {
    try {
        const response = await api.get('/inventory-distribution');
        return response.data;
    } catch (error) {
        console.error('Error fetching distributions:', error);
        throw error;
    }
};

export const getDistributionById = async (id) => {
    try {
        const response = await api.get(`/inventory-distribution/${id}`);
        return response.data;
    } catch (error) {
        console.error('Error fetching distribution details:', error);
        throw error;
    }
};

export const updateDistributionStatus = async (distributionId, statusName) => {
    try {
        const response = await api.put(`/inventory-distribution/${distributionId}/status`, { statusName });
        return response.data;
    } catch (error) {
        console.error('Error updating distribution status:', error);
        throw error;
    }
};

export const getLocationsForDistribution = async (type) => {
    try {
        const response = await api.get(`/inventory-distribution/locations/${type}`);
        return response.data;
    } catch (error) {
        console.error('Error fetching locations for distribution:', error);
        throw error;
    }
};

export const calculateDistributionPreview = (items, destinations, method, respectReplenishment = false) => {
    if (!items?.length || !destinations?.length) return null;
    
    const preview = {};
    let lastRemainderIndex = 0;
    
    // Pre-process destinations to avoid repeated calculations
    const validDestinations = destinations.filter(d => d.locationId && d.percentage);
    const totalPercentage = validDestinations.reduce((sum, d) => sum + d.percentage, 0);
    
    // Validate total percentage
    if (Math.abs(totalPercentage - 100) > 0.01) {
        console.warn(`Total percentage (${totalPercentage}%) does not equal 100%`);
        return null;
    }
    
    items.forEach(item => {
        const itemId = item.itemId || item.item_id;
        preview[itemId] = {};
        const itemQuantity = parseInt(item.quantity || item.available_quantity) || 0;
        
        // Initialize all destinations to 0
        destinations.forEach(dest => {
            preview[itemId][dest.locationId] = 0;
        });
        
        if (itemQuantity === 0) return;
        
        let distributionDestinations = [...validDestinations];
        let remainingQuantity = itemQuantity;
        
        if (respectReplenishment) {
            const destinationMatches = item.destinationMatches || [];
            const destinationNeeds = {};
            let totalNeeds = 0;
            
            // Calculate needs and validate replenishment data
            destinationMatches.forEach(match => {
                if (match.needs_replenish && 
                    typeof match.replenish_level === 'number' && 
                    typeof match.inventory_level === 'number') {
                    const neededQuantity = Math.max(0, match.replenish_level - match.inventory_level);
                    if (neededQuantity > 0) {
                        destinationNeeds[match.destination_id] = {
                            neededQuantity,
                            replenishLevel: match.replenish_level,
                            currentLevel: match.inventory_level
                        };
                        totalNeeds += neededQuantity;
                    }
                }
            });
            
            // Filter and adjust destinations based on needs
            distributionDestinations = distributionDestinations
                .filter(d => destinationNeeds[d.locationId])
                .map(d => {
                    const need = destinationNeeds[d.locationId];
                    const needPercentage = (need.neededQuantity / totalNeeds) * 100;
                    const originalPercentageWeight = 0.4;
                    const needPercentageWeight = 0.6;
                    
                    return {
                        ...d,
                        neededQuantity: need.neededQuantity,
                        scaledPercentage: (
                            (originalPercentageWeight * (d.percentage / totalPercentage) * 100) +
                            (needPercentageWeight * needPercentage)
                        )
                    };
                });
            
            // Normalize scaled percentages
            if (distributionDestinations.length > 0) {
                const totalScaled = distributionDestinations.reduce((sum, d) => sum + d.scaledPercentage, 0);
                distributionDestinations = distributionDestinations.map(d => ({
                    ...d,
                    scaledPercentage: (d.scaledPercentage / totalScaled) * 100
                }));
            }
        }
        
        // Sort destinations for consistent distribution
        distributionDestinations.sort((a, b) => {
            const percentA = respectReplenishment ? a.scaledPercentage : a.percentage;
            const percentB = respectReplenishment ? b.scaledPercentage : b.percentage;
            return percentB - percentA;
        });
        
        // If no valid destinations after filtering, skip distribution
        if (distributionDestinations.length === 0) return;
        
        let totalDistributed = 0;
        
        // First pass: Calculate and allocate floor values
        distributionDestinations.forEach(dest => {
            const percentage = respectReplenishment ? dest.scaledPercentage : dest.percentage;
            const exactQuantity = (itemQuantity * percentage) / 100;
            
            let allocatedQuantity;
            if (respectReplenishment) {
                allocatedQuantity = Math.min(
                    Math.floor(exactQuantity),
                    dest.neededQuantity
                );
            } else {
                allocatedQuantity = Math.floor(exactQuantity);
            }
            
            preview[itemId][dest.locationId] = allocatedQuantity;
            totalDistributed += allocatedQuantity;
        });
        
        remainingQuantity = itemQuantity - totalDistributed;
        
        // Second pass: Handle minimum quantities for small distributions
        if (remainingQuantity > 0) {
            const zeroAllocationDests = distributionDestinations.filter(dest => {
                if (!respectReplenishment) {
                    return preview[itemId][dest.locationId] === 0;
                }
                return preview[itemId][dest.locationId] === 0 && 
                       preview[itemId][dest.locationId] < (dest.neededQuantity || Infinity);
            });
            
            // Ensure minimum allocation if possible
            if (zeroAllocationDests.length > 0 && remainingQuantity >= zeroAllocationDests.length) {
                zeroAllocationDests.forEach(dest => {
                    if (!respectReplenishment || preview[itemId][dest.locationId] < (dest.neededQuantity || Infinity)) {
                        preview[itemId][dest.locationId] = 1;
                        remainingQuantity--;
                    }
                });
            }
            
            // Third pass: Distribute remaining using weighted round-robin
            while (remainingQuantity > 0) {
                let distributed = false;
                
                for (let i = 0; i < distributionDestinations.length; i++) {
                    const destIndex = (lastRemainderIndex + i) % distributionDestinations.length;
                    const dest = distributionDestinations[destIndex];
                    
                    if (!respectReplenishment || 
                        preview[itemId][dest.locationId] < (dest.neededQuantity || Infinity)) {
                        preview[itemId][dest.locationId]++;
                        remainingQuantity--;
                        distributed = true;
                        lastRemainderIndex = (destIndex + 1) % distributionDestinations.length;
                        break;
                    }
                }
                
                // If we couldn't distribute any more (all destinations at max), break
                if (!distributed) break;
            }
        }
        
        // Verify total distributed matches item quantity
        const finalTotal = Object.values(preview[itemId]).reduce((sum, qty) => sum + qty, 0);
        if (finalTotal !== itemQuantity) {
            console.warn(`Distribution mismatch for item ${itemId}: ${finalTotal} distributed vs ${itemQuantity} available`);
        }
    });
    
    return preview;
};