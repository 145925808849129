import React, { useState, useEffect, useMemo } from 'react';
import {
    Box,
    Typography,
    Button,
    CircularProgress,
    Backdrop,
    Tooltip,
    LinearProgress,
    FormControlLabel,
    Switch,
    Paper,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField
} from '@mui/material';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputNumber } from 'primereact/inputnumber';
import { InputText } from 'primereact/inputtext';
import { shipmentCreationApi } from '../../services/shipment-creation-api';
import { debounce } from 'lodash';

// Simplified header with filter controls
const FilterControls = React.memo(({ 
    globalFilter, 
    setGlobalFilter,
    respectReserved,
    setRespectReserved,
    onRefresh
}) => (
    <Box sx={{ mb: 2, p: 2 }} component={Paper}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
            <Typography variant="h6">Available Items</Typography>
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText
                    value={globalFilter}
                    onChange={(e) => setGlobalFilter(e.target.value)}
                    placeholder="Search items..."
                    className="p-inputtext-sm"
                />
            </span>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
            <FormControlLabel
                control={
                    <Switch
                        checked={respectReserved}
                        onChange={(e) => {
                            setRespectReserved(e.target.checked);
                            // Force reload when this changes
                            onRefresh();
                        }}
                    />
                }
                label={
                    <Tooltip title="When enabled, only shows items that are not reserved">
                        <Typography>Respect reserved inventory</Typography>
                    </Tooltip>
                }
            />
        </Box>
    </Box>
));

// Simplified quantity breakdown with clearer naming
const QuantityBreakdown = React.memo(({ rowData }) => {
    const inventoryQuantity = parseInt(rowData.inventory_quantity || rowData.total_quantity) || 0;
    const reservedQuantity = parseInt(rowData.reserved_quantity) || 0;
    const allocatableQuantity = parseInt(rowData.allocatable_quantity || rowData.available_quantity) || 0;
    const reservedCurrent = parseInt(rowData.reserved_in_current_shipment) || 0;
    const reservedOther = parseInt(rowData.reserved_in_other_shipments) || 0;

    return (
        <Tooltip title={
            <Box>
                <Typography variant="body2">Total: {inventoryQuantity}</Typography>
                {reservedCurrent > 0 && (
                    <Typography variant="body2">Current Shipment: -{reservedCurrent}</Typography>
                )}
                {reservedOther > 0 && (
                    <Typography variant="body2">Other Shipments: -{reservedOther}</Typography>
                )}
                <Typography variant="body2" color="warning.main">
                    Reserved: {reservedQuantity}
                </Typography>
                <Typography variant="body2" sx={{ fontWeight: 'bold', color: 'success.main' }}>
                    Inventory: {inventoryQuantity}
                </Typography>
                <Typography variant="body2" sx={{ fontWeight: 'bold', color: 'primary.main' }}>
                    Allocatable: {allocatableQuantity}
                </Typography>
            </Box>
        }>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <Typography color={allocatableQuantity > 0 ? 'success.main' : 'error.main'}>
                    {inventoryQuantity}
                </Typography>
                {(reservedQuantity > 0) && (
                    <Typography variant="caption" color="warning.main">
                        ({reservedQuantity} reserved)
                    </Typography>
                )}
            </Box>
        </Tooltip>
    );
});

export default function AdditionalItemsStep({
    shipmentId,
    additionalItems,
    setAdditionalItems,
    onError,
    onSuccess
}) {
    const [loading, setLoading] = useState(false);
    const [addingItems, setAddingItems] = useState(false);
    const [availableItems, setAvailableItems] = useState([]);
    const [selectedItems, setSelectedItems] = useState([]);
    const [globalFilter, setGlobalFilter] = useState('');
    const [respectReserved, setRespectReserved] = useState(true);
    const [reserveReason, setReserveReason] = useState('');
    const [maxQuantityDialogOpen, setMaxQuantityDialogOpen] = useState(false);

    // Load available items when filters change
    useEffect(() => {
        if (shipmentId) {
            loadAvailableItems();
        }
    }, [shipmentId, respectReserved]);

    // Sync with parent state
    useEffect(() => {
        if (additionalItems?.length) {
            const itemsWithDetails = availableItems.filter(item => 
                additionalItems.some(ai => ai.warehouseItemId === item.warehouse_item_id)
            ).map(item => ({
                ...item,
                quantity_to_add: additionalItems.find(
                    ai => ai.warehouseItemId === item.warehouse_item_id
                )?.quantity || 0
            }));
            setSelectedItems(itemsWithDetails);
        }
    }, [additionalItems, availableItems]);

    const loadAvailableItems = async () => {
        setLoading(true);
        try {
            // Add cache-busting parameter to ensure we get fresh data
            const timestamp = new Date().getTime();
            const response = await shipmentCreationApi.getRemainingInventory(
                shipmentId, 
                respectReserved,
                timestamp // This won't be used by the backend but prevents browser caching
            );
            
            if (response?.success) {
                console.log("Refreshed available items:", response.data.length, "items");
                
                // Process the data to ensure all properties are properly parsed
                const processedData = response.data.map(item => ({
                    ...item,
                    warehouse_item_id: parseInt(item.warehouse_item_id || 0),
                    item_id: parseInt(item.item_id || 0),
                    total_quantity: parseInt(item.total_quantity || 0),
                    available_quantity: parseInt(item.available_quantity || 0),
                    reserved_quantity: parseInt(item.reserved_quantity || 0),
                    quantity_in_shipment: parseInt(item.quantity_in_shipment || 0)
                }));
                
                setAvailableItems(processedData);
            } else {
                console.error("Failed to refresh inventory:", response?.error);
                onError(response?.error || 'Failed to load inventory');
            }
        } catch (error) {
            console.error("Error refreshing inventory:", error);
            onError('Failed to load inventory');
        } finally {
            setLoading(false);
        }
    };

    const handleAddItems = async (shouldReserve = false) => {
        if (selectedItems.length === 0) return;

        setAddingItems(true);
        try {
            const itemsToAdd = selectedItems
                .filter(item => item.quantity_to_add > 0)
                .map(item => ({
                    warehouseItemId: item.warehouse_item_id,
                    quantity: item.quantity_to_add
                }));

            if (itemsToAdd.length === 0) {
                throw new Error('Please specify quantities for selected items');
            }

            // Use the passed parameter to determine whether to reserve inventory
            const response = await shipmentCreationApi.addAdditionalItems(
                shipmentId, 
                itemsToAdd, 
                respectReserved,
                shouldReserve, // Whether to reserve inventory
                reserveReason || `Added to shipment ID ${shipmentId}`
            );
            
            if (!response.success) {
                throw new Error(response.error || 'Failed to add items');
            }

            // Update parent state
            setAdditionalItems(prev => {
                const newItems = [...prev];
                itemsToAdd.forEach(item => {
                    const existingIndex = newItems.findIndex(i => i.warehouseItemId === item.warehouseItemId);
                    if (existingIndex >= 0) {
                        newItems[existingIndex] = item;
                    } else {
                        newItems.push(item);
                    }
                });
                return newItems;
            });

            // Force refresh available items to get updated quantities
            console.log("Refreshing available items after add");
            await loadAvailableItems();
            
            // Only reset the selected items that were just added
            const updatedSelection = selectedItems.filter(item => 
                !itemsToAdd.some(added => added.warehouseItemId === item.warehouse_item_id)
            );
            setSelectedItems(updatedSelection);
            
            onSuccess?.(`Added ${itemsToAdd.length} items to shipment${shouldReserve ? ' and reserved inventory' : ''}`);
        } catch (error) {
            onError('Failed to add items: ' + error.message);
        } finally {
            setAddingItems(false);
        }
    };

    // Use memoized debounced function to prevent excessive re-renders
    const handleQuantityChange = useMemo(() => {
        const updateQuantity = (item, value) => {
            // Ensure value is a proper number or default to 0
            const numericValue = parseInt(value) || 0;
            
            // Use allocatable quantity for limiting input
            const allocatableQuantity = parseInt(item.allocatable_quantity || item.available_quantity) || 0;
            
            // Apply max limit with allocatable quantity
            const maxValue = Math.min(numericValue, allocatableQuantity);
            
            // Update only the changed item using functional state update
            setSelectedItems(prev => {
                // Check if item already exists in the selection
                const itemExists = prev.some(i => i.warehouse_item_id === item.warehouse_item_id);
                
                if (itemExists) {
                    // Update only the changed item
                    return prev.map(i => 
                        i.warehouse_item_id === item.warehouse_item_id 
                            ? { ...i, quantity_to_add: maxValue } 
                            : i
                    );
                } else {
                    // Add new item if not already in the selection
                    return [...prev, {
                        ...item,
                        quantity_to_add: maxValue
                    }];
                }
            });
        };
        
        // Return debounced function with 50ms delay
        return debounce(updateQuantity, 50);
    }, []); // Empty dependency array ensures this is only created once

    const handleSelectionChange = (e) => {
        // Get the newly selected items (items in e.value that aren't in selectedItems)
        const newlySelectedItems = e.value.filter(item => 
            !selectedItems.some(si => si.warehouse_item_id === item.warehouse_item_id)
        );
        
        // Get the previously selected items that are still selected
        const stillSelectedItems = selectedItems.filter(item =>
            e.value.some(si => si.warehouse_item_id === item.warehouse_item_id)
        );
        
        // Combine them, ensuring new selections start with quantity_to_add = 0
        const newSelection = [
            ...stillSelectedItems,
            ...newlySelectedItems.map(item => ({
                ...item,
                quantity_to_add: 0 // Initialize to 0, not max quantity
            }))
        ];
        
        setSelectedItems(newSelection);
    };

    // Memoize the quantity template to prevent unnecessary re-renders
    const quantityTemplate = useMemo(() => (rowData) => {
        const selectedItem = selectedItems.find(i => i.warehouse_item_id === rowData.warehouse_item_id);
        const value = selectedItem ? selectedItem.quantity_to_add : 0;
        const maxValue = parseInt(rowData.allocatable_quantity || rowData.available_quantity) || 0;
        
        // Use minimal rendering approach with direct DOM elements for best performance
        return (
            <div style={{ textAlign: 'center' }}>
                <input
                    type="number"
                    value={value || 0}
                    onChange={(e) => handleQuantityChange(rowData, e.target.value)}
                    min={0}
                    max={maxValue}
                    style={{ 
                        width: '100%', 
                        padding: '8px', 
                        textAlign: 'center',
                        border: '1px solid #ccc',
                        borderRadius: '4px'
                    }}
                />
                <div style={{ 
                    marginTop: '4px', 
                    fontSize: '0.75rem', 
                    fontWeight: 'bold',
                    color: '#d32f2f',
                    whiteSpace: 'nowrap'
                }}>
                    Max: {maxValue}
                </div>
                {rowData.has_order_items && (
                    <div style={{ 
                        marginTop: '4px', 
                        fontSize: '0.75rem',
                        color: '#d32f2f',
                        textAlign: 'center' 
                    }}>
                        ⚠️ Used in orders
                    </div>
                )}
            </div>
        );
    }, [selectedItems, handleQuantityChange]);

    // Show dialog to ask if user wants to respect reserved inventory
    const handleSetMaximumClick = () => {
        if (selectedItems.length === 0) return;
        setMaxQuantityDialogOpen(true);
    };
    
    // Update function to set maximum quantities for selected items - simplified
    const handleSetMaximumQuantities = (respectReserved = true) => {
        if (selectedItems.length === 0) return;
        
        console.log("Setting max quantities with respectReserved =", respectReserved);
        
        const newItems = selectedItems.map(item => {
            const availableItem = availableItems.find(ai => ai.warehouse_item_id === item.warehouse_item_id);
            
            if (!availableItem) {
                console.warn(`Item with ID ${item.warehouse_item_id} not found in available items`);
                return item;
            }
            
            let maxQuantity;
            
            if (respectReserved) {
                // Backend now provides correct available_quantity with reserved inventory respected
                maxQuantity = parseInt(availableItem.available_quantity) || 0;
                
                console.log(`Item ${availableItem.item_name}: available=${maxQuantity} (respecting reserved)`);
            } else {
                // For ignoring reserved, use available_with_reserved or total_quantity
                maxQuantity = parseInt(availableItem.available_with_reserved || availableItem.total_quantity) || 0;
                
                console.log(`Item ${availableItem.item_name}: total=${maxQuantity} (ignoring reserved inventory)`);
            }
            
            return {
                ...item,
                quantity_to_add: maxQuantity
            };
        });
        
        setSelectedItems(newItems);
        setMaxQuantityDialogOpen(false);
    };

    return (
        <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
            <FilterControls 
                globalFilter={globalFilter}
                setGlobalFilter={setGlobalFilter}
                respectReserved={respectReserved}
                setRespectReserved={setRespectReserved}
                onRefresh={loadAvailableItems}
            />

            {loading ? (
                <Box sx={{ display: 'flex', justifyContent: 'center', p: 4 }}>
                    <CircularProgress />
                </Box>
            ) : (
                <>
                    <DataTable
                        value={availableItems}
                        selection={selectedItems}
                        onSelectionChange={handleSelectionChange}
                        dataKey="warehouse_item_id"
                        globalFilter={globalFilter}
                        globalFilterFields={['item_name', 'type', 'size', 'shape']}
                        emptyMessage="No items available"
                        paginator
                        rows={10}
                        rowsPerPageOptions={[5, 10, 25, 50]}
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                        scrollable
                        scrollHeight="flex"
                        selectionMode="multiple"
                        lazy={false}
                        responsiveLayout="scroll"
                    >
                        <Column selectionMode="multiple" headerStyle={{ width: '3rem' }} />
                        <Column field="item_name" header="Item" sortable />
                        <Column field="type" header="Type" sortable />
                        <Column field="reserved_quantity" header="Reserved" sortable 
                            body={(rowData) => {
                                const reservedQuantity = parseInt(rowData.reserved_quantity) || 0;
                                
                                return (
                                    <Typography 
                                        color={reservedQuantity > 0 ? "warning.main" : "textSecondary"}
                                    >
                                        {reservedQuantity}
                                    </Typography>
                                );
                            }}
                        />
                        <Column 
                            header="Inventory" 
                            body={(rowData) => <QuantityBreakdown rowData={rowData} />}
                            sortable
                            sortField="available_quantity"
                        />
                        <Column 
                            header="Quantity" 
                            body={quantityTemplate}
                            style={{ width: '120px' }}
                        />
                    </DataTable>

                    <Box sx={{ mt: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center', p: 2 }} component={Paper}>
                        {selectedItems.length > 0 && (
                            <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                                <Typography>
                                    {selectedItems.length} items selected
                                </Typography>
                                <Button
                                    variant="outlined"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        handleSetMaximumClick();
                                    }}
                                    disabled={addingItems}
                                >
                                    Set Max Quantities
                                </Button>
                            </Box>
                        )}
                        
                        {selectedItems.some(item => (item.quantity_to_add || 0) > 0) && (
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        // Show confirmation dialog to ask about reserving
                                        const shouldReserve = window.confirm("Do you want to reserve the inventory when adding these items?");
                                        if (shouldReserve) {
                                            setReserveReason(`Reserved for shipment ${shipmentId}`);
                                        } else {
                                            setReserveReason('');
                                        }
                                        handleAddItems(shouldReserve);
                                    }}
                                    disabled={selectedItems.length === 0 || addingItems || !selectedItems.some(item => (item.quantity_to_add || 0) > 0)}
                                    startIcon={addingItems && <CircularProgress size={20} color="inherit" />}
                                >
                                    {addingItems ? 'Adding...' : 'Add Items to Shipment'}
                                </Button>
                            </Box>
                        )}
                    </Box>
                </>
            )}

            <Backdrop open={addingItems} sx={{ position: 'absolute', zIndex: 9999 }}>
                <CircularProgress color="inherit" />
            </Backdrop>
            
            {/* Simple Maximum Quantity Dialog */}
            <Dialog
                open={maxQuantityDialogOpen}
                onClose={() => setMaxQuantityDialogOpen(false)}
                maxWidth="xs"
                fullWidth
            >
                <DialogTitle>Set Maximum Quantities</DialogTitle>
                <DialogContent>
                    <Box sx={{ py: 1 }}>
                        <Typography variant="body1">
                            Use reserved inventory?
                        </Typography>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={(e) => {
                        e.preventDefault();
                        handleSetMaximumQuantities(true);
                    }}>
                        No - Respect Reserved
                    </Button>
                    <Button 
                        color="warning"
                        onClick={(e) => {
                            e.preventDefault();
                            handleSetMaximumQuantities(false);
                        }}
                    >
                        Yes - Ignore Reserved
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
} 