import api from './api';

/**
 * Get reorder level history for a specific warehouse item
 * @param {string} warehouseItemId - The ID of the warehouse item
 * @returns {Promise<Array>} - The reorder level history
 */
export const getReorderLevelHistory = async (warehouseItemId) => {
  try {
    const response = await api.get(`/reorder-levels/history/item/${warehouseItemId}`);
    return response.data.data;
  } catch (error) {
    console.error('Error fetching reorder level history:', error);
    throw error;
  }
};

/**
 * Get reorder level history for a specific warehouse
 * @param {string} warehouseId - The ID of the warehouse
 * @returns {Promise<Array>} - The reorder level history for the warehouse
 */
export const getWarehouseReorderLevelHistory = async (warehouseId) => {
  try {
    const response = await api.get(`/reorder-levels/history/warehouse/${warehouseId}`);
    return response.data.data;
  } catch (error) {
    console.error('Error fetching warehouse reorder level history:', error);
    throw error;
  }
};

/**
 * Update reorder level for a warehouse item
 * @param {string} warehouseItemId - The ID of the warehouse item
 * @param {number} reorderLevel - The new reorder level
 * @returns {Promise<Object>} - The updated warehouse item
 */
export const updateReorderLevel = async (warehouseItemId, reorderLevel) => {
  try {
    const response = await api.put(`/reorder-levels/item/${warehouseItemId}`, {
      reorder_level: reorderLevel
    });
    return response.data.data;
  } catch (error) {
    console.error('Error updating reorder level:', error);
    throw error;
  }
};

/**
 * Batch update reorder levels for multiple warehouse items
 * @param {Array} items - Array of objects with warehouse_item_id and reorder_level
 * @returns {Promise<Object>} - The result of the batch update
 */
export const batchUpdateReorderLevels = async (items) => {
  try {
    const response = await api.post('/reorder-levels/batch-update', {
      items: items
    });
    return response.data;
  } catch (error) {
    console.error('Error batch updating reorder levels:', error);
    throw error;
  }
};

const reorderLevelApi = {
  getReorderLevelHistory,
  getWarehouseReorderLevelHistory,
  updateReorderLevel,
  batchUpdateReorderLevels
};

export default reorderLevelApi; 