import React, { useState, useEffect } from 'react';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
import { loginWithVerification, verifyCode } from '../services/api';
import { Container, TextField, Button, Typography, Paper, Box, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { styled } from '@mui/material/styles';
import CircularProgress from '@mui/material/CircularProgress';
import { motion } from 'framer-motion';
import { keyframes } from '@mui/system';

const LOGIN_WITHOUT_EMAIL_ENABLED = false;

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const Root = styled(motion.div)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  minHeight: '100vh',
  background: 'var(--color-background)',
  padding: theme.spacing(3),
  transition: 'all 0.3s ease-in-out',
}));

const StyledPaper = styled(motion.div)(({ theme }) => ({
  padding: theme.spacing(6),
  maxWidth: 450,
  width: '100%',
  backgroundColor: 'var(--color-surface)',
  borderRadius: 24,
  boxShadow: '0 8px 32px rgba(0, 0, 0, 0.12)',
  animation: `${fadeIn} 0.6s ease-out`,
  position: 'relative',
  overflow: 'hidden',
  marginBottom: theme.spacing(2),
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    height: '4px',
    background: 'linear-gradient(90deg, var(--color-primary), var(--color-accent))',
  },
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(4),
    margin: theme.spacing(2),
  }
}));

const LogoText = styled(motion.h1)(({ theme }) => ({
  fontSize: '2.8rem',
  fontWeight: 700,
  background: 'linear-gradient(45deg, var(--color-primary), var(--color-accent))',
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
  marginBottom: theme.spacing(4),
  textAlign: 'center',
  letterSpacing: '0.05em',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  [theme.breakpoints.down('sm')]: {
    fontSize: '2rem',
    marginBottom: theme.spacing(2),
  }
}));

const Title = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(3),
  color: 'var(--color-primary)',
  fontWeight: 600,
  textAlign: 'center',
}));

const ErrorText = styled(Typography)(({ theme }) => ({
  color: 'red',
  marginBottom: theme.spacing(2),
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  marginBottom: theme.spacing(3),
  '& .MuiOutlinedInput-root': {
    transition: 'all 0.3s ease',
    borderRadius: 12,
    '& fieldset': {
      borderColor: 'var(--color-border)',
      transition: 'all 0.3s ease',
    },
    '&:hover fieldset': {
      borderColor: 'var(--color-primary)',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'var(--color-accent)',
      borderWidth: '2px',
    },
  },
  '& .MuiInputLabel-root': {
    color: 'var(--color-text-secondary)',
    '&.Mui-focused': {
      color: 'var(--color-accent)',
    },
  },
}));

const ButtonContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  marginTop: theme.spacing(3),
}));

const LoginButton = styled(Button)(({ theme }) => ({
  padding: theme.spacing(1.5),
  fontWeight: 600,
  borderRadius: 30,
  textTransform: 'none',
  fontSize: '1rem',
  transition: 'all 0.3s ease',
  backgroundColor: 'var(--color-accent)',
  color: 'var(--color-primary)',
  '&:hover': {
    backgroundColor: 'var(--color-accent-light)',
    transform: 'translateY(-2px)',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  },
}));

const WarningText = styled(Typography)(({ theme }) => ({
  color: theme.palette.warning.main,
  marginTop: theme.spacing(2),
  textAlign: 'center',
  fontSize: '0.9rem',
}));

const QuoteBox = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(3),
  textAlign: 'center',
}));

const QuoteText = styled(Typography)(({ theme }) => ({
  fontStyle: 'italic',
  marginBottom: theme.spacing(1),
}));

const QuoteAuthor = styled(Typography)(({ theme }) => ({
  fontWeight: 'bold',
}));

const CustomerPortalLink = styled(RouterLink)(({ theme }) => ({
  color: 'var(--color-primary)',
  textDecoration: 'none',
  transition: 'all 0.3s ease',
  position: 'relative',
  '&:hover': {
    color: 'var(--color-accent)',
    '&::after': {
      width: '100%',
    }
  },
  '&::after': {
    content: '""',
    position: 'absolute',
    bottom: -2,
    left: 0,
    width: '0%',
    height: '2px',
    backgroundColor: 'var(--color-accent)',
    transition: 'width 0.3s ease',
  }
}));

const StyledBox = styled(Box)(({ theme }) => ({
  textAlign: 'center',
  padding: theme.spacing(2),
  position: 'relative',
  width: '100%',
  maxWidth: 450,
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(1),
    '& .MuiTypography-body2': {
      fontSize: '0.9rem',
    }
  }
}));

const quotes = [
  
  {
    "quote": "Success usually comes to those who are too busy to be looking for it.",
    "author": "Henry David Thoreau"
  },
  {
    "quote": "Believe you can and you're halfway there.",
    "author": "Theodore Roosevelt"
  },
  {
    "quote": "Life is 10% what happens to us and 90% how we react to it.",
    "author": "Charles R. Swindoll"
  },
  {
    "quote": "The only limit to our realization of tomorrow is our doubts of today.",
    "author": "Franklin D. Roosevelt"
  },
  {
    "quote": "Don't be afraid to give up the good to go for the great.",
    "author": "John D. Rockefeller"
  },
  {
    "quote": "I find that the harder I work, the more luck I seem to have.",
    "author": "Thomas Jefferson"
  },
  {
    "quote": "Success is not in what you have, but who you are.",
    "author": "Bo Bennett"
  },
  {
    "quote": "It does not matter how slowly you go as long as you do not stop.",
    "author": "Confucius"
  },
  {
    "quote": "Difficulties in life are intended to make us better, not bitter.",
    "author": "Dan Reeves"
  },
  {
    "quote": "Success is not the key to happiness. Happiness is the key to success.",
    "author": "Albert Schweitzer"
  },
  {
    "quote": "Your time is limited, don't waste it living someone else's life.",
    "author": "Steve Jobs"
  },
  {
    "quote": "A journey of a thousand miles begins with a single step.",
    "author": "Lao Tzu"
  },
  {
    "quote": "To succeed in life, you need three things: a wishbone, a backbone, and a funny bone.",
    "author": "Reba McEntire"
  },
  {
    "quote": "The way to get started is to quit talking and begin doing.",
    "author": "Walt Disney"
  },
  {
    "quote": "The only way to do great work is to love what you do.",
    "author": "Steve Jobs"
  },
  {
    "quote": "You are never too old to set another goal or to dream a new dream.",
    "author": "C.S. Lewis"
  },
  {
    "quote": "Success seems to be connected with action. Successful people keep moving.",
    "author": "Conrad Hilton"
  },
  {
    "quote": "The only limit to our realization of tomorrow is our doubts of today.",
    "author": "Franklin D. Roosevelt"
  },
  {
    "quote": "Every artist was first an amateur.",
    "author": "Ralph Waldo Emerson"
  },
  {
    "quote": "Don't watch the clock; do what it does. Keep going.",
    "author": "Sam Levenson"
  },
  {
    "quote": "The best way to predict the future is to create it.",
    "author": "Peter Drucker"
  },
  {
    "quote": "Your work is going to fill a large part of your life, and the only way to be truly satisfied is to do what you believe is great work.",
    "author": "Steve Jobs"
  },
  {
    "quote": "If you are not willing to risk the usual, you will have to settle for the ordinary.",
    "author": "Jim Rohn"
  },
  {
    "quote": "Act as if what you do makes a difference. It does.",
    "author": "William James"
  },
  {
    "quote": "The future belongs to those who believe in the beauty of their dreams.",
    "author": "Eleanor Roosevelt"
  },
  {
    "quote": "It is never too late to be what you might have been.",
    "author": "George Eliot"
  },
  {
    "quote": "You don't have to be great to start, but you have to start to be great.",
    "author": "Zig Ziglar"
  },
  {
    "quote": "The only place where success comes before work is in the dictionary.",
    "author": "Vidal Sassoon"
  },
  {
    "quote": "The greatest glory in living lies not in never falling, but in rising every time we fall.",
    "author": "Nelson Mandela"
  },
  {
    "quote": "What you do today can improve all your tomorrows.",
    "author": "Ralph Marston"
  },
  {
    "quote": "Keep your eyes on the stars, and your feet on the ground.",
    "author": "Theodore Roosevelt"
  },
  {
    "quote": "Do what you can, with what you have, where you are.",
    "author": "Theodore Roosevelt"
  },
  {
    "quote": "Success is not how high you have climbed, but how you make a positive difference to the world.",
    "author": "Roy T. Bennett"
  },
  {
    "quote": "We are what we repeatedly do. Excellence, then, is not an act, but a habit.",
    "author": "Aristotle"
  },
  {
    "quote": "The only way to achieve the impossible is to believe it is possible.",
    "author": "Charles Kingsleigh"
  },
  {
    "quote": "Hardships often prepare ordinary people for an extraordinary destiny.",
    "author": "C.S. Lewis"
  },
  {
    "quote": "Dream big and dare to fail.",
    "author": "Norman Vaughan"
  },
  {
    "quote": "Don't be pushed around by the fears in your mind. Be led by the dreams in your heart.",
    "author": "Roy T. Bennett"
  },
  {
    "quote": "The only impossible journey is the one you never begin.",
    "author": "Tony Robbins"
  },
  {
    "quote": "Strive not to be a success, but rather to be of value.",
    "author": "Albert Einstein"
  },
  {
    "quote": "Happiness is not something ready-made. It comes from your own actions.",
    "author": "Dalai Lama"
  },
  {
    "quote": "Your life does not get better by chance, it gets better by change.",
    "author": "Jim Rohn"
  },
  {
    "quote": "You miss 100% of the shots you don't take.",
    "author": "Wayne Gretzky"
  },
  {
    "quote": "Challenges are what make life interesting and overcoming them is what makes life meaningful.",
    "author": "Joshua J. Marine"
  },
  {
    "quote": "It always seems impossible until it's done.",
    "author": "Nelson Mandela"
  },
  {
    "quote": "The only way to do great work is to love what you do.",
    "author": "Steve Jobs"
  },
  {
    "quote": "Great things are not done by impulse, but by a series of small things brought together.",
    "author": "Vincent Van Gogh"
  },
  {
    "quote": "Do not wait; the time will never be 'just right.' Start where you stand.",
    "author": "Napoleon Hill"
  },
  {
    "quote": "Go the extra mile. It's never crowded.",
    "author": "Wayne Dyer"
  },
  {
    "quote": "Failure is simply the opportunity to begin again, this time more intelligently.",
    "author": "Henry Ford"
  },
  {
    "quote": "Action is the foundational key to all success.",
    "author": "Pablo Picasso"
  },
  {
    "quote": "Success is not final, failure is not fatal: It is the courage to continue that counts.",
    "author": "Winston S. Churchill"
  },
  {
    "quote": "The best time to plant a tree was 20 years ago. The second best time is now.",
    "author": "Chinese Proverb"
  },
  {
    "quote": "Dream as if you'll live forever. Live as if you'll die today.",
    "author": "James Dean"
  },
  {
    "quote": "The harder the conflict, the more glorious the triumph.",
    "author": "Thomas Paine"
  },
  {
    "quote": "I can't change the direction of the wind, but I can adjust my sails to always reach my destination.",
    "author": "Jimmy Dean"
  },
  {
    "quote": "You must be the change you wish to see in the world.",
    "author": "Mahatma Gandhi"
  },
  {
    "quote": "Courage is not the absence of fear, but the triumph over it.",
    "author": "Nelson Mandela"
  },
  {
    "quote": "Start where you are. Use what you have. Do what you can.",
    "author": "Arthur Ashe"
  },
  {
    "quote": "The only way to do great work is to love what you do.",
    "author": "Steve Jobs"
  },
  {
    "quote": "It is our attitude at the beginning of a difficult task which, more than anything else, will affect its successful outcome.",
    "author": "William James"
  },
  {
    "quote": "Nothing is impossible, the word itself says 'I'm possible!'",
    "author": "Audrey Hepburn"
  },
  {
    "quote": "To be a champion, I think you have to see the big picture.",
    "author": "Summer Sanders"
  },
  {
    "quote": "Keep going. Be all in.",
    "author": "Bryan Hutchinson"
  },
  {
    "quote": "Whether you think you can or you think you can't, you're right.",
    "author": "Henry Ford"
  },
  {
    "quote": "All our dreams can come true if we have the courage to pursue them.",
    "author": "Walt Disney"
  },
  {
    "quote": "You have power over your mind – not outside events. Realize this, and you will find strength.",
    "author": "Marcus Aurelius"
  },
  {
    "quote": "Success isn't about how much money you make, it's about the difference you make in people's lives.",
    "author": "Michelle Obama"
  },
  {
    "quote": "Do not be embarrassed by your failures, learn from them and start again.",
    "author": "Richard Branson"
  },
  {
    "quote": "I've failed over and over and over again in my life. And that is why I succeed.",
    "author": "Michael Jordan"
  },
  {
    "quote": "In the middle of every difficulty lies opportunity.",
    "author": "Albert Einstein"
  },
  {
    "quote": "The secret of getting ahead is getting started.",
    "author": "Mark Twain"
  },
  {
    "quote": "Quality is not an act, it is a habit.",
    "author": "Aristotle"
  },
  {
    "quote": "The future depends on what you do today.",
    "author": "Mahatma Gandhi"
  },
  {
    "quote": "The mind is everything. What you think you become.",
    "author": "Buddha"
  },
  {
    "quote": "Everything you've ever wanted is on the other side of fear.",
    "author": "George Addair"
  },
  {
    "quote": "Success is walking from failure to failure with no loss of enthusiasm.",
    "author": "Winston Churchill"
  },
  {
    "quote": "What you lack in talent can be made up with desire, hustle and giving 110% all the time.",
    "author": "Don Zimmer"
  },
  {
    "quote": "The difference between ordinary and extraordinary is that little extra.",
    "author": "Jimmy Johnson"
  },
  {
    "quote": "The only person you should try to be better than is the person you were yesterday.",
    "author": "Anonymous"
  },
  {
    "quote": "If opportunity doesn't knock, build a door.",
    "author": "Milton Berle"
  },
  {
    "quote": "The difference between who you are and who you want to be is what you do.",
    "author": "Anonymous"
  },
  {
    "quote": "Everything is hard before it is easy.",
    "author": "Goethe"
  },
  {
    "quote": "The past cannot be changed. The future is yet in your power.",
    "author": "Mary Pickford"
  },
  {
    "quote": "Life is like photography. You need the negatives to develop.",
    "author": "Anonymous"
  },
  {
    "quote": "The road to success is always under construction.",
    "author": "Lily Tomlin"
  },
  {
    "quote": "Don't wait for opportunity. Create it.",
    "author": "Anonymous"
  },
  {
    "quote": "Sometimes later becomes never. Do it now.",
    "author": "Anonymous"
  },
  {
    "quote": "The key to success is to start before you're ready.",
    "author": "Marie Forleo"
  },
  {
    "quote": "If life were predictable, it would cease to be life and be without flavor.",
    "author": "Eleanor Roosevelt"
  },
  {
    "quote": "In the end, it's not the years in your life that count. It's the life in your years.",
    "author": "Abraham Lincoln"
  },
  {
    "quote": "Life is a succession of lessons which must be lived to be understood.",
    "author": "Ralph Waldo Emerson"
  },
  {
    "quote": "You will face many defeats in life, but never let yourself be defeated.",
    "author": "Maya Angelou"
  },
  {
    "quote": "Never let the fear of striking out keep you from playing the game.",
    "author": "Babe Ruth"
  },
  {
    "quote": "Life is never fair, and perhaps it is a good thing for most of us that it is not.",
    "author": "Oscar Wilde"
  },
  {
    "quote": "In this life, we cannot do great things. We can only do small things with great love.",
    "author": "Mother Teresa"
  },
  {
    "quote": "Only a life lived for others is a life worthwhile.",
    "author": "Albert Einstein"
  },
  {
    "quote": "The purpose of our lives is to be happy.",
    "author": "Dalai Lama"
  },
  {
    "quote": "You may say I'm a dreamer, but I'm not the only one. I hope someday you'll join us. And the world will live as one.",
    "author": "John Lennon"
  },
  {
    "quote": "You only live once, but if you do it right, once is enough.",
    "author": "Mae West"
  },
  {
    "quote": "Life is really simple, but we insist on making it complicated.",
    "author": "Confucius"
  },
  {
    "quote": "May you live all the days of your life.",
    "author": "Jonathan Swift"
  },
  {
    "quote": "Life itself is the most wonderful fairy tale.",
    "author": "Hans Christian Andersen"
  },
  {
    "quote": "Do not let making a living prevent you from making a life.",
    "author": "John Wooden"
  },
  {
    "quote": "Keep smiling, because life is a beautiful thing and there's so much to smile about.",
    "author": "Marilyn Monroe"
  },
  {
    "quote": "In the depth of winter, I finally learned that within me there lay an invincible summer.",
    "author": "Albert Camus"
  },
  {
    "quote": "Many of life's failures are people who did not realize how close they were to success when they gave up.",
    "author": "Thomas A. Edison"
  },
  {
    "quote": "Everything you can imagine is real.",
    "author": "Pablo Picasso"
  },
  {
    "quote": "There are no secrets to success. It is the result of preparation, hard work, and learning from failure.",
    "author": "Colin Powell"
  },
  {
    "quote": "The real test is not whether you avoid this failure, because you won't. It's whether you let it harden or shame you into inaction, or whether you learn from it.",
    "author": "Barack Obama"
  },
  {
    "quote": "It is better to fail in originality than to succeed in imitation.",
    "author": "Herman Melville"
  }
];

// Add loading overlay component
const LoadingOverlay = styled(motion.div)({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: 'rgba(255, 255, 255, 0.9)',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  zIndex: 10,
});

// Add this motion variant near the top of the file with other animations
const pageTransition = {
  exit: {
    opacity: 0,
    x: -100,
    transition: {
      duration: 0.3
    }
  }
};

function Login({ onLogin }) {
  const [usernameOrEmail, setUsernameOrEmail] = useState('');
  const [password, setPassword] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [error, setError] = useState('');
  const [isVerificationStep, setIsVerificationStep] = useState(false);
  const [maskedEmail, setMaskedEmail] = useState('');
  const [userId, setUserId] = useState(null);
  const [countdown, setCountdown] = useState(0);
  const [showSuccessDialog, setShowSuccessDialog] = useState(false);
  const navigate = useNavigate();
  const [currentQuote, setCurrentQuote] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const randomQuote = quotes[Math.floor(Math.random() * quotes.length)];
    setCurrentQuote(randomQuote);
  }, []);

  useEffect(() => {
    let timer;
    if (countdown > 0) {
      timer = setTimeout(() => setCountdown(countdown - 1), 1000);
    }
    return () => clearTimeout(timer);
  }, [countdown]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setIsLoading(true);

    try {
      if (!isVerificationStep) {
        const response = await loginWithVerification({ usernameOrEmail, password });
        setMaskedEmail(response.data.maskedEmail);
        setUserId(response.data.userId);
        setIsVerificationStep(true);
      } else {
        const response = await verifyCode({ userId, code: verificationCode });
        onLogin(response.data.user);
        navigate('/retail/home');
      }
    } catch (error) {
      console.error('Login error:', error);
      if (error.response && error.response.status === 429) {
        setCountdown(60);
      }
      setError(error.response?.data?.error || error.message || 'An error occurred during login');
    } finally {
      setIsLoading(false);
    }
  };

  const handleResendCode = async () => {
    try {
      await loginWithVerification({ usernameOrEmail, password });
      setError('');
    } catch (error) {
      console.error('Error resending code:', error);
      setError('Failed to resend verification code');
    }
  };

  const handleCloseSuccessDialog = () => {
    setShowSuccessDialog(false);
    navigate('/retail/home');
  };

  // Animation variants for framer-motion
  const containerVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { 
      opacity: 1, 
      y: 0,
      transition: { 
        duration: 0.6,
        when: "beforeChildren",
        staggerChildren: 0.1
      }
    }
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { 
      opacity: 1, 
      y: 0,
      transition: { duration: 0.6 }
    }
  };

  return (
    <Root
      initial={{ opacity: 0, x: 100 }}
      animate={{ opacity: 1, x: 0 }}
      exit={{ opacity: 0, x: -100 }}
      transition={{ duration: 0.3 }}
    >
      <StyledPaper
        initial="hidden"
        animate="visible"
        variants={containerVariants}
      >
        {isLoading && (
          <LoadingOverlay
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <CircularProgress size={48} sx={{ color: 'var(--color-primary)' }} />
            <Typography variant="h6" sx={{ mt: 2, color: 'var(--color-primary)' }}>
              Logging in...
            </Typography>
          </LoadingOverlay>
        )}
        
        <motion.div variants={itemVariants}>
          <LogoText>PIM BY ELI</LogoText>
        </motion.div>

        <Title variant="h4">
          Welcome Back
        </Title>
        {error && <ErrorText>{error}</ErrorText>}
        <form onSubmit={handleSubmit}>
          {!isVerificationStep ? (
            <>
              <StyledTextField
                fullWidth
                label="Username or Email"
                value={usernameOrEmail}
                onChange={(e) => setUsernameOrEmail(e.target.value)}
                required
                variant="outlined"
              />
              <StyledTextField
                fullWidth
                label="Password"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
                variant="outlined"
              />
              <ButtonContainer>
                <LoginButton
                  type="submit"
                  variant="contained"
                  fullWidth
                >
                  Login
                </LoginButton>
              </ButtonContainer>
            </>
          ) : (
            <>
              <Typography variant="body1" gutterBottom>
                Enter the verification code sent to {maskedEmail}
              </Typography>
              <StyledTextField
                fullWidth
                label="Verification Code"
                value={verificationCode}
                onChange={(e) => setVerificationCode(e.target.value)}
                required
                margin="normal"
              />
              {countdown > 0 ? (
                <Typography variant="body2">
                  You can request a new code in {countdown} seconds
                </Typography>
              ) : (
                <Button onClick={handleResendCode} disabled={countdown > 0}>
                  Resend Code
                </Button>
              )}
              <LoginButton
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 2 }}
              >
                Verify
              </LoginButton>
            </>
          )}
        </form>
        <QuoteBox>
          <QuoteText variant="body1">"{currentQuote.quote}"</QuoteText>
          <QuoteAuthor variant="body2">- {currentQuote.author}</QuoteAuthor>
        </QuoteBox>
        <StyledBox>
          <Typography variant="body2" color="textSecondary">
            Looking for the Customer Portal?{' '}
            <CustomerPortalLink to="/customer/login">
              Click here
            </CustomerPortalLink>
          </Typography>
        </StyledBox>
      </StyledPaper>
      <Dialog open={showSuccessDialog} onClose={handleCloseSuccessDialog}>
        <DialogTitle>Login Successful</DialogTitle>
        <DialogContent>
          <Typography>
            You have successfully logged in. Please note that a new verification system will be implemented in the next 24 hours. 
            Ensure your email address is up to date as it will be required for the new login process.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseSuccessDialog} color="primary">
            Continue
          </Button>
        </DialogActions>
      </Dialog>
    </Root>
  );
}

export default Login;