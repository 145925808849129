import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Typography,
  TextField,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Autocomplete,
  Snackbar,
  Alert,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControlLabel,
  Switch,
  CircularProgress,
  Box,
  Paper,
  Grid,
  Tab,
  Tabs,
  Card,
  CardContent,
  CardActionArea,
} from '@mui/material';
import {
  Save as SaveIcon,
  ArrowBack as ArrowBackIcon,
  Delete as DeleteIcon,
  Search as SearchIcon,
  Comment as CommentIcon,
  CheckCircle as CheckCircleIcon,
  Share as ShareIcon,
  CloudDownload as CloudDownloadIcon,
  ViewList as ViewListIcon,
  GridView as GridViewIcon,
  QrCode as BarcodeIcon,
  CompareArrows as CompareArrowsIcon,
} from '@mui/icons-material';
import debounce from 'lodash/debounce';
import * as inventoryCheckApi from '../services/inventoryCheckApi';
import FullScreenLayout from './layouts/FullScreenLayout';
import useSound from 'use-sound';
import { motion, AnimatePresence } from 'framer-motion';
import ProcessingAnimation from './ProcessingAnimation';
import ItemSearch from './common/ItemSearch';
import SpreadsheetEditableV2 from './spreadsheets/SpreadsheetEditableV2';
import OptimizedSpreadsheet from './spreadsheets/OptimizedSpreadsheet';
import OptimizedItemSearch from './common/OptimizedItemSearch';
import { getWarehouseInventorySpreadsheet } from '../services/inventoryCheckApi';

const successSound = '/success.wav';
const errorSound = '/error.wav';

const AnimatedButton = ({ loading, onClick, children, ...props }) => (
  <Button
    {...props}
    onClick={onClick}
    disabled={loading}
    sx={{
      position: 'relative',
      ...props.sx
    }}
  >
    {loading && (
      <CircularProgress
        size={24}
        sx={{
          position: 'absolute',
          left: '50%',
          marginLeft: '-12px',
        }}
      />
    )}
    <Box sx={{ opacity: loading ? 0 : 1 }}>
      {children}
    </Box>
  </Button>
);

const LoadingSpinner = () => (
  <Box 
    sx={{ 
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      textAlign: 'center'
    }}
  >
    <motion.div
      initial={{ scale: 0.8, opacity: 0 }}
      animate={{ 
        scale: [0.8, 1.2, 0.8],
        opacity: [0.5, 1, 0.5],
      }}
      transition={{ 
        duration: 1.5,
        repeat: Infinity,
        ease: "easeInOut"
      }}
      style={{
        width: '120px',
        height: '120px',
        borderRadius: '50%',
        border: '4px solid #1976d2',
        borderTopColor: 'transparent',
        margin: '0 auto 24px'
      }}
    />
    <Typography variant="h6" sx={{ mt: 2, color: 'primary.main' }}>
      Loading...
    </Typography>
  </Box>
);

const ViewSelectionDialog = ({ open, onSelect }) => {
  const [selectedView, setSelectedView] = useState(null);
  const [spreadsheetMode, setSpreadsheetMode] = useState(null);

  const handleViewSelect = (view) => {
    if (view === 'spreadsheet') {
      setSelectedView(view);
    } else {
      onSelect({ view, mode: null });
    }
  };

  const handleSpreadsheetModeSelect = (mode) => {
    onSelect({ view: 'spreadsheet', mode });
    setSelectedView(null);
    setSpreadsheetMode(null);
  };

  return (
    <Dialog 
      open={open} 
      maxWidth="md" 
      fullWidth
      PaperProps={{
        sx: {
          borderRadius: 2,
          p: 2
        }
      }}
    >
      <DialogTitle sx={{ textAlign: 'center', pb: 3 }}>
        <Typography variant="h5" fontWeight="bold">
          {selectedView === 'spreadsheet' ? 'Choose Spreadsheet Mode' : 'Choose Inventory Check Method'}
        </Typography>
      </DialogTitle>
      <DialogContent>
        {!selectedView ? (
          <Grid container spacing={3} justifyContent="center">
            <Grid item xs={12} sm={6}>
              <motion.div whileHover={{ scale: 1.02 }} whileTap={{ scale: 0.98 }}>
                <Card 
                  sx={{ 
                    height: '100%',
                    cursor: 'pointer',
                    '&:hover': { boxShadow: 6 }
                  }}
                  onClick={() => handleViewSelect('barcode')}
                >
                  <CardActionArea sx={{ height: '100%', p: 2 }}>
                    <CardContent sx={{ textAlign: 'center' }}>
                      <BarcodeIcon sx={{ fontSize: 60, color: 'primary.main', mb: 2 }} />
                      <Typography variant="h6" gutterBottom>
                        Barcode Scanner
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        Use barcode scanner to count items individually. 
                        Best for precise counting and tracking specific items.
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </motion.div>
            </Grid>
            <Grid item xs={12} sm={6}>
              <motion.div whileHover={{ scale: 1.02 }} whileTap={{ scale: 0.98 }}>
                <Card 
                  sx={{ 
                    height: '100%',
                    cursor: 'pointer',
                    '&:hover': { boxShadow: 6 }
                  }}
                  onClick={() => handleViewSelect('spreadsheet')}
                >
                  <CardActionArea sx={{ height: '100%', p: 2 }}>
                    <CardContent sx={{ textAlign: 'center' }}>
                      <ViewListIcon sx={{ fontSize: 60, color: 'primary.main', mb: 2 }} />
                      <Typography variant="h6" gutterBottom>
                        Spreadsheet View
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        Edit quantities in a spreadsheet format. 
                        Ideal for bulk updates and overview of all items.
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </motion.div>
            </Grid>
          </Grid>
        ) : (
          <Grid container spacing={3} justifyContent="center">
            <Grid item xs={12} sm={6}>
              <motion.div whileHover={{ scale: 1.02 }} whileTap={{ scale: 0.98 }}>
                <Card 
                  sx={{ 
                    height: '100%',
                    cursor: 'pointer',
                    '&:hover': { boxShadow: 6 }
                  }}
                  onClick={() => handleSpreadsheetModeSelect('simple')}
                >
                  <CardActionArea sx={{ height: '100%', p: 2 }}>
                    <CardContent sx={{ textAlign: 'center' }}>
                      <ViewListIcon sx={{ fontSize: 60, color: 'primary.main', mb: 2 }} />
                      <Typography variant="h6" gutterBottom>
                        Simple Mode
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        Single column for counted quantities.
                        Quick and straightforward counting.
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </motion.div>
            </Grid>
            <Grid item xs={12} sm={6}>
              <motion.div whileHover={{ scale: 1.02 }} whileTap={{ scale: 0.98 }}>
                <Card 
                  sx={{ 
                    height: '100%',
                    cursor: 'pointer',
                    '&:hover': { boxShadow: 6 }
                  }}
                  onClick={() => handleSpreadsheetModeSelect('comparison')}
                >
                  <CardActionArea sx={{ height: '100%', p: 2 }}>
                    <CardContent sx={{ textAlign: 'center' }}>
                      <CompareArrowsIcon sx={{ fontSize: 60, color: 'primary.main', mb: 2 }} />
                      <Typography variant="h6" gutterBottom>
                        Comparison Mode
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        View previous quantities alongside new counts.
                        Easy to compare and verify changes.
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </motion.div>
            </Grid>
          </Grid>
        )}
      </DialogContent>
      {selectedView && (
        <DialogActions sx={{ justifyContent: 'center', pb: 2 }}>
          <Button onClick={() => setSelectedView(null)}>
            Back to View Selection
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
};

const formatSpreadsheetData = (formData, warehouseItems) => {
  const data = {};
  
  // Add existing counted quantities
  formData.items.forEach(item => {
    data[item.warehouse_item_id] = {
      quantity: item.counted_quantity || 0,
      previous_quantity: item.previous_quantity || 0
    };
  });

  // Add remaining warehouse items
  warehouseItems.forEach(item => {
    if (!data[item.warehouse_item_id]) {
      data[item.warehouse_item_id] = {
        quantity: 0,
        previous_quantity: item.current_quantity || 0
      };
    }
  });

  return data;
};

const InventoryCheckForm = () => {
  const { warehouseId, checkId } = useParams();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    warehouse_id: warehouseId,
    reset_uncounted_items: false,
    notes: '',
    items: []
  });
  
  const [warehouseItems, setWarehouseItems] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [loading, setLoading] = useState(true);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
  const [confirmDialog, setConfirmDialog] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [isSearching, setIsSearching] = useState(false);
  const [playSuccess] = useSound(successSound, {
    volume: 0.5,
    onload: () => console.log('Success sound loaded'),
    onloaderror: (error) => console.error('Error loading success sound:', error)
  });
  const [playError] = useSound(errorSound, {
    volume: 0.5,
    onload: () => console.log('Error sound loaded'),
    onloaderror: (error) => console.error('Error loading error sound:', error)
  });
  const searchInputRef = useRef(null);
  const [showNotes, setShowNotes] = useState(false);
  const [itemNotes, setItemNotes] = useState({});
  const searchCache = useRef(new Map());
  const [totals, setTotals] = useState({
    totalItems: 0,
    totalScanned: 0,
    totalQuantity: 0
  });
  const [isCreating, setIsCreating] = useState(false);
  const [showResetDialog, setShowResetDialog] = useState(false);
  const [showSuccessDialog, setShowSuccessDialog] = useState(false);
  const [viewMode, setViewMode] = useState(null);
  const [spreadsheetMode, setSpreadsheetMode] = useState(null);
  const [spreadsheetData, setSpreadsheetData] = useState({});
  const [originalInventory, setOriginalInventory] = useState({});
  const [spreadsheetLoading, setSpreadsheetLoading] = useState(true);
  const [spreadsheetChanges, setSpreadsheetChanges] = useState([]);
  const [showViewSelection, setShowViewSelection] = useState(true);
  const [comparisonData, setComparisonData] = useState(null);
  const [comparisonLoading, setComparisonLoading] = useState(false);
  const [showComparison, setShowComparison] = useState(false);
  const [localNotes, setLocalNotes] = useState('');
  const [showResetConfirmDialog, setShowResetConfirmDialog] = useState(false);

  const debouncedSearch = useCallback(
    debounce(async (term, items) => {
      if (!term) {
        setSearchResults([]);
        return;
      }

      const results = items.filter(item => 
        item.barcode === term ||
        item.alternative_lookups?.some(lookup => lookup.includes(term)) ||
        item.item_name.toLowerCase().includes(term.toLowerCase()) ||
        item.item_code?.toLowerCase().includes(term.toLowerCase())
      );

      setSearchResults(results);
      setIsSearching(false);
    }, 150),
    []
  );

  useEffect(() => {
    const fetchData = async () => {
      try {
        const items = await inventoryCheckApi.getWarehouseItemsWithBarcodes(warehouseId);
        const itemsWithIndex = items.map(item => ({
          ...item,
          searchTerms: [
            item.barcode,
            item.item_name,
            item.item_type,
            item.item_size,
            item.item_shape,
            item.item_brim,
            ...(item.alternative_lookups || []),
            `${item.item_type || ''} ${item.item_size || ''}`,
            `${item.item_size || ''} ${item.item_type || ''}`,
            `${item.item_type || ''} ${item.item_shape || ''}`,
          ].filter(Boolean).map(term => term.toLowerCase())
        }));
        setWarehouseItems(itemsWithIndex);

        if (checkId) {
          const checkData = await inventoryCheckApi.getInventoryCheckById(checkId);
          setFormData({
            ...checkData,
            warehouse_id: warehouseId,
            items: checkData.items.map(item => ({
              warehouse_item_id: item.warehouse_item_id,
              previous_quantity: item.previous_quantity,
              counted_quantity: item.counted_quantity,
              notes: item.notes || ''
            }))
          });
          setLocalNotes(checkData.notes || '');
          const initialItemNotes = {};
          checkData.items.forEach(item => {
            if (item.notes) {
              initialItemNotes[item.warehouse_item_id] = item.notes;
            }
          });
          setItemNotes(initialItemNotes);
        }
      } catch (error) {
        setSnackbar({
          open: true,
          message: 'Failed to fetch data: ' + error.message,
          severity: 'error'
        });
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [warehouseId, checkId]);

  useEffect(() => {
    const newTotals = formData.items.reduce((acc, item) => {
      return {
        totalItems: acc.totalItems + 1,
        totalScanned: acc.totalScanned + 1,
        totalQuantity: acc.totalQuantity + (item.counted_quantity || 0)
      };
    }, { totalItems: 0, totalScanned: 0, totalQuantity: 0 });

    setTotals(newTotals);
  }, [formData.items]);

  const performSearch = useCallback((term, items) => {
    if (!term) return [];
    
    const cached = searchCache.current.get(term);
    if (cached) return cached;

    const searchTerm = term.toLowerCase();
    
    const exactMatches = items.filter(item => 
      item.barcode === term || 
      item.alternative_lookups?.includes(term)
    );

    const partialBarcodeMatches = items.filter(item => 
      !exactMatches.includes(item) && (
        (item.barcode?.toLowerCase().includes(searchTerm)) ||
        (item.alternative_lookups?.some(lookup => 
          lookup.toLowerCase().includes(searchTerm)
        ))
      )
    );

    const partialMatches = items.filter(item => 
      !exactMatches.includes(item) && 
      !partialBarcodeMatches.includes(item) && 
      item.searchTerms.some(term => term.includes(searchTerm))
    );

    const results = [...exactMatches, ...partialBarcodeMatches, ...partialMatches];
    searchCache.current.set(term, results);
    return results;
  }, []);

  const handleAddItemToCount = (itemToAdd) => {
    const existingItemIndex = formData.items.findIndex(
      item => item.warehouse_item_id === itemToAdd.id
    );

    if (existingItemIndex !== -1) {
      const newItems = [...formData.items];
      newItems[existingItemIndex] = {
        ...newItems[existingItemIndex],
        counted_quantity: (newItems[existingItemIndex].counted_quantity || 0) + 1
      };
      setFormData({ ...formData, items: newItems });
      
      setSnackbar({
        open: true,
        message: `Increased ${itemToAdd.item_name} quantity to ${newItems[existingItemIndex].counted_quantity}`,
        severity: 'success'
      });
    } else {
      const newItem = {
        warehouse_item_id: itemToAdd.id,
        previous_quantity: itemToAdd.current_quantity || 0,
        counted_quantity: 1,
        notes: ''
      };
      setFormData({ ...formData, items: [...formData.items, newItem] });
      
      setSnackbar({
        open: true,
        message: `Added ${itemToAdd.item_name} with quantity 1`,
        severity: 'success'
      });
    }

    if (searchInputRef.current) {
      setTimeout(() => {
        searchInputRef.current.focus();
      }, 0);
    }
  };

  const handleRemoveItem = (warehouseItemId) => {
    setFormData({
      ...formData,
      items: formData.items.filter(item => item.warehouse_item_id !== warehouseItemId)
    });
  };

  const handleNotesChange = (event, itemId = null) => {
    const value = event.target.value;
    
    if (itemId) {
      const newItems = formData.items.map(item => {
        if (item.warehouse_item_id === itemId) {
          return { ...item, notes: value };
        }
        return item;
      });
      
      setFormData(prev => ({
        ...prev,
        items: newItems
      }));
      
      setItemNotes(prev => ({
        ...prev,
        [itemId]: value
      }));
    } else {
      setLocalNotes(value);
      setFormData(prev => ({
        ...prev,
        notes: value
      }));
    }
  };

  const handleSubmit = async (notes = null) => {
    if (notes !== null) {
      setFormData(prev => ({
        ...prev,
        notes: notes
      }));
    }

    setIsCreating(true);
    try {
      const minLoadingTime = new Promise(resolve => setTimeout(resolve, 5000));
      const dataToSubmit = notes !== null ? {
        ...formData,
        notes: notes
      } : formData;

      const apiCall = checkId
        ? inventoryCheckApi.updateInventoryCheck(checkId, dataToSubmit)
        : inventoryCheckApi.createInventoryCheck(dataToSubmit);

      await Promise.all([minLoadingTime, apiCall]);
      
      setShowSuccessDialog(true);
      setTimeout(() => {
        setShowSuccessDialog(false);
        navigate(`/warehouses/${warehouseId}`);
      }, 3500);
    } catch (error) {
      setSnackbar({
        open: true,
        message: `Failed to ${checkId ? 'update' : 'create'} inventory check: ${error.message}`,
        severity: 'error'
      });
    } finally {
      setIsCreating(false);
    }
  };

  const renderHeader = () => (
    <Box sx={{ 
      display: 'flex', 
      justifyContent: 'space-between', 
      alignItems: 'center', 
      mb: 3,
      px: 2,
      py: 1,
      backgroundColor: 'background.paper',
      borderBottom: 1,
      borderColor: 'divider'
    }}>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
        <IconButton
          onClick={() => navigate(`/warehouses/${warehouseId}`)}
          size="large"
        >
          <ArrowBackIcon />
        </IconButton>
        <Typography variant="h5">
          {checkId ? 'Edit Inventory Check' : 'New Inventory Check'}
        </Typography>
      </Box>
      
      <AnimatedButton
        variant="contained"
        color="primary"
        startIcon={<SaveIcon />}
        loading={isCreating}
        onClick={() => setShowResetDialog(true)}
        size="large"
        sx={{ 
          px: 4,
          py: 1,
          fontSize: '1.1rem',
          minWidth: 180
        }}
        disabled={isCreating}
      >
        {checkId ? 'Update' : 'Complete'} Check
      </AnimatedButton>
    </Box>
  );

  const renderSearchField = () => (
    <Box sx={{ 
      mb: 3,
      width: '100%',
      maxWidth: '100%',
      position: 'sticky',
      top: 0,
      zIndex: 1200,
      backgroundColor: 'background.paper',
      p: 2,
      borderBottom: '1px solid',
      borderColor: 'divider',
      boxShadow: 2
    }}>
      <Typography variant="h6" sx={{ mb: 1 }}>
        Scan or Search Items
      </Typography>
      <OptimizedItemSearch
        onItemSelect={(item, isEmptyEnter) => {
          if (isEmptyEnter) {
            // Handle empty Enter press (increment last item)
            if (formData.items.length > 0) {
              const lastItem = formData.items[formData.items.length - 1];
              const newItems = [...formData.items];
              newItems[newItems.length - 1] = {
                ...lastItem,
                counted_quantity: (lastItem.counted_quantity || 0) + 1
              };
              setFormData({ ...formData, items: newItems });
              playSuccess();
              setSnackbar({
                open: true,
                message: `Increased quantity to ${newItems[newItems.length - 1].counted_quantity}`,
                severity: 'success'
              });
            }
          } else if (item) {
            handleAddItemToCount(item);
            playSuccess();
          } else {
            playError();
            setSnackbar({
              open: true,
              message: 'Item not found',
              severity: 'warning'
            });
          }
        }}
        searchRef={searchInputRef}
        showQuantity={true}
        placeholder="Scan barcode or search items..."
        clearOnSelect={true}
      />
    </Box>
  );

  const TotalsDisplay = () => (
    <Box
      sx={{
        display: 'flex',
        gap: 3,
        p: 2,
        bgcolor: 'background.paper',
        borderRadius: 2,
        boxShadow: 2,
        mb: 2,
        mx: 2
      }}
    >
      <Box sx={{ textAlign: 'center' }}>
        <Typography variant="h6" color="primary.main">
          {totals.totalItems}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          Unique Items
        </Typography>
      </Box>
      <Box sx={{ textAlign: 'center' }}>
        <Typography variant="h6" color="success.main">
          {totals.totalScanned}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          Total Scans
        </Typography>
      </Box>
      <Box sx={{ textAlign: 'center' }}>
        <Typography variant="h6" color="info.main">
          {totals.totalQuantity}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          Total Quantity
        </Typography>
      </Box>
    </Box>
  );

  const handleViewSelection = ({ view, mode }) => {
    setViewMode(view);
    setSpreadsheetMode(mode);
    setShowViewSelection(false);
    setShowComparison(mode === 'comparison');

    // Update form data with view mode
    setFormData(prev => ({
      ...prev,
      view_mode: view,
      spreadsheet_mode: mode
    }));
  };

  const renderBarcodeView = () => (
    <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      {renderSearchField()}
      <TotalsDisplay />
      
      <TableContainer 
        component={Paper} 
        sx={{ 
          flex: 1, 
          overflow: 'auto',
          boxShadow: 2,
          borderRadius: 2,
          mx: 2,
          '& .MuiTableRow-root:hover': {
            backgroundColor: 'action.hover'
          }
        }}
      >
        <Table stickyHeader size="medium">
          <TableHead>
            <TableRow>
              <TableCell 
                sx={{ 
                  fontWeight: 'bold', 
                  backgroundColor: 'primary.main', 
                  color: 'white',
                  fontSize: '1rem'
                }}
              >
                Item Name
              </TableCell>
              <TableCell 
                sx={{ 
                  fontWeight: 'bold', 
                  backgroundColor: 'primary.main', 
                  color: 'white',
                  fontSize: '1rem'
                }}
              >
                Type
              </TableCell>
              <TableCell 
                align="right" 
                sx={{ 
                  fontWeight: 'bold', 
                  backgroundColor: 'primary.main', 
                  color: 'white',
                  fontSize: '1rem'
                }}
              >
                Previous
              </TableCell>
              <TableCell 
                align="right" 
                sx={{ 
                  fontWeight: 'bold', 
                  backgroundColor: 'primary.main', 
                  color: 'white',
                  fontSize: '1rem'
                }}
              >
                Counted
              </TableCell>
              <TableCell 
                align="right" 
                sx={{ 
                  fontWeight: 'bold', 
                  backgroundColor: 'primary.main', 
                  color: 'white',
                  fontSize: '1rem'
                }}
              >
                Difference
              </TableCell>
              <TableCell 
                align="center" 
                sx={{ 
                  fontWeight: 'bold', 
                  backgroundColor: 'primary.main', 
                  color: 'white',
                  fontSize: '1rem'
                }}
              >
                Actions
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {formData.items.map((item, index) => {
              const warehouseItem = warehouseItems.find(wi => wi.warehouse_item_id === item.warehouse_item_id);
              const difference = item.counted_quantity - item.previous_quantity;
              
              return (
                <TableRow 
                  key={item.warehouse_item_id}
                  sx={{
                    '&:last-child td, &:last-child th': { border: 0 },
                    bgcolor: index === formData.items.length - 1 ? 'action.selected' : 'inherit'
                  }}
                >
                  <TableCell sx={{ 
                    fontWeight: 'bold',
                    fontSize: '0.95rem'
                  }}>
                    {warehouseItem?.item_name}
                  </TableCell>
                  <TableCell sx={{ fontSize: '0.95rem' }}>
                    {warehouseItem?.item_type}
                  </TableCell>
                  <TableCell align="right" sx={{ fontSize: '0.95rem' }}>
                    {item.previous_quantity}
                  </TableCell>
                  <TableCell align="right">
                    <TextField
                      type="number"
                      value={item.counted_quantity}
                      onChange={(e) => {
                        const newItems = [...formData.items];
                        newItems[index].counted_quantity = parseInt(e.target.value) || 0;
                        setFormData({ ...formData, items: newItems });
                      }}
                      size="small"
                      sx={{ 
                        width: 80,
                        '& input': {
                          fontSize: '0.95rem',
                          fontWeight: 'bold',
                          textAlign: 'right'
                        }
                      }}
                    />
                  </TableCell>
                  <TableCell align="right">
                    <Typography
                      sx={{ 
                        fontWeight: 'bold',
                        fontSize: '0.95rem'
                      }}
                      color={difference > 0 ? 'success.main' : difference < 0 ? 'error.main' : 'text.primary'}
                    >
                      {difference > 0 ? '+' : ''}{difference}
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Box sx={{ display: 'flex', justifyContent: 'center', gap: 1 }}>
                      <IconButton
                        size="small"
                        onClick={() => {
                          setItemNotes({ ...itemNotes, [item.warehouse_item_id]: item.notes });
                          setShowNotes(true);
                        }}
                      >
                        <CommentIcon color={item.notes ? 'primary' : 'action'} />
                      </IconButton>
                      <IconButton
                        color="error"
                        size="small"
                        onClick={() => handleRemoveItem(item.warehouse_item_id)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Box>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );

  // Load spreadsheet data
  useEffect(() => {
    const loadSpreadsheetData = async () => {
      setSpreadsheetLoading(true);
      try {
        if (!checkId) {
          // For new checks, use current warehouse quantities as previous
          const initialData = warehouseItems.reduce((acc, item) => {
            // Store original inventory separately
            setOriginalInventory(prev => ({
              ...prev,
              [item.warehouse_item_id]: {
                quantity: item.current_quantity || 0,
                item_name: item.item_name,
                item_type: item.item_type
              }
            }));

            // Set initial counting data
            acc[item.warehouse_item_id] = {
              quantity: 0,
              previous_quantity: item.current_quantity || 0,
              original_quantity: item.current_quantity || 0 // Store original quantity
            };
            return acc;
          }, {});
          setSpreadsheetData(initialData);
        } else {
          const data = await inventoryCheckApi.getInventoryCheckSpreadsheetData(checkId);
          
          // Store original inventory
          warehouseItems.forEach(item => {
            setOriginalInventory(prev => ({
              ...prev,
              [item.warehouse_item_id]: {
                quantity: item.current_quantity || 0,
                item_name: item.item_name,
                item_type: item.item_type
              }
            }));
          });

          // Set inventory check data
          setSpreadsheetData(data.items || {});
        }
      } catch (error) {
        setSnackbar({
          open: true,
          message: 'Failed to load spreadsheet data: ' + error.message,
          severity: 'error'
        });
      } finally {
        setSpreadsheetLoading(false);
      }
    };

    if (warehouseItems.length > 0) {
      loadSpreadsheetData();
    }
  }, [checkId, warehouseItems]);

  // Load comparison data
  useEffect(() => {
    const loadComparisonData = async () => {
      if (!showComparison) return;
      
      setComparisonLoading(true);
      try {
        const data = await getWarehouseInventorySpreadsheet(warehouseId);
        setComparisonData(data.items);
      } catch (error) {
        setSnackbar({
          open: true,
          message: 'Failed to load comparison data: ' + error.message,
          severity: 'error'
        });
      } finally {
        setComparisonLoading(false);
      }
    };

    loadComparisonData();
  }, [warehouseId, showComparison]);

  // Handle spreadsheet changes
  const handleSpreadsheetChange = useCallback(({ itemId, metric, newValue }) => {
    if (metric === 'quantity') {
      const newItems = [...formData.items];
      const existingIndex = newItems.findIndex(item => item.warehouse_item_id === itemId);
      const originalQuantity = originalInventory[itemId]?.quantity || 0;
      
      if (existingIndex !== -1) {
        newItems[existingIndex] = {
          ...newItems[existingIndex],
          counted_quantity: newValue,
          previous_quantity: originalQuantity
        };
      } else {
        newItems.push({
          warehouse_item_id: itemId,
          previous_quantity: originalQuantity,
          counted_quantity: newValue,
          notes: ''
        });
      }
      
      setFormData(prev => ({
        ...prev,
        items: newItems
      }));

      setSpreadsheetData(prev => ({
        ...prev,
        [itemId]: {
          ...prev[itemId],
          quantity: newValue,
          previous_quantity: originalQuantity
        }
      }));

      playSuccess();
    }
  }, [formData.items, originalInventory, playSuccess]);

  const renderSpreadsheetView = () => {
    // Prepare data based on spreadsheet mode
    const displayData = spreadsheetMode === 'comparison' 
      ? Object.keys(spreadsheetData).reduce((acc, itemId) => {
          acc[itemId] = {
            ...spreadsheetData[itemId],
            original_quantity: originalInventory[itemId]?.quantity || 0,
            item_name: originalInventory[itemId]?.item_name,
            item_type: originalInventory[itemId]?.item_type
          };
          return acc;
        }, {})
      : spreadsheetData;

    // Define metrics based on mode
    const metrics = {
      simple: ['quantity'],
      detailed: ['quantity', 'previous_quantity'],
      comparison: ['quantity', 'original_quantity']
    }[spreadsheetMode];

    return (
      <Box sx={{ height: '100%', p: 2 }}>
        <OptimizedSpreadsheet
          data={displayData}
          metrics={metrics}
          title={`Inventory Check ${checkId ? '- Edit' : '- New'} ${
            spreadsheetMode === 'comparison' ? '(Comparison View)' : ''
          }`}
          onCellValueChanged={handleSpreadsheetChange}
          readOnly={false}
          fullScreen={true}
          hideZeros={false}
          readOnlyMetrics={spreadsheetMode === 'comparison' ? ['original_quantity'] : ['previous_quantity']}
          isLoading={spreadsheetLoading || loading}
          columnLabels={{
            quantity: 'Counted Quantity',
            original_quantity: 'Original Inventory',
            previous_quantity: 'Previous Quantity'
          }}
        />
      </Box>
    );
  };

  const ResetDialog = () => {
    const [statistics, setStatistics] = useState({
      totalItems: 0,
      totalCounted: 0,
      totalQuantity: 0,
      percentageCounted: 0,
      significantDifferences: 0
    });

    useEffect(() => {
      if (showResetDialog) {
        setLocalNotes(formData.notes || '');
        // Calculate statistics
        const stats = formData.items.reduce((acc, item) => {
          const prevQuantity = item.previous_quantity || 0;
          const newQuantity = item.counted_quantity || 0;
          const difference = Math.abs(prevQuantity - newQuantity);
          
          return {
            totalItems: acc.totalItems + 1,
            totalCounted: acc.totalCounted + (newQuantity > 0 ? 1 : 0),
            totalQuantity: acc.totalQuantity + newQuantity,
            significantDifferences: acc.significantDifferences + (difference > 0 ? 1 : 0)
          };
        }, {
          totalItems: 0,
          totalCounted: 0,
          totalQuantity: 0,
          significantDifferences: 0
        });

        stats.percentageCounted = (stats.totalCounted / stats.totalItems) * 100;
        setStatistics(stats);
      }
    }, [showResetDialog, formData.items]);

    return (
      <Dialog 
        open={showResetDialog} 
        onClose={() => setShowResetDialog(false)}
        maxWidth="lg"
        fullWidth
      >
        <DialogTitle>Complete Inventory Check</DialogTitle>
        <DialogContent>
          {/* Statistics Cards */}
          <Box sx={{ mb: 3, display: 'flex', gap: 2 }}>
            <Paper sx={{ p: 2, flex: 1, textAlign: 'center' }}>
              <Typography variant="h6">{statistics.totalItems}</Typography>
              <Typography color="textSecondary">Total Items</Typography>
            </Paper>
            <Paper sx={{ p: 2, flex: 1, textAlign: 'center' }}>
              <Typography variant="h6">{statistics.totalCounted}</Typography>
              <Typography color="textSecondary">Items Counted</Typography>
            </Paper>
            <Paper sx={{ p: 2, flex: 1, textAlign: 'center' }}>
              <Typography variant="h6">{statistics.percentageCounted.toFixed(1)}%</Typography>
              <Typography color="textSecondary">Completion Rate</Typography>
            </Paper>
            <Paper sx={{ p: 2, flex: 1, textAlign: 'center', bgcolor: statistics.significantDifferences > 0 ? 'warning.light' : 'success.light' }}>
              <Typography variant="h6">{statistics.significantDifferences}</Typography>
              <Typography color="textSecondary">Differences Found</Typography>
            </Paper>
          </Box>

          {/* Comparison Table */}
          <TableContainer component={Paper} sx={{ mb: 3, maxHeight: 400 }}>
            <Table stickyHeader size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Item Name</TableCell>
                  <TableCell>Type</TableCell>
                  <TableCell align="right">Previous Qty</TableCell>
                  <TableCell align="right">Counted Qty</TableCell>
                  <TableCell align="right">Difference</TableCell>
                  <TableCell>Notes</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {formData.items.map((item) => {
                  const warehouseItem = warehouseItems.find(wi => wi.warehouse_item_id === item.warehouse_item_id);
                  const difference = (item.counted_quantity || 0) - (item.previous_quantity || 0);
                  const hasDifference = difference !== 0;

                  return (
                    <TableRow 
                      key={item.warehouse_item_id}
                      sx={{ 
                        bgcolor: hasDifference ? 'warning.lighter' : 'inherit',
                        '&:hover': { bgcolor: hasDifference ? 'warning.light' : 'action.hover' }
                      }}
                    >
                      <TableCell>{warehouseItem?.item_name}</TableCell>
                      <TableCell>{warehouseItem?.item_type}</TableCell>
                      <TableCell align="right">{item.previous_quantity || 0}</TableCell>
                      <TableCell align="right">{item.counted_quantity || 0}</TableCell>
                      <TableCell 
                        align="right"
                        sx={{ 
                          color: difference > 0 ? 'success.main' : difference < 0 ? 'error.main' : 'inherit',
                          fontWeight: hasDifference ? 'bold' : 'normal'
                        }}
                      >
                        {difference > 0 ? `+${difference}` : difference}
                      </TableCell>
                      <TableCell>{item.notes || '-'}</TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>

          {/* Settings and Notes */}
          <FormControlLabel
            control={
              <Switch
                checked={formData.reset_uncounted_items}
                onChange={(e) => {
                  // If trying to enable the reset option, show confirmation dialog
                  if (e.target.checked) {
                    setShowResetConfirmDialog(true);
                  } else {
                    // If disabling, just update the state directly
                    setFormData({ 
                      ...formData, 
                      reset_uncounted_items: false 
                    });
                  }
                }}
              />
            }
            label="Reset uncounted items to 0"
          />
          <TextField
            fullWidth
            multiline
            rows={4}
            label="Add Notes (Optional)"
            value={localNotes}
            onChange={(e) => setLocalNotes(e.target.value)}
            sx={{ mt: 2 }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowResetDialog(false)}>Cancel</Button>
          <Button 
            onClick={() => {
              setShowResetDialog(false);
              handleSubmit(localNotes);
            }} 
            variant="contained" 
            color="primary"
            disabled={statistics.totalCounted === 0}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const SuccessDialog = () => (
    <Dialog 
      open={showSuccessDialog}
      maxWidth="sm"
      fullWidth
      PaperProps={{
        sx: {
          background: 'rgba(255, 255, 255, 0.95)',
          backdropFilter: 'blur(8px)',
        }
      }}
    >
      <DialogContent sx={{ textAlign: 'center', py: 6 }}>
        <motion.div
          initial={{ scale: 0, rotate: -180 }}
          animate={{ scale: 1, rotate: 0 }}
          transition={{ 
            type: "spring",
            stiffness: 260,
            damping: 20 
          }}
        >
          <CheckCircleIcon 
            sx={{ 
              fontSize: 80,
              color: 'success.main',
              filter: 'drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.2))'
            }} 
          />
        </motion.div>
        <motion.div
          initial={{ y: 20, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ delay: 0.2, duration: 0.5 }}
        >
          <Typography variant="h5" sx={{ mt: 3, mb: 1, fontWeight: 'bold' }}>
            Success!
          </Typography>
          <Typography variant="body1" color="text.secondary" sx={{ mb: 3 }}>
            Inventory Check {checkId ? 'Updated' : 'Created'} Successfully
          </Typography>
          
          <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2, mt: 2 }}>
            <motion.div
              initial={{ x: -20, opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              transition={{ delay: 0.4 }}
            >
              <Button
                startIcon={<ShareIcon />}
                variant="outlined"
                onClick={() => {
                  setSnackbar({
                    open: true,
                    message: '🚀 Sharing feature coming soon!',
                    severity: 'info'
                  });
                }}
              >
                Share
              </Button>
            </motion.div>
            
            <motion.div
              initial={{ x: 20, opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              transition={{ delay: 0.6 }}
            >
              <Button
                startIcon={<CloudDownloadIcon />}
                variant="outlined"
                onClick={() => {
                  setSnackbar({
                    open: true,
                    message: '📊 Export features coming soon!',
                    severity: 'info'
                  });
                }}
              >
                Export
              </Button>
            </motion.div>
          </Box>
        </motion.div>
      </DialogContent>
    </Dialog>
  );

  const NotesDialog = () => (
    <Dialog 
      open={showNotes} 
      onClose={() => setShowNotes(false)} 
      maxWidth="sm" 
      fullWidth
      PaperProps={{
        sx: {
          maxHeight: '80vh'
        }
      }}
    >
      <DialogTitle>
        <Typography variant="h6">
          Inventory Check Notes
        </Typography>
      </DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          fullWidth
          multiline
          rows={4}
          label="General Notes"
          value={localNotes}
          onChange={(e) => handleNotesChange(e)}
          variant="outlined"
          sx={{ mb: 3 }}
          placeholder="Add general notes about this inventory check..."
        />
        
        <Typography variant="subtitle1" sx={{ mb: 2, fontWeight: 'bold' }}>
          Item-specific Notes
        </Typography>
        
        {formData.items.map((item) => {
          const warehouseItem = warehouseItems.find(wi => wi.warehouse_item_id === item.warehouse_item_id);
          return (
            <TextField
              key={item.warehouse_item_id}
              fullWidth
              multiline
              rows={2}
              label={`Notes for ${warehouseItem?.item_name}`}
              value={item.notes || ''}
              onChange={(e) => handleNotesChange(e, item.warehouse_item_id)}
              variant="outlined"
              sx={{ mb: 2 }}
              placeholder={`Add notes for ${warehouseItem?.item_name}...`}
            />
          );
        })}
      </DialogContent>
      <DialogActions>
        <Button 
          onClick={() => setShowNotes(false)}
          variant="contained"
          color="primary"
        >
          Done
        </Button>
      </DialogActions>
    </Dialog>
  );

  const ResetConfirmDialog = () => (
    <Dialog
      open={showResetConfirmDialog}
      onClose={() => setShowResetConfirmDialog(false)}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle>
        <Typography variant="h6" color="error">
          Warning: Reset Uncounted Items
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Typography variant="body1" paragraph>
          Are you sure you want to reset all items not included in this inventory check to zero?
        </Typography>
        <Typography variant="body2" color="text.secondary" paragraph>
          This will set the quantity of all warehouse items that were not counted in this check to 0.
          This action can have significant impact on your inventory levels.
        </Typography>
        <Typography variant="body2" fontWeight="bold" color="error.main">
          This action cannot be undone after the inventory check is completed.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button 
          onClick={() => {
            setShowResetConfirmDialog(false);
            // Revert the switch back to off
            setFormData({ 
              ...formData, 
              reset_uncounted_items: false 
            });
          }}
          color="primary"
        >
          Cancel
        </Button>
        <Button 
          onClick={() => {
            setShowResetConfirmDialog(false);
            // Confirm the reset
            setFormData({ 
              ...formData, 
              reset_uncounted_items: true 
            });
          }}
          variant="contained"
          color="error"
        >
          Yes, Reset Uncounted Items
        </Button>
      </DialogActions>
    </Dialog>
  );

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <FullScreenLayout
        header={renderHeader()}
        mainPanel={
          <>
            <ViewSelectionDialog 
              open={showViewSelection} 
              onSelect={handleViewSelection}
            />
            {viewMode === 'barcode' && renderBarcodeView()}
            {viewMode === 'spreadsheet' && renderSpreadsheetView()}
          </>
        }
        hasNavigation
      />
      
      <ResetDialog />
      <SuccessDialog />
      <ProcessingAnimation open={isCreating} checkId={checkId} />
      <NotesDialog />
      <ResetConfirmDialog />
      
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert severity={snackbar.severity} onClose={() => setSnackbar({ ...snackbar, open: false })}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default InventoryCheckForm; 