import React, { useEffect } from 'react';
import { Outlet, Link, useLocation, useNavigate } from 'react-router-dom';
import { Box, Container, AppBar, Toolbar, Typography, Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import { checkCustomerAuth, customerLogout } from '../../services/customerapi';
import LogoutIcon from '@mui/icons-material/Logout';

const RootContainer = styled(Box)(({ theme }) => ({
  minHeight: '100vh',
  backgroundColor: '#f5f7fa',
  display: 'flex',
  flexDirection: 'column'
}));

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  backgroundColor: '#ffffff',
  boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
  borderBottom: '1px solid rgba(0, 0, 0, 0.05)',
}));

const Logo = styled(Typography)(({ theme }) => ({
  fontWeight: 800,
  color: '#212B36',
  fontSize: '1.25rem',
  letterSpacing: '0.5px',
  background: 'linear-gradient(45deg, #1a73e8 30%, #304FFE 90%)',
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
  textShadow: '0 2px 4px rgba(26,115,232,0.2)',
  textDecoration: 'none',
  cursor: 'pointer',
  '&:hover': {
    transform: 'translateY(-1px)',
  },
}));

const MainContent = styled(Container)(({ theme }) => ({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(3),
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(2),
  },
}));

const LogoutButton = styled(Button)(({ theme }) => ({
  marginLeft: 'auto',
  color: theme.palette.grey[700],
  backgroundColor: 'rgba(0, 0, 0, 0.04)',
  '&:hover': {
    backgroundColor: 'rgba(0, 0, 0, 0.08)',
  },
}));

const HeaderText = styled(Typography)(({ theme }) => ({
  marginLeft: theme.spacing(2),
  color: theme.palette.grey[600],
  fontWeight: 500,
  fontSize: '1rem',
  [theme.breakpoints.down('sm')]: {
    display: 'none',
  },
}));

const CustomerLayout = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const isAuthenticated = checkCustomerAuth();
  
  const publicPaths = [
    '/customer/login',
    '/customer/forgot-password',
    '/customer/reset-password'
  ];
  
  // Check if current path is a public path
  const isPublicPath = publicPaths.some(path => location.pathname.startsWith(path));
  
  useEffect(() => {
    // Only redirect to login if not authenticated and not on a public path
    if (!isAuthenticated && !publicPaths.some(path => location.pathname.startsWith(path))) {
      navigate('/customer/login', { 
        state: { from: location },
        replace: true 
      });
    }
  }, [isAuthenticated, location, navigate]);

  const handleLogout = () => {
    customerLogout();
    navigate('/customer/login', { replace: true });
  };

  return (
    <RootContainer>
      {/* Only render the AppBar if not on a public path */}
      {!isPublicPath && (
        <StyledAppBar position="static">
          <Toolbar>
            <Box display="flex" alignItems="center">
              <Logo 
                variant="h6" 
                component={Link} 
                to={isAuthenticated ? "/customer/dashboard" : "/customer/login"}
              >
                PIM BY ELI
              </Logo>
              {isAuthenticated && (
                <HeaderText variant="h6">
                  / Customer Dashboard
                </HeaderText>
              )}
            </Box>
            {isAuthenticated && (
              <LogoutButton 
                onClick={handleLogout}
                startIcon={<LogoutIcon />}
              >
                Logout
              </LogoutButton>
            )}
          </Toolbar>
        </StyledAppBar>
      )}
      <MainContent maxWidth={isPublicPath ? false : "lg"} sx={{ padding: isPublicPath ? 0 : undefined }}>
        <Outlet />
      </MainContent>
    </RootContainer>
  );
};

export default CustomerLayout; 