import React from 'react';
import {
  Box,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Chip,
  IconButton,
  Menu,
  MenuItem,
} from '@mui/material';
import { format } from 'date-fns';
import EditIcon from '@mui/icons-material/Edit';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ShareIcon from '@mui/icons-material/Share';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import { StyledTableContainer } from './StyledComponents';

const InventoryChecksTab = ({
  inventoryChecks,
  handleInventoryCheckClick,
  handleActionClick,
  actionMenuAnchor,
  handleActionClose,
  handleShareClick,
  handleExportGoogleSheets,
  handleExportExcel,
  navigate,
  warehouseId,
}) => {
  return (
    <Box sx={{ p: 2 }}>
      <StyledTableContainer>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Created At</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Items Count</TableCell>
              <TableCell>Reset Uncounted</TableCell>
              <TableCell>Notes</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {inventoryChecks.map((check) => (
              <TableRow 
                key={check.check_id}
                hover
                onClick={(e) => handleInventoryCheckClick(check.check_id, e)}
                sx={{ 
                  cursor: 'pointer',
                  '&:hover': {
                    backgroundColor: 'rgba(0, 0, 0, 0.04)',
                  }
                }}
              >
                <TableCell>{check.check_id}</TableCell>
                <TableCell>
                  {format(new Date(check.created_at), 'MMM d, yyyy HH:mm')}
                </TableCell>
                <TableCell>
                  <Chip 
                    label={check.status}
                    color={
                      check.status === 'draft' ? 'default' :
                      check.status === 'pending' ? 'warning' :
                      check.status === 'confirmed' ? 'success' :
                      check.status === 'cancelled' ? 'error' :
                      'default'
                    }
                  />
                </TableCell>
                <TableCell>{check.items?.length || 0}</TableCell>
                <TableCell>
                  <Chip 
                    label={check.reset_uncounted_items ? 'Yes' : 'No'}
                    color={check.reset_uncounted_items ? 'warning' : 'default'}
                  />
                </TableCell>
                <TableCell>{check.notes}</TableCell>
                <TableCell>
                  <Box sx={{ display: 'flex', gap: 1 }}>
                    {check.status === 'draft' && (
                      <IconButton 
                        onClick={(e) => {
                          e.stopPropagation();
                          navigate(`/warehouses/${warehouseId}/inventory-checks/${check.check_id}/edit`);
                        }}
                      >
                        <EditIcon />
                      </IconButton>
                    )}
                    <IconButton
                      size="small"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleActionClick(e, check.check_id, 'inventory');
                      }}
                    >
                      <MoreVertIcon />
                    </IconButton>
                  </Box>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </StyledTableContainer>

      <Menu
        anchorEl={actionMenuAnchor}
        open={Boolean(actionMenuAnchor)}
        onClose={handleActionClose}
      >
        <MenuItem onClick={handleShareClick}>
          <ShareIcon fontSize="small" sx={{ mr: 1 }} />
          Share
        </MenuItem>
        <MenuItem onClick={handleExportGoogleSheets}>
          <CloudDownloadIcon fontSize="small" sx={{ mr: 1 }} />
          Export to Google Sheets
        </MenuItem>
        <MenuItem onClick={handleExportExcel}>
          <CloudDownloadIcon fontSize="small" sx={{ mr: 1 }} />
          Export to Excel
        </MenuItem>
      </Menu>
    </Box>
  );
};

export default InventoryChecksTab;
