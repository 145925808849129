import api from './api';

export const shipmentCreationApi = {
    // Step 1: Create initial shipment
    createInitialShipment: async (initialData) => {
        try {
            const response = await api.post('/shipment-creation/create-initial', initialData);
            return {
                success: true,
                data: response.data
            };
        } catch (error) {
            console.error('Error creating initial shipment:', error);
            return {
                success: false,
                error: error.response?.data?.error || 'Failed to create shipment'
            };
        }
    },

    // Step 2: Get available orders - Simplified
    getAvailableOrders: async (shipmentId, settings = {}) => {
        try {
            const params = new URLSearchParams();
            
            // Add order statuses
            if (settings?.orderStatuses?.length) {
                params.append('statuses', JSON.stringify(settings.orderStatuses));
            }
            
            // Add customer filter
            if (settings?.selectedCustomers?.length) {
                params.append('customerIds', JSON.stringify(settings.selectedCustomers));
            }
            
            const response = await api.get(
                `/shipment-creation/available-orders/${shipmentId}?${params.toString()}`
            );
            console.log('API Response for available orders:', response.data);
            
            return response.data;
        } catch (error) {
            console.error('Error fetching available orders:', error);
            return { 
                success: false, 
                error: error.response?.data?.error || error.message 
            };
        }
    },

    // Step 3: Get available items for order - Simplified
    getAvailableOrderItems: async (shipmentId, orderId, respectReserved = true, timestamp = null) => {
        try {
            const params = new URLSearchParams();
            params.append('respectReserved', respectReserved);
            
            // Add timestamp for cache busting if provided
            if (timestamp) {
                params.append('_t', timestamp);
            }
            
            const response = await api.get(
                `/shipment-creation/available-order-items/${shipmentId}/${orderId}?${params.toString()}`
            );
            console.log('API Response for available order items:', response.data);
            
            if (!response.data?.success) {
                throw new Error(response.data?.error || 'Failed to fetch order items');
            }
            
            return {
                success: true,
                data: Array.isArray(response.data.data) ? response.data.data : []
            };
        } catch (error) {
            console.error('Error fetching available order items:', error);
            return {
                success: false,
                error: error.response?.data?.error || error.message,
                data: [] // Always return an array
            };
        }
    },

    // Step 4: Add order items to shipment - With optional reservation
    addOrderItems: async (shipmentId, items, reserveInventory = false, reserveReason = '') => {
        try {
            const response = await api.post(
                `/shipment-creation/add-order-items/${shipmentId}`, 
                { items, reserveInventory, reserveReason }
            );
            return {
                success: true,
                data: response.data
            };
        } catch (error) {
            console.error('Error adding order items:', error);
            return {
                success: false,
                error: error.response?.data?.error || 'Failed to add order items'
            };
        }
    },

    // Step 5: Get remaining inventory - Simplified
    getRemainingInventory: async (shipmentId, respectReserved = true, timestamp = null) => {
        try {
            const params = new URLSearchParams();
            params.append('respectReserved', respectReserved);
            
            // Add timestamp for cache busting if provided
            if (timestamp) {
                params.append('_t', timestamp);
            }
            
            const response = await api.get(
                `/shipment-creation/remaining-inventory/${shipmentId}?${params.toString()}`
            );
            console.log('API Response for remaining inventory:', response.data);
            
            if (!response.data?.success) {
                throw new Error(response.data?.error || 'Failed to fetch inventory');
            }
            
            return {
                success: true,
                data: Array.isArray(response.data.data) ? response.data.data : []
            };
        } catch (error) {
            console.error('Error fetching remaining inventory:', error);
            return {
                success: false,
                error: error.response?.data?.error || error.message,
                data: [] // Always return an array
            };
        }
    },

    // Step 6: Add additional items - With optional reservation
    addAdditionalItems: async (shipmentId, items, respectReserved = true, reserveInventory = false, reserveReason = '') => {
        try {
            const response = await api.post(
                `/shipment-creation/add-additional-items/${shipmentId}`, 
                { 
                    items, 
                    respectReserved, 
                    reserveInventory, 
                    reserveReason 
                }
            );
            return {
                success: true,
                data: response.data
            };
        } catch (error) {
            console.error('Error adding additional items:', error);
            return {
                success: false,
                error: error.response?.data?.error || 'Failed to add additional items'
            };
        }
    },

    // Get shipment details
    getShipmentDetails: async (shipmentId, settings = {}) => {
        try {
            const params = new URLSearchParams();
            
            // Add settings to params if they exist
            if (settings?.showOnlyAvailable !== undefined) {
                params.append('showOnlyAvailable', settings.showOnlyAvailable);
            }

            // Add inventory calculation settings
            if (settings?.subtractShipments !== undefined) {
                params.append('subtractShipments', settings.subtractShipments);
            }
            
            if (Array.isArray(settings?.shipmentStatusesToSubtract)) {
                params.append('shipmentStatusesToSubtract', JSON.stringify(settings.shipmentStatusesToSubtract));
            }
            
            if (settings?.shipmentScope) {
                params.append('shipmentScope', settings.shipmentScope);
            }
            
            const response = await api.get(
                `/shipment-creation/details/${shipmentId}?${params.toString()}`
            );
            
            return {
                success: true,
                data: response.data
            };
        } catch (error) {
            console.error('Error fetching shipment details:', error);
            return {
                success: false,
                error: error.response?.data?.error || 'Failed to fetch shipment details'
            };
        }
    },

    // Delete shipment
    deleteShipment: async (shipmentId) => {
        try {
            const response = await api.delete(`/shipment-creation/${shipmentId}`);
            return {
                success: true,
                data: response.data
            };
        } catch (error) {
            console.error('Error deleting shipment:', error);
            return {
                success: false,
                error: error.response?.data?.error || 'Failed to delete shipment'
            };
        }
    }
};