import React, { useMemo, useCallback, useState, useEffect, memo } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { Box, Tabs, Tab, Paper, IconButton, Menu, MenuItem, Switch, FormControlLabel, Select, FormControl, Button, ButtonGroup } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import CustomerItemsCacheService from '../../services/customerItemsCacheService';

const SPREADSHEET_SETTINGS_KEY = 'customerSpreadsheetSettings';

const getDefaultSettings = () => ({
  hideZeros: true,
  showOldBrims: false,
  zoomLevel: 100,
});

const loadUserSettings = () => {
  try {
    const savedSettings = localStorage.getItem(SPREADSHEET_SETTINGS_KEY);
    return savedSettings ? JSON.parse(savedSettings) : getDefaultSettings();
  } catch (error) {
    console.error('Error loading settings:', error);
    return getDefaultSettings();
  }
};

function CustomerOptimizedSpreadsheet({ 
  data,
  metrics,
  title, 
  onCellValueChanged,
  readOnly = false,
  hideZeros: hideZerosProp,
  fullScreen = false,
  isLoading: externalLoading = false,
  readOnlyMetrics = []
}) {
  const [items, setItems] = useState([]);
  const [itemConfig, setItemConfig] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [activeType, setActiveType] = useState('');
  const [settings, setSettings] = useState(loadUserSettings());
  const [anchorEl, setAnchorEl] = useState(null);
  const [gridApi, setGridApi] = useState(null);
  const [showZoomControls, setShowZoomControls] = useState(false);
  const [isFullscreen, setIsFullscreen] = useState(false);

  const zoomLevels = [50, 75, 90, 100, 110, 125, 150, 200];

  useEffect(() => {
    const fetchItems = async () => {
      try {
        let config = CustomerItemsCacheService.getCachedConfig();
        let cachedItems = CustomerItemsCacheService.getCachedItems();
        
        if (config && cachedItems) {
          setItems(cachedItems);
          setItemConfig(config);
          setActiveType(config.types[0]);
          setTimeout(() => applyZoomLevel(settings.zoomLevel), 0);
          setIsLoading(false);
          return;
        }

        const freshItems = await CustomerItemsCacheService.getItems();
        config = CustomerItemsCacheService.getCachedConfig();
        
        setItems(freshItems);
        setItemConfig(config);
        setActiveType(config.types[0]);
        setTimeout(() => applyZoomLevel(settings.zoomLevel), 0);
      } catch (err) {
        console.error('Error loading items:', err);
        setError('Failed to load items data');
      } finally {
        setIsLoading(false);
      }
    };

    fetchItems();
  }, [settings.zoomLevel]);

  const cellKeyToItemMap = useMemo(() => {
    if (!itemConfig) return new Map();
    
    const lookupMap = new Map();
    itemConfig.sizes.forEach(size => {
      itemConfig.brims.forEach(brim => {
        itemConfig.shapes.forEach(shape => {
          const cellKey = `${activeType}-${size.label}-${brim.id}-${shape}`;
          const itemId = Array.from(itemConfig.itemMap.entries())
            .find(([_, config]) => config.cellKey === cellKey)?.[0];
          if (itemId) {
            lookupMap.set(cellKey, {
              itemId,
              brimId: brim.id,
              shape
            });
          }
        });
      });
    });
    return lookupMap;
  }, [itemConfig, activeType]);

  const handleCellValueChanged = useCallback((params) => {
    if (!onCellValueChanged || !itemConfig) return;

    const { data: rowData, colDef, newValue, oldValue } = params;
    if (!colDef || !rowData) return;

    const [brimId, shape] = colDef.field.split('-');
    if (!brimId || !shape) return;
    
    const cellKey = `${activeType}-${rowData.sizeLabel}-${brimId}-${shape}`;
    const cellInfo = cellKeyToItemMap.get(cellKey);

    if (cellInfo) {
      onCellValueChanged({
        itemId: cellInfo.itemId,
        metric: rowData.metric,
        newValue,
        oldValue
      });
    }
  }, [onCellValueChanged, cellKeyToItemMap]);

  const CellRenderer = memo(({ value }) => {
    if (value === null || value === undefined) return null;
    return <div className="cell-content">{value}</div>;
  }, (prevProps, nextProps) => prevProps.value === nextProps.value);

  const columnDefs = useMemo(() => {
    if (!itemConfig) return [];
    
    const columns = [
      {
        headerName: 'Size',
        field: 'sizeLabel',
        pinned: 'left',
        lockPinned: true,
        cellClass: 'size-column',
        suppressMovable: true,
        suppressSizeToFit: true,
        suppressResize: true,
        width: 80,
        headerClass: 'size-header',
        editable: false,
        rowSpan: params => params.rowIndex % metrics.length === 0 ? metrics.length : 1,
        cellClassRules: {
          'cell-span': params => params.rowIndex % metrics.length === 0
        }
      },
      {
        headerName: 'Metric',
        field: 'metric',
        pinned: 'left',
        lockPinned: true,
        cellClass: 'metric-column',
        suppressMovable: true,
        suppressSizeToFit: true,
        suppressResize: true,
        width: 100,
        headerClass: 'metric-header',
        editable: false
      }
    ];

    const filteredBrims = itemConfig.brims.filter(brim => 
      settings.showOldBrims || brim.is_new
    );

    filteredBrims.forEach(brim => {
      const groupChildren = itemConfig.shapes.map((shape, shapeIndex) => ({
        headerName: shape,
        field: `${brim.id}-${shape}`,
        cellRenderer: CellRenderer,
        cellClass: params => {
          const isReadOnly = readOnlyMetrics.includes(params.data.metric);
          return [
            'data-cell',
            isReadOnly ? 'readonly-cell' : 'editable-cell',
            shapeIndex === itemConfig.shapes.length - 1 ? 'brim-group-border' : ''
          ].filter(Boolean).join(' ');
        },
        headerClass: 'data-header',
        suppressMovable: true,
        suppressResize: true,
        width: 80,
        editable: (params) => {
          if (readOnly) return false;
          return !readOnlyMetrics.includes(params.data.metric);
        },
        valueParser: params => {
          const newValue = Number(params.newValue);
          return isNaN(newValue) ? params.oldValue : newValue;
        },
        valueFormatter: params => {
          if (params.value === undefined || params.value === null || params.value === '') {
            return '';
          }
          return params.value === 0 && settings.hideZeros ? '' : params.value;
        }
      }));

      columns.push({
        headerName: brim.label,
        children: groupChildren,
        headerClass: 'brim-group-header'
      });
    });

    return columns;
  }, [readOnly, metrics.length, itemConfig, settings.hideZeros, settings.showOldBrims, readOnlyMetrics]);

  const rowData = useMemo(() => {
    if (!data || !activeType || !itemConfig) return [];

    const rows = [];
    const filteredBrims = itemConfig.brims.filter(brim => 
      settings.showOldBrims || brim.is_new
    );
    
    itemConfig.sizes.forEach(size => {
      metrics.forEach(metric => {
        const row = { 
          sizeLabel: size.label,
          metric
        };
        
        filteredBrims.forEach(brim => {
          itemConfig.shapes.forEach(shape => {
            const cellKey = `${activeType}-${size.id}-${brim.id}-${shape}`;
            const itemId = Array.from(itemConfig.itemMap.entries())
              .find(([_, config]) => config.cellKey === cellKey)?.[0];
            
            const value = itemId ? data[itemId]?.[metric] : undefined;
            
            if (value === 0 && settings.hideZeros) {
              row[`${brim.id}-${shape}`] = '';
            } else {
              row[`${brim.id}-${shape}`] = value ?? '';
            }
          });
        });
        
        rows.push(row);
      });
    });
    
    return rows;
  }, [data, activeType, metrics, settings.hideZeros, settings.showOldBrims, itemConfig]);

  const defaultColDef = useMemo(() => ({
    resizable: false,
    sortable: false,
    filter: false,
    suppressMovable: true,
    enableCellChangeFlash: true,
    cellStyle: params => {
      const isEditable = !readOnly && !readOnlyMetrics.includes(params.data?.metric);
      return {
        backgroundColor: isEditable ? '#ffffff' : '#fafafa',
        cursor: isEditable ? 'cell' : 'not-allowed',
      };
    },
    enterMovesDown: true,
    enterMovesDownAfterEdit: true,
    stopEditingWhenCellsLoseFocus: false,
    tabToNextCell: true,
    suppressCellSelection: false,
  }), [readOnly, readOnlyMetrics]);

  const gridOptions = useMemo(() => ({
    navigateToNextCell: (params) => {
      const suggestedNextCell = params.nextCellPosition;
      const KEY_UP = 38;
      const KEY_DOWN = 40;
      const KEY_LEFT = 37;
      const KEY_RIGHT = 39;
      const KEY_ENTER = 13;

      switch (params.key) {
        case KEY_DOWN:
        case KEY_UP:
          return suggestedNextCell;
        case KEY_LEFT:
        case KEY_RIGHT:
          return suggestedNextCell;
        case KEY_ENTER:
          return { ...suggestedNextCell, rowIndex: suggestedNextCell.rowIndex + 1 };
        default:
          return suggestedNextCell;
      }
    },

    onCellEditingStarted: (params) => {
      const input = params.api.getCellEditorInstances()[0]?.getGui();
      if (input) {
        input.select();
      }
    },

    getRowStyle: params => ({
      'border-bottom': (params.rowIndex + 1) % metrics.length === 0 ? 
        '1px solid #e0e0e0' : 'none'
    }),

    rowBuffer: 10,
    suppressColumnVirtualisation: false,
    animateRows: false,
  }), [metrics.length]);

  const handleSettingsClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleSettingsClose = () => {
    setAnchorEl(null);
  };

  const handleSettingChange = (setting, value) => {
    const newSettings = { ...settings, [setting]: value };
    setSettings(newSettings);
    localStorage.setItem(SPREADSHEET_SETTINGS_KEY, JSON.stringify(newSettings));
  };

  const handleGridReady = (params) => {
    setGridApi(params.api);
    applyZoomLevel(settings.zoomLevel);
  };

  const applyZoomLevel = (zoom) => {
    const gridElement = document.querySelector('.custom-grid');
    const containerElement = document.querySelector('.grid-container');
    
    if (gridElement && containerElement) {
      const containerWidth = containerElement.offsetWidth;
      const containerHeight = containerElement.offsetHeight;
      
      gridElement.style.transform = `scale(${zoom / 100})`;
      gridElement.style.transformOrigin = 'top left';
      
      const newHeight = containerHeight * (100 / zoom);
      const newWidth = containerWidth * (100 / zoom);
      
      gridElement.style.height = `${newHeight}px`;
      gridElement.style.width = `${newWidth}px`;
      
      if (gridApi) {
        gridApi.refreshCells();
        gridApi.sizeColumnsToFit();
      }
    }
  };

  const handleZoomChange = (newZoom) => {
    const newSettings = { ...settings, zoomLevel: newZoom };
    setSettings(newSettings);
    localStorage.setItem(SPREADSHEET_SETTINGS_KEY, JSON.stringify(newSettings));
    applyZoomLevel(newZoom);
  };

  const handleFullscreenToggle = () => {
    setIsFullscreen(!isFullscreen);
  };

  if (isLoading || externalLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <Box sx={{ 
      height: isFullscreen ? 'calc(100vh - 64px)' : (fullScreen ? '100%' : 'auto'),
      width: '100%',
      position: isFullscreen ? 'absolute' : 'relative',
      top: isFullscreen ? 64 : 'auto',
      left: isFullscreen ? 0 : 'auto',
      right: 0,
      zIndex: isFullscreen ? 1200 : 'auto',
      backgroundColor: 'white',
      display: 'flex',
      flexDirection: 'column',
      '& .spreadsheet-container': {
        height: fullScreen ? '100%' : 'auto',
        margin: 0,
        border: 'none',
        borderRadius: 0,
      }
    }}>
      <Paper sx={{ 
        mb: isFullscreen ? 0 : 2,
        flexShrink: 0,
        borderRadius: isFullscreen ? 0 : 1 
      }}>
        {title && <h1 style={{ textAlign: 'center', margin: '20px 0' }}>{title}</h1>}
        <Box sx={{ 
          display: 'flex', 
          alignItems: 'center',
          borderBottom: 1,
          borderColor: 'divider'
        }}>
          <Tabs 
            value={itemConfig.types.indexOf(activeType)}
            onChange={(_, newValue) => setActiveType(itemConfig.types[newValue])}
            variant="scrollable"
            scrollButtons="auto"
            sx={{ 
              flex: 1,
              '& .MuiTabs-flexContainer': {
                borderBottom: 'none'
              }
            }}
          >
            {itemConfig.types.map((type) => (
              <Tab key={type} label={type} />
            ))}
          </Tabs>
          <IconButton
            onClick={handleFullscreenToggle}
            sx={{
              mr: 1,
              '&:hover': {
                backgroundColor: 'rgba(0, 0, 0, 0.04)'
              }
            }}
          >
            {isFullscreen ? <FullscreenExitIcon fontSize="small" /> : <FullscreenIcon fontSize="small" />}
          </IconButton>
          <Box 
            onMouseEnter={() => setShowZoomControls(true)}
            onMouseLeave={() => setShowZoomControls(false)}
            sx={{ 
              display: 'flex',
              alignItems: 'center',
              gap: 1,
              mr: 2,
              position: 'relative',
              minWidth: '32px',
              height: '28px',
            }}
          >
            {showZoomControls ? (
              <ButtonGroup 
                size="small" 
                sx={{ 
                  height: 28,
                  transition: 'all 0.2s ease',
                  '& .MuiButton-root': {
                    minWidth: '28px',
                    padding: '4px',
                    border: 'none',
                    backgroundColor: 'transparent',
                    '&:hover': {
                      backgroundColor: 'rgba(0, 0, 0, 0.04)',
                      border: 'none'
                    }
                  }
                }}
              >
                <Button
                  onClick={() => {
                    const currentIndex = zoomLevels.indexOf(settings.zoomLevel);
                    if (currentIndex > 0) {
                      handleZoomChange(zoomLevels[currentIndex - 1]);
                    }
                  }}
                  disabled={settings.zoomLevel === zoomLevels[0]}
                >
                  <ZoomOutIcon fontSize="small" />
                </Button>
                <FormControl 
                  size="small" 
                  sx={{ 
                    minWidth: '70px',
                    '& .MuiSelect-select': {
                      py: 0.5,
                      px: 1,
                      backgroundColor: 'transparent'
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                      border: 'none'
                    },
                    '&:hover .MuiOutlinedInput-root': {
                      backgroundColor: 'rgba(0, 0, 0, 0.04)'
                    }
                  }}
                >
                  <Select
                    value={settings.zoomLevel}
                    onChange={(e) => handleZoomChange(e.target.value)}
                  >
                    {zoomLevels.map((zoom) => (
                      <MenuItem key={zoom} value={zoom}>
                        {zoom}%
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <Button
                  onClick={() => {
                    const currentIndex = zoomLevels.indexOf(settings.zoomLevel);
                    if (currentIndex < zoomLevels.length - 1) {
                      handleZoomChange(zoomLevels[currentIndex + 1]);
                    }
                  }}
                  disabled={settings.zoomLevel === zoomLevels[zoomLevels.length - 1]}
                >
                  <ZoomInIcon fontSize="small" />
                </Button>
              </ButtonGroup>
            ) : (
              <Box 
                sx={{ 
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: '32px',
                  height: '28px',
                  opacity: 0.4,
                  cursor: 'pointer',
                  '&:hover': {
                    opacity: 1,
                    backgroundColor: 'rgba(0, 0, 0, 0.04)'
                  }
                }}
              >
                <span style={{ fontSize: '12px' }}>{settings.zoomLevel}%</span>
              </Box>
            )}
          </Box>
          <IconButton 
            onClick={handleSettingsClick}
            sx={{ 
              ml: 1,
              mr: 1,
              '&:hover': {
                backgroundColor: 'rgba(0, 0, 0, 0.04)'
              }
            }}
          >
            <MoreVertIcon fontSize="small" />
          </IconButton>
        </Box>
      </Paper>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleSettingsClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem sx={{ py: 0 }}>
          <FormControlLabel
            control={
              <Switch
                checked={settings.hideZeros}
                onChange={(e) => handleSettingChange('hideZeros', e.target.checked)}
                size="small"
              />
            }
            label="Hide zeros"
          />
        </MenuItem>
        <MenuItem sx={{ py: 0 }}>
          <FormControlLabel
            control={
              <Switch
                checked={settings.showOldBrims}
                onChange={(e) => handleSettingChange('showOldBrims', e.target.checked)}
                size="small"
              />
            }
            label="Show old brims"
          />
        </MenuItem>
      </Menu>

      <div className="grid-container" style={{ 
        width: '100%', 
        height: isFullscreen ? 'calc(100vh - 180px)' : '600px',
        overflow: 'auto',
        flex: isFullscreen ? 1 : 'none',
        display: 'flex',
        flexDirection: 'column'
      }}>
        <div className="ag-theme-alpine custom-grid" style={{
          width: '100%',
          height: '100%',
          flex: 1
        }}>
          <AgGridReact 
            {...gridOptions}
            columnDefs={columnDefs}
            rowData={rowData}
            defaultColDef={defaultColDef}
            onCellValueChanged={handleCellValueChanged}
            onGridReady={handleGridReady}
            suppressPropertyNamesCheck={true}
            suppressReactUi={true}
            rowModelType="clientSide"
            enableRangeSelection={true}
            enableFillHandle={true}
            headerHeight={40}
            groupHeaderHeight={45}
            rowHeight={35}
            stopEditingWhenCellsLoseFocus={false}
            enterMovesDown={true}
            singleClickEdit={true}
          />
        </div>
      </div>
      <style>
        {`
          .custom-grid {
            --ag-header-height: 40px;
            --ag-header-foreground-color: #181D1F;
            --ag-header-background-color: #f8f9fa;
            --ag-header-cell-hover-background-color: #f8f9fa;
            --ag-header-cell-moving-background-color: #f8f9fa;
            --ag-borders: none;
            --ag-border-color: #e0e0e0;
            --ag-cell-horizontal-border: solid #e0e0e0;
            --ag-row-border-color: #e0e0e0;
          }

          .ag-theme-alpine .ag-header-group-cell {
            border-right: 1px solid #e0e0e0 !important;
          }

          .size-column {
            background-color: #f8f9fa;
            font-weight: 600;
            text-align: center;
            border-right: 1px solid #e0e0e0 !important;
          }

          .metric-column {
            background-color: #f8f9fa;
            border-right: 1px solid #e0e0e0 !important;
          }

          .data-cell {
            text-align: center;
          }

          .editable-cell {
            background-color: #ffffff;
          }

          .editable-cell:not(.ag-cell-editing):hover {
            background-color: #fafafa;
            cursor: pointer;
          }

          .brim-group-border {
            border-right: 1px solid #e0e0e0 !important;
          }

          .metric-border {
            border-bottom: 1px solid #e0e0e0 !important;
          }

          .cell-span {
            background-color: #f8f9fa !important;
          }

          .ag-cell-focus {
            border: 1px solid #1976d2 !important;
            outline: none !important;
          }

          .ag-cell-editing {
            background-color: #ffffff !important;
            border: 1px solid #1976d2 !important;
            padding: 1px !important;
          }

          .size-header,
          .metric-header {
            background-color: #f8f9fa !important;
            font-weight: 600;
            border-bottom: 1px solid #e0e0e0;
          }

          .data-header {
            background-color: #f8f9fa !important;
            font-weight: 500;
            text-align: center;
          }

          .brim-group-header {
            background-color: #f8f9fa !important;
            font-weight: 600;
            text-align: center;
            border-bottom: 1px solid #e0e0e0;
          }

          .readonly-cell {
            background-color: #fafafa !important;
            cursor: not-allowed;
          }

          .readonly-cell:hover {
            background-color: #f5f5f5 !important;
          }

          .ag-header-row {
            background-color: #f8f9fa;
          }

          .custom-grid {
            transition: transform 0.2s ease;
            transform-origin: top left;
            position: relative;
            will-change: transform;
            height: 100%;
            width: 100%;
          }

          .grid-container {
            position: relative;
            overflow: auto;
            transition: all 0.2s ease;
            width: 100%;
          }

          .fullscreen-spreadsheet {
            position: absolute;
            top: 64px;
            left: 240px;
            right: 0;
            bottom: 0;
            z-index: 1200;
            background-color: white;
            display: flex;
            flex-direction: column;
          }

          ${isFullscreen ? `
            .ag-root-wrapper {
              height: 100% !important;
            }
            .ag-root {
              height: 100% !important;
            }
            .ag-body-viewport {
              height: 100% !important;
            }
          ` : ''}

          .ag-theme-alpine {
            --ag-selected-row-background-color: rgba(33, 150, 243, 0.1);
            --ag-range-selection-border-color: #2196f3;
            --ag-range-selection-background-color: rgba(33, 150, 243, 0.1);
            --ag-cell-horizontal-padding: 8px;
          }

          .ag-cell-edit-input {
            height: 100% !important;
            padding: 0 8px !important;
            border: none !important;
            border-radius: 0 !important;
            background: white !important;
          }

          .ag-cell-inline-editing {
            padding: 0 !important;
            height: 100% !important;
          }

          .ag-cell-focus {
            border: 2px solid #2196f3 !important;
          }

          .ag-cell-highlight {
            background-color: rgba(33, 150, 243, 0.1) !important;
          }
        `}
      </style>
    </Box>
  );
}

export default CustomerOptimizedSpreadsheet; 