import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  TextField,
  Button,
  Paper,
  CircularProgress,
  Alert,
  Divider,
  Chip,
  Card,
  CardContent,
  Grid,
  IconButton,
  Tooltip,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  FormControlLabel,
  Switch,
} from '@mui/material';
import {
  ContentPaste,
  OpenInNew,
  CheckCircleOutline,
  ContentCopy,
  WarningAmber,
} from '@mui/icons-material';
import { generateBarcodeSheet } from '../services/api';

const BarcodeSheetImport = () => {
  const [sourceUrl, setSourceUrl] = useState('');
  const [sheetName, setSheetName] = useState('Barcode Import');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [result, setResult] = useState(null);
  
  // New state for sheet selection
  const [availableSheets, setAvailableSheets] = useState([]);
  const [selectedSheet, setSelectedSheet] = useState('');
  const [loadingSheets, setLoadingSheets] = useState(false);
  
  // New state for column selection
  const [barcodeColumns, setBarcodeColumns] = useState(['A']);
  const [newColumn, setNewColumn] = useState('');
  
  // New state for row handling
  const [skipHeaderRow, setSkipHeaderRow] = useState(true);

  // Add a column to the list
  const handleAddColumn = () => {
    if (!newColumn) return;
    
    // Convert to uppercase and validate it's a valid column reference
    const column = newColumn.toUpperCase();
    if (!/^[A-Z]+$/.test(column)) {
      setError('Please enter a valid column letter (A-Z)');
      return;
    }
    
    if (!barcodeColumns.includes(column)) {
      setBarcodeColumns([...barcodeColumns, column]);
    }
    
    setNewColumn('');
  };

  // Remove a column from the list
  const handleRemoveColumn = (columnToRemove) => {
    // Never remove the last column
    if (barcodeColumns.length <= 1) return;
    
    setBarcodeColumns(barcodeColumns.filter(column => column !== columnToRemove));
  };

  // Fetch available sheets when URL changes
  useEffect(() => {
    const fetchSheets = async () => {
      if (!sourceUrl || !validateUrl(sourceUrl)) return;
      
      setLoadingSheets(true);
      setError(null);
      
      try {
        // We'll just fetch sheets info when the user clicks Process
        // This is just a placeholder for the structure
        setAvailableSheets([]);
        setSelectedSheet('');
      } catch (err) {
        console.error('Error fetching sheets:', err);
        setError('Could not fetch sheets from the provided URL');
      } finally {
        setLoadingSheets(false);
      }
    };
    
    // For now, we'll just reset sheet selection when URL changes
    setSelectedSheet('');
    setAvailableSheets([]);
    
  }, [sourceUrl]);

  const handlePasteFromClipboard = async () => {
    try {
      const text = await navigator.clipboard.readText();
      setSourceUrl(text);
    } catch (err) {
      setError('Unable to read from clipboard. Please paste the URL manually.');
    }
  };

  const handleCopyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
  };

  const validateUrl = (url) => {
    // Basic validation to check if it looks like a Google Sheets URL
    return url.includes('docs.google.com/spreadsheets') || 
           /^[a-zA-Z0-9-_]{20,}$/.test(url); // For direct sheet IDs
  };

  const handleGenerateSheet = async () => {
    if (!sourceUrl.trim()) {
      setError('Please enter a source Google Sheet URL');
      return;
    }

    if (!validateUrl(sourceUrl)) {
      setError('Please enter a valid Google Sheet URL');
      return;
    }

    setLoading(true);
    setError(null);
    setResult(null);

    try {
      const response = await generateBarcodeSheet(
        sourceUrl, 
        sheetName,
        barcodeColumns,
        selectedSheet,
        skipHeaderRow
      );
      setResult(response);
    } catch (err) {
      setError(err.response?.data?.details || err.response?.data?.error || err.message || 'Failed to generate barcode sheet');
    } finally {
      setLoading(false);
    }
  };

  const renderStats = () => {
    if (!result || !result.stats) return null;
    
    const { totalBarcodes, matchedItems, unmatchedBarcodes, itemsByType } = result.stats;
    
    return (
      <Card sx={{ mt: 3, mb: 3 }}>
        <CardContent>
          <Typography variant="h6" gutterBottom>
            Processing Results
          </Typography>
          
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
            <Typography>
              Total Barcodes Processed: <strong>{totalBarcodes}</strong>
            </Typography>
            <Typography>
              Successfully Matched: <strong>{matchedItems}</strong>
            </Typography>
            <Typography>
              Unmatched Barcodes: <strong>{unmatchedBarcodes}</strong>
            </Typography>
          </Box>
          
          <Divider sx={{ my: 2 }} />
          
          <Typography variant="subtitle1" gutterBottom>
            Items By Type
          </Typography>
          
          <Grid container spacing={1}>
            {itemsByType.map((item) => (
              <Grid item key={item.type}>
                <Chip 
                  label={`${item.type}: ${item.count}`} 
                  color="primary" 
                  variant="outlined"
                  sx={{ m: 0.5 }}
                />
              </Grid>
            ))}
          </Grid>
        </CardContent>
      </Card>
    );
  };

  return (
    <Paper sx={{ p: 4, maxWidth: 800, mx: 'auto', my: 4 }}>
      <Typography variant="h5" gutterBottom>
        Barcode Sheet Processor
      </Typography>
      
      <Typography variant="body1" color="text.secondary" paragraph>
        Convert a list of barcodes into an inventory sheet. Upload a Google Sheet containing barcodes,
        select which columns and sheet contain your barcodes, and get back a new sheet with recognized items populated with quantities.
      </Typography>
      
      <Alert severity="info" sx={{ my: 2 }}>
        You can specify multiple columns containing barcodes. The system will count occurrences of each barcode across all specified columns.
      </Alert>
      
      <Box sx={{ mt: 3 }}>
        <TextField
          label="Google Sheet URL or ID"
          fullWidth
          value={sourceUrl}
          onChange={(e) => setSourceUrl(e.target.value)}
          placeholder="https://docs.google.com/spreadsheets/d/..."
          InputProps={{
            endAdornment: (
              <Tooltip title="Paste from clipboard">
                <IconButton edge="end" onClick={handlePasteFromClipboard}>
                  <ContentPaste />
                </IconButton>
              </Tooltip>
            ),
          }}
          sx={{ mb: 2 }}
        />
        
        <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
          <TextField
            label="Output Sheet Name (Optional)"
            fullWidth
            value={sheetName}
            onChange={(e) => setSheetName(e.target.value)}
            placeholder="Barcode Import"
          />
          
          <TextField
            label="Sheet Name (Optional)"
            fullWidth
            value={selectedSheet}
            onChange={(e) => setSelectedSheet(e.target.value)}
            placeholder="Sheet1"
            helperText="Leave blank to use first sheet"
          />
        </Box>
        
        <Box sx={{ mb: 3 }}>
          <FormControlLabel
            control={
              <Switch
                checked={skipHeaderRow}
                onChange={(e) => setSkipHeaderRow(e.target.checked)}
                color="primary"
              />
            }
            label="Skip header row (first row)"
          />
          <FormHelperText>
            Enable this if your sheet has column headers in the first row
          </FormHelperText>
        </Box>
        
        <Typography variant="subtitle1" gutterBottom>
          Barcode Columns
        </Typography>
        
        <Box sx={{ display: 'flex', alignItems: 'flex-start', mb: 2 }}>
          <TextField
            label="Add Column"
            value={newColumn}
            onChange={(e) => setNewColumn(e.target.value)}
            placeholder="A"
            size="small"
            sx={{ width: 100, mr: 1 }}
          />
          
          <Button
            variant="outlined"
            onClick={handleAddColumn}
            sx={{ mr: 2 }}
          >
            Add
          </Button>
          
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
            {barcodeColumns.map(column => (
              <Chip
                key={column}
                label={`Column ${column}`}
                onDelete={() => handleRemoveColumn(column)}
                color="primary"
              />
            ))}
          </Box>
        </Box>
        
        <FormHelperText>
          Specify which columns contain barcodes. The default is column A.
        </FormHelperText>
        
        <Button
          variant="contained"
          color="primary"
          fullWidth
          disabled={loading}
          onClick={handleGenerateSheet}
          sx={{ py: 1.5, mt: 2 }}
        >
          {loading ? <CircularProgress size={24} color="inherit" /> : 'Process Barcodes'}
        </Button>
      </Box>
      
      {error && (
        <Alert severity="error" sx={{ mt: 3 }}>
          {error}
        </Alert>
      )}
      
      {result && (
        <>
          {renderStats()}
          
          <Card sx={{ mb: 2, bgcolor: 'success.light' }}>
            <CardContent>
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                <CheckCircleOutline color="success" sx={{ mr: 1 }} />
                <Typography variant="h6">
                  Sheet Generated Successfully
                </Typography>
              </Box>
              
              <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
                <TextField
                  fullWidth
                  value={result.sheetLink}
                  InputProps={{
                    readOnly: true,
                  }}
                  size="small"
                />
                <Tooltip title="Copy to clipboard">
                  <IconButton onClick={() => handleCopyToClipboard(result.sheetLink)}>
                    <ContentCopy />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Open in new tab">
                  <IconButton onClick={() => window.open(result.sheetLink, '_blank')}>
                    <OpenInNew />
                  </IconButton>
                </Tooltip>
              </Box>
            </CardContent>
          </Card>
          
          {result.stats.unmatchedBarcodes > 0 && (
            <Alert severity="warning" sx={{ mt: 2 }}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <WarningAmber sx={{ mr: 1 }} />
                <Typography>
                  {result.stats.unmatchedBarcodes} barcodes could not be matched to items. 
                  These are listed in the "Unmatched Barcodes" sheet of the generated document.
                </Typography>
              </Box>
            </Alert>
          )}
        </>
      )}
    </Paper>
  );
};

export default BarcodeSheetImport;