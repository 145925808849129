import React, { useState, useCallback } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Box,
  Typography,
  LinearProgress,
  InputAdornment
} from '@mui/material';

const BatchEditDialog = ({
  open,
  onClose,
  batchEditValues,
  onBatchFieldChange,
  selectedItems,
  onBatchUpdate,
  updateProgress
}) => {
  // Local state for input values to improve responsiveness
  const [localValues, setLocalValues] = useState({
    quantity: '',
    reorder_level: ''
  });

  // Reset local values when dialog opens
  React.useEffect(() => {
    if (open) {
      setLocalValues({
        quantity: batchEditValues.quantity.value || '',
        reorder_level: batchEditValues.reorder_level.value || ''
      });
    }
  }, [open, batchEditValues]);

  // Debounced update function to avoid excessive re-renders
  const debouncedUpdate = useCallback((field, value) => {
    // Allow empty string or valid numbers
    if (value === '' || (!isNaN(value) && parseInt(value) >= 0)) {
      // Update local state immediately for responsive UI
      setLocalValues(prev => ({
        ...prev,
        [field]: value
      }));
      
      // Update parent state with a slight delay to avoid excessive re-renders
      onBatchFieldChange(field, value);
    }
  }, [onBatchFieldChange]);

  return (
    <Dialog 
      open={open} 
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      PaperProps={{
        sx: {
          borderRadius: 2
        }
      }}
    >
      <DialogTitle>
        Batch Edit Items ({selectedItems.length} selected)
      </DialogTitle>
      <DialogContent>
        <Box sx={{ mt: 2 }}>
          <TextField
            label="Quantity"
            type="number"
            fullWidth
            value={localValues.quantity}
            onChange={(e) => debouncedUpdate('quantity', e.target.value)}
            inputProps={{ min: 0 }}
            sx={{ mb: 2 }}
            helperText="Leave blank to keep existing values"
            InputProps={{
              inputMode: 'numeric',
              endAdornment: (
                <InputAdornment position="end">
                  units
                </InputAdornment>
              )
            }}
            variant="outlined"
          />
          <TextField
            label="Reorder Level"
            type="number"
            fullWidth
            value={localValues.reorder_level}
            onChange={(e) => debouncedUpdate('reorder_level', e.target.value)}
            inputProps={{ min: 0 }}
            helperText="Leave blank to keep existing values"
            InputProps={{
              inputMode: 'numeric',
              endAdornment: (
                <InputAdornment position="end">
                  units
                </InputAdornment>
              )
            }}
            variant="outlined"
          />
        </Box>
        
        {updateProgress.show && (
          <Box sx={{ mt: 3 }}>
            <Typography variant="body2" color="textSecondary" gutterBottom>
              {updateProgress.status}
            </Typography>
            <LinearProgress 
              variant="determinate" 
              value={(updateProgress.current / updateProgress.total) * 100} 
            />
            <Typography variant="caption" color="textSecondary" sx={{ mt: 1 }}>
              {updateProgress.current} of {updateProgress.total} items processed
            </Typography>
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="inherit">
          Cancel
        </Button>
        <Button 
          onClick={onBatchUpdate}
          color="primary"
          variant="contained"
          disabled={updateProgress.show || (
            !batchEditValues.quantity.touched && 
            !batchEditValues.reorder_level.touched
          )}
        >
          Update Items
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default BatchEditDialog;
