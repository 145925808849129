import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Container,
  Typography,
  Grid,
  Card,
  CardContent,
  Button,
  TextField,
  IconButton,
  Box,
  Snackbar,
  Alert,
  Checkbox,
  FormControlLabel,
  Menu,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  LinearProgress,
  Chip,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  Tab,
  CircularProgress,
  Divider,
  InputAdornment,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Chart } from 'primereact/chart';
import {
  getWarehouseById,
  getWarehouseItems,
  updateWarehouse,
  updateWarehouseItem,
  batchUpdateWarehouseItems,
  getProductionEntriesByWarehouse,
  getWarehouseInventoryChecks,
  resetReservedInventory,
} from '../services/api';
import { shipmentPackingApi } from '../services/shipmentPackingApi';
import ViewColumnIcon from '@mui/icons-material/ViewColumn';
import LoadingOverlay from './LoadingOverlay';
import { format } from 'date-fns';
import { Add as AddIcon, CheckCircle as CheckCircleIcon, Send as SendIcon, Assignment as AssignmentIcon } from '@mui/icons-material';
import FullScreenLayout from './layouts/FullScreenLayout';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ShareIcon from '@mui/icons-material/Share';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import TableChartIcon from '@mui/icons-material/TableChart';
import BatchProcessingAnimation from './BatchProcessingAnimation';
import { styled } from '@mui/material/styles';
import InventoryIcon from '@mui/icons-material/Inventory';
import { motion, AnimatePresence } from 'framer-motion';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import OptimizedSpreadsheet from './spreadsheets/OptimizedSpreadsheet';
import ItemsCacheService from '../services/itemsCacheService';
import SearchIcon from '@mui/icons-material/Search';
import reorderLevelApi from '../services/reorderLevelApi';

// Import components from warehouse-details directory
import InventorySection from './warehouse-details/InventorySection';
import InventoryStatsTab from './warehouse-details/InventoryStatsTab';
import WarehouseItemsTab from './warehouse-details/WarehouseItemsTab';
import ReservedInventoryTab from './warehouse-details/ReservedInventoryTab';
import ProductionEntriesTab from './warehouse-details/ProductionEntriesTab';
import InventoryChecksTab from './warehouse-details/InventoryChecksTab';
import ShipmentsTab from './warehouse-details/ShipmentsTab';
import InventoryGridTab from './warehouse-details/InventoryGridTab';
import { 
  PageContainer, 
  ContentPaper, 
  HeaderContainer, 
  HeaderButton,
  getStatusColor,
  WarehouseNameContainer
} from './warehouse-details/StyledComponents';

const WarehouseDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [warehouse, setWarehouse] = useState(null);
  const [items, setItems] = useState([]);
  const [editingItem, setEditingItem] = useState(null);
  const [editedValues, setEditedValues] = useState({});
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: 'contains' },
    item_name: { value: null, matchMode: 'contains' },
    item_type: { value: null, matchMode: 'contains' },
    quantity: { value: null, matchMode: 'equals' },
  });
  const [globalFilterValue, setGlobalFilterValue] = useState('');
  const [loading, setLoading] = useState(true);
  const [selectedItems, setSelectedItems] = useState([]);
  const [batchEditMode, setBatchEditMode] = useState(false);
  const [batchEditValues, setBatchEditValues] = useState({
    quantity: { value: '', touched: false },
    reorder_level: { value: '', touched: false }
  });
  const [visibleColumns, setVisibleColumns] = useState(() => ({
    item_name: true,
    item_type: true,
    size: true,
    shape: true,
    brim: true,
    quantity: true,
    reserved_inventory: true,
    reason_reserved: true,
    reorder_level: true
  }));
  const [columnMenuAnchor, setColumnMenuAnchor] = useState(null);
  const [updateProgress, setUpdateProgress] = useState({ show: false, current: 0, total: 0, status: '' });
  const [productionEntries, setProductionEntries] = useState([]);
  const [activeTab, setActiveTab] = useState(() => {
    return localStorage.getItem('warehouseDetailsActiveTab') || 'inventory-stats';
  });
  const [inventoryChecks, setInventoryChecks] = useState([]);
  const [actionMenuAnchor, setActionMenuAnchor] = useState(null);
  const [selectedCheckId, setSelectedCheckId] = useState(null);
  const [selectedItemType, setSelectedItemType] = useState(null);
  const [inventoryExpanded, setInventoryExpanded] = useState(false);
  const [checksExpanded, setChecksExpanded] = useState(false);
  const [productionExpanded, setProductionExpanded] = useState(false);
  const [shipments, setShipments] = useState([]);
  const [shipmentsLoading, setShipmentsLoading] = useState(true);
  const [shipmentsError, setShipmentsError] = useState(null);
  const [debouncedBatchValues, setDebouncedBatchValues] = useState({
    quantity: { value: '', touched: false },
    reorder_level: { value: '', touched: false }
  });
  const [spreadsheetData, setSpreadsheetData] = useState({});
  const [spreadsheetLoading, setSpreadsheetLoading] = useState(true);
  const [showMethodDialog, setShowMethodDialog] = useState(false);
  const [refreshSpreadsheet, setRefreshSpreadsheet] = useState(0);
  const [confirmDialog, setConfirmDialog] = useState({
    open: false,
    title: '',
    message: '',
    confirmText: '',
    cancelText: '',
    onConfirm: () => {}
  });

  const handleBatchFieldChange = useCallback((field, value) => {
    setBatchEditValues(prev => ({
      ...prev,
      [field]: {
        value: value,
        touched: value !== ''
      }
    }));
  }, []);

  useEffect(() => {
    console.log('Current warehouse ID:', id);
  }, [id]);

  useEffect(() => {
    fetchWarehouseDetails();
    fetchWarehouseItems();
    fetchProductionEntries();
    fetchInventoryChecks();
    
    // Add event listener for resetting reserved inventory
    const handleResetReservedInventory = () => {
      setConfirmDialog({
        open: true,
        title: 'Reset Reserved Inventory',
        message: 'Are you sure you want to reset all reserved inventory? This action cannot be undone.',
        confirmText: 'Reset All',
        cancelText: 'Cancel',
        onConfirm: resetAllReservedInventory
      });
    };

    // Add event listener for changing tabs from other components
    const handleChangeTab = (event) => {
      setActiveTab(event.detail);
    };
    
    window.addEventListener('resetReservedInventory', handleResetReservedInventory);
    window.addEventListener('changeWarehouseTab', handleChangeTab);
    
    return () => {
      window.removeEventListener('resetReservedInventory', handleResetReservedInventory);
      window.removeEventListener('changeWarehouseTab', handleChangeTab);
    };
  }, [id]);

  useEffect(() => {
    const fetchWarehouseShipments = async () => {
      setShipmentsLoading(true);
      try {
        const response = await shipmentPackingApi.getWarehouseShipments(id);
        setShipments(Array.isArray(response) ? response : []);
        setShipmentsError(null);
      } catch (error) {
        console.error('Error fetching warehouse shipments:', error);
        setShipmentsError('Failed to load shipments');
        setShipments([]);
      } finally {
        setShipmentsLoading(false);
      }
    };

    if (id) {
      fetchWarehouseShipments();
    }
  }, [id]);

  useEffect(() => {
    const prepareSpreadsheetData = async () => {
      setSpreadsheetLoading(true);
      try {
        // Get all items from cache service
        const allItems = await ItemsCacheService.getItems();
        
        if (!allItems || allItems.length === 0) {
          console.warn('No items found in cache');
          setSpreadsheetData({});
          return;
        }
        
        // Get item configuration
        const itemConfig = ItemsCacheService.getCachedConfig();
        
        if (!itemConfig) {
          console.warn('No item configuration found in cache');
          setSpreadsheetData({});
          return;
        }
        
        // Filter items for this warehouse and format data for spreadsheet
        const warehouseItems = items.reduce((acc, item) => {
          // Find the corresponding item in allItems
          const baseItem = allItems.find(i => i.id === item.item_id);
          if (baseItem) {
            // Create data object with quantity and reorder_level
            acc[item.item_id] = {
              quantity: item.quantity || 0,
              reorder_level: item.reorder_level || 0
            };
          }
          return acc;
        }, {});

        // Make sure we have at least one item
        if (Object.keys(warehouseItems).length === 0) {
          console.warn('No matching warehouse items found');
          setSpreadsheetData({});
          return;
        }

        setSpreadsheetData(warehouseItems);
      } catch (error) {
        console.error('Error preparing spreadsheet data:', error);
        setSnackbar({
          open: true,
          message: 'Failed to load inventory spreadsheet',
          severity: 'error'
        });
        setSpreadsheetData({});
      } finally {
        setSpreadsheetLoading(false);
      }
    };

    if (items.length > 0) {
      prepareSpreadsheetData();
    } else {
      setSpreadsheetData({});
      setSpreadsheetLoading(false);
    }
  }, [items, refreshSpreadsheet]);

  const fetchWarehouseDetails = async () => {
    try {
      setLoading(true);
      const data = await getWarehouseById(id);
      console.log('Fetched warehouse data:', data);
      setWarehouse(data);
    } catch (error) {
      console.error('Error fetching warehouse details:', error);
      setSnackbar({
        open: true,
        message: 'Failed to fetch warehouse details',
        severity: 'error',
      });
    } finally {
      setLoading(false);
    }
  };

  const fetchWarehouseItems = async () => {
    try {
      const data = await getWarehouseItems(id);
      setItems(data);
      setLoading(false);
    } catch (error) {
      setSnackbar({
        open: true,
        message: 'Failed to fetch warehouse items',
        severity: 'error',
      });
      setLoading(false);
    }
  };

  const fetchProductionEntries = async () => {
    try {
      const data = await getProductionEntriesByWarehouse(id);
      setProductionEntries(data);
    } catch (error) {
      setSnackbar({
        open: true,
        message: 'Failed to fetch production entries',
        severity: 'error',
      });
    }
  };

  const fetchInventoryChecks = async () => {
    try {
      const data = await getWarehouseInventoryChecks(id);
      setInventoryChecks(data);
    } catch (error) {
      setSnackbar({
        open: true,
        message: 'Failed to fetch inventory checks',
        severity: 'error',
      });
    }
  };

  const handleEditItem = (item) => {
    setEditingItem(item.warehouse_item_id);
    setEditedValues({
      quantity: item.quantity,
      reorder_level: item.reorder_level,
    });
  };

  const handleSaveItem = async () => {
    try {
      await updateWarehouseItem(id, editingItem, editedValues);
      setEditingItem(null);
      fetchWarehouseItems();
      setSnackbar({
        open: true,
        message: 'Item updated successfully',
        severity: 'success',
      });
    } catch (error) {
      setSnackbar({
        open: true,
        message: 'Failed to update item',
        severity: 'error',
      });
    }
  };
  
  const resetAllReservedInventory = async () => {
    try {
      setLoading(true);
      
      // Optimistically update the UI first
      setConfirmDialog({ ...confirmDialog, open: false });
      
      // Create an optimistic copy of items with reserved inventory cleared
      const updatedItems = items.map(item => ({
        ...item,
        reserved_inventory: 0,
        reason_reserved: null
      }));
      
      // Update UI immediately
      setItems(updatedItems);
      
      // Show optimistic success message
      setSnackbar({
        open: true,
        message: 'Resetting reserved inventory...',
        severity: 'info',
      });
      
      // Make the API call in the background
      resetReservedInventory(id)
        .then(response => {
          // Refresh warehouse items to ensure data consistency
          fetchWarehouseItems();
          
          setSnackbar({
            open: true,
            message: `Successfully reset reserved inventory for ${response.affectedItems} items`,
            severity: 'success',
          });
        })
        .catch(error => {
          console.error('Error resetting reserved inventory:', error);
          // Revert optimistic update by re-fetching data
          fetchWarehouseItems();
          
          setSnackbar({
            open: true,
            message: 'Failed to reset reserved inventory',
            severity: 'error',
          });
        });
    } finally {
      setLoading(false);
    }
  };

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };
    _filters['global'].value = value;
    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const handleBatchUpdate = async () => {
    const BATCH_SIZE = 100;
    const totalItems = selectedItems.length;
    
    // Only include fields that were actually modified
    const updateFields = Object.entries(batchEditValues)
      .filter(([_, field]) => field.touched && field.value !== '')
      .reduce((acc, [key, field]) => ({
        ...acc,
        [key]: parseInt(field.value)
      }), {});
    
    // Validate that we have items selected and at least one field to update
    if (selectedItems.length === 0) {
      setSnackbar({
        open: true,
        message: 'Please select items to update',
        severity: 'warning'
      });
      return;
    }
    
    if (Object.keys(updateFields).length === 0) {
      setSnackbar({
        open: true,
        message: 'Please specify at least one field to update',
        severity: 'warning'
      });
      return;
    }
    
    // Show progress UI
    setUpdateProgress({
      show: true,
      current: 0,
      total: totalItems,
      status: 'Starting batch update...'
    });
    
    try {
      // Process in batches to avoid overwhelming the server
      for (let i = 0; i < totalItems; i += BATCH_SIZE) {
        const batchItems = selectedItems.slice(i, i + BATCH_SIZE);
        
        // Format items for the API
        const formattedItems = batchItems.map(item => ({
          warehouse_item_id: item.warehouse_item_id,
          ...updateFields
        }));
        
        setUpdateProgress(prev => ({
          ...prev,
          current: i,
          total: totalItems,
          status: `Processing items ${i + 1} to ${Math.min(i + BATCH_SIZE, totalItems)} of ${totalItems}...`
        }));
        
        await batchUpdateWarehouseItems(id, formattedItems);
        
        // Update progress
        setUpdateProgress(prev => ({
          ...prev,
          current: Math.min(i + BATCH_SIZE, totalItems)
        }));
      }
      
      // Reset state and refresh data
      setBatchEditMode(false);
      setBatchEditValues({
        quantity: { value: '', touched: false },
        reorder_level: { value: '', touched: false }
      });
      setSelectedItems([]);
      fetchWarehouseItems();
      
      setSnackbar({
        open: true,
        message: `Successfully updated ${totalItems} items`,
        severity: 'success'
      });
    } catch (error) {
      console.error('Batch update error:', error);
      setSnackbar({
        open: true,
        message: error.message || 'Error updating items',
        severity: 'error'
      });
    } finally {
      // Hide progress UI
      setUpdateProgress({ show: false, current: 0, total: 0, status: '' });
    }
  };

  const handleColumnVisibilityChange = (columnName) => {
    setVisibleColumns(prev => ({
      ...prev,
      [columnName]: !prev[columnName]
    }));
  };

  const getCapacityChartData = () => {
    // Keep this function for backward compatibility
    // It's no longer used in the updated InventoryStatsTab component
    const totalItems = items.reduce((sum, item) => sum + item.quantity, 0);
    const capacity = warehouse?.capacity || 0;
    
    let usedPercentage = 0;
    let availablePercentage = 100;
    
    if (capacity > 0) {
      usedPercentage = Math.min(Math.round((totalItems / capacity) * 100), 100);
      availablePercentage = 100 - usedPercentage;
    }
    
    return {
      labels: ['Used', 'Available'],
      datasets: [
        {
          data: [usedPercentage, availablePercentage],
          backgroundColor: [
            'rgba(255, 99, 132, 0.7)',
            'rgba(54, 162, 235, 0.7)',
          ],
          borderColor: [
            'rgb(255, 99, 132)',
            'rgb(54, 162, 235)',
          ],
          borderWidth: 1,
        }
      ]
    };
  };

  const handleCreateProduction = () => {
    console.log('handleCreateProduction called');
    setShowMethodDialog(true);
    console.log('showMethodDialog set to true');
  };

  const handleMethodSelect = (method) => {
    setShowMethodDialog(false);
    if (method === 'sheet') {
      navigate(`/warehouses/${id}/production/create?method=sheet`);
    } else {
      navigate(`/warehouses/${id}/production/create`);
    }
  };

  const handleProductionClick = (productionId) => {
    navigate(`/warehouses/${id}/production/${productionId}`);
  };

  const handleInventoryCheckClick = (checkId, e) => {
    navigate(`/warehouses/${id}/inventory-checks/${checkId}`);
  };

  const handleActionClick = (event, id, type) => {
    setSelectedCheckId(id);
    setSelectedItemType(type);
    setActionMenuAnchor(event.currentTarget);
  };

  const handleActionClose = () => {
    setActionMenuAnchor(null);
    setSelectedCheckId(null);
  };

  const handleShareClick = () => {
    setActionMenuAnchor(null);
    const itemType = selectedItemType === 'production' ? 'production entry' : 'inventory check';
    setSnackbar({
      open: true,
      message: `🚀 Sharing ${itemType} feature coming soon! Stay tuned for collaborative inventory management.`,
      severity: 'info'
    });
  };

  const handleExportGoogleSheets = () => {
    setActionMenuAnchor(null);
    const itemType = selectedItemType === 'production' ? 'production entry' : 'inventory check';
    setSnackbar({
      open: true,
      message: `📊 Export ${itemType} to Google Sheets feature is on its way! Get ready for seamless spreadsheet integration.`,
      severity: 'info'
    });
  };

  const handleExportExcel = () => {
    setActionMenuAnchor(null);
    const itemType = selectedItemType === 'production' ? 'production entry' : 'inventory check';
    setSnackbar({
      open: true,
      message: `📑 Export ${itemType} to Excel capability coming soon! Your data will be just a click away.`,
      severity: 'info'
    });
  };

  const getStats = () => {
    if (!items || items.length === 0) {
      return {
        totalItems: 0,
        uniqueItems: 0,
        itemTypes: [],
        belowReorderLevel: 0,
        zeroQuantity: 0,
        highStock: 0,
        totalReserved: 0,
        reservedPercentage: 0,
        itemsWithReserved: 0,
        lastInventoryCheck: null,
        reservationReasons: []
      };
    }
    
    // Calculate total items
    const totalItems = items.reduce((sum, item) => sum + item.quantity, 0);
    
    // Calculate total reserved inventory
    const totalReserved = items.reduce((sum, item) => sum + (item.reserved_inventory || 0), 0);
    
    // Calculate reserved percentage
    const reservedPercentage = totalItems > 0 ? Math.round((totalReserved / totalItems) * 100) : 0;
    
    // Count items with reserved inventory
    const itemsWithReserved = items.filter(item => item.reserved_inventory > 0).length;
    
    // Group reserved items by shipment numbers
    const reservationReasonMap = {};
    items.forEach(item => {
      if (item.reserved_inventory > 0 && item.reason_reserved) {
        // Extract shipment numbers using regex - includes SHIP- format
        const shipmentMatches = item.reason_reserved.match(/Shipment #[A-Z0-9-]+/g) || [];
        
        if (shipmentMatches.length > 0) {
          // Process each matched shipment number
          shipmentMatches.forEach(shipment => {
            if (!reservationReasonMap[shipment]) {
              reservationReasonMap[shipment] = {
                reason: shipment,
                items: 0,
                quantity: 0
              };
            }
            reservationReasonMap[shipment].items += 1;
            reservationReasonMap[shipment].quantity += item.reserved_inventory;
          });
        } else {
          // If no shipment numbers found but there is a reason, categorize by that exact reason
          const reasonParts = item.reason_reserved.split(', ');
          
          reasonParts.forEach(reasonPart => {
            const reason = reasonPart.trim();
            if (reason !== '') {
              if (!reservationReasonMap[reason]) {
                reservationReasonMap[reason] = {
                  reason: reason,
                  items: 0,
                  quantity: 0
                };
              }
              reservationReasonMap[reason].items += 1;
              reservationReasonMap[reason].quantity += item.reserved_inventory / reasonParts.length; // Distribute quantity proportionally
            }
          });
        }
      }
    });
    
    const reservationReasons = Object.values(reservationReasonMap)
      .sort((a, b) => b.quantity - a.quantity);
    
    // Count unique items
    const uniqueItems = items.length;
    
    // Group by item type
    const typeMap = items.reduce((acc, item) => {
      const type = item.item_type || 'Unknown';
      if (!acc[type]) {
        acc[type] = 0;
      }
      acc[type]++;
      return acc;
    }, {});
    
    const itemTypes = Object.entries(typeMap).map(([name, count]) => ({
      name,
      count
    })).sort((a, b) => b.count - a.count);
    
    // Count items below reorder level
    const belowReorderLevel = items.filter(item => 
      item.reorder_level && item.quantity < item.reorder_level
    ).length;
    
    // Count items with zero quantity
    const zeroQuantity = items.filter(item => item.quantity === 0).length;
    
    // Count items with high stock (higher than reorder level)
    const highStock = items.filter(item => 
      item.reorder_level && item.quantity > item.reorder_level
    ).length;
    
    // Get date of last inventory check
    let lastInventoryCheck = null;
    if (inventoryChecks && inventoryChecks.length > 0) {
      const sortedChecks = [...inventoryChecks]
        .filter(check => check.status === 'confirmed')
        .sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
      
      if (sortedChecks.length > 0) {
        lastInventoryCheck = new Date(sortedChecks[0].created_at).toLocaleDateString();
      }
    }
    
    return {
      totalItems,
      uniqueItems,
      itemTypes,
      belowReorderLevel,
      zeroQuantity,
      highStock,
      totalReserved,
      reservedPercentage,
      itemsWithReserved,
      lastInventoryCheck,
      reservationReasons
    };
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
    localStorage.setItem('warehouseDetailsActiveTab', newValue);
    
    // Scroll down to the content area when inventory grid tab is selected
    if (newValue === 'inventory-grid') {
      setTimeout(() => {
        const contentElement = document.querySelector('.MuiTabs-root');
        if (contentElement) {
          contentElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
      }, 100);
    }
  };

  useEffect(() => {
    console.log('showMethodDialog changed:', showMethodDialog);
  }, [showMethodDialog]);

  const handleRefreshSpreadsheet = () => {
    setRefreshSpreadsheet(prev => prev + 1);
    fetchWarehouseItems();
  };

  // Function was moved to the top of the file and now uses the API service

  if (loading) {
    return <LoadingOverlay />;
  }

  return (
    <>
      <FullScreenLayout
        headerPanel={
          <HeaderContainer>
            <Box sx={{ 
              display: 'flex', 
              flexDirection: { xs: 'column', sm: 'row' },
              justifyContent: 'space-between', 
              alignItems: { xs: 'flex-start', sm: 'center' },
              width: '100%',
              gap: { xs: 2, sm: 4 }
            }}>
              <Box sx={{ flex: 1 }}>
                <WarehouseNameContainer>
                  <Typography 
                    variant="h4" 
                    component="h1"
                  >
                    {warehouse?.name || 'Loading...'}
                  </Typography>
                  <Typography 
                    className="location"
                    variant="subtitle1"
                  >
                    {warehouse?.location || 'Loading location...'}
                  </Typography>
                </WarehouseNameContainer>
              </Box>
              {warehouse && (
                <Box sx={{ 
                  display: 'flex', 
                  gap: 2,
                  alignSelf: { xs: 'stretch', sm: 'center' }
                }}>
                  <HeaderButton
                    variant="outlined"
                    startIcon={<EditIcon />}
                    onClick={() => navigate(`/warehouses/${id}/edit`)}
                  >
                    Edit Warehouse
                  </HeaderButton>
                </Box>
              )}
            </Box>
          </HeaderContainer>
        }
        mainPanel={
          <PageContainer>
            <Box sx={{ 
              mt: 3, 
              mb: 2, 
              display: 'flex', 
              gap: { xs: 1, sm: 2 }, 
              justifyContent: { xs: 'center', sm: 'flex-end' },
              flexWrap: 'wrap'
            }}>
              <HeaderButton
                variant="contained"
                color="primary"
                startIcon={<AddIcon />}
                onClick={handleCreateProduction}
                sx={{ 
                  fontSize: { xs: '0.8rem', sm: 'inherit' },
                  py: { xs: 1 },
                  px: { xs: 2 }
                }}
              >
                Create Production Entry
              </HeaderButton>
              <HeaderButton
                variant="contained"
                color="primary"
                startIcon={<AssignmentIcon />}
                onClick={() => navigate(`/warehouses/${id}/inventory-checks/create`)}
                sx={{ 
                  fontSize: { xs: '0.8rem', sm: 'inherit' },
                  py: { xs: 1 },
                  px: { xs: 2 }
                }}
              >
                Create Inventory Check
              </HeaderButton>
            </Box>

            <ContentPaper>
              <Box sx={{ width: '100%' }}>
                <Tabs 
                  value={activeTab} 
                  onChange={handleTabChange}
                  variant="scrollable"
                  scrollButtons="auto"
                  allowScrollButtonsMobile
                  aria-label="warehouse management tabs"
                  sx={{ 
                    borderBottom: 1, 
                    borderColor: 'divider',
                    '& .MuiTab-root': {
                      textTransform: 'none',
                      fontWeight: 500,
                      fontSize: '1rem',
                      minWidth: 100,
                      '&.Mui-selected': {
                        fontWeight: 600
                      }
                    },
                    maxWidth: '100%',
                    '& .MuiTabs-scroller': {
                      overflowX: 'auto',
                      '&::-webkit-scrollbar': {
                        display: 'none'
                      },
                      scrollbarWidth: 'none',
                      msOverflowStyle: 'none'
                    }
                  }}
                >
                  <Tab label="Inventory Stats" value="inventory-stats" />
                  <Tab label="Warehouse Items" value="items" />
                  <Tab label="Reserved Inventory" value="reserved-inventory" />
                  <Tab label="Production Entries" value="production" />
                  <Tab label="Inventory Checks" value="inventory-checks" />
                  <Tab label="Shipments" value="shipments" />
                  <Tab label="Inventory Grid" value="inventory-grid" />
                </Tabs>

                <Box sx={{ mt: 3 }}>
                  {/* Warehouse Items Tab */}
                  {activeTab === 'items' && (
                    <WarehouseItemsTab 
                      items={items}
                      loading={loading}
                      filters={filters}
                      globalFilterValue={globalFilterValue}
                      onGlobalFilterChange={onGlobalFilterChange}
                      selectedItems={selectedItems}
                      setSelectedItems={setSelectedItems}
                      visibleColumns={visibleColumns}
                      handleColumnVisibilityChange={handleColumnVisibilityChange}
                      editingItem={editingItem}
                      editedValues={editedValues}
                      setEditedValues={setEditedValues}
                      handleEditItem={handleEditItem}
                      handleSaveItem={handleSaveItem}
                      setEditingItem={setEditingItem}
                      batchEditMode={batchEditMode}
                      setBatchEditMode={setBatchEditMode}
                      batchEditValues={batchEditValues}
                      handleBatchFieldChange={handleBatchFieldChange}
                      handleBatchUpdate={handleBatchUpdate}
                      updateProgress={updateProgress}
                      warehouse={warehouse}
                    />
                  )}
                  
                  {/* Reserved Inventory Tab */}
                  {activeTab === 'reserved-inventory' && (
                    <ReservedInventoryTab
                      items={items}
                      loading={loading}
                      resetReservedInventory={() => window.dispatchEvent(new CustomEvent('resetReservedInventory'))}
                      getStats={getStats}
                    />
                  )}

                  {/* Production Entries Tab */}
                  {activeTab === 'production' && (
                    <ProductionEntriesTab 
                      productionEntries={productionEntries}
                      handleProductionClick={handleProductionClick}
                      handleActionClick={handleActionClick}
                      actionMenuAnchor={actionMenuAnchor}
                      handleActionClose={handleActionClose}
                      handleShareClick={handleShareClick}
                      handleExportGoogleSheets={handleExportGoogleSheets}
                      handleExportExcel={handleExportExcel}
                      selectedCheckId={selectedCheckId}
                      warehouse={warehouse}
                    />
                  )}

                  {/* Inventory Checks Tab */}
                  {activeTab === 'inventory-checks' && (
                    <InventoryChecksTab 
                      inventoryChecks={inventoryChecks}
                      handleInventoryCheckClick={handleInventoryCheckClick}
                      handleActionClick={handleActionClick}
                      actionMenuAnchor={actionMenuAnchor}
                      handleActionClose={handleActionClose}
                      handleShareClick={handleShareClick}
                      handleExportGoogleSheets={handleExportGoogleSheets}
                      handleExportExcel={handleExportExcel}
                      navigate={navigate}
                      warehouseId={id}
                      warehouse={warehouse}
                    />
                  )}

                  {/* Shipments Tab */}
                  {activeTab === 'shipments' && (
                    <ShipmentsTab 
                      shipments={shipments}
                      shipmentsLoading={shipmentsLoading}
                      shipmentsError={shipmentsError}
                      navigate={navigate}
                    />
                  )}

                  {/* Inventory Grid Tab */}
                  {activeTab === 'inventory-grid' && (
                    <InventoryGridTab 
                      spreadsheetData={spreadsheetData}
                      spreadsheetLoading={spreadsheetLoading}
                      onRefreshData={handleRefreshSpreadsheet}
                      warehouseId={id}
                    />
                  )}

                  {/* Inventory Stats Tab */}
                  {activeTab === 'inventory-stats' && (
                    <InventoryStatsTab 
                      warehouse={warehouse}
                      items={items}
                      inventoryExpanded={inventoryExpanded}
                      setInventoryExpanded={setInventoryExpanded}
                      getCapacityChartData={getCapacityChartData}
                      getStats={getStats}
                      productionEntries={productionEntries}
                      inventoryChecks={inventoryChecks}
                    />
                  )}
                </Box>
              </Box>
            </ContentPaper>
          </PageContainer>
        }
      />

      <Dialog
        open={showMethodDialog}
        onClose={() => setShowMethodDialog(false)}
        maxWidth="md"
        PaperProps={{
          sx: {
            borderRadius: 2,
            p: 2
          }
        }}
      >
        <DialogTitle sx={{ textAlign: 'center', pb: 3 }}>
          <Typography variant="h5" component="div" fontWeight="bold">
            Choose Production Entry Method
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={3} sx={{ width: '100%', minWidth: 600 }}>
            <Grid item xs={6}>
              <Card 
                sx={{ 
                  height: '100%',
                  transition: 'transform 0.2s',
                  '&:hover': {
                    transform: 'scale(1.02)',
                    cursor: 'pointer'
                  }
                }}
                onClick={() => handleMethodSelect('manual')}
              >
                <CardContent sx={{ textAlign: 'center', p: 4 }}>
                  <SearchIcon sx={{ fontSize: 48, color: 'primary.main', mb: 2 }} />
                  <Typography variant="h6" gutterBottom>
                    Manual Entry
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Search and add items one by one with our intuitive interface
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={6}>
              <Card 
                sx={{ 
                  height: '100%',
                  transition: 'transform 0.2s',
                  '&:hover': {
                    transform: 'scale(1.02)',
                    cursor: 'pointer'
                  }
                }}
                onClick={() => handleMethodSelect('sheet')}
              >
                <CardContent sx={{ textAlign: 'center', p: 4 }}>
                  <TableChartIcon sx={{ fontSize: 48, color: 'secondary.main', mb: 2 }} />
                  <Typography variant="h6" gutterBottom>
                    Import from Sheet
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Import production data directly from a spreadsheet
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>

      <Menu
        anchorEl={actionMenuAnchor}
        open={Boolean(actionMenuAnchor)}
        onClose={handleActionClose}
      >
        {/* ... menu items ... */}
      </Menu>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity={snackbar.severity}>
          {snackbar.message}
        </Alert>
      </Snackbar>
      
      {/* Confirmation Dialog */}
      <Dialog
        open={confirmDialog.open}
        onClose={() => setConfirmDialog({ ...confirmDialog, open: false })}
      >
        <DialogTitle>{confirmDialog.title}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {confirmDialog.message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setConfirmDialog({ ...confirmDialog, open: false })} color="primary">
            {confirmDialog.cancelText || 'Cancel'}
          </Button>
          <Button onClick={confirmDialog.onConfirm} color="error" variant="contained" autoFocus>
            {confirmDialog.confirmText || 'Confirm'}
          </Button>
        </DialogActions>
      </Dialog>
      <BatchProcessingAnimation 
        open={updateProgress.show}
        current={updateProgress.current}
        total={updateProgress.total}
        status={updateProgress.status}
      />
    </>
  );
};

export default WarehouseDetails; 