import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Paper,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Divider,
  Button,
  IconButton,
  useTheme,
  useMediaQuery,
  Drawer,
  Card,
  CardContent,
  CardMedia,
  CardActionArea,
} from '@mui/material';
import {
  PlayCircleOutline,
  Share as ShareIcon,
  Menu as MenuIcon,
  ChevronLeft,
  ArrowForward,
} from '@mui/icons-material';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import FullScreenLayout from './layouts/FullScreenLayout';

// Video sections data
const videoSections = [
  {
    id: 'inventory-distribution',
    title: 'Inventory Distribution',
    thumbnail: 'https://img.youtube.com/vi/sOeyitWNN68/maxresdefault.jpg',
    description: 'Learn how to efficiently distribute inventory across your locations using our powerful distribution system.',
    videos: [
      {
        id: '1-1',
        title: 'How to Distribute Inventory Effectively',
        url: 'https://www.youtube.com/embed/sOeyitWNN68',
        description: 'Learn how to efficiently distribute inventory across your locations using our powerful distribution system. This comprehensive guide will walk you through the process step by step.',
      },
    ],
  },
];

const MainDocsPage = ({ isMobile }) => {
  const navigate = useNavigate();
  
  return (
    <Box 
      sx={{ 
        p: { xs: 2, md: 3 },
        display: 'grid',
        gridTemplateColumns: {
          xs: '1fr',
          sm: 'repeat(2, 1fr)',
          md: 'repeat(3, 1fr)',
        },
        gap: 3,
      }}
    >
      {videoSections.map((section) => (
        <Card 
          key={section.id}
          sx={{ 
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            borderRadius: 2,
            boxShadow: 2,
            '&:hover': {
              boxShadow: 4,
              transform: 'translateY(-2px)',
            },
            transition: 'all 0.2s ease-in-out',
          }}
        >
          <CardActionArea 
            onClick={() => navigate(`/docs/${section.id}`)}
            sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}
          >
            <CardMedia
              component="img"
              height={160}
              image={section.thumbnail}
              alt={section.title}
              sx={{ objectFit: 'cover' }}
            />
            <CardContent sx={{ flex: 1 }}>
              <Typography 
                gutterBottom 
                variant={isMobile ? "h6" : "h5"} 
                component="h2"
                sx={{ 
                  fontWeight: 600,
                  color: '#1a73e8',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                {section.title}
                <ArrowForward sx={{ fontSize: isMobile ? 20 : 24 }} />
              </Typography>
              <Typography 
                variant={isMobile ? "body2" : "body1"} 
                color="text.secondary"
              >
                {section.description}
              </Typography>
              <Typography 
                variant="caption" 
                sx={{ 
                  display: 'block',
                  mt: 2,
                  color: 'primary.main',
                }}
              >
                {section.videos.length} video{section.videos.length !== 1 ? 's' : ''}
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
      ))}
    </Box>
  );
};

const DocSection = ({ section, isMobile }) => {
  const [selectedVideo, setSelectedVideo] = useState(section.videos[0]);
  const [mobileOpen, setMobileOpen] = useState(false);
  const navigate = useNavigate();
  const theme = useTheme();
  const location = useLocation();

  useEffect(() => {
    // When the section changes, reset the selected video to the first one
    setSelectedVideo(section.videos[0]);
  }, [section]);

  const handleVideoSelect = (video) => {
    setSelectedVideo(video);
    if (isMobile) {
      setMobileOpen(false);
    }
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleBackClick = () => {
    navigate('/docs');
  };

  const mainContent = (
    <Box 
      sx={{ 
        height: '100%', 
        overflow: 'auto', 
        p: { xs: 1, md: 3 },
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Paper 
        sx={{ 
          p: { xs: 1.5, md: 3 }, 
          borderRadius: 2, 
          mb: 2,
          background: 'rgba(255, 255, 255, 0.9)',
          backdropFilter: 'blur(10px)',
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
          {isMobile && (
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 1 }}
            >
              <MenuIcon />
            </IconButton>
          )}
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <IconButton
              onClick={handleBackClick}
              sx={{ 
                color: 'primary.main',
                '&:hover': {
                  backgroundColor: 'rgba(25, 118, 210, 0.04)',
                },
              }}
            >
              <ChevronLeft />
            </IconButton>
            <Typography 
              variant={isMobile ? "h6" : "h4"} 
              component="h1"
              sx={{ 
                fontWeight: 600,
                color: '#1a73e8',
              }}
            >
              {section.title}
            </Typography>
          </Box>
        </Box>
        {selectedVideo.description && (
          <Typography 
            variant={isMobile ? "body2" : "body1"} 
            color="text.secondary" 
            paragraph
            sx={{ mb: 1 }}
          >
            {selectedVideo.description}
          </Typography>
        )}
        <Button
          variant="contained"
          startIcon={<ShareIcon />}
          size={isMobile ? "small" : "medium"}
          onClick={() => navigate('/inventory-distribution')}
          sx={{
            textTransform: 'none',
            boxShadow: 2,
          }}
        >
          Go to Inventory Distribution
        </Button>
      </Paper>

      <Paper 
        sx={{ 
          borderRadius: 2, 
          overflow: 'hidden', 
          mb: 2,
          flex: 1,
          minHeight: isMobile ? '300px' : '400px',
        }}
      >
        <Box sx={{ position: 'relative', height: '100%', minHeight: isMobile ? '300px' : '400px' }}>
          <iframe
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              border: 0,
            }}
            src={selectedVideo.url}
            title={selectedVideo.title}
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </Box>
      </Paper>

      {isMobile && (
        <Paper 
          sx={{ 
            borderRadius: 2,
            background: 'rgba(255, 255, 255, 0.9)',
            backdropFilter: 'blur(10px)',
          }}
        >
          <List dense>
            {section.videos.map((video, index) => (
              <React.Fragment key={video.id}>
                {index > 0 && <Divider />}
                <ListItem
                  button
                  selected={selectedVideo.id === video.id}
                  onClick={() => handleVideoSelect(video)}
                  sx={{
                    py: 1,
                    '&.Mui-selected': {
                      backgroundColor: 'primary.light',
                      '&:hover': {
                        backgroundColor: 'primary.light',
                      },
                    },
                  }}
                >
                  <ListItemIcon sx={{ minWidth: 36 }}>
                    <PlayCircleOutline fontSize="small" />
                  </ListItemIcon>
                  <ListItemText
                    primary={video.title}
                    primaryTypographyProps={{
                      variant: 'body2',
                      fontWeight: selectedVideo.id === video.id ? 600 : 400,
                      fontSize: '0.875rem',
                    }}
                  />
                </ListItem>
              </React.Fragment>
            ))}
          </List>
        </Paper>
      )}
    </Box>
  );

  return (
    <Box sx={{ display: 'flex', height: '100%' }}>
      {!isMobile && (
        <Box sx={{ width: 280, p: 2 }}>
          <Paper
            sx={{
              width: '100%',
              height: '100%',
              borderRadius: 2,
              overflow: 'hidden',
              background: 'rgba(255, 255, 255, 0.9)',
              backdropFilter: 'blur(10px)',
            }}
          >
            <List>
              {section.videos.map((video, index) => (
                <React.Fragment key={video.id}>
                  {index > 0 && <Divider />}
                  <ListItem
                    button
                    selected={selectedVideo.id === video.id}
                    onClick={() => handleVideoSelect(video)}
                    sx={{
                      '&.Mui-selected': {
                        backgroundColor: 'primary.light',
                        '&:hover': {
                          backgroundColor: 'primary.light',
                        },
                      },
                    }}
                  >
                    <ListItemIcon>
                      <PlayCircleOutline />
                    </ListItemIcon>
                    <ListItemText
                      primary={video.title}
                      primaryTypographyProps={{
                        fontWeight: selectedVideo.id === video.id ? 600 : 400,
                      }}
                    />
                  </ListItem>
                </React.Fragment>
              ))}
            </List>
          </Paper>
        </Box>
      )}
      <Box sx={{ flex: 1 }}>
        {mainContent}
      </Box>
      {isMobile && (
        <Drawer
          variant="temporary"
          anchor="left"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            '& .MuiDrawer-paper': {
              width: '85%',
              maxWidth: 280,
              mt: `${theme.mixins.toolbar.minHeight}px`,
              background: 'rgba(255, 255, 255, 0.95)',
              backdropFilter: 'blur(10px)',
            },
          }}
        >
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', p: 1 }}>
            <IconButton onClick={handleDrawerToggle}>
              <ChevronLeft />
            </IconButton>
          </Box>
          <List dense>
            {section.videos.map((video, index) => (
              <React.Fragment key={video.id}>
                {index > 0 && <Divider />}
                <ListItem
                  button
                  selected={selectedVideo.id === video.id}
                  onClick={() => handleVideoSelect(video)}
                  sx={{
                    '&.Mui-selected': {
                      backgroundColor: 'primary.light',
                      '&:hover': {
                        backgroundColor: 'primary.light',
                      },
                    },
                  }}
                >
                  <ListItemIcon sx={{ minWidth: 36 }}>
                    <PlayCircleOutline fontSize="small" />
                  </ListItemIcon>
                  <ListItemText
                    primary={video.title}
                    primaryTypographyProps={{
                      variant: 'body2',
                      fontWeight: selectedVideo.id === video.id ? 600 : 400,
                      fontSize: '0.875rem',
                    }}
                  />
                </ListItem>
              </React.Fragment>
            ))}
          </List>
        </Drawer>
      )}
    </Box>
  );
};

const Docs = () => {
  const { sectionId } = useParams();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const section = videoSections.find(s => s.id === sectionId);

  return (
    <FullScreenLayout
      mainPanel={
        section ? (
          <DocSection section={section} isMobile={isMobile} />
        ) : (
          <MainDocsPage isMobile={isMobile} />
        )
      }
    />
  );
};

export default Docs; 