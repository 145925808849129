import React, { useState } from 'react';
import {
  Box,
  Typography,
  Button,
  Paper,
  Chip,
  IconButton,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import SearchIcon from '@mui/icons-material/Search';
import FilterListIcon from '@mui/icons-material/FilterList';
import InfoIcon from '@mui/icons-material/Info';
import { StyledDataTable, InventoryCard, InventoryCategory } from './StyledComponents';

const ReservedInventoryTab = ({ 
  items,
  loading,
  resetReservedInventory,
  getStats,
}) => {
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: 'contains' },
    item_name: { value: null, matchMode: 'contains' },
    item_type: { value: null, matchMode: 'contains' },
    reason_reserved: { value: null, matchMode: 'contains' },
  });
  const [globalFilterValue, setGlobalFilterValue] = useState('');
  const [showInfo, setShowInfo] = useState(false);

  // Filter items to only show those with reserved inventory
  const reservedItems = items.filter(item => item.reserved_inventory > 0)
    .sort((a, b) => b.reserved_inventory - a.reserved_inventory);

  const stats = getStats();

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };
    _filters['global'].value = value;
    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const renderHeader = () => {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <span className="p-input-icon-left">
            <SearchIcon />
            <InputText
              value={globalFilterValue}
              onChange={onGlobalFilterChange}
              placeholder="Search reserved items..."
              style={{ width: '300px' }}
            />
          </span>
        </Box>
        <Box sx={{ display: 'flex', gap: 1 }}>
          <Button
            variant="outlined"
            color="error"
            onClick={resetReservedInventory}
            disabled={reservedItems.length === 0}
          >
            Reset All Reserved Inventory
          </Button>
          <IconButton 
            color="primary"
            onClick={() => setShowInfo(true)}
          >
            <InfoIcon />
          </IconButton>
        </Box>
      </Box>
    );
  };

  const formatReservationReasons = (rowData) => {
    if (!rowData.reason_reserved) return 'Not specified';
    
    return (
      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
        {rowData.reason_reserved.split(', ').map((reason, idx) => (
          <Chip 
            key={idx}
            label={reason}
            size="small" 
            color="info"
            sx={{ 
              m: 0.3,
              height: { xs: 20, sm: 24 }, 
              '& .MuiChip-label': { 
                fontSize: { xs: '0.65rem', sm: '0.75rem' } 
              } 
            }}
          />
        ))}
      </Box>
    );
  };

  const formatPercentage = (rowData) => {
    const percentage = Math.round((rowData.reserved_inventory / rowData.quantity) * 100);
    return `${percentage}%`;
  };

  return (
    <Box sx={{ p: 2 }}>
      {/* Summary Card */}
      <Paper 
        elevation={0} 
        sx={{ 
          p: { xs: 2, sm: 3 }, 
          mb: 4, 
          backgroundColor: 'rgba(0, 0, 0, 0.02)', 
          borderRadius: 2,
          border: '1px solid rgba(0, 0, 0, 0.05)'
        }}
      >
        <Box 
          sx={{ 
            display: 'flex', 
            flexDirection: { xs: 'column', sm: 'row' }, 
            justifyContent: 'space-between',
            alignItems: { xs: 'flex-start', sm: 'center' },
            gap: 2,
            mb: 3
          }}
        >
          <Typography variant="h5" fontWeight={600}>
            Reserved Inventory
          </Typography>
          <Button 
            variant="outlined" 
            color="error" 
            size="small"
            disabled={stats.totalReserved === 0}
            onClick={resetReservedInventory}
            sx={{ fontSize: '0.8rem', py: 0.5 }}
          >
            Reset All Reserved Items
          </Button>
        </Box>
        
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 3 }}>
          <InventoryCard 
            sx={{ 
              minWidth: { xs: '100%', sm: 200 }, 
              flex: { xs: '1 1 100%', sm: '1 1 auto' },
              cursor: 'default'
            }}
          >
            <InventoryCategory>Total Reserved</InventoryCategory>
            <Typography variant="h4" fontWeight={600} color="info.main" sx={{ my: 1 }}>
              {stats.totalReserved}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {stats.reservedPercentage}% of total inventory
            </Typography>
          </InventoryCard>
          
          <InventoryCard 
            sx={{ 
              minWidth: { xs: '100%', sm: 200 }, 
              flex: { xs: '1 1 100%', sm: '1 1 auto' },
              cursor: 'default'
            }}
          >
            <InventoryCategory>Items with Reservations</InventoryCategory>
            <Typography variant="h4" fontWeight={600} color="info.main" sx={{ my: 1 }}>
              {stats.itemsWithReserved}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              out of {stats.uniqueItems} total items
            </Typography>
          </InventoryCard>
          
          {stats.reservationReasons && stats.reservationReasons.length > 0 && (
            <InventoryCard 
              sx={{ 
                flex: { xs: '1 1 100%', sm: '2 1 auto' },
                cursor: 'default'
              }}
            >
              <InventoryCategory>Top Reservations</InventoryCategory>
              <Box>
                {stats.reservationReasons.slice(0, 3).map((reason, idx) => (
                  <Box key={idx} sx={{ display: 'flex', justifyContent: 'space-between', mb: 1.5 }}>
                    <Box sx={{ maxWidth: '70%', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                      <Typography variant="body2" sx={{ fontSize: '0.9rem' }}>
                        {reason.reason}:
                      </Typography>
                    </Box>
                    <Typography variant="body2" fontWeight="bold" color="info.main">
                      {Math.round(reason.quantity)} ({reason.items} item{reason.items !== 1 ? 's' : ''})
                    </Typography>
                  </Box>
                ))}
                {stats.reservationReasons.length > 3 && (
                  <Typography variant="body2" color="text.secondary" sx={{ mt: 1, fontStyle: 'italic' }}>
                    +{stats.reservationReasons.length - 3} more reservation reasons
                  </Typography>
                )}
              </Box>
            </InventoryCard>
          )}
        </Box>
      </Paper>

      {/* Reserved Items Data Table */}
      {reservedItems.length > 0 ? (
        <StyledDataTable
          value={reservedItems}
          paginator
          rows={10}
          dataKey="warehouse_item_id"
          filters={filters}
          filterDisplay="menu"
          loading={loading}
          globalFilterFields={['item_name', 'item_type', 'reason_reserved']}
          header={renderHeader()}
          emptyMessage="No reserved items found."
          responsiveLayout="scroll"
          scrollHeight="600px"
          className="reserved-items-table"
        >
          <Column 
            field="item_name" 
            header="Item Name" 
            sortable 
            filter 
            filterPlaceholder="Search by name"
            style={{ minWidth: '200px' }} 
          />
          <Column 
            field="item_type" 
            header="Type" 
            sortable 
            filter 
            filterPlaceholder="Search by type"
            style={{ minWidth: '120px' }} 
          />
          <Column 
            field="size" 
            header="Size" 
            sortable 
            style={{ minWidth: '100px' }} 
          />
          <Column 
            field="shape" 
            header="Shape" 
            sortable 
            style={{ minWidth: '100px' }} 
          />
          <Column 
            field="quantity" 
            header="Total Quantity" 
            sortable 
            style={{ minWidth: '120px' }} 
          />
          <Column 
            field="reserved_inventory" 
            header="Reserved Quantity" 
            sortable 
            style={{ minWidth: '150px' }}
            body={(rowData) => (
              <Typography sx={{ fontWeight: 600, color: 'info.main' }}>
                {rowData.reserved_inventory}
              </Typography>
            )}
          />
          <Column 
            header="% Reserved" 
            style={{ minWidth: '120px' }}
            body={formatPercentage}
            sortable
            sortField="reserved_inventory"
          />
          <Column 
            field="reason_reserved" 
            header="Reserved For" 
            sortable 
            filter 
            filterPlaceholder="Search by reason"
            style={{ minWidth: '300px' }}
            body={formatReservationReasons}
          />
        </StyledDataTable>
      ) : (
        <Box 
          sx={{ 
            p: 4, 
            textAlign: 'center', 
            backgroundColor: 'rgba(0, 0, 0, 0.02)', 
            borderRadius: 2,
            border: '1px dashed rgba(0, 0, 0, 0.1)'
          }}
        >
          <Typography variant="h6" sx={{ mb: 2, color: 'text.secondary' }}>
            No Reserved Inventory
          </Typography>
          <Typography variant="body1" sx={{ color: 'text.secondary' }}>
            There are currently no items with reserved inventory in this warehouse.
          </Typography>
        </Box>
      )}

      {/* Info Dialog */}
      <Dialog
        open={showInfo}
        onClose={() => setShowInfo(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>About Reserved Inventory</DialogTitle>
        <DialogContent>
          <Typography variant="body1" paragraph>
            Reserved inventory represents items that are set aside for specific purposes such as pending shipments, customer orders, or internal allocations.
          </Typography>
          <Typography variant="body1" paragraph>
            Items with reservations are still counted in your total inventory, but they are marked as unavailable for other purposes.
          </Typography>
          <Typography variant="body1" paragraph>
            The "Reserved For" column shows the reason for the reservation, typically referencing a shipment number or purpose.
          </Typography>
          <Typography variant="body1" paragraph>
            Use the "Reset All Reserved Items" button to clear all reservations at once. This action cannot be undone.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowInfo(false)} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default ReservedInventoryTab;