import React, { useState } from 'react';
import {
  Box,
  Typography,
  TextField,
  Button,
  Paper,
  Alert,
  Tooltip,
  Collapse,
  IconButton,
  Divider,
  Link,
  Chip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import {
  TableChart as TableChartIcon,
  CloudUpload as CloudUploadIcon,
  ContentCopy as ContentCopyIcon,
  Info as InfoIcon,
  ExpandMore as ExpandMoreIcon,
  ExpandLess as ExpandLessIcon,
  CheckCircle as CheckCircleIcon,
  Error as ErrorIcon,
  Help as HelpIcon,
} from '@mui/icons-material';
import { motion } from 'framer-motion';

/**
 * A reusable component for importing data from Google Sheets
 * 
 * @param {Object} props - Component props
 * @param {string} props.title - Title for the import section
 * @param {string} props.subtitle - Subtitle/description text
 * @param {string} props.sheetUrl - Current sheet URL value
 * @param {function} props.onSheetUrlChange - Handler for sheet URL changes
 * @param {function} props.onImport - Handler for import button click
 * @param {boolean} props.isLoading - Whether import is in progress
 * @param {string} props.error - Error message if import failed
 * @param {boolean} props.success - Whether import was successful
 * @param {string} props.successMessage - Message to show on successful import
 * @param {function} props.onViewImported - Handler for "View Imported Items" button
 * @param {string} props.templateUrl - URL for the template sheet
 * @param {Array} props.additionalNotes - Additional notes to display
 * @param {Object} props.sx - Additional styles for the component
 */
const GoogleSheetImport = ({
  title = "Import from Google Sheet",
  subtitle = "Enter your Google Sheet URL to import data",
  sheetUrl = "",
  onSheetUrlChange,
  onImport,
  isLoading = false,
  error = null,
  success = false,
  successMessage = "Successfully imported items",
  onViewImported,
  templateUrl = "https://docs.google.com/spreadsheets/d/1TiBSZ4vO5Hdmk0p1nSZckIvCDyYq6b6P27DM6ejGx_c/",
  additionalNotes = [],
  sx = {}
}) => {
  const [showNotes, setShowNotes] = useState(false);
  const [helpDialogOpen, setHelpDialogOpen] = useState(false);
  const [copySuccess, setCopySuccess] = useState(false);
  
  const serviceAccountEmail = "sheets-pimbyeli@marine-service-432623-d7.iam.gserviceaccount.com";

  const handleCopyEmail = () => {
    navigator.clipboard.writeText(serviceAccountEmail);
    setCopySuccess(true);
    setTimeout(() => setCopySuccess(false), 2000);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (onImport) onImport(sheetUrl);
  };

  return (
    <Box sx={{ maxWidth: 800, mx: 'auto', ...sx }}>
      <Paper 
        elevation={3} 
        sx={{ 
          p: 4, 
          borderRadius: 2,
          background: 'linear-gradient(to bottom, #ffffff, #f9f9f9)',
          boxShadow: '0 4px 20px rgba(0,0,0,0.08)'
        }}
      >
        <Box sx={{ textAlign: 'center', mb: 4 }}>
          <motion.div
            initial={{ scale: 0.8, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            transition={{ duration: 0.5 }}
          >
            <TableChartIcon 
              sx={{ 
                fontSize: 64, 
                color: 'primary.main', 
                mb: 2,
                filter: 'drop-shadow(0 2px 4px rgba(0,0,0,0.2))'
              }} 
            />
          </motion.div>
          <Typography variant="h5" gutterBottom fontWeight="bold">
            {title}
          </Typography>
          <Typography variant="body1" color="text.secondary">
            {subtitle}
          </Typography>
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 1 }}>
            <Chip 
              icon={<InfoIcon />} 
              label="View Import Instructions" 
              onClick={() => setHelpDialogOpen(true)}
              variant="outlined"
              color="primary"
              sx={{ cursor: 'pointer' }}
            />
          </Box>
        </Box>

        <Box component="form" onSubmit={handleSubmit}>
          <TextField
            fullWidth
            label="Google Sheet URL"
            value={sheetUrl}
            onChange={(e) => onSheetUrlChange(e.target.value)}
            placeholder="https://docs.google.com/spreadsheets/d/..."
            error={!!error}
            helperText={error}
            sx={{ 
              mb: 3,
              '& .MuiOutlinedInput-root': {
                borderRadius: 2,
                '&:hover fieldset': {
                  borderColor: 'primary.main',
                },
              }
            }}
            InputProps={{
              startAdornment: (
                <TableChartIcon sx={{ color: 'action.active', mr: 1 }} />
              ),
            }}
          />

          <LoadingButton
            type="submit"
            variant="contained"
            fullWidth
            size="large"
            loading={isLoading}
            startIcon={<CloudUploadIcon />}
            sx={{ 
              py: 1.5,
              borderRadius: 2,
              background: 'linear-gradient(45deg, #1976d2 30%, #42a5f5 90%)',
              boxShadow: '0 3px 5px 2px rgba(33, 150, 243, .3)',
              '&:hover': {
                background: 'linear-gradient(45deg, #1565c0 30%, #1976d2 90%)',
              }
            }}
          >
            {isLoading ? 'Importing...' : 'Import Data'}
          </LoadingButton>
        </Box>

        {success && (
          <Box sx={{ mt: 4 }}>
            <Alert 
              severity="success" 
              sx={{ 
                mb: 2,
                borderRadius: 2,
                '& .MuiAlert-icon': {
                  fontSize: '1.5rem'
                }
              }}
              icon={<CheckCircleIcon fontSize="inherit" />}
            >
              {successMessage}
            </Alert>
            {onViewImported && (
              <Button
                variant="outlined"
                onClick={onViewImported}
                startIcon={<TableChartIcon />}
                sx={{ borderRadius: 2 }}
              >
                View Imported Items
              </Button>
            )}
          </Box>
        )}

        <Box sx={{ mt: 4 }}>
          <Box 
            sx={{ 
              display: 'flex', 
              justifyContent: 'space-between', 
              alignItems: 'center',
              cursor: 'pointer',
              '&:hover': {
                bgcolor: 'rgba(0, 0, 0, 0.02)',
              },
              p: 1,
              borderRadius: 1
            }}
            onClick={() => setShowNotes(!showNotes)}
          >
            <Typography variant="subtitle2" color="text.secondary" sx={{ fontWeight: 'bold' }}>
              Important Notes
            </Typography>
            <IconButton size="small">
              {showNotes ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </IconButton>
          </Box>
          
          <Collapse in={showNotes}>
            <Box sx={{ 
              p: 2, 
              bgcolor: 'grey.50', 
              borderRadius: 2,
              mt: 1,
              border: '1px solid',
              borderColor: 'divider'
            }}>
              <Typography variant="body2" color="text.secondary" component="div">
                <Box component="ul" sx={{ pl: 2, mt: 0 }}>
                  <Box component="li" sx={{ mb: 1 }}>
                    Make sure the Google Sheet is shared with the appropriate permissions
                  </Box>
                  <Box component="li" sx={{ mb: 1 }}>
                    The sheet should follow the required format with item cells
                  </Box>
                  <Box component="li" sx={{ mb: 1 }}>
                    Only non-zero quantities will be imported
                  </Box>
                  {additionalNotes.map((note, index) => (
                    <Box component="li" key={index} sx={{ mb: 1 }}>
                      {note}
                    </Box>
                  ))}
                  <Box component="li" sx={{ mb: 1 }}>
                    Google Sheet should follow this template format: <Link href={templateUrl} target="_blank" rel="noopener noreferrer">View Template</Link>
                  </Box>
                  <Box component="li" sx={{ mb: 1 }}>
                    Share your Google Sheet with our service account:
                    <Box sx={{ display: 'flex', alignItems: 'center', mt: 1, ml: 1 }}>
                      <Tooltip 
                        title={copySuccess ? "Copied!" : "Click to copy email"} 
                        placement="top"
                      >
                        <Button
                          variant="outlined"
                          size="small"
                          startIcon={copySuccess ? <CheckCircleIcon color="success" /> : <ContentCopyIcon />}
                          onClick={(e) => {
                            e.stopPropagation();
                            handleCopyEmail();
                          }}
                          color={copySuccess ? "success" : "primary"}
                          sx={{ borderRadius: 2 }}
                        >
                          {copySuccess ? "Copied!" : "Copy Service Account Email"}
                        </Button>
                      </Tooltip>
                    </Box>
                  </Box>
                </Box>
              </Typography>
            </Box>
          </Collapse>
        </Box>
      </Paper>

      {/* Help Dialog */}
      <Dialog 
        open={helpDialogOpen} 
        onClose={() => setHelpDialogOpen(false)}
        maxWidth="md"
        PaperProps={{
          sx: {
            borderRadius: 2,
            boxShadow: '0 8px 32px rgba(0,0,0,0.2)'
          }
        }}
      >
        <DialogTitle sx={{ 
          bgcolor: 'primary.main', 
          color: 'white',
          display: 'flex',
          alignItems: 'center',
          gap: 1
        }}>
          <HelpIcon /> How to Import from Google Sheets
        </DialogTitle>
        <DialogContent sx={{ p: 3, mt: 2 }}>
          <Typography variant="h6" gutterBottom>Step 1: Create or Use a Google Sheet</Typography>
          <Typography variant="body1" paragraph>
            Create a new Google Sheet or use an existing one that follows our template format.
            You can view and copy our template here: <Link href={templateUrl} target="_blank" rel="noopener noreferrer">View Template</Link>
          </Typography>
          <Divider sx={{ my: 2 }} />

          <Typography variant="h6" gutterBottom>Step 2: Share with Service Account</Typography>
          <Typography variant="body1" paragraph>
            Share your Google Sheet with our service account to allow our system to access it:
          </Typography>
          <Box sx={{ 
            bgcolor: 'grey.100', 
            p: 2, 
            borderRadius: 2, 
            fontFamily: 'monospace',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            mb: 2
          }}>
            <Typography variant="body2">
              {serviceAccountEmail}
            </Typography>
            <Button 
              size="small" 
              startIcon={<ContentCopyIcon />}
              onClick={handleCopyEmail}
              variant="outlined"
            >
              Copy
            </Button>
          </Box>
          <Typography variant="body2" color="text.secondary">
            In your Google Sheet, click "Share" in the top right, paste this email, and give it "Viewer" access.
          </Typography>
          <Divider sx={{ my: 2 }} />

          <Typography variant="h6" gutterBottom>Step 3: Copy Sheet URL</Typography>
          <Typography variant="body1" paragraph>
            Copy the URL of your Google Sheet from your browser's address bar and paste it into the input field.
          </Typography>
          <Divider sx={{ my: 2 }} />

          <Typography variant="h6" gutterBottom>Step 4: Import Data</Typography>
          <Typography variant="body1">
            Click the "Import Data" button to start the import process. The system will read your sheet and import all valid items.
          </Typography>
        </DialogContent>
        <DialogActions sx={{ p: 2, bgcolor: 'grey.50' }}>
          <Button onClick={() => setHelpDialogOpen(false)} variant="contained">
            Got it
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default GoogleSheetImport; 