import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Box,
  Paper,
  Typography,
  Grid,
  Divider,
  Tab,
  Tabs,
  IconButton,
  Button,
  Chip,
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Alert,
  Snackbar
} from '@mui/material';
import {
  Business as BusinessIcon,
  Email as EmailIcon,
  Phone as PhoneIcon,
  Assignment as AssignmentIcon,
  LocalShipping as ShippingIcon,
  ArrowBack as ArrowBackIcon,
  Edit as EditIcon,
  Add as AddIcon,
  Delete as DeleteIcon,
  Check as CheckIcon
} from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import { getCustomerById, getCustomerOrders, getShipmentsByCustomer, getCustomerUpdates, createCustomerUpdate, deleteCustomerUpdate, approveCustomerUpdate, updateCustomer } from '../services/api';
import { format } from 'date-fns';
import FullScreenLayout from './layouts/FullScreenLayout';
import { Link } from '@mui/material';
import { Dialog, DialogTitle, DialogContent, DialogActions, TextField, FormControlLabel, Checkbox } from '@mui/material';

const StyledCard = styled(Card)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  '& .MuiCardContent-root': {
    flexGrow: 1,
  },
}));

const InfoItem = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
  marginBottom: theme.spacing(2),
}));

const TabPanel = ({ children, value, index, ...other }) => (
  <div
    role="tabpanel"
    hidden={value !== index}
    id={`tabpanel-${index}`}
    {...other}
  >
    {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
  </div>
);

const EditCustomerDialog = ({ open, onClose, customer, onSave }) => {
  const [formData, setFormData] = useState({
    company_name: '',
    business_type: '',
    tax_id: '',
    primary_contact_name: '',
    primary_contact_email: '',
    primary_contact_phone: '',
    secondary_contact_name: '',
    secondary_contact_email: '',
    secondary_contact_phone: '',
    billing_address_street: '',
    billing_address_city: '',
    billing_address_state: '',
    billing_address_postal: '',
    billing_address_country: '',
    shipping_address_street: '',
    shipping_address_city: '',
    shipping_address_state: '',
    shipping_address_postal: '',
    shipping_address_country: '',
    preferred_payment_method: '',
    preferred_shipping_method: '',
    credit_limit: '',
    payment_terms: '',
    special_instructions: '',
    notes: '',
    send_emails: true,
    status: ''
  });
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (customer) {
      console.log('Setting form data from customer:', customer);
      // Set all fields from customer data, preserving empty strings for editing
      setFormData({
        company_name: customer.company_name || '',
        business_type: customer.business_type || '',
        tax_id: customer.tax_id || '',
        primary_contact_name: customer.primary_contact_name || '',
        primary_contact_email: customer.primary_contact_email || '',
        primary_contact_phone: customer.primary_contact_phone || '',
        secondary_contact_name: customer.secondary_contact_name || '',
        secondary_contact_email: customer.secondary_contact_email || '',
        secondary_contact_phone: customer.secondary_contact_phone || '',
        billing_address_street: customer.billing_address_street || '',
        billing_address_city: customer.billing_address_city || '',
        billing_address_state: customer.billing_address_state || '',
        billing_address_postal: customer.billing_address_postal || '',
        billing_address_country: customer.billing_address_country || '',
        shipping_address_street: customer.shipping_address_street || '',
        shipping_address_city: customer.shipping_address_city || '',
        shipping_address_state: customer.shipping_address_state || '',
        shipping_address_postal: customer.shipping_address_postal || '',
        shipping_address_country: customer.shipping_address_country || '',
        preferred_payment_method: customer.preferred_payment_method || '',
        preferred_shipping_method: customer.preferred_shipping_method || '',
        credit_limit: customer.credit_limit || '',
        payment_terms: customer.payment_terms || '',
        special_instructions: customer.special_instructions || '',
        notes: customer.notes || '',
        send_emails: typeof customer.send_emails === 'boolean' ? customer.send_emails : true,
        status: customer.status || 'active'
      });
      setErrors({});
    }
  }, [customer]);

  const validateForm = () => {
    const newErrors = {};
    
    if (!formData.company_name?.trim()) {
      newErrors.company_name = 'Company name is required';
    }
    
    if (!formData.primary_contact_name?.trim()) {
      newErrors.primary_contact_name = 'Primary contact name is required';
    }
    
    if (!formData.primary_contact_email?.trim()) {
      newErrors.primary_contact_email = 'Primary contact email is required';
    } else {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(formData.primary_contact_email)) {
        newErrors.primary_contact_email = 'Invalid email format';
      }
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    const newValue = type === 'checkbox' ? checked : value;
    
    console.log('Handling form change:', { name, value: newValue, type });
    
    setFormData(prev => ({
      ...prev,
      [name]: newValue
    }));

    // Clear error when field is edited
    if (errors[name]) {
      setErrors(prev => ({
        ...prev,
        [name]: undefined
      }));
    }
  };

  const handleSubmit = () => {
    console.log('Submitting form data:', formData);
    if (validateForm()) {
      onSave(formData);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>Edit Customer</DialogTitle>
      <DialogContent>
        <Grid container spacing={2} sx={{ mt: 1 }}>
          <Grid item xs={12}>
            <Typography variant="h6" gutterBottom>Company Information</Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              name="company_name"
              label="Company Name"
              fullWidth
              required
              value={formData.company_name}
              onChange={handleChange}
              error={!!errors.company_name}
              helperText={errors.company_name}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              name="business_type"
              label="Business Type"
              fullWidth
              value={formData.business_type}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              name="tax_id"
              label="Tax ID"
              fullWidth
              value={formData.tax_id}
              onChange={handleChange}
            />
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h6" gutterBottom>Primary Contact</Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              name="primary_contact_name"
              label="Name"
              fullWidth
              required
              value={formData.primary_contact_name}
              onChange={handleChange}
              error={!!errors.primary_contact_name}
              helperText={errors.primary_contact_name}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              name="primary_contact_email"
              label="Email"
              fullWidth
              required
              value={formData.primary_contact_email}
              onChange={handleChange}
              error={!!errors.primary_contact_email}
              helperText={errors.primary_contact_email}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              name="primary_contact_phone"
              label="Phone"
              fullWidth
              value={formData.primary_contact_phone}
              onChange={handleChange}
            />
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h6" gutterBottom>Secondary Contact</Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              name="secondary_contact_name"
              label="Name"
              fullWidth
              value={formData.secondary_contact_name}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              name="secondary_contact_email"
              label="Email"
              fullWidth
              value={formData.secondary_contact_email}
              onChange={handleChange}
              error={!!errors.secondary_contact_email}
              helperText={errors.secondary_contact_email}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              name="secondary_contact_phone"
              label="Phone"
              fullWidth
              value={formData.secondary_contact_phone}
              onChange={handleChange}
            />
          </Grid>

          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={formData.send_emails}
                  onChange={handleChange}
                  name="send_emails"
                />
              }
              label="Send Email Notifications"
            />
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h6" gutterBottom>Billing Address</Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              name="billing_address_street"
              label="Street Address"
              fullWidth
              value={formData.billing_address_street}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              name="billing_address_city"
              label="City"
              fullWidth
              value={formData.billing_address_city}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              name="billing_address_state"
              label="State/Province"
              fullWidth
              value={formData.billing_address_state}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              name="billing_address_postal"
              label="Postal Code"
              fullWidth
              value={formData.billing_address_postal}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              name="billing_address_country"
              label="Country"
              fullWidth
              value={formData.billing_address_country}
              onChange={handleChange}
            />
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h6" gutterBottom>Shipping Address</Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              name="shipping_address_street"
              label="Street Address"
              fullWidth
              value={formData.shipping_address_street}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              name="shipping_address_city"
              label="City"
              fullWidth
              value={formData.shipping_address_city}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              name="shipping_address_state"
              label="State/Province"
              fullWidth
              value={formData.shipping_address_state}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              name="shipping_address_postal"
              label="Postal Code"
              fullWidth
              value={formData.shipping_address_postal}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              name="shipping_address_country"
              label="Country"
              fullWidth
              value={formData.shipping_address_country}
              onChange={handleChange}
            />
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h6" gutterBottom>Additional Information</Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              name="preferred_payment_method"
              label="Preferred Payment Method"
              fullWidth
              value={formData.preferred_payment_method}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              name="preferred_shipping_method"
              label="Preferred Shipping Method"
              fullWidth
              value={formData.preferred_shipping_method}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              name="credit_limit"
              label="Credit Limit"
              fullWidth
              value={formData.credit_limit}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              name="payment_terms"
              label="Payment Terms"
              fullWidth
              value={formData.payment_terms}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              name="special_instructions"
              label="Special Instructions"
              fullWidth
              multiline
              rows={2}
              value={formData.special_instructions}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              name="notes"
              label="Notes"
              fullWidth
              multiline
              rows={2}
              value={formData.notes}
              onChange={handleChange}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleSubmit} variant="contained" color="primary">
          Save Changes
        </Button>
      </DialogActions>
    </Dialog>
  );
};

function CustomerDetails() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [customer, setCustomer] = useState(null);
  const [orders, setOrders] = useState([]);
  const [shipments, setShipments] = useState([]);
  const [tabValue, setTabValue] = useState(0);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
  const [updates, setUpdates] = useState([]);
  const [openNewUpdateDialog, setOpenNewUpdateDialog] = useState(false);
  const [newUpdate, setNewUpdate] = useState({ message: '', link: '', sendEmail: false });
  const [editDialogOpen, setEditDialogOpen] = useState(false);

  useEffect(() => {
    fetchCustomerData();
  }, [id]);

  useEffect(() => {
    if (id) {
      fetchUpdates();
    }
  }, [id]);

  const fetchCustomerData = async () => {
    try {
      // First get the customer data
      const response = await getCustomerById(id);
      if (!response || !response.data) {
        throw new Error('Customer not found');
      }

      // Extract the customer data from the response
      const customerData = response.data;

      // Clean up the data by removing null values and empty strings
      const cleanedData = Object.entries(customerData).reduce((acc, [key, value]) => {
        // Keep the value if it's not null/undefined and not an empty string
        if (value !== null && value !== undefined && value !== '') {
          acc[key] = value;
        }
        // Special case for boolean values
        if (typeof value === 'boolean') {
          acc[key] = value;
        }
        return acc;
      }, {});

      console.log('Cleaned customer data:', cleanedData);
      setCustomer(cleanedData);

      // Then get orders and shipments
      const [ordersData, shipmentsData] = await Promise.all([
        getCustomerOrders(id),
        getShipmentsByCustomer(id)
      ]);

      setOrders(ordersData?.data || []);
      setShipments(shipmentsData?.data || []);
    } catch (error) {
      console.error('Error fetching customer data:', error);
      setSnackbar({
        open: true,
        message: 'Error fetching customer data: ' + (error.response?.data?.error || error.message),
        severity: 'error'
      });
    }
  };

  const fetchUpdates = async () => {
    try {
      const updatesData = await getCustomerUpdates(id);
      setUpdates(updatesData);
    } catch (error) {
      setSnackbar({
        open: true,
        message: 'Error fetching customer updates: ' + error.message,
        severity: 'error'
      });
    }
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const getStatusColor = (status) => {
    const statusColors = {
      'pending': 'warning',
      'approved': 'success',
      'rejected': 'error',
      'completed': 'success',
      'cancelled': 'error',
      'processing': 'info',
      'shipped': 'success',
      'delivered': 'success'
    };
    return statusColors[status?.toLowerCase()] || 'default';
  };

  const headerContent = (
    <Box display="flex" alignItems="center" gap={2}>
      <IconButton onClick={() => navigate('/customers')}>
        <ArrowBackIcon />
      </IconButton>
      <Typography variant="h4">Customer Details</Typography>
    </Box>
  );

  const handleCreateUpdate = async () => {
    try {
      await createCustomerUpdate(id, newUpdate);
      setOpenNewUpdateDialog(false);
      setNewUpdate({ message: '', link: '', sendEmail: false });
      await fetchUpdates();
      setSnackbar({
        open: true,
        message: 'Update created successfully',
        severity: 'success'
      });
    } catch (error) {
      setSnackbar({
        open: true,
        message: 'Error creating update: ' + error.message,
        severity: 'error'
      });
    }
  };

  const handleDeleteUpdate = async (updateId) => {
    if (window.confirm('Are you sure you want to delete this update?')) {
      try {
        await deleteCustomerUpdate(id, updateId);
        await fetchUpdates();
        setSnackbar({
          open: true,
          message: 'Update deleted successfully',
          severity: 'success'
        });
      } catch (error) {
        setSnackbar({
          open: true,
          message: 'Error deleting update: ' + error.message,
          severity: 'error'
        });
      }
    }
  };

  const handleApproveUpdate = async (updateId) => {
    try {
      await approveCustomerUpdate(id, updateId);
      await fetchUpdates();
      setSnackbar({
        open: true,
        message: 'Update approved successfully',
        severity: 'success'
      });
    } catch (error) {
      setSnackbar({
        open: true,
        message: 'Error approving update: ' + error.message,
        severity: 'error'
      });
    }
  };

  const handleEditClick = () => {
    if (customer) {
      setEditDialogOpen(true);
    }
  };

  const handleEditSave = async (formData) => {
    try {
      // Clean up the data before sending
      const cleanedData = Object.entries(formData).reduce((acc, [key, value]) => {
        // Keep the value if it's not null/undefined and not an empty string
        if (value !== null && value !== undefined && value.toString().trim() !== '') {
          acc[key] = value;
        }
        // Special case for boolean values
        if (typeof value === 'boolean') {
          acc[key] = value;
        }
        return acc;
      }, {});

      // Make sure we're sending the customer_id with the update
      const updateData = {
        ...cleanedData,
        customer_id: id
      };
      
      console.log('Sending update data:', updateData);
      const response = await updateCustomer(id, updateData);
      
      if (response && response.data) {
        // Update the local customer state with the response data
        setCustomer(response.data);
        setEditDialogOpen(false);
        setSnackbar({
          open: true,
          message: 'Customer updated successfully',
          severity: 'success'
        });
      } else {
        throw new Error('Invalid response from server');
      }
    } catch (error) {
      console.error('Error updating customer:', error);
      const errorMessage = error.response?.data?.error || error.message;
      setSnackbar({
        open: true,
        message: 'Error updating customer: ' + errorMessage,
        severity: 'error'
      });
    }
  };

  const mainContent = customer ? (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <StyledCard>
            <CardContent>
              <Box display="flex" justifyContent="space-between" alignItems="flex-start">
                <Typography variant="h5" gutterBottom>
                  {customer.company_name}
                </Typography>
                <Button
                  startIcon={<EditIcon />}
                  onClick={handleEditClick}
                >
                  Edit Customer
                </Button>
              </Box>
              <Grid container spacing={4}>
                <Grid item xs={12} md={6}>
                  <Typography variant="h6" gutterBottom>Company Information</Typography>
                  {customer.business_type && (
                    <InfoItem>
                      <BusinessIcon />
                      <Typography>
                        <strong>Business Type:</strong> {customer.business_type}
                      </Typography>
                    </InfoItem>
                  )}
                  {customer.tax_id && (
                    <InfoItem>
                      <AssignmentIcon />
                      <Typography>
                        <strong>Tax ID:</strong> {customer.tax_id}
                      </Typography>
                    </InfoItem>
                  )}
                  <InfoItem>
                    <EmailIcon />
                    <Typography>
                      <strong>Email Notifications:</strong> {customer.send_emails ? 'Enabled' : 'Disabled'}
                    </Typography>
                  </InfoItem>
                  {customer.status && (
                    <InfoItem>
                      <BusinessIcon />
                      <Typography>
                        <strong>Status:</strong> {customer.status}
                      </Typography>
                    </InfoItem>
                  )}
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography variant="h6" gutterBottom>Primary Contact</Typography>
                  {customer.primary_contact_name && (
                    <InfoItem>
                      <BusinessIcon />
                      <Typography>
                        <strong>Name:</strong> {customer.primary_contact_name}
                      </Typography>
                    </InfoItem>
                  )}
                  {customer.primary_contact_email && (
                    <InfoItem>
                      <EmailIcon />
                      <Typography>
                        <strong>Email:</strong> {customer.primary_contact_email}
                      </Typography>
                    </InfoItem>
                  )}
                  {customer.primary_contact_phone && (
                    <InfoItem>
                      <PhoneIcon />
                      <Typography>
                        <strong>Phone:</strong> {customer.primary_contact_phone}
                      </Typography>
                    </InfoItem>
                  )}
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography variant="h6" gutterBottom>Secondary Contact</Typography>
                  {customer.secondary_contact_name && (
                    <InfoItem>
                      <BusinessIcon />
                      <Typography>
                        <strong>Name:</strong> {customer.secondary_contact_name}
                      </Typography>
                    </InfoItem>
                  )}
                  {customer.secondary_contact_email && (
                    <InfoItem>
                      <EmailIcon />
                      <Typography>
                        <strong>Email:</strong> {customer.secondary_contact_email}
                      </Typography>
                    </InfoItem>
                  )}
                  {customer.secondary_contact_phone && (
                    <InfoItem>
                      <PhoneIcon />
                      <Typography>
                        <strong>Phone:</strong> {customer.secondary_contact_phone}
                      </Typography>
                    </InfoItem>
                  )}
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography variant="h6" gutterBottom>Billing Address</Typography>
                  {customer.billing_address_street && (
                    <InfoItem>
                      <BusinessIcon />
                      <Typography>
                        <strong>Street:</strong> {customer.billing_address_street}
                      </Typography>
                    </InfoItem>
                  )}
                  {customer.billing_address_city && (
                    <InfoItem>
                      <BusinessIcon />
                      <Typography>
                        <strong>City:</strong> {customer.billing_address_city}
                      </Typography>
                    </InfoItem>
                  )}
                  {customer.billing_address_state && (
                    <InfoItem>
                      <BusinessIcon />
                      <Typography>
                        <strong>State/Province:</strong> {customer.billing_address_state}
                      </Typography>
                    </InfoItem>
                  )}
                  {customer.billing_address_postal && (
                    <InfoItem>
                      <BusinessIcon />
                      <Typography>
                        <strong>Postal Code:</strong> {customer.billing_address_postal}
                      </Typography>
                    </InfoItem>
                  )}
                  {customer.billing_address_country && (
                    <InfoItem>
                      <BusinessIcon />
                      <Typography>
                        <strong>Country:</strong> {customer.billing_address_country}
                      </Typography>
                    </InfoItem>
                  )}
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography variant="h6" gutterBottom>Shipping Address</Typography>
                  {customer.shipping_address_street && (
                    <InfoItem>
                      <BusinessIcon />
                      <Typography>
                        <strong>Street:</strong> {customer.shipping_address_street}
                      </Typography>
                    </InfoItem>
                  )}
                  {customer.shipping_address_city && (
                    <InfoItem>
                      <BusinessIcon />
                      <Typography>
                        <strong>City:</strong> {customer.shipping_address_city}
                      </Typography>
                    </InfoItem>
                  )}
                  {customer.shipping_address_state && (
                    <InfoItem>
                      <BusinessIcon />
                      <Typography>
                        <strong>State/Province:</strong> {customer.shipping_address_state}
                      </Typography>
                    </InfoItem>
                  )}
                  {customer.shipping_address_postal && (
                    <InfoItem>
                      <BusinessIcon />
                      <Typography>
                        <strong>Postal Code:</strong> {customer.shipping_address_postal}
                      </Typography>
                    </InfoItem>
                  )}
                  {customer.shipping_address_country && (
                    <InfoItem>
                      <BusinessIcon />
                      <Typography>
                        <strong>Country:</strong> {customer.shipping_address_country}
                      </Typography>
                    </InfoItem>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h6" gutterBottom>Additional Information</Typography>
                  {customer.preferred_payment_method && (
                    <InfoItem>
                      <BusinessIcon />
                      <Typography>
                        <strong>Preferred Payment Method:</strong> {customer.preferred_payment_method}
                      </Typography>
                    </InfoItem>
                  )}
                  {customer.preferred_shipping_method && (
                    <InfoItem>
                      <BusinessIcon />
                      <Typography>
                        <strong>Preferred Shipping Method:</strong> {customer.preferred_shipping_method}
                      </Typography>
                    </InfoItem>
                  )}
                  {customer.credit_limit && (
                    <InfoItem>
                      <BusinessIcon />
                      <Typography>
                        <strong>Credit Limit:</strong> {customer.credit_limit}
                      </Typography>
                    </InfoItem>
                  )}
                  {customer.payment_terms && (
                    <InfoItem>
                      <BusinessIcon />
                      <Typography>
                        <strong>Payment Terms:</strong> {customer.payment_terms}
                      </Typography>
                    </InfoItem>
                  )}
                  {customer.special_instructions && (
                    <InfoItem>
                      <BusinessIcon />
                      <Typography>
                        <strong>Special Instructions:</strong> {customer.special_instructions}
                      </Typography>
                    </InfoItem>
                  )}
                  {customer.notes && (
                    <InfoItem>
                      <BusinessIcon />
                      <Typography>
                        <strong>Notes:</strong> {customer.notes}
                      </Typography>
                    </InfoItem>
                  )}
                </Grid>
              </Grid>
            </CardContent>
          </StyledCard>
        </Grid>

        {/* Tabs Section */}
        <Grid item xs={12}>
          <Paper>
            <Tabs value={tabValue} onChange={handleTabChange}>
              <Tab label="Orders" />
              <Tab label="Shipments" />
              <Tab label="Updates" />
            </Tabs>

            {/* Orders Tab */}
            <TabPanel value={tabValue} index={0}>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Order ID</TableCell>
                      <TableCell>Date</TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell>Total Items</TableCell>
                      <TableCell>Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {orders.map((order) => (
                      <TableRow key={order.order_id}>
                        <TableCell>{order.order_id}</TableCell>
                        <TableCell>
                          {format(new Date(order.created_at), 'MMM dd, yyyy')}
                        </TableCell>
                        <TableCell>
                          <Chip 
                            label={order.status} 
                            color={getStatusColor(order.status)}
                            size="small"
                          />
                        </TableCell>
                        <TableCell>{order.total_items}</TableCell>
                        <TableCell>
                          <Button
                            size="small"
                            onClick={() => navigate(`/orders/${order.order_id}/details`)}
                          >
                            View Details
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                    {orders.length === 0 && (
                      <TableRow>
                        <TableCell colSpan={5} align="center">
                          No orders found
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </TabPanel>

            {/* Shipments Tab */}
            <TabPanel value={tabValue} index={1}>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Shipment ID</TableCell>
                      <TableCell>Date</TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell>Items</TableCell>
                      <TableCell>Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {shipments.map((shipment) => (
                      <TableRow key={shipment.shipment_id}>
                        <TableCell>{shipment.shipment_id}</TableCell>
                        <TableCell>
                          {format(new Date(shipment.created_at), 'MMM dd, yyyy')}
                        </TableCell>
                        <TableCell>
                          <Chip 
                            label={shipment.status} 
                            color={getStatusColor(shipment.status)}
                            size="small"
                          />
                        </TableCell>
                        <TableCell>{shipment.total_items}</TableCell>
                        <TableCell>
                          <Button
                            size="small"
                            onClick={() => navigate(`/shipments/${shipment.shipment_id}`)}
                          >
                            View Details
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                    {shipments.length === 0 && (
                      <TableRow>
                        <TableCell colSpan={5} align="center">
                          No shipments found
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </TabPanel>

            {/* Updates Tab */}
            <TabPanel value={tabValue} index={2}>
              <Box display="flex" justifyContent="flex-end" mb={2}>
                <Button
                  startIcon={<AddIcon />}
                  variant="contained"
                  onClick={() => setOpenNewUpdateDialog(true)}
                >
                  New Update
                </Button>
              </Box>

              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Date</TableCell>
                      <TableCell>Message</TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell>Created By</TableCell>
                      <TableCell>Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {updates.map((update) => (
                      <TableRow key={update.update_id}>
                        <TableCell>
                          {format(new Date(update.created_at), 'MMM dd, yyyy')}
                        </TableCell>
                        <TableCell>
                          {update.message}
                          {update.link && (
                            <Link href={update.link} target="_blank" display="block" sx={{ mt: 1 }}>
                              View Link
                            </Link>
                          )}
                        </TableCell>
                        <TableCell>
                          <Chip
                            label={update.is_approved ? 'Approved' : 'Pending'}
                            color={update.is_approved ? 'success' : 'warning'}
                            size="small"
                          />
                          {update.send_email && (
                            <Chip
                              label={update.email_sent ? 'Email Sent' : 'Email Pending'}
                              color={update.email_sent ? 'success' : 'info'}
                              size="small"
                              sx={{ ml: 1 }}
                            />
                          )}
                        </TableCell>
                        <TableCell>{update.created_by_username}</TableCell>
                        <TableCell>
                          {!update.is_approved && (
                            <>
                              <Tooltip title="Approve">
                                <IconButton
                                  size="small"
                                  onClick={() => handleApproveUpdate(update.update_id)}
                                  color="success"
                                >
                                  <CheckIcon />
                                </IconButton>
                              </Tooltip>
                              <Tooltip title="Delete">
                                <IconButton
                                  size="small"
                                  onClick={() => handleDeleteUpdate(update.update_id)}
                                  color="error"
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </Tooltip>
                            </>
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                    {updates.length === 0 && (
                      <TableRow>
                        <TableCell colSpan={5} align="center">
                          No updates found
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </TabPanel>
          </Paper>
        </Grid>
      </Grid>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert
          onClose={() => setSnackbar({ ...snackbar, open: false })}
          severity={snackbar.severity}
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>

      <EditCustomerDialog
        open={editDialogOpen}
        onClose={() => setEditDialogOpen(false)}
        customer={customer}
        onSave={handleEditSave}
      />

      <Dialog open={openNewUpdateDialog} onClose={() => setOpenNewUpdateDialog(false)}>
        <DialogTitle>Create New Update</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Message"
            fullWidth
            multiline
            rows={4}
            value={newUpdate.message}
            onChange={(e) => setNewUpdate({ ...newUpdate, message: e.target.value })}
          />
          <TextField
            margin="dense"
            label="Link (optional)"
            fullWidth
            value={newUpdate.link}
            onChange={(e) => setNewUpdate({ ...newUpdate, link: e.target.value })}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={newUpdate.sendEmail}
                onChange={(e) => setNewUpdate({ ...newUpdate, sendEmail: e.target.checked })}
              />
            }
            label="Send Email"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenNewUpdateDialog(false)}>Cancel</Button>
          <Button onClick={handleCreateUpdate} variant="contained" disabled={!newUpdate.message}>
            Create
          </Button>
        </DialogActions>
      </Dialog>
    </>
  ) : null;

  return (
    <FullScreenLayout
      header={headerContent}
      mainPanel={mainContent}
    />
  );
}

export default CustomerDetails; 