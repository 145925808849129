import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useParams, useNavigate, useSearchParams } from 'react-router-dom';
import {
  Container,
  Typography,
  Paper,
  Grid,
  TextField,
  Button,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  Snackbar,
  Alert,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Autocomplete,
  CircularProgress,
  FormControlLabel,
  Switch,
  Card,
  CardContent,
  Collapse,
  Divider,
  Chip,
  Backdrop,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import {
  Add as AddIcon,
  Delete as DeleteIcon,
  Save as SaveIcon,
  ArrowBack as ArrowBackIcon,
  Search as SearchIcon,
  Comment as CommentIcon,
  CheckCircle as CheckCircleIcon,
  Share as ShareIcon,
  CloudDownload as CloudDownloadIcon,
  ViewList as ViewListIcon,
  GridView as GridViewIcon,
  ExpandLess,
  ExpandMore,
  TableChart as TableChartIcon,
  CloudUpload as CloudUploadIcon,
} from '@mui/icons-material';
import {
  getWarehouseItemsForProduction,
  getProductionEntryById,
  createProductionEntry,
  updateProductionEntry,
} from '../services/api';
import debounce from 'lodash/debounce';
import useSound from 'use-sound';
import { motion, AnimatePresence } from 'framer-motion';
import ProcessingAnimation from './ProcessingAnimation';
import FullScreenLayout from './layouts/FullScreenLayout';
import OptimizedItemSearch from './common/OptimizedItemSearch';
import OptimizedSpreadsheet from './spreadsheets/OptimizedSpreadsheet';
import ItemsCacheService from '../services/itemsCacheService';
import { useBeforeUnload } from 'react-use';
import { extractSheetData } from '../services/sheetExtractorApi';
import GoogleSheetImport from './common/GoogleSheetImport';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider, DateTimePicker } from '@mui/x-date-pickers';

const successSound = '/success.wav';
const errorSound = '/error.wav';
const PRODUCTION_CACHE_KEY = 'production_entry_cache';
const ITEMS_CACHE_KEY = 'warehouse_items_cache';

const saveToCache = (data) => {
  try {
    localStorage.setItem(PRODUCTION_CACHE_KEY, JSON.stringify(data));
  } catch (error) {
    console.error('Error saving to cache:', error);
  }
};

const loadFromCache = () => {
  try {
    const cached = localStorage.getItem(PRODUCTION_CACHE_KEY);
    return cached ? JSON.parse(cached) : null;
  } catch (error) {
    console.error('Error loading from cache:', error);
    return null;
  }
};

const clearCache = () => {
  localStorage.removeItem(PRODUCTION_CACHE_KEY);
};

const AnimatedButton = ({ children, ...props }) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <Button
      {...props}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      sx={{
        ...props.sx,
        transition: 'all 0.3s ease',
        '&:hover': {
          transform: isHovered ? 'scale(1.05)' : 'scale(1)',
        },
      }}
    >
      {children}
    </Button>
  );
};

const LoadingSpinner = () => (
  <Box sx={{ 
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    textAlign: 'center'
  }}>
    <motion.div
      initial={{ scale: 0.8, opacity: 0 }}
      animate={{ 
        scale: [0.8, 1.2, 0.8],
        opacity: [0.5, 1, 0.5],
      }}
      transition={{ 
        duration: 1.5,
        repeat: Infinity,
        ease: "easeInOut"
      }}
      style={{
        width: '120px',
        height: '120px',
        borderRadius: '50%',
        border: '4px solid #1976d2',
        borderTopColor: 'transparent',
        margin: '0 auto 24px'
      }}
    />
    <Typography variant="h6" sx={{ mt: 2, color: 'primary.main' }}>
      Loading...
    </Typography>
  </Box>
);

const ProductionEntry = () => {
  const navigate = useNavigate();
  const { warehouseId, productionId } = useParams();
  const [searchParams] = useSearchParams();
  const editProductionId = productionId;
  const isSheetMode = searchParams.get('method') === 'sheet';
  const [loading, setLoading] = useState(true);
  const [formData, setFormData] = useState({
    warehouse_id: warehouseId,
    notes: '',
    items: [],
    production_date: new Date()
  });
  const [cachedItems, setCachedItems] = useState([]);
  const [selectedItems, setSelectedItems] = useState(new Map());
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
  const [confirmDialog, setConfirmDialog] = useState(false);
  const [playSuccess] = useSound(successSound, { volume: 0.5 });
  const [playError] = useSound(errorSound, { volume: 0.5 });
  const searchInputRef = useRef(null);
  const [showNotes, setShowNotes] = useState(false);
  const [itemNotes, setItemNotes] = useState({});
  const searchCache = useRef(new Map());
  const [totals, setTotals] = useState({
    totalItems: 0,
    totalScans: 0,
    totalQuantity: 0
  });
  const [isCreating, setIsCreating] = useState(false);
  const [showSuccessDialog, setShowSuccessDialog] = useState(false);
  const [viewMode, setViewMode] = useState('list');
  const [spreadsheetData, setSpreadsheetData] = useState({});
  const [pendingChanges, setPendingChanges] = useState([]);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [pendingChangesMinimized, setPendingChangesMinimized] = useState(false);
  const [sheetUrl, setSheetUrl] = useState('');
  const [isImporting, setIsImporting] = useState(false);
  const [importError, setImportError] = useState(null);
  const [confirmProductionDialog, setConfirmProductionDialog] = useState(false);
  const [warehouseName, setWarehouseName] = useState('');
  const [isProcessing, setIsProcessing] = useState(false);

  useBeforeUnload(
    hasUnsavedChanges,
    'You have unsaved changes. Are you sure you want to leave?'
  );

  useEffect(() => {
    const handleBeforeUnload = (e) => {
      if (hasUnsavedChanges) {
        e.preventDefault();
        e.returnValue = '';
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => window.removeEventListener('beforeunload', handleBeforeUnload);
  }, [hasUnsavedChanges]);

  useEffect(() => {
    fetchData();
  }, [warehouseId, editProductionId]);

  useEffect(() => {
    const newTotals = {
      totalItems: selectedItems.size,
      totalScans: selectedItems.size, // or could be sum of all quantities
      totalQuantity: Array.from(selectedItems.values())
        .reduce((sum, item) => sum + (item.quantity || 0), 0)
    };
    setTotals(newTotals);
  }, [selectedItems]);

  useEffect(() => {
    const newSpreadsheetData = {};
    
    // Add all cached items with 0 quantities by default
    cachedItems.forEach(item => {
      newSpreadsheetData[item.id] = {
        current_quantity: 0,
        new_quantity: 0
      };
    });

    // Override with selected items' quantities
    selectedItems.forEach((data, itemId) => {
      newSpreadsheetData[itemId] = {
        current_quantity: data.quantity,
        new_quantity: data.quantity
      };
    });
    
    setSpreadsheetData(newSpreadsheetData);
  }, [cachedItems, selectedItems]);

  const fetchData = async () => {
    try {
      setLoading(true);
      
      let items = ItemsCacheService.getCachedItems();
      if (!items) {
        items = await ItemsCacheService.getItems();
      }
      setCachedItems(items);

      // Only fetch production data if we have a valid edit ID
      if (editProductionId && !isNaN(parseInt(editProductionId))) {
        const productionData = await getProductionEntryById(editProductionId);
        
        if (productionData.status !== 'pending') {
          setSnackbar({
            open: true,
            message: 'Cannot edit a production entry that is not pending',
            severity: 'error'
          });
          navigate(`/warehouses/${warehouseId}/production/${editProductionId}`);
          return;
        }

        const itemsMap = new Map();
        if (productionData.items && Array.isArray(productionData.items)) {
          productionData.items.forEach(item => {
            // If the API returns item_id, use that directly
            if (item.item_id) {
              const matchingItem = items.find(i => i.id === item.item_id);
              if (matchingItem) {
                itemsMap.set(matchingItem.id, {
                  quantity: item.quantity,
                  item_details: matchingItem,
                  notes: item.notes
                });
                return;
              }
            }
            
            // Fallback to matching by name and type
            const matchingItem = items.find(i => 
              i.name === item.item_name && 
              i.type === item.item_type && 
              i.size === item.item_size
            );
            
            if (matchingItem) {
              itemsMap.set(matchingItem.id, {
                quantity: item.quantity,
                item_details: matchingItem,
                notes: item.notes
              });
            } else {
              console.warn('Could not find matching item for:', item);
            }
          });
        }
        
        setSelectedItems(itemsMap);
        
        // Ensure production_date is properly parsed as a Date object
        const productionDate = productionData.production_date 
          ? new Date(productionData.production_date) 
          : new Date();
        
        console.log('Setting production date from API:', productionDate);
        
        setFormData(prev => ({
          ...prev,
          notes: productionData.notes || '',
          production_date: productionDate
        }));

        // Try to get warehouse name
        try {
          const warehouseData = await getWarehouseItemsForProduction(warehouseId);
          if (warehouseData && warehouseData.name) {
            setWarehouseName(warehouseData.name);
          }
        } catch (error) {
          console.error('Error fetching warehouse name:', error);
        }
      } else {
        // For new entries, try to load from cache
        const cachedData = loadFromCache();
        if (cachedData) {
          setSelectedItems(new Map(cachedData.selectedItems));
          
          // Ensure production_date from cache is properly parsed as a Date object
          const cachedFormData = { ...cachedData.formData };
          if (cachedFormData.production_date && typeof cachedFormData.production_date === 'string') {
            cachedFormData.production_date = new Date(cachedFormData.production_date);
          }
          
          setFormData(cachedFormData);
          setSnackbar({
            open: true,
            message: 'Restored from previous session',
            severity: 'info'
          });
        }

        // Try to get warehouse name
        try {
          const warehouseData = await getWarehouseItemsForProduction(warehouseId);
          if (warehouseData && warehouseData.name) {
            setWarehouseName(warehouseData.name);
          }
        } catch (error) {
          console.error('Error fetching warehouse name:', error);
        }
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      setSnackbar({
        open: true,
        message: 'Failed to fetch data: ' + (error.response?.data?.error || error.message),
        severity: 'error'
      });
      if (editProductionId) {
        navigate(`/warehouses/${warehouseId}`);
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (selectedItems.size > 0 || formData.notes) {
      setHasUnsavedChanges(true);
      saveToCache({
        selectedItems: Array.from(selectedItems.entries()),
        formData
      });
    }
  }, [selectedItems, formData]);

  const handleAddItem = (itemToAdd) => {
    if (!itemToAdd) return;

    const currentItem = selectedItems.get(itemToAdd.id);
    const newQuantity = (currentItem?.quantity || 0) + 1;

    setSelectedItems(prev => new Map(prev).set(itemToAdd.id, {
      quantity: newQuantity,
      item_details: itemToAdd
    }));

    playSuccess();
    
    setSnackbar({
      open: true,
      message: `${currentItem ? 'Increased' : 'Added'} ${itemToAdd.item_name} quantity to ${newQuantity}`,
      severity: 'success'
    });

    if (searchInputRef.current) {
      setTimeout(() => {
        searchInputRef.current.focus();
      }, 0);
    }
  };

  const handleRemoveItem = (itemId) => {
    setSelectedItems(prev => {
      const newMap = new Map(prev);
      newMap.delete(itemId);
      return newMap;
    });
  };

  const handleItemChange = (index, field, value) => {
    setFormData(prev => ({
      ...prev,
      items: prev.items.map((item, i) => 
        i === index ? { ...item, [field]: value } : item
      )
    }));
  };

  const validateForm = () => {
    if (formData.items.length === 0) {
      setSnackbar({
        open: true,
        message: 'Please add at least one item',
        severity: 'error'
      });
      return false;
    }

    for (const item of formData.items) {
      if (!item.warehouse_item_id || item.quantity <= 0) {
        setSnackbar({
          open: true,
          message: 'Please fill in all required fields for each item',
          severity: 'error'
        });
        return false;
      }
    }

    return true;
  };

  const handleSubmit = async () => {
    if (selectedItems.size === 0) {
      setSnackbar({
        open: true,
        message: 'Please add at least one item',
        severity: 'error'
      });
      return;
    }

    // Show confirmation dialog instead of submitting directly
    setConfirmProductionDialog(true);
  };

  const handleConfirmAndSubmit = async () => {
    setConfirmProductionDialog(false);
    setIsProcessing(true);
    
    try {
      // Format the production date properly to ensure it's sent correctly to the backend
      const productionData = {
        warehouse_id: parseInt(warehouseId),
        notes: formData.notes,
        production_date: formData.production_date instanceof Date ? formData.production_date.toISOString() : formData.production_date,
        items: Array.from(selectedItems.entries()).map(([itemId, data]) => ({
          item_id: parseInt(itemId),
          quantity: data.quantity,
          notes: data.notes || null
        }))
      };

      console.log('Submitting production data with date:', productionData.production_date);
      
      const minLoadingTime = new Promise(resolve => setTimeout(resolve, 2000));
      
      let apiResponse;
      if (editProductionId) {
        apiResponse = await updateProductionEntry(editProductionId, productionData);
      } else {
        apiResponse = await createProductionEntry(productionData);
      }

      await minLoadingTime;
      
      clearCache();
      setShowSuccessDialog(true);
      setTimeout(() => {
        setShowSuccessDialog(false);
        // Redirect to the correct production details page
        const redirectId = editProductionId || apiResponse.production_id;
        navigate(`/warehouses/${warehouseId}/production/${redirectId}`);
      }, 2000);
    } catch (error) {
      console.error('Error submitting production entry:', error);
      setSnackbar({
        open: true,
        message: `Failed to ${editProductionId ? 'update' : 'create'} production entry: ${error.message}`,
        severity: 'error'
      });
      playError();
    } finally {
      setIsProcessing(false);
    }
  };

  const handleCancel = () => {
    if (hasUnsavedChanges) {
      setConfirmDialog(true);
    } else {
      navigate(`/warehouses/${warehouseId}`);
    }
  };

  const handleConfirmedNavigation = () => {
    clearCache();
    setConfirmDialog(false);
    navigate(`/warehouses/${warehouseId}`);
  };

  const handleSpreadsheetChange = useCallback(({ itemId, metric, newValue }) => {
    if (metric !== 'new_quantity') return;
    
    const numericId = parseInt(itemId);
    const item = cachedItems.find(item => item.id === numericId);
    if (!item) return;

    const currentValue = selectedItems.get(numericId)?.quantity || 0;
    
    setPendingChanges(prev => [{
      timestamp: new Date().toLocaleTimeString(),
      warehouse_item_id: numericId,
      itemName: item.item_name,
      type: item.type_name,
      size: item.size_name,
      brim: item.brim_name,
      shape: item.shape_name,
      oldValue: currentValue,
      newValue
    }, ...prev]);

    setSpreadsheetData(prev => ({
      ...prev,
      [itemId]: {
        ...prev[itemId],
        new_quantity: newValue
      }
    }));
  }, [cachedItems, selectedItems]);

  const applySpreadsheetChanges = () => {
    const newSelectedItems = new Map(selectedItems);
    
    pendingChanges.forEach(change => {
      const item = cachedItems.find(i => i.id === change.warehouse_item_id);
      if (!item) return;

      if (change.newValue > 0) {
        newSelectedItems.set(change.warehouse_item_id, {
          quantity: change.newValue,
          item_details: item
        });
      } else {
        newSelectedItems.delete(change.warehouse_item_id);
      }
    });

    setSelectedItems(newSelectedItems);
    setPendingChanges([]);
    playSuccess();
    setSnackbar({
      open: true,
      message: 'Changes applied successfully',
      severity: 'success'
    });
  };

  const handleSheetImport = async (url) => {
    if (!url) {
      setSnackbar({
        open: true,
        message: 'Please enter a Google Sheet URL',
        severity: 'error'
      });
      return;
    }

    setIsImporting(true);
    setImportError(null);
    try {
      const result = await extractSheetData(url);
      if (result.success && result.data) {
        const validItems = result.data.filter(item => item.value > 0);
        
        const newSelectedItems = new Map();
        validItems.forEach(item => {
          const matchingItem = cachedItems.find(i => i.id === item.itemId);
          if (matchingItem) {
            newSelectedItems.set(item.itemId, {
              quantity: item.value,
              item_details: matchingItem
            });
          }
        });

        setSelectedItems(newSelectedItems);
        setViewMode('list'); // Switch to list view to show imported items
        
        setSnackbar({
          open: true,
          message: `Successfully imported ${validItems.length} items from sheet`,
          severity: 'success'
        });
        playSuccess();
      }
    } catch (error) {
      setImportError(error.message);
      setSnackbar({
        open: true,
        message: `Failed to import from sheet: ${error.message}`,
        severity: 'error'
      });
      playError();
    } finally {
      setIsImporting(false);
    }
  };

  const renderHeader = () => (
    <Box sx={{ 
      display: 'flex', 
      justifyContent: 'space-between', 
      alignItems: 'center', 
      mb: 3,
      px: 2,
      py: 1,
      backgroundColor: 'background.paper',
      borderBottom: 1,
      borderColor: 'divider'
    }}>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
        <IconButton onClick={handleCancel} size="large">
          <ArrowBackIcon />
        </IconButton>
        <Typography variant="h5">
          {editProductionId ? 'Edit Production Entry' : 'New Production Entry'}
        </Typography>
        {(!isSheetMode || selectedItems.size > 0) && (
          <Box sx={{ ml: 2 }}>
            <IconButton 
              onClick={() => setViewMode('list')}
              color={viewMode === 'list' ? 'primary' : 'default'}
            >
              <ViewListIcon />
            </IconButton>
            <IconButton 
              onClick={() => setViewMode('spreadsheet')}
              color={viewMode === 'spreadsheet' ? 'primary' : 'default'}
            >
              <GridViewIcon />
            </IconButton>
          </Box>
        )}
      </Box>
      
      {(!isSheetMode || selectedItems.size > 0) && (
        <AnimatedButton
          variant="contained"
          color="primary"
          startIcon={editProductionId ? <SaveIcon /> : <AddIcon />}
          loading={isCreating}
          onClick={handleSubmit}
          size="large"
          sx={{ 
            px: 4,
            py: 1,
            fontSize: '1.1rem',
            minWidth: 180,
            background: 'linear-gradient(45deg, #1976d2 30%, #42a5f5 90%)',
            boxShadow: '0 3px 5px 2px rgba(33, 150, 243, .3)',
            '&:hover': {
              background: 'linear-gradient(45deg, #1565c0 30%, #1976d2 90%)',
            }
          }}
        >
          {editProductionId ? 'Save Changes' : 'Create Production'}
        </AnimatedButton>
      )}
    </Box>
  );

  const renderSearchField = () => (
    <Box sx={{ 
      mb: 3,
      width: '100%',
      maxWidth: '100%',
      position: 'sticky',
      top: 0,
      zIndex: 1200,
      backgroundColor: 'background.paper',
      p: 2,
      borderBottom: '1px solid',
      borderColor: 'divider',
      boxShadow: 2
    }}>
      <Typography variant="h6" sx={{ mb: 1 }}>
        Search Items
      </Typography>
      <OptimizedItemSearch
        onItemSelect={(item) => {
          if (item) {
            handleAddItem(item);
          }
        }}
        searchRef={searchInputRef}
        showQuantity={true}
        placeholder="Search or scan items..."
        clearOnSelect={true}
      />
    </Box>
  );

  const renderSheetImport = () => (
    <Box sx={{ p: 3, maxWidth: 800, mx: 'auto' }}>
      <GoogleSheetImport
        sheetUrl={sheetUrl}
        onSheetUrlChange={(url) => setSheetUrl(url)}
        onImport={handleSheetImport}
        isLoading={isImporting}
        error={importError}
        success={selectedItems.size > 0}
        successMessage={`Successfully imported ${selectedItems.size} items`}
        onViewImported={() => setViewMode('list')}
        additionalNotes={["Items not available in this warehouse will be skipped"]}
      />
    </Box>
  );

  const renderMainContent = () => (
    <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      {isSheetMode && selectedItems.size === 0 ? renderSheetImport() : (
        <>
          {viewMode === 'list' && renderSearchField()}
          
          <Box sx={{ 
            display: 'flex',
            gap: 3,
            p: viewMode === 'list' ? 1 : 0.5,
            bgcolor: 'background.paper',
            borderRadius: 2,
            boxShadow: viewMode === 'list' ? 2 : 1,
            mb: viewMode === 'list' ? 2 : 1,
            mx: viewMode === 'list' ? 2 : 0,
            justifyContent: 'center',
            ...(viewMode === 'spreadsheet' && {
              position: 'sticky',
              top: 0,
              zIndex: 1100,
              borderBottom: 1,
              borderColor: 'divider',
            })
          }}>
            <Box sx={{ textAlign: 'center' }}>
              <Typography variant="h6" color="primary.main">
                {totals.totalItems || 0}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Unique Items
              </Typography>
            </Box>
            <Box sx={{ textAlign: 'center' }}>
              <Typography variant="h6" color="success.main">
                {totals.totalScans || 0}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Total Scans
              </Typography>
            </Box>
            <Box sx={{ textAlign: 'center' }}>
              <Typography variant="h6" color="info.main">
                {totals.totalQuantity || 0}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Total Quantity
              </Typography>
            </Box>
          </Box>
          
          {viewMode === 'list' ? (
            <TableContainer 
              component={Paper} 
              sx={{ 
                flex: 1, 
                overflow: 'auto',
                boxShadow: 2,
                borderRadius: 2,
                mx: 2
              }}
            >
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ fontWeight: 'bold', backgroundColor: 'primary.main', color: 'white' }}>
                      Item
                    </TableCell>
                    <TableCell sx={{ fontWeight: 'bold', backgroundColor: 'primary.main', color: 'white' }}>
                      Quantity
                    </TableCell>
                    <TableCell sx={{ fontWeight: 'bold', backgroundColor: 'primary.main', color: 'white' }}>
                      Notes
                    </TableCell>
                    <TableCell sx={{ fontWeight: 'bold', backgroundColor: 'primary.main', color: 'white' }}>
                      Actions
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Array.from(selectedItems.entries()).map(([itemId, data]) => (
                    <TableRow key={itemId}>
                      <TableCell>
                        <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
                          {data.item_details.item_name}
                          {data.item_details.barcode && (
                            <Typography 
                              component="span" 
                              sx={{ 
                                ml: 1,
                                color: 'text.secondary',
                                fontFamily: 'monospace',
                                fontSize: '0.9em'
                              }}
                            >
                              ({data.item_details.barcode})
                            </Typography>
                          )}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                          {[
                            data.item_details.type_name,
                            data.item_details.size_name,
                            data.item_details.shape_name,
                            data.item_details.brim_name
                          ]
                            .filter(Boolean)
                            .join(' • ')}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <TextField
                          type="number"
                          value={data.quantity}
                          onChange={(e) => {
                            const newQuantity = parseInt(e.target.value) || 0;
                            setSelectedItems(prev => new Map(prev).set(itemId, {
                              ...data,
                              quantity: newQuantity
                            }));
                          }}
                          size="small"
                          inputProps={{ min: 0 }}
                        />
                      </TableCell>
                      <TableCell>
                        <IconButton 
                          size="small" 
                          onClick={() => {
                            setItemNotes({ ...itemNotes, [itemId]: data.item_details.notes });
                            setShowNotes(true);
                          }}
                        >
                          <CommentIcon 
                            color={data.item_details.notes ? 'primary' : 'action'} 
                            sx={{ 
                              transition: 'transform 0.2s',
                              '&:hover': { transform: 'scale(1.1)' }
                            }}
                          />
                        </IconButton>
                      </TableCell>
                      <TableCell>
                        <IconButton onClick={() => handleRemoveItem(itemId)} color="error" size="small">
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <Box sx={{ 
              flex: 1,
              height: 'calc(100vh - 150px)',
              p: 1,
              mt: -1,
              position: 'relative',
              display: 'flex',
              flexDirection: 'column'
            }}>
              <Box sx={{ 
                width: '100%',
                height: '100%',
                position: 'relative',
                flex: 1,
                display: 'flex',
                flexDirection: 'column'
              }}>
                <OptimizedSpreadsheet
                  data={spreadsheetData}
                  metrics={['current_quantity', 'new_quantity']}
                  onCellValueChanged={handleSpreadsheetChange}
                  hideZeros={false}
                  fullScreen
                  title="Production Entry Items"
                  readOnlyMetrics={['current_quantity']}
                />
                
                <Collapse
                  in={!pendingChangesMinimized}
                  collapsedSize={48}
                  sx={{
                    position: 'absolute',
                    right: 0,
                    top: 0,
                    width: 300,
                    maxHeight: 'calc(100vh - 150px)',
                    backgroundColor: 'background.paper',
                    borderRadius: 2,
                    boxShadow: 3,
                    transition: 'all 0.3s ease',
                    zIndex: 1000,
                    '&:hover': {
                      boxShadow: 4
                    }
                  }}
                >
                  <Card sx={{ 
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    border: 'none',
                    borderRadius: 2,
                  }}>
                    <CardContent sx={{ 
                      p: 2, 
                      pb: 1,
                      display: 'flex',
                      flexDirection: 'column',
                      height: '100%'
                    }}>
                      <Box sx={{ 
                        display: 'flex', 
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        mb: pendingChangesMinimized ? 0 : 2
                      }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                          <Typography variant="h6" sx={{ 
                            fontSize: pendingChangesMinimized ? '1rem' : '1.25rem',
                            transition: 'all 0.3s ease'
                          }}>
                            Pending Changes {pendingChanges.length > 0 && `(${pendingChanges.length})`}
                          </Typography>
                          <IconButton 
                            size="small"
                            onClick={() => setPendingChangesMinimized(!pendingChangesMinimized)}
                          >
                            {pendingChangesMinimized ? <ExpandMore /> : <ExpandLess />}
                          </IconButton>
                        </Box>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={applySpreadsheetChanges}
                          startIcon={<SaveIcon />}
                          disabled={pendingChanges.length === 0}
                          size="small"
                          sx={{
                            minWidth: pendingChangesMinimized ? 'unset' : 'auto',
                            px: pendingChangesMinimized ? 1 : 2
                          }}
                        >
                          {pendingChangesMinimized ? '' : 'Apply Changes'}
                        </Button>
                      </Box>
                      
                      {!pendingChangesMinimized && (
                        <Box sx={{ 
                          overflow: 'auto',
                          flex: 1,
                          maxHeight: 'calc(100vh - 300px)',
                          transition: 'all 0.3s ease'
                        }}>
                          {pendingChanges.length === 0 ? (
                            <Typography 
                              variant="body2" 
                              color="text.secondary" 
                              sx={{ 
                                textAlign: 'center', 
                                py: 2,
                                fontStyle: 'italic'
                              }}
                            >
                              No pending changes
                            </Typography>
                          ) : (
                            pendingChanges.map((change, index) => (
                              <Paper 
                                key={index} 
                                sx={{ 
                                  p: 1.5,
                                  mb: 1,
                                  backgroundColor: 'rgba(0, 0, 0, 0.02)',
                                  borderLeft: '3px solid',
                                  borderColor: change.newValue > change.oldValue 
                                    ? 'success.main' 
                                    : 'error.main',
                                  transition: 'all 0.2s ease',
                                  '&:hover': {
                                    transform: 'translateX(4px)',
                                    boxShadow: 1
                                  }
                                }}
                              >
                                <Typography variant="caption" display="block" color="text.secondary">
                                  {change.timestamp}
                                </Typography>
                                <Typography variant="body2" sx={{ fontWeight: 500 }}>
                                  {change.itemName}
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                  {change.type} • {change.size}
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                  {change.brim} • {change.shape}
                                </Typography>
                                <Typography 
                                  variant="body2" 
                                  sx={{ 
                                    mt: 0.5,
                                    color: change.newValue > change.oldValue 
                                      ? 'success.main' 
                                      : 'error.main',
                                    fontWeight: 500 
                                  }}
                                >
                                  Quantity: {change.oldValue} → {change.newValue}
                                </Typography>
                              </Paper>
                            ))
                          )}
                        </Box>
                      )}
                    </CardContent>
                  </Card>
                </Collapse>
              </Box>
            </Box>
          )}
        </>
      )}
    </Box>
  );

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <FullScreenLayout
        header={renderHeader()}
        mainPanel={renderMainContent()}
        hasNavigation
        sx={{
          '& > .main-panel': {
            height: 'calc(100vh - 64px)',
            overflow: 'hidden',
            display: 'flex',
            flexDirection: 'column'
          }
        }}
      />
      
      {/* Processing Backdrop */}
      <Backdrop
        sx={{ 
          color: '#fff', 
          zIndex: (theme) => theme.zIndex.drawer + 1,
          flexDirection: 'column',
          gap: 2
        }}
        open={isProcessing}
      >
        <CircularProgress color="inherit" size={60} thickness={4} />
        <Box sx={{ 
          display: 'flex', 
          flexDirection: 'column', 
          alignItems: 'center',
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          padding: 3,
          borderRadius: 2,
          maxWidth: 300,
          textAlign: 'center'
        }}>
          <Typography variant="h6" sx={{ mb: 1 }}>
            {editProductionId ? 'Updating' : 'Creating'} Production Entry
          </Typography>
          <Typography variant="body2">
            Please wait while we process your request...
          </Typography>
        </Box>
      </Backdrop>
      
      <Dialog open={confirmDialog} onClose={() => setConfirmDialog(false)}>
        <DialogTitle>Unsaved Changes</DialogTitle>
        <DialogContent>
          <Typography>
            You have unsaved changes that will be lost if you leave this page. 
            Are you sure you want to proceed?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setConfirmDialog(false)}>
            Stay
          </Button>
          <Button 
            onClick={handleConfirmedNavigation} 
            color="error"
            variant="contained"
          >
            Leave and Discard Changes
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog 
        open={confirmProductionDialog} 
        onClose={() => setConfirmProductionDialog(false)}
        maxWidth="md"
        fullWidth
        PaperProps={{
          sx: {
            borderRadius: 2,
            boxShadow: '0 8px 16px rgba(0,0,0,0.1)'
          }
        }}
      >
        <DialogTitle sx={{ pb: 1, textAlign: 'center' }}>
          <Box sx={{ textAlign: 'center', mb: 2 }}>
            <motion.div
              initial={{ scale: 0 }}
              animate={{ scale: 1 }}
              transition={{ type: "spring", stiffness: 260, damping: 20 }}
            >
              <CheckCircleIcon sx={{ fontSize: 64, color: 'primary.main', mb: 1 }} />
            </motion.div>
            <Typography variant="h5" component="div">
              Confirm Production Details
            </Typography>
          </Box>
        </DialogTitle>
        <DialogContent sx={{ pt: 2 }}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <Card sx={{ mb: 3 }}>
                <CardContent>
                  <Typography variant="h6" gutterBottom>
                    Production Information
                  </Typography>
                  <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                      <Typography variant="subtitle2" color="text.secondary">Warehouse</Typography>
                      <Typography variant="body1" fontWeight="medium">
                        {warehouseName || `Warehouse #${warehouseId}`}
                      </Typography>
                    </Box>
                    <Divider />
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                      <Typography variant="subtitle2" color="text.secondary">Status</Typography>
                      <Chip 
                        label="PENDING"
                        color="warning"
                        size="small"
                      />
                    </Box>
                    {formData.notes && (
                      <>
                        <Divider />
                        <Box>
                          <Typography variant="subtitle2" color="text.secondary">Notes</Typography>
                          <Typography variant="body2" sx={{ mt: 1 }}>
                            {formData.notes}
                          </Typography>
                        </Box>
                      </>
                    )}
                  </Box>
                </CardContent>
              </Card>

              <Card>
                <CardContent>
                  <Typography variant="h6" gutterBottom>
                    Production Statistics
                  </Typography>
                  <Box sx={{ display: 'flex', gap: 3 }}>
                    <Box sx={{ flex: 1, textAlign: 'center', p: 2, bgcolor: 'primary.light', borderRadius: 2 }}>
                      <Typography variant="h4" color="primary.contrastText">
                        {selectedItems.size}
                      </Typography>
                      <Typography variant="body2" color="primary.contrastText">
                        Total Items
                      </Typography>
                    </Box>
                    <Box sx={{ flex: 1, textAlign: 'center', p: 2, bgcolor: 'secondary.light', borderRadius: 2 }}>
                      <Typography variant="h4" color="secondary.contrastText">
                        {Array.from(selectedItems.values()).reduce((sum, item) => sum + (parseInt(item.quantity) || 0), 0)}
                      </Typography>
                      <Typography variant="body2" color="secondary.contrastText">
                        Total Quantity
                      </Typography>
                    </Box>
                  </Box>
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12} md={6}>
              <Card sx={{ mb: 3 }}>
                <CardContent>
                  <Typography variant="h6" gutterBottom>
                    Custom Production Date (Optional)
                  </Typography>
                  <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
                    If this production occurred at a different time, you can specify the actual date:
                  </Typography>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DateTimePicker
                      label="Production Date"
                      value={formData.production_date}
                      onChange={(newDate) => {
                        if (newDate) {
                          console.log('Selected production date:', newDate);
                          setFormData(prev => ({
                            ...prev,
                            production_date: newDate
                          }));
                        }
                      }}
                      slotProps={{ textField: { fullWidth: true } }}
                      maxDateTime={new Date()}
                    />
                  </LocalizationProvider>
                </CardContent>
              </Card>

              <Card>
                <CardContent>
                  <Typography variant="h6" gutterBottom>
                    Production Items
                  </Typography>
                  <TableContainer sx={{ maxHeight: 300 }}>
                    <Table stickyHeader size="small">
                      <TableHead>
                        <TableRow>
                          <TableCell>Item</TableCell>
                          <TableCell align="right">Quantity</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {Array.from(selectedItems.entries()).map(([itemId, data]) => (
                          <TableRow key={itemId}>
                            <TableCell>
                              {data.item_details?.name || 'Unknown Item'}
                              {data.notes && (
                                <Typography variant="caption" display="block" color="text.secondary">
                                  Note: {data.notes}
                                </Typography>
                              )}
                            </TableCell>
                            <TableCell align="right">{data.quantity}</TableCell>
                          </TableRow>
                        ))}
                        {selectedItems.size === 0 && (
                          <TableRow>
                            <TableCell colSpan={2} align="center">
                              <Typography variant="body2" color="text.secondary">
                                No items added
                              </Typography>
                            </TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ p: 3, pt: 1, justifyContent: 'center' }}>
          <Button 
            onClick={() => setConfirmProductionDialog(false)}
            variant="outlined"
            sx={{ borderRadius: 2, minWidth: 120 }}
          >
            Cancel
          </Button>
          <Button 
            onClick={handleConfirmAndSubmit}
            variant="contained"
            color="primary"
            sx={{ 
              borderRadius: 2,
              minWidth: 120,
              ml: 2,
              background: 'linear-gradient(45deg, #1976d2 30%, #42a5f5 90%)',
              '&:hover': {
                background: 'linear-gradient(45deg, #1565c0 30%, #1976d2 90%)',
              }
            }}
          >
            {editProductionId ? 'Save Changes' : 'Create Production'}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog 
        open={showSuccessDialog}
        maxWidth="sm"
        fullWidth
        PaperProps={{
          sx: {
            background: 'rgba(255, 255, 255, 0.95)',
            backdropFilter: 'blur(8px)',
          }
        }}
      >
        <DialogContent sx={{ textAlign: 'center', py: 6 }}>
          <motion.div
            initial={{ scale: 0, rotate: -180 }}
            animate={{ scale: 1, rotate: 0 }}
            transition={{ 
              type: "spring",
              stiffness: 260,
              damping: 20 
            }}
          >
            <CheckCircleIcon 
              sx={{ 
                fontSize: 80,
                color: 'success.main',
                filter: 'drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.2))'
              }} 
            />
          </motion.div>
          <motion.div
            initial={{ y: 20, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ delay: 0.2, duration: 0.5 }}
          >
            <Typography variant="h5" sx={{ mt: 3, mb: 1, fontWeight: 'bold' }}>
              Success!
            </Typography>
            <Typography variant="body1" color="text.secondary">
              Production Entry {editProductionId ? 'Updated' : 'Created'} Successfully
            </Typography>
          </motion.div>
        </DialogContent>
      </Dialog>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar(prev => ({ ...prev, open: false }))}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert 
          onClose={() => setSnackbar(prev => ({ ...prev, open: false }))} 
          severity={snackbar.severity || 'info'}
          variant="filled"
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default ProductionEntry; 