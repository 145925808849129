import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Button,
  IconButton,
  Chip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Menu,
  MenuItem,
  Grid,
  Card,
  Paper,
  Container,
} from '@mui/material';
import {
  Add as AddIcon,
  Visibility as VisibilityIcon,
  Comment as CommentIcon,
  LocalShipping as LocalShippingIcon,
  Inventory as InventoryIcon,
  CheckCircle as CheckCircleIcon,
  Cancel as CancelIcon,
} from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import {
  getAllShipments,
  updateShipmentStatus,
  addShipmentComment,
  getShipmentComments,
} from '../services/api';
import FullScreenLayout from './layouts/FullScreenLayout';

const PageContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(4),
  maxWidth: '100%',
  margin: '0 auto',
  backgroundColor: theme.palette.background.default,
}));

const ContentPaper = styled(Paper)(({ theme }) => ({
  borderRadius: '16px',
  padding: theme.spacing(3),
  backgroundColor: theme.palette.background.paper,
  boxShadow: '0 2px 12px rgba(0,0,0,0.03)',
}));

const CreateShipmentButton = styled(Button)(({ theme }) => ({
  borderRadius: '12px',
  padding: '10px 24px',
  fontSize: '1rem',
  fontWeight: 600,
  textTransform: 'none',
  boxShadow: '0 4px 12px rgba(0,0,0,0.05)',
  '&:hover': {
    boxShadow: '0 6px 16px rgba(0,0,0,0.1)',
    transform: 'translateY(-1px)',
  },
  transition: 'all 0.2s ease',
}));

const StatsCard = styled(Card)(({ theme }) => ({
  padding: theme.spacing(3),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
  transition: 'all 0.2s ease',
  boxShadow: 'none',
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: '16px',
  backgroundColor: theme.palette.background.paper,
  '&:hover': {
    transform: 'translateY(-4px)',
    boxShadow: '0 4px 12px rgba(0,0,0,0.05)',
  },
}));

const StatusChip = styled(Chip)(({ theme, status }) => {
  const colors = {
    pending: theme.palette.warning.main,
    in_transit: theme.palette.info.main,
    delivered: theme.palette.success.main,
    cancelled: theme.palette.error.main,
  };

  return {
    backgroundColor: colors[status] || theme.palette.grey[500],
    color: theme.palette.common.white,
    fontWeight: 600,
    padding: '4px 12px',
    height: '24px',
    '& .MuiChip-label': {
      padding: '0 8px',
    },
  };
});

const CommentDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    borderRadius: '16px',
    padding: theme.spacing(2),
  },
}));

const CommentBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  marginBottom: theme.spacing(2),
  backgroundColor: theme.palette.grey[50],
  borderRadius: '8px',
  border: `1px solid ${theme.palette.divider}`,
}));

function Shipments() {
  const navigate = useNavigate();
  const [shipments, setShipments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [commentDialogOpen, setCommentDialogOpen] = useState(false);
  const [selectedShipment, setSelectedShipment] = useState(null);
  const [newComment, setNewComment] = useState('');
  const [comments, setComments] = useState([]);
  const [statusMenuAnchor, setStatusMenuAnchor] = useState(null);
  const [selectedShipmentForStatus, setSelectedShipmentForStatus] = useState(null);
  const [cancellationReason, setCancellationReason] = useState('');
  const [cancellationDialogOpen, setCancellationDialogOpen] = useState(false);
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: 'contains' },
    shipment_number: { value: null, matchMode: 'contains' },
    source_warehouse_name: { value: null, matchMode: 'contains' },
    status: { value: null, matchMode: 'equals' },
  });
  const [globalFilterValue, setGlobalFilterValue] = useState('');

  useEffect(() => {
    fetchShipments();
  }, []);

  const fetchShipments = async () => {
    try {
      const data = await getAllShipments();
      // Filter out cancelled shipments by default
      const filteredData = data.filter(shipment => shipment.status !== 'cancelled');
      setShipments(filteredData);
    } catch (error) {
      console.error('Error fetching shipments:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleCreateShipment = () => {
    navigate('/shipments/create');
  };

  const handleStatusClick = (event, shipment) => {
    setStatusMenuAnchor(event.currentTarget);
    setSelectedShipmentForStatus(shipment);
  };

  const handleStatusClose = () => {
    setStatusMenuAnchor(null);
    setSelectedShipmentForStatus(null);
  };

  const handleStatusChange = async (newStatus) => {
    try {
      if (newStatus === 'cancelled') {
        setCancellationDialogOpen(true);
      } else {
        await updateShipmentStatus(selectedShipmentForStatus.shipment_id, newStatus);
        fetchShipments();
      }
    } catch (error) {
      console.error('Error updating status:', error);
    }
    handleStatusClose();
  };

  const handleCancellationConfirm = async () => {
    try {
      await updateShipmentStatus(
        selectedShipmentForStatus.shipment_id,
        'cancelled',
        cancellationReason
      );
      fetchShipments();
      setCancellationDialogOpen(false);
      setCancellationReason('');
    } catch (error) {
      console.error('Error cancelling shipment:', error);
    }
  };

  const handleCommentClick = async (shipment) => {
    setSelectedShipment(shipment);
    try {
      const commentsData = await getShipmentComments(shipment.shipment_id);
      setComments(commentsData);
      setCommentDialogOpen(true);
    } catch (error) {
      console.error('Error fetching comments:', error);
    }
  };

  const handleAddComment = async () => {
    try {
      await addShipmentComment(selectedShipment.shipment_id, newComment);
      const updatedComments = await getShipmentComments(selectedShipment.shipment_id);
      setComments(updatedComments);
      setNewComment('');
    } catch (error) {
      console.error('Error adding comment:', error);
    }
  };

  const getStatusLabel = (status) => {
    const labels = {
      pending: 'Pending',
      in_transit: 'In Transit',
      delivered: 'Delivered',
      cancelled: 'Cancelled',
    };
    return labels[status] || status;
  };

  const getShipmentStats = () => {
    return {
      pending: shipments.filter(shipment => shipment.status === 'pending').length,
      in_transit: shipments.filter(shipment => shipment.status === 'in_transit').length,
      delivered: shipments.filter(shipment => shipment.status === 'delivered').length,
      total: shipments.length
    };
  };

  const stats = getShipmentStats();

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };
    _filters['global'].value = value;
    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const renderHeader = () => {
    return (
      <Box sx={{ 
        display: 'flex', 
        justifyContent: 'space-between', 
        alignItems: 'center', 
        mb: 3,
        flexWrap: 'wrap',
        gap: 2
      }}>
        <Typography variant="h4" sx={{ fontWeight: 600, color: 'text.primary' }}>
          Shipments
        </Typography>
        <CreateShipmentButton
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={handleCreateShipment}
        >
          Create New Shipment
        </CreateShipmentButton>
      </Box>
    );
  };

  const statusTemplate = (rowData) => {
    return (
      <StatusChip
        label={getStatusLabel(rowData.status)}
        status={rowData.status}
        onClick={(e) => handleStatusClick(e, rowData)}
      />
    );
  };

  const actionsTemplate = (rowData) => {
    return (
      <Box>
        <IconButton
          onClick={(e) => {
            e.stopPropagation();
            navigate(`/shipments/${rowData.shipment_id}`);
          }}
        >
          <VisibilityIcon />
        </IconButton>
        <IconButton
          onClick={(e) => {
            e.stopPropagation();
            handleCommentClick(rowData);
          }}
        >
          <CommentIcon />
        </IconButton>
      </Box>
    );
  };

  const mainContent = (
    <>
      <Grid container spacing={3} sx={{ mb: 4 }}>
        <Grid item xs={12} md={3}>
          <StatsCard>
            <Typography variant="h3" sx={{ mb: 1, color: 'text.primary', fontWeight: 600 }}>
              {stats.total}
            </Typography>
            <Typography variant="subtitle1" color="text.secondary">
              Total Shipments
            </Typography>
          </StatsCard>
        </Grid>
        <Grid item xs={12} md={3}>
          <StatsCard>
            <Typography variant="h3" sx={{ mb: 1, color: 'warning.main', fontWeight: 600 }}>
              {stats.pending}
            </Typography>
            <Typography variant="subtitle1" color="text.secondary">
              Pending Shipments
            </Typography>
          </StatsCard>
        </Grid>
        <Grid item xs={12} md={3}>
          <StatsCard>
            <Typography variant="h3" sx={{ mb: 1, color: 'info.main', fontWeight: 600 }}>
              {stats.in_transit}
            </Typography>
            <Typography variant="subtitle1" color="text.secondary">
              In Transit
            </Typography>
          </StatsCard>
        </Grid>
        <Grid item xs={12} md={3}>
          <StatsCard>
            <Typography variant="h3" sx={{ mb: 1, color: 'success.main', fontWeight: 600 }}>
              {stats.delivered}
            </Typography>
            <Typography variant="subtitle1" color="text.secondary">
              Delivered
            </Typography>
          </StatsCard>
        </Grid>
      </Grid>

      <ContentPaper elevation={0}>
        <DataTable
          value={shipments}
          paginator
          rows={10}
          dataKey="shipment_id"
          filters={filters}
          filterDisplay="menu"
          loading={loading}
          globalFilterFields={['shipment_number', 'source_warehouse_name', 'status']}
          header={renderHeader()}
          emptyMessage="No shipments found."
          responsiveLayout="scroll"
          scrollHeight="600px"
          onRowClick={(e) => navigate(`/shipments/${e.data.shipment_id}`)}
          rowHover
          className="shipments-table"
          style={{ 
            borderRadius: '12px',
            overflow: 'hidden',
          }}
        >
          <Column 
            field="shipment_number" 
            header="Shipment #" 
            sortable 
            filter 
            filterPlaceholder="Search by number"
          />
          <Column 
            field="source_warehouse_name" 
            header="From" 
            sortable 
            filter 
            filterPlaceholder="Search by source"
          />
          <Column 
            field="destination_warehouse_name" 
            header="To" 
            sortable 
            filter 
            filterPlaceholder="Search by destination"
          />
          <Column 
            field="status" 
            header="Status" 
            sortable 
            filter 
            filterPlaceholder="Search by status"
            body={statusTemplate}
          />
          <Column 
            field="created_at" 
            header="Created" 
            sortable
            body={(rowData) => format(new Date(rowData.created_at), 'MMM d, yyyy HH:mm')}
          />
          <Column 
            field="items" 
            header="Items" 
            sortable
            body={(rowData) => `${rowData.items?.length || 0} items`}
          />
          <Column 
            header="Actions" 
            body={actionsTemplate} 
            style={{ width: '100px' }}
          />
        </DataTable>
      </ContentPaper>

      <Menu
        anchorEl={statusMenuAnchor}
        open={Boolean(statusMenuAnchor)}
        onClose={handleStatusClose}
        PaperProps={{
          elevation: 3,
          sx: {
            borderRadius: '12px',
            mt: 1,
          },
        }}
      >
        <MenuItem onClick={() => handleStatusChange('in_transit')}>Mark as In Transit</MenuItem>
        <MenuItem onClick={() => handleStatusChange('delivered')}>Mark as Delivered</MenuItem>
        <MenuItem onClick={() => handleStatusChange('cancelled')}>Cancel Shipment</MenuItem>
      </Menu>

      <CommentDialog 
        open={commentDialogOpen} 
        onClose={() => setCommentDialogOpen(false)}
        maxWidth="sm" 
        fullWidth
      >
        <DialogTitle>Comments</DialogTitle>
        <DialogContent>
          <Box mb={2}>
            {comments.map((comment) => (
              <CommentBox key={comment.comment_id}>
                <Typography variant="subtitle2" sx={{ fontWeight: 600 }}>
                  {comment.user_name}
                </Typography>
                <Typography variant="caption" color="text.secondary" display="block" sx={{ mb: 1 }}>
                  {format(new Date(comment.created_at), 'MMM d, yyyy HH:mm')}
                </Typography>
                <Typography>{comment.comment_text}</Typography>
              </CommentBox>
            ))}
          </Box>
          <TextField
            fullWidth
            multiline
            rows={3}
            variant="outlined"
            placeholder="Add a comment..."
            value={newComment}
            onChange={(e) => setNewComment(e.target.value)}
            sx={{
              '& .MuiOutlinedInput-root': {
                borderRadius: '12px',
              },
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setCommentDialogOpen(false)}>Close</Button>
          <Button
            onClick={handleAddComment}
            color="primary"
            variant="contained"
            disabled={!newComment.trim()}
          >
            Add Comment
          </Button>
        </DialogActions>
      </CommentDialog>

      <Dialog
        open={cancellationDialogOpen}
        onClose={() => setCancellationDialogOpen(false)}
        PaperProps={{
          sx: {
            borderRadius: '16px',
          },
        }}
      >
        <DialogTitle>Cancel Shipment</DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            multiline
            rows={3}
            variant="outlined"
            label="Reason for cancellation"
            value={cancellationReason}
            onChange={(e) => setCancellationReason(e.target.value)}
            margin="normal"
            sx={{
              '& .MuiOutlinedInput-root': {
                borderRadius: '12px',
              },
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setCancellationDialogOpen(false)}>Cancel</Button>
          <Button
            onClick={handleCancellationConfirm}
            color="primary"
            variant="contained"
            disabled={!cancellationReason.trim()}
          >
            Confirm Cancellation
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );

  return (
    <FullScreenLayout
      mainPanel={
        <PageContainer>
          {mainContent}
        </PageContainer>
      }
    />
  );
}

export default Shipments;