import React from 'react';
import {
  Grid,
  Typography,
  Box,
  Chip,
  IconButton,
  Divider,
  Paper,
  Button,
} from '@mui/material';
import InventoryIcon from '@mui/icons-material/Inventory';
import AssignmentIcon from '@mui/icons-material/Assignment';
import ProductionQuantityLimitsIcon from '@mui/icons-material/ProductionQuantityLimits';
import WarehouseIcon from '@mui/icons-material/Warehouse';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { AnimatePresence, motion } from 'framer-motion';
import {
  InventoryCard,
  TotalInventory,
  TypeGrid,
  TypeCard,
  InventoryCategory,
} from './StyledComponents';

const InventoryStatsTab = ({
  warehouse,
  items,
  inventoryExpanded,
  setInventoryExpanded,
  getCapacityChartData,
  getStats,
  productionEntries,
  inventoryChecks,
}) => {
  const stats = getStats();
  
  return (
    <Box sx={{ p: 2 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Typography variant="h5" sx={{ fontWeight: 600, color: 'primary.main' }}>
            {warehouse?.name || 'Warehouse'}
          </Typography>
          <Typography variant="subtitle1">Warehouse Statistics</Typography>
        </Box>
        <Chip 
          icon={<WarehouseIcon />} 
          label={`ID: ${warehouse?.id || warehouse?.warehouse_id || 'N/A'}`} 
          variant="outlined" 
          color="primary"
        />
      </Box>
      
      <Paper elevation={0} sx={{ p: { xs: 1.5, sm: 2 }, mb: 4, backgroundColor: 'rgba(0, 0, 0, 0.02)', borderRadius: 2 }}>
        <Typography variant="subtitle1" sx={{ mb: 2, fontWeight: 500 }}>
          Overview
        </Typography>
        <Grid container spacing={{ xs: 2, sm: 3 }}>
          {/* Total Inventory Card */}
          <Grid item xs={12} sm={6} md={4}>
            <InventoryCard
              onClick={() => setInventoryExpanded(!inventoryExpanded)}
              whileHover={{ y: -5 }}
              transition={{ duration: 0.2 }}
              sx={{ height: '100%', minHeight: { xs: 150, sm: 180 } }}
            >
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                <InventoryCategory sx={{ fontSize: { xs: '1rem', sm: '1.1rem' } }}>Total Inventory</InventoryCategory>
                <IconButton size="small">
                  {inventoryExpanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </IconButton>
              </Box>
              <TotalInventory sx={{ fontSize: { xs: '2rem', sm: '2.5rem' } }}>
                <InventoryIcon sx={{ fontSize: { xs: '1.75rem', sm: '2rem' }, color: 'primary.main' }} />
                {stats.totalItems}
              </TotalInventory>
              <Typography variant="body2" color="text.secondary" sx={{ fontSize: { xs: '0.8rem', sm: '0.875rem' } }}>
                {stats.uniqueItems} unique items across {stats.itemTypes.length} categories
              </Typography>
              
              <AnimatePresence>
                {inventoryExpanded && (
                  <motion.div
                    initial={{ opacity: 0, height: 0 }}
                    animate={{ opacity: 1, height: 'auto' }}
                    exit={{ opacity: 0, height: 0 }}
                    transition={{ duration: 0.3 }}
                  >
                    <TypeGrid>
                      {stats.itemTypes.map((type) => (
                        <TypeCard
                          key={type.name}
                          whileHover={{ y: -3 }}
                          transition={{ duration: 0.2 }}
                        >
                          <Typography variant="h6" sx={{ fontWeight: 600 }}>
                            {type.count}
                          </Typography>
                          <Chip
                            label={type.name}
                            size="small"
                            sx={{
                              backgroundColor: 'rgba(25, 118, 210, 0.08)',
                              color: 'primary.main',
                            }}
                          />
                        </TypeCard>
                      ))}
                    </TypeGrid>
                  </motion.div>
                )}
              </AnimatePresence>
            </InventoryCard>
          </Grid>
          
          {/* Warehouse Information Card - Replacing the Capacity Chart */}
          <Grid item xs={12} sm={6} md={4}>
            <InventoryCard sx={{ height: '100%', minHeight: { xs: 150, sm: 180 } }}>
              <InventoryCategory sx={{ fontSize: { xs: '1rem', sm: '1.1rem' } }}>Warehouse Information</InventoryCategory>
              <Box sx={{ mt: 2, display: 'flex', flexDirection: 'column', gap: { xs: 1.5, sm: 2 } }}>
                <Box>
                  <Typography variant="body2" color="text.secondary" sx={{ fontSize: { xs: '0.8rem', sm: '0.875rem' } }}>
                    Name
                  </Typography>
                  <Typography variant="h6" sx={{ fontWeight: 600, color: 'primary.main', fontSize: { xs: '1rem', sm: '1.25rem' } }}>
                    {warehouse?.name || 'Unnamed Warehouse'}
                  </Typography>
                </Box>
                
                <Box>
                  <Typography variant="body2" color="text.secondary" sx={{ fontSize: { xs: '0.8rem', sm: '0.875rem' } }}>
                    Location
                  </Typography>
                  <Typography variant="body1" sx={{ fontSize: { xs: '0.9rem', sm: '1rem' } }}>
                    {warehouse?.location || 'No location specified'}
                  </Typography>
                </Box>
                
                <Box>
                  <Typography variant="body2" color="text.secondary" sx={{ fontSize: { xs: '0.8rem', sm: '0.875rem' } }}>
                    Capacity
                  </Typography>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <Typography variant="body1" sx={{ fontWeight: 600, fontSize: { xs: '0.9rem', sm: '1rem' } }}>
                      {warehouse?.capacity || 'Not set'}
                    </Typography>
                    {warehouse?.capacity && (
                      <Typography variant="body2" color="text.secondary" sx={{ fontSize: { xs: '0.75rem', sm: '0.875rem' } }}>
                        units ({Math.round((stats.totalItems / warehouse.capacity) * 100)}% used)
                      </Typography>
                    )}
                  </Box>
                </Box>
              </Box>
            </InventoryCard>
          </Grid>
          
          {/* Inventory Health Card */}
          <Grid item xs={12} sm={6} md={4}>
            <InventoryCard sx={{ height: '100%', minHeight: { xs: 150, sm: 180 } }}>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 1 }}>
                <InventoryCategory sx={{ fontSize: { xs: '1rem', sm: '1.1rem' } }}>Inventory Health</InventoryCategory>
                {stats.belowReorderLevel > 0 && (
                  <Chip 
                    icon={<ReportProblemIcon sx={{ fontSize: '0.8rem !important' }} />} 
                    label={`${stats.belowReorderLevel} below reorder`} 
                    color="warning" 
                    size="small"
                    sx={{ height: { xs: 20, sm: 24 }, '& .MuiChip-label': { fontSize: { xs: '0.65rem', sm: '0.75rem' } } }}
                  />
                )}
              </Box>
              <Box sx={{ mt: 2 }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                  <Typography variant="body2" sx={{ fontSize: { xs: '0.8rem', sm: '0.875rem' } }}>Items with zero quantity:</Typography>
                  <Typography variant="body2" fontWeight="bold" color={stats.zeroQuantity > 0 ? 'error.main' : 'success.main'} sx={{ fontSize: { xs: '0.8rem', sm: '0.875rem' } }}>
                    {stats.zeroQuantity}
                  </Typography>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                  <Typography variant="body2" sx={{ fontSize: { xs: '0.8rem', sm: '0.875rem' } }}>Items above reorder level:</Typography>
                  <Typography variant="body2" fontWeight="bold" color="success.main" sx={{ fontSize: { xs: '0.8rem', sm: '0.875rem' } }}>
                    {stats.highStock}
                  </Typography>
                </Box>
              </Box>
              <Box sx={{ mt: 3 }}>
                <Typography variant="body2" color="text.secondary" sx={{ fontSize: { xs: '0.75rem', sm: '0.875rem' } }}>
                  Last inventory check: {stats.lastInventoryCheck || 'Never'}
                </Typography>
              </Box>
            </InventoryCard>
          </Grid>
          
          {/* Reserved Inventory Card */}
          <Grid item xs={12} sm={6} md={4}>
            <InventoryCard 
              sx={{ height: '100%', minHeight: { xs: 150, sm: 180 } }}
              onClick={() => {
                // Change to reserved-inventory tab
                localStorage.setItem('warehouseDetailsActiveTab', 'reserved-inventory');
                window.dispatchEvent(new CustomEvent('changeWarehouseTab', { detail: 'reserved-inventory' }));
              }}
            >
              <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', position: 'relative' }}>
                <InventoryCategory sx={{ fontSize: { xs: '1rem', sm: '1.1rem' } }}>Reserved Inventory</InventoryCategory>
                <Chip 
                  label="View Details" 
                  color="info" 
                  size="small"
                  sx={{ height: 24, '& .MuiChip-label': { fontSize: '0.75rem' } }}
                />
              </Box>
              <Box sx={{ mt: 3, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Typography variant="h4" sx={{ fontWeight: 600, color: 'info.main', fontSize: { xs: '1.75rem', sm: '2.125rem' } }}>
                  {stats.totalReserved}
                </Typography>
                <Typography variant="body2" color="text.secondary" sx={{ fontSize: { xs: '0.8rem', sm: '0.875rem' }, mb: 2 }}>
                  Total reserved items
                </Typography>
                
                <Box sx={{ width: '100%', mt: 2 }}>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                    <Typography variant="body2" sx={{ fontSize: { xs: '0.8rem', sm: '0.875rem' } }}>Percentage of total:</Typography>
                    <Typography variant="body2" fontWeight="bold" color="info.main" sx={{ fontSize: { xs: '0.8rem', sm: '0.875rem' } }}>
                      {stats.reservedPercentage}%
                    </Typography>
                  </Box>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                    <Typography variant="body2" sx={{ fontSize: { xs: '0.8rem', sm: '0.875rem' } }}>Items with reservations:</Typography>
                    <Typography variant="body2" fontWeight="bold" color="info.main" sx={{ fontSize: { xs: '0.8rem', sm: '0.875rem' } }}>
                      {stats.itemsWithReserved} of {stats.uniqueItems}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </InventoryCard>
          </Grid>
        </Grid>
      </Paper>

      {/* We've removed the reserved items section since it now has its own dedicated tab */}

      <Paper elevation={0} sx={{ p: { xs: 1.5, sm: 2 }, mb: 2, backgroundColor: 'rgba(0, 0, 0, 0.02)', borderRadius: 2 }}>
        <Typography variant="subtitle1" sx={{ mb: 2, fontWeight: 500 }}>
          Activity
        </Typography>
        <Grid container spacing={{ xs: 2, sm: 3 }}>
          {/* Production Entries Card */}
          <Grid item xs={12} sm={6}>
            <InventoryCard sx={{ height: '100%', minHeight: { xs: 130, sm: 150 } }}>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: { xs: 1, sm: 2 } }}>
                <ProductionQuantityLimitsIcon sx={{ fontSize: { xs: '2rem', sm: '2.5rem' }, color: 'primary.main' }} />
                <Box>
                  <InventoryCategory sx={{ fontSize: { xs: '1rem', sm: '1.1rem' } }}>Production Entries</InventoryCategory>
                  <Typography variant="h4" sx={{ fontWeight: 600, my: 1, fontSize: { xs: '1.75rem', sm: '2.125rem' } }}>
                    {productionEntries.length}
                  </Typography>
                  <Box sx={{ mt: 1 }}>
                    <Chip 
                      label={`${productionEntries.filter(e => e.status === 'pending').length} Pending`} 
                      size="small" 
                      color="warning"
                      sx={{ 
                        mr: 1, 
                        mb: 1, 
                        height: { xs: 20, sm: 24 }, 
                        '& .MuiChip-label': { 
                          fontSize: { xs: '0.65rem', sm: '0.75rem' } 
                        } 
                      }}
                    />
                    <Chip 
                      label={`${productionEntries.filter(e => e.status === 'confirmed').length} Confirmed`} 
                      size="small" 
                      color="success"
                      sx={{ 
                        mr: 1, 
                        mb: 1, 
                        height: { xs: 20, sm: 24 }, 
                        '& .MuiChip-label': { 
                          fontSize: { xs: '0.65rem', sm: '0.75rem' } 
                        } 
                      }}
                    />
                    <Chip 
                      label={`${productionEntries.filter(e => e.status === 'cancelled').length} Cancelled`} 
                      size="small" 
                      color="error"
                      sx={{ 
                        mb: 1, 
                        height: { xs: 20, sm: 24 }, 
                        '& .MuiChip-label': { 
                          fontSize: { xs: '0.65rem', sm: '0.75rem' } 
                        } 
                      }}
                    />
                  </Box>
                </Box>
              </Box>
            </InventoryCard>
          </Grid>

          {/* Inventory Checks Card */}
          <Grid item xs={12} sm={6}>
            <InventoryCard sx={{ height: '100%', minHeight: { xs: 130, sm: 150 } }}>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: { xs: 1, sm: 2 } }}>
                <AssignmentIcon sx={{ fontSize: { xs: '2rem', sm: '2.5rem' }, color: 'primary.main' }} />
                <Box>
                  <InventoryCategory sx={{ fontSize: { xs: '1rem', sm: '1.1rem' } }}>Inventory Checks</InventoryCategory>
                  <Typography variant="h4" sx={{ fontWeight: 600, my: 1, fontSize: { xs: '1.75rem', sm: '2.125rem' } }}>
                    {inventoryChecks.length}
                  </Typography>
                  <Box sx={{ mt: 1 }}>
                    <Chip 
                      label={`${inventoryChecks.filter(c => c.status === 'draft').length} Draft`} 
                      size="small" 
                      color="default"
                      sx={{ 
                        mr: 1, 
                        mb: 1, 
                        height: { xs: 20, sm: 24 }, 
                        '& .MuiChip-label': { 
                          fontSize: { xs: '0.65rem', sm: '0.75rem' } 
                        } 
                      }}
                    />
                    <Chip 
                      label={`${inventoryChecks.filter(c => c.status === 'pending').length} Pending`} 
                      size="small" 
                      color="warning"
                      sx={{ 
                        mr: 1, 
                        mb: 1, 
                        height: { xs: 20, sm: 24 }, 
                        '& .MuiChip-label': { 
                          fontSize: { xs: '0.65rem', sm: '0.75rem' } 
                        } 
                      }}
                    />
                    <Chip 
                      label={`${inventoryChecks.filter(c => c.status === 'confirmed').length} Confirmed`} 
                      size="small" 
                      color="success"
                      sx={{ 
                        mr: 1, 
                        mb: 1, 
                        height: { xs: 20, sm: 24 }, 
                        '& .MuiChip-label': { 
                          fontSize: { xs: '0.65rem', sm: '0.75rem' } 
                        } 
                      }}
                    />
                    <Chip 
                      label={`${inventoryChecks.filter(c => c.status === 'cancelled').length} Cancelled`} 
                      size="small" 
                      color="error"
                      sx={{ 
                        mb: 1, 
                        height: { xs: 20, sm: 24 }, 
                        '& .MuiChip-label': { 
                          fontSize: { xs: '0.65rem', sm: '0.75rem' } 
                        } 
                      }}
                    />
                  </Box>
                </Box>
              </Box>
            </InventoryCard>
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
};

export default InventoryStatsTab; 